import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button } from 'antd';
import { PDFDocument } from 'pdf-lib';
import { useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Logo from '../../assets/icons/voyages/LogoA.svg'

const DownloadInvoice = ({ icon, label, doc }) => {
  const { statementDocument, invoice } = useSelector((state) => state.voyage);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pdfRef = useRef();

  useEffect(() => {
    async function editPDF(existingPdfBytes) {
      const response = await fetch(existingPdfBytes);
      const data = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(data);
      const form = pdfDoc.getForm();

      for (let [key, value] of Object.entries(invoice)) {
        if (key === "oil") {
          let fuelPrefix = "";
          value.forEach((fuel, index) => {
            switch (index) {
              case 0: fuelPrefix = "_i"; break;
              case 1: fuelPrefix = "_ii"; break;
              case 2: fuelPrefix = "_iii"; break;
              case 3: fuelPrefix = "_iV"; break;
              default: break;
            }

            Object.entries(fuel).forEach(([fuelKey, fuelValue]) => {
              const dynamicKey = `${fuelKey}${fuelPrefix}`;
              const oilTextField = form.getTextField(dynamicKey);
              if (oilTextField) {
                if (typeof fuelValue === "number") {
                  fuelValue = fuelValue.toFixed(2);
                }
                oilTextField.setText(fuelValue.toString());
              } else {
                console.error(`Text field with name '${dynamicKey}' not found.`);
              }
            });
          });
        } else {
          try {
            const balanceTextField = form.getTextField(key);
            if (balanceTextField) {
              if (typeof value === "number") {
                value = value.toFixed(2);
              }
              balanceTextField.setText(value.toString());
            } else {
              console.error(`Text field with name '${key}' not found.`);
            }
          } catch {
            console.error(`Text field with name '${key}' not found.`);
          }
        }
      }

      form.flatten();
      const modifiedPdfBytes = await pdfDoc.save();
      const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setDownloadUrl(url);
    }

    editPDF(doc);
  }, [invoice]);

  const handleDownload = async () => {
    const pdf = new jsPDF();
    const data = await html2canvas(pdfRef.current);
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);

    // Extract necessary information
    const { voyage_number, imo_number } = statementDocument;
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();

    // Construct filename
    const formattedDate = `${day}${month}${year}`;
    const filename = `invoice-${imo_number}-${voyage_number}-${formattedDate}.pdf`;

    pdf.save(filename);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>
        <img src={icon} style={{ height: "20px" }} alt="" />
        <span className='card-icon-span'>{label}</span>
      </div>

      <Modal
        title="Download Invoice"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="download" type="primary" onClick={handleDownload}>
            Download
          </Button>,
        ]}
        width={700}
      >
        <div ref={pdfRef}>
          <div className="invoice">
            <div className="div">
              <img className="picture" src={Logo} alt="Azolla" />
              <div className="text-wrapper">AZOLLA PTE. LTD.</div>
              <div className="text-wrapper-2">info@azolla.com | www.azolla.sg</div>
              <p className="azolla-pte-ltd-kim">
                <span className="span">Azolla Pte Ltd<br /></span>
                <span className="text-wrapper-3">
                  1 Kim Seng Promenade<br /># 10/11-12 Great World City<br />237944, Singapore
                </span>
              </p>
              <p className="p">
                TRADE INVOICE for EU Emission Trading Scheme, EU Allowances
              </p>
              <div className="frame"><div className="text-wrapper-4">Buyer</div></div>
              <div className="div-wrapper">
                <p className="text-wrapper-4">Buyer’s Nominated Recipient (if any)</p>
              </div>
              <div className="contract-volume-wrapper">
                <p className="contract-volume">
                  <span className="span">Contract Volume:<br /></span>
                  <span className="text-wrapper-3">Allowances (EUA)<br />(t/CO2)</span>
                </p>
              </div>
              <div className="frame-2"><div className="text-wrapper-5">0.00</div></div>
              <div className="frame-3"><div className="text-wrapper-6">Product</div></div>
              <div className="frame-4"><div className="text-wrapper-5">0.00</div></div>
              <div className="frame-5"><div className="text-wrapper-7">Delivery date</div></div>
              <div className="frame-6"><div className="text-wrapper-8">0.00</div></div>
              <div className="allowance-price-wrapper">
                <div className="allowance-price">Allowance<br />Price</div>
              </div>
              <div className="frame-7"><div className="text-wrapper-9">0.00</div></div>
              <div className="total-purchase-price-wrapper">
                <div className="total-purchase-price">Total Purchase<br />Price</div>
              </div>
              <div className="frame-8"><div className="text-wrapper-9">0.00</div></div>
              <div className="frame-9"><div className="text-wrapper-10">Payment Date</div></div>
              <div className="frame-10"><div className="text-wrapper-11">0.00</div></div>
              <p className="bill-to-company">
                <span className="span">Bill To Company: </span>
                <span className="text-wrapper-3">
                  Lorem Ipsum is<br />simply dummy text of the printing<br />and typesetting industry. Lorem<br />Ipsum has been
                </span>
              </p>
              <div className="navbar">
                <p className="text">
                  <span className="text-wrapper-3">Balance Due:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Invoice Date:&nbsp;&nbsp;</span>
                  <span className="text-wrapper-12">2024-07-17<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Terms:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Vessel Name:&nbsp;&nbsp;</span>
                  <span className="text-wrapper-12">ETOILE<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Currency:<br /></span>
                </p>
                <p className="text"><span className="text-wrapper-3">Category:</span></p>
              </div>
              <p className="invoice-azin">
                <span className="span">Invoice<br /></span>
                <span className="text-wrapper-3">azin/9232060/05B/20240717</span>
              </p>
              <div className="navbar-2">
                <p className="text-2">
                  <span className="span">Trade Date:&nbsp;&nbsp;</span>
                  <span className="text-wrapper-12">2024-07-17<br /></span>
                </p>
                <p className="text-2">
                  <span className="text-wrapper-13">Contract No.<br /></span>
                </p>
                <p className="text-2">
                  <span className="text-wrapper-14">Seller:<br /></span>
                </p>
                <p className="text-2"><span className="text-wrapper-14">Buyer:</span></p>
              </div>
              <div className="flexcontainer">
                <p className="text">
                  <span className="span">Bank Account Details of Seller<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Beneficiary:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Account with:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Swift:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Account:<br /></span>
                </p>
                <p className="text"><span className="text-wrapper-3">Iban:</span></p>
              </div>
              <div className="navbar-3">
                <p className="text">
                  <span className="text-wrapper-3">Company:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Address:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Contact person:<br /></span>
                </p>
                <p className="text"><span className="text-wrapper-3">Invoice Email:</span></p>
              </div>
              <div className="flexcontainer-2">
                <p className="text">
                  <span className="text-wrapper-3">Company:<br /></span>
                </p>
                <p className="text"><span className="text-wrapper-3">Address:</span></p>
              </div>
              <div className="flexcontainer-3">
                <p className="text">
                  <span className="text-wrapper-3">Company No.:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">ETS Account No.:</span>
                </p>
              </div>
              <div className="navbar-4">
                <p className="text">
                  <span className="text-wrapper-3">Company No.:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Vat no:<br /></span>
                </p>
                <p className="text">
                  <span className="text-wrapper-3">Phone:<br /></span>
                </p>
                <p className="text"><span className="text-wrapper-3">Email:</span></p>
              </div>
              <div className="text-wrapper-15">INVOICE</div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DownloadInvoice;
