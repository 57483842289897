import React, { useEffect, useState, useRef } from "react";
import { Select, Spin, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {useLocation } from "react-router";
import { fetchCasperID, fetchShipOwners, fetchVessels, fetchRawData, deselectAllRecordsInSequentialFlow } from "../features/RawData/RawDataSlice";
import { fetchDocSuggestions, fetchVesselData } from '../features/VesselsAnalysis/VesselsAnalysisSlice'
import { fetchCasperRecords } from "../features/RawData/RawDataSlice";
const { Option } = Select;

const euoptions = [
  // { value: "", label: "None" },
  { value: "non eu", label: "NonEU" },
  { value: "eu", label: "EU" },
];

const yearoptions = [{ value: "2024", label: "2024" }];

const RawDataFilters = ({ type, setSelectedCasperIdInFilters, iMONumber, setIMONumber, voyageYear, setVoyageYear, setSelectedCardsWithEssentialData }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {shipOwners, shipOwnersStatus, vesselDetails, vesselStatus, casperID, globalFilters } = useSelector((state) => state.rawData);
  const { docSuggestions, docSuggestionsStatus, vesselAnalysisFilters } = useSelector((state) => state.vesselsAnalysis);
  const [casperIDs, setCasperID] = useState(["Not Selected"]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shipOwner, setShipOwner] = useState(
    location.pathname === "/vessels-analysis" ? vesselAnalysisFilters?.shipowner : globalFilters?.shipOwner || ""
  );
  const [docValue, setDOC] = useState(
    location.pathname === "/vessels-analysis" ? vesselAnalysisFilters?.docValue : globalFilters?.docValue || ""
  );
  const [vesselName, setVesselName] = useState(
     location.pathname === "/vessels-analysis" ? vesselAnalysisFilters?.vesselName : globalFilters?.vesselName || ""
  );
  const [fromIdentifier, setFromIdentifier] = useState(globalFilters?.fromIdentifier || '');
  const [toIdentifier, setToIdentifier] = useState(globalFilters?.toIdentifier || '');
  const [casperId, setCasperId] = useState(globalFilters?.casperID || "");
  const [isResetDone, setResetDone] = useState(false);

  // console.log(shipOwners)
  const hasApplied = useRef(false);
  useEffect(() => {
    if (vesselStatus === "succeeded" && vesselDetails.length > 0 && vesselName === "" && location.pathname !== "/vessels-analysis") {
      const defaultVessel = vesselDetails[0]; // Default to the first vessel
      setVesselName(defaultVessel.vessel_name);
      setIMONumber(defaultVessel.imo_number);
      // dispatch(fetchCasperID(defaultVessel.imo_number));
    }
  }, [dispatch, vesselDetails, vesselStatus]);

  useEffect(() => {
    if (
      shipOwnersStatus === "succeeded" &&
      shipOwners.length > 0 &&
      shipOwner === "" && location.pathname !== "/vessels-analysis"
    ) {
      const defaultShipOwner = shipOwners[0]; // Default to the first ship owner
      setShipOwner(defaultShipOwner);
      dispatch(fetchVessels(defaultShipOwner));
    }else if(shipOwners.length == 0){
      setShipOwner('');
      setVesselName('');
      setIMONumber('');
    }
  }, [dispatch, shipOwnersStatus, shipOwners, shipOwner,type]);

  useEffect(() => {
    if (
      docSuggestionsStatus === "succeeded" &&
      docSuggestions.length > 0 &&
      docValue === "" && location.pathname !== "/vessels-analysis"
    ) {
      const defaultDOC = docSuggestions[0]; 
        setDOC(defaultDOC);
        dispatch(fetchShipOwners(defaultDOC));
    }
  }, [dispatch, docSuggestionsStatus, docSuggestions, docValue]);

  useEffect(() => {
    if (vesselName) {
      const selectedVessel = vesselDetails.find(
        (vessel) => vessel.vessel_name === vesselName
      );
      if (selectedVessel) {
        setIMONumber(selectedVessel.imo_number);
        dispatch(fetchCasperID(selectedVessel.imo_number));
      }
    }
  }, [vesselName, vesselDetails, dispatch]);

  useEffect(() => {
    if (iMONumber) {
      const selectedVessel = vesselDetails.find(
        (vessel) => vessel.imo_number === iMONumber
      );
      if (selectedVessel) {
        setVesselName(selectedVessel.vessel_name);
        // dispatch(fetchCasperID(selectedVessel.imo_number));
      }
    }
  }, [iMONumber, vesselDetails, dispatch]);

  useEffect(() => {
    const isEmptyObject = Object.keys(globalFilters).length === 0;
    // console.log(isEmptyObject);
    if (isEmptyObject) {
      // console.log("IS IT");
      setDOC("");
      resetFilters();
    }
  }, [dispatch]);

  const handleShipOwnerChange = (value) => {
    dispatch(fetchVessels(value));
    setCasperId('');
    setSelectedCasperIdInFilters("");
    setShipOwner(value);
    setVesselName(""); // Reset vessel name when ship owner changes
    setIMONumber(""); // Reset IMO number when ship owner changes
  };

  const handleDOCChange = (value) => {
    dispatch(fetchShipOwners(value));
    setDOC(value);
    setCasperId('');
    setSelectedCasperIdInFilters("");
    setShipOwner('');
    setVesselName(""); // Reset vessel name when ship owner changes
    setIMONumber(""); // Reset IMO number when ship owner changes
  };

  const handleVesselChange = (value) => {
    setVesselName(value);
    setCasperId('');
  };

  const handleYearChange = (value) => {
    setVoyageYear(value);
  };

  const fromIdentifierChange = (value) => {
    setFromIdentifier(value ? value : "");
  };

  const toIdentifierChange = (value) => {
    setToIdentifier(value ? value : "");
  };

  const casperIdChange = (value) => {
    setCasperId(value ? value : "");
  };

  const handleIMONumberChange = (value) => {
    setIMONumber(value);
    setCasperId('');
  };

  const resetFilters = () => {
    if(location && location.pathname === "/vessels-analysis"){
      // alert("Filters reset")
      dispatch(fetchDocSuggestions())
      setDOC('')
      setShipOwner("");
      setVesselName("");
      setIMONumber('')
      dispatch(fetchVesselData({
        page: 1,
        shipowner_name: '',
        doc : '',
        imo_number: '',
        vessel_name: ''
      }));
      return
    }
    localStorage.removeItem("selectedFilters");
    dispatch(fetchDocSuggestions())
    // dispatch(fetchShipOwners());
    setDOC('')
    setShipOwner("");
    setSelectedCasperIdInFilters("");
    setVesselName("");
    setVoyageYear(yearoptions[0].value);
    setFromIdentifier('');
    setToIdentifier('');
    setIMONumber("");
    setCasperId("");
    setIMONumber("");

    setResetDone(true);

    if (type === "Raw Data") {
      setSelectedCardsWithEssentialData({});
      dispatch(deselectAllRecordsInSequentialFlow())
    }
  };

  useEffect(() => {
    handleApply();
  }, [type]);

  useEffect(() => {
    if (isResetDone && type === "Raw Data" && docValue !== '' && shipOwner !== "" && vesselName !== "" && voyageYear === yearoptions[0].value && fromIdentifier === '' && toIdentifier === '' && iMONumber !== "" && casperId === "") {
      dispatch(
        fetchRawData({
          docValue,
          shipOwner,
          vesselName,
          year: voyageYear,
          from: fromIdentifier,
          to: toIdentifier,
          imoNumber: iMONumber,
          requestedData: "VOYAGE_DATA",
          casperID: '',
          uniqueID: "",
          pageNumber: 1,
          start_date: '',
          end_date: ''
        })
      );
      setResetDone(false)
    }
    else if (isResetDone && type === "Casper Analysis" && docValue !== '' && shipOwner !== "" && vesselName !== "" && voyageYear === yearoptions[0].value && fromIdentifier === '' && toIdentifier === '' && iMONumber !== "" && casperId === "") {
      dispatch(
        fetchCasperRecords({
          docValue,
          shipOwner,
          vesselName,
          year: voyageYear,
          from: fromIdentifier,
          to: toIdentifier,
          imoNumber: iMONumber,
          requestedData: "VOYAGE_DATA",
          casperID: '',
          uniqueID: "",
          pageNumber: 1,
          start_date: '',
          end_date: ''
        })
      )
      setResetDone(false)
    }
  }, [isResetDone, docSuggestions, type, docValue, shipOwner, vesselName, voyageYear, fromIdentifier, toIdentifier, iMONumber, casperId]);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  useEffect(() => {
    localStorage.removeItem("selectedFilters");
  }, [type]);

  const handleApply = () => {

    if (type === "Raw Data") {
      setSelectedCasperIdInFilters(casperId);
      const selectedFilters = {
        docValue,
        shipOwner,
        vesselName,
        voyageYear,
        fromIdentifier,
        toIdentifier,
        iMONumber,
        casperId,
      };

      // localStorage.setItem('selectedFilters', JSON.stringify(selectedFilters));
      dispatch(
        fetchRawData({
          docValue,
          shipOwner,
          vesselName,
          year: voyageYear,
          from: fromIdentifier,
          to: toIdentifier,
          imoNumber: iMONumber,
          requestedData: "VOYAGE_DATA",
          casperID: casperId,
          uniqueID: "",
          pageNumber: 1,
          start_date: globalFilters && globalFilters.start_date ? globalFilters.start_date : '',
          end_date: globalFilters && globalFilters.end_date ? globalFilters.end_date : '',
        })
      );
    } else if (type === "Casper Analysis") {
      dispatch(
        fetchCasperRecords({
          docValue,
          shipOwner,
          vesselName,
          year: voyageYear,
          from: fromIdentifier,
          to: toIdentifier,
          imoNumber: iMONumber,
          requestedData: "VOYAGE_DATA",
          casperID: casperId,
          uniqueID: "",
          pageNumber: 1,
          start_date: globalFilters ? globalFilters.start_date : '',
          end_date: globalFilters ? globalFilters.end_date : '',
        })
      );
    } else if (type === "Vessal Analysis") {
      dispatch(fetchVesselData({
        page: 1,
        shipowner_name: shipOwner,
        doc : docValue,
        imo_number: iMONumber,
        vessel_name: vesselName
      }));
    }

    //remove previously selected cards in sequential flow
    // if (type === "Raw Data") {
    //   dispatch(deselectAllRecordsInSequentialFlow())
    //   setSelectedCardsWithEssentialData({});
    // }
  };

  const handleClear = (idenType) => {
    if (idenType === "To") {
      // console.log("HERE")
      toIdentifierChange("")
    } else if (idenType === "Casper") {
      casperIdChange('')
    } else {
      fromIdentifierChange("")
    }
  }

  return (
    <div
      className="raw-data-filters"
      style={{ width: type === "Raw Data" || type === "Vessal Analysis" ? "98%" : "65vw" }}
    >
      {/* <div className="filter-title">Filters</div> */}
      <div className="filter-1-row">

        <div className="filters">
          <div className="filter-title">DOC</div>
          {docSuggestionsStatus === "loading" ? (
            <Spin />
          ) : docSuggestionsStatus === "failed" ? (
            <Alert
              message="Error"
              description="Failed to load DOC."
              type="error"
              showIcon
            />
          ) : (
            <Select
              showSearch
              value={docValue}
              style={{ width: 270 }}
              onChange={handleDOCChange}
              allowClear = {type === "Vessal Analysis" }
            >
              {docSuggestions &&
                docSuggestions.map((doc) => (
                  <Option key={doc} value={doc}>
                    {doc}
                  </Option>
                ))}
            </Select>
          )}
        </div>

        <div className="filters">
          {/* <div className="filter-heading">Ship Owner</div> */}
          <div className="filter-title">Ship Owner</div>
          {shipOwnersStatus === "loading" ? (
            <Spin />
          ) : shipOwnersStatus === "failed" ? (
            <Alert
              message="Error"
              description="Failed to load ship owners."
              type="error"
              showIcon
            />
          ) : (
            <Select
              showSearch
              value={shipOwner}
              style={{ width: 270 }}
              onChange={handleShipOwnerChange}
              allowClear = {type === "Vessal Analysis" }
            >
              {shipOwners &&
                shipOwners.map((owner) => (
                  <Option key={owner} value={owner}>
                    {owner}
                  </Option>
                ))}
            </Select>
          )}
        </div>
        <div className="filters">
          <div className="filter-title">Vessel Name</div>
          {vesselStatus === "loading" ? (
            <Spin />
          ) : vesselStatus === "failed" ? (
            <Alert
              message="Error"
              description="Failed to load vessels."
              type="error"
              showIcon
            />
          ) : (
            <Select
              showSearch
              value={vesselName}
              style={{ width: 270 }}
              onChange={handleVesselChange}
              allowClear = {type === "Vessal Analysis" }
            >
              {vesselDetails &&
                vesselDetails.map((vessel) => (
                  <Option key={vessel.vessel_name} value={vessel.vessel_name}>
                    {vessel.vessel_name}
                  </Option>
                ))}
            </Select>
          )}
        </div>

        {type !== "Vessal Analysis" &&
        <div className="filters">
          <div className="filter-title">Voyage Year</div>
          <Select
            value={voyageYear}
            style={{ width: 270 }}
            onChange={handleYearChange}
          >
            {yearoptions.map((year) => (
              <Option key={year.value} value={year.value}>
                {year.label}
              </Option>
            ))}
          </Select>
        </div>
}
        {type === "Raw Data" && (
          <div className="filters">
            <div className="filter-title">EU Identifier</div>
            <div className="filters-eu">
              <div>
                <span className="filter-heading" style={{ marginRight: '5px' }}>From</span>
                <Select
                  showSearch
                  value={fromIdentifier}
                  style={{ width: 100 }}
                  onChange={fromIdentifierChange}

                  onClear={() => handleClear("From")}
                  allowClear
                >
                  {euoptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </div>
              <div>
                <span className="filter-heading" style={{ marginRight: '5px' }}>To</span>
                <Select
                  showSearch
                  value={toIdentifier}
                  style={{ width: 100 }}
                  onChange={toIdentifierChange}
                  onClear={() => handleClear("To")}
                  allowClear
                >
                  {euoptions.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>

              </div>
            </div>
          </div>
        )}
        <div className="filters">
          <div className="filter-title">IMO Number</div>
          <Select
            showSearch
            value={iMONumber}
            style={{ width: 270 }}
            allowClear = {type === "Vessal Analysis" }
            onChange={handleIMONumberChange}
          >
            {vesselDetails &&
              vesselDetails.map((vessel) => (
                <Option key={vessel.imo_number} value={vessel.imo_number}>
                  {vessel.imo_number}
                </Option>
              ))}
          </Select>
        </div>

        {type !== "Vessal Analysis" &&
        <div className="filters">
          <div className="filter-title">Casper ID</div>
          <Select
            showSearch
            value={casperId}
            style={{ width: 270 }}
            onChange={casperIdChange}
            onClear={() => handleClear("Casper")}
            allowClear
          >
            {casperID.map((id) => (
              <Option key={id} value={id}>
                {id}
              </Option>
            ))}
          </Select>
        </div>
}
      </div>
      <div className="buttons-container">
        <div
          style={{
            fontSize: "1.8vh",
            opacity: "0.7",
            fontStyle: "italic !important",
          }}
        >
        {type !== "Vessal Analysis" &&   "*Kindly select all applicable filters to view the corresponding records."
        }
        </div>
        <div style={{ display: "flex", gap: "1vw" }}>
          <div className="reset-btn" onClick={resetFilters}>
            Reset
          </div>
          <div className="apply-btn" onClick={() => 
      {dispatch(deselectAllRecordsInSequentialFlow());handleApply()}}>
            Apply
          </div>
        </div>
      </div>
    </div>
  )
}

export default RawDataFilters;
