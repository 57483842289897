  import React, { useEffect, useState } from 'react';
  import ApexCharts from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEuaDifferenceChart } from '../../features/Voyage/VoyageSlice';
import { Skeleton } from 'antd';
  const LineChart = () => {

    const dispatch = useDispatch()
    const {euaDifference, selectedVoyage} = useSelector(state=>state.voyage)

    useEffect(()=>{
      if(selectedVoyage!==null)
      {
        dispatch(fetchEuaDifferenceChart(selectedVoyage))
      }
    },[selectedVoyage, dispatch])

    if(euaDifference===null){
      return(
        <Skeleton active/>
      )
    }
    const data = euaDifference;

    // Extract the x-axis labels (dates)
    const dates = data.map((item) => item.date);
  
    // Extract actual, estimated, and difference data
    const actualData = data.map((item) => item.actual);
    const estimatedData = data.map((item) => item.estimated);
    const differenceData = data.map((item) => item.difference);
  

    const options = {
      series: [
        {
          name: 'Actual',
          data: actualData,
          type: 'line'
        },
        {
          name: 'Estimated',
          data: estimatedData,
          type: 'line'
        },
        {
          name: 'Difference',
          data: differenceData,
          type: 'line'
        },
      ],
      chart: {
        type: 'line'
      },
      legend: {
        position: 'bottom',
        offsetY: 10,
        horizontalAlign: 'left', // Adjust the alignment to 'left'
        itemMargin: {
          vertical: 5,
        },
        markers: {
          width: 12,
          height: 12,
        },
        itemStyle: {
          fontSize: '12px',
          fontFamily: 'Aeonik, sans-serif',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: [2, 2, 2],
        dashArray: [0, 0, 5],
      },
      xaxis: {
        type: 'datetime',
        categories: dates
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm'
        }
      },
      colors: ['#06C270', '#FF8585', '#265CFF'],
    };

    

    return (
      <div style={{height:"90%"}}>
        <ApexCharts options={options} series={options.series} type="line" height="100%" />
      </div>
    );
  };

  export default LineChart;