import React, { useEffect, useState } from 'react'
import SequentialFlowList from './SequentialFlowList'
import StepperFirst from './StepperFirst'
import StepperSecond from './StepperSecond'
import StepperThird from './StepperThird'
import StepperHeader from './StepperHeader'
import StepperFooter from './StepperFooter'
import { useDispatch, useSelector } from 'react-redux';
const SequentialRecordsContainer = ({ currentStep, onStepChange, setShowStepperCard, selectedCasperId, setSelectedCasperId, selectedCardsWithEssentialData, setSelectedCardsWithEssentialData }) => {
  
  const { selectedRecordsPerPageUniqueIds } = useSelector((state) => state.rawData);
  
  const [selectedUniqueId, setSelectedUniqueId] = useState( selectedRecordsPerPageUniqueIds[Object.keys(selectedRecordsPerPageUniqueIds)[0]][0])
  const [isNextButtonClicked, setIsNextButtonClicked] = useState(false)

  // console.log(selectedCards)
  let StepperComponent;

  switch (currentStep) {
    case 0:
      StepperComponent = <StepperFirst selectedUniqueId={selectedUniqueId} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />;
      break;
    case 1:
      StepperComponent = <StepperSecond selectedUniqueId={selectedUniqueId} selectedCasperId={selectedCasperId} setSelectedCasperId={setSelectedCasperId} selectedCardsWithEssentialData={selectedCardsWithEssentialData} />;
      break;
    case 2:
      StepperComponent = <StepperThird selectedUniqueId={selectedUniqueId} />;
      break;
    default:
      <></>;
  }

  return (
    <div className='Sequential-Flow-Container'>
      <SequentialFlowList currentStep={currentStep} selectedUniqueId={selectedUniqueId} setSelectedUniqueId={setSelectedUniqueId} selectedCardsWithEssentialData={selectedCardsWithEssentialData} isNextButtonClicked={isNextButtonClicked} setIsNextButtonClicked={setIsNextButtonClicked} />
      <div className='card Sequential-Flow-Steps'>
        <StepperHeader currentStep={currentStep} selectedUniqueId={selectedUniqueId} setSelectedUniqueId={setSelectedUniqueId} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />
        <div className='Sequential-Flow-Card'>
          {StepperComponent}
        </div>
        <StepperFooter onStepChange={onStepChange} currentStep={currentStep} selectedCasperId={selectedCasperId} setSelectedCasperId={setSelectedCasperId} setShowStepperCard={setShowStepperCard} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} isNextButtonClicked={isNextButtonClicked} setIsNextButtonClicked={setIsNextButtonClicked} />
      </div>
    </div>
  );
};

export default SequentialRecordsContainer
