import React from "react";

const FuelConsuptionsComponent = ({ data }) => {
  console.log(data);
  return (
    <div className="voyageData-Card">
      <div className="voyageData-Card-Elements">
        <div className="voyageData-Card-Children" style={{maxHeight:"10vh", overflowY:"scroll" }}>
          {Object.keys(data).map((value, key) => {
            console.log(data[value]);
            if (data[value]?.quantity > 0) {
              return (
                <div className="voyageData-Card-Children-Data" >
                  <div className="voyageData-Card-Children-Data1">
                    <h1>{value}</h1>
                  </div>
                  <div className="voyageData-Card-Children-Data2">
                    <p>
                      {data[value]?.quantity} {data[value]?.unit}
                    </p>
                  </div>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default FuelConsuptionsComponent;
