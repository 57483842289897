import { Divider, Button, message } from 'antd';
import React from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { deselectAllRecordsInSequentialFlow, putCasperId, runCalculations } from '../../../features/RawData/RawDataSlice';
const StepperFooter = ({ onStepChange, currentStep, selectedCasperId, setSelectedCasperId,  setShowStepperCard, selectedCardsWithEssentialData, setSelectedCardsWithEssentialData, isNextButtonClicked, setIsNextButtonClicked }) => {
    const dispatch = useDispatch();
    const { selectedRecordsPerPageUniqueIds} = useSelector((state) => state.rawData);
    

const selectedCards = Object.values(selectedRecordsPerPageUniqueIds).reduce((acc, curr) => acc.concat(curr), []);

    const handleNext = async () => {
        switch (currentStep) {
            case 0:
                const hasFalseValue = Object.values(selectedCardsWithEssentialData).some(entry =>
                    !entry.at_port_consumption
                );

                if (hasFalseValue) {
                    // Show error modal
                    setIsNextButtonClicked(true)
                    message.error("This fields cannot be empty! => Vessel Status, From Port and To Port Details");
                    return;
                }

                onStepChange(1);
                break;
            case 1:

                if (selectedCasperId !== '') {
                    const sortedArray = selectedCards?.sort((a, b) => {
                        const dateA = a.split("_")[1];
                        const dateB = b.split("_")[1];
                        return dateA.localeCompare(dateB);
                      });
                    // Create payload for putCasperId
                    const putCasperIdPayload = {
                        unique_id: sortedArray,
                        casper_id: selectedCasperId
                    };


                    try {
                        // Dispatch putCasperId and wait for it to complete
                        await dispatch(putCasperId(putCasperIdPayload));

                        // After putCasperId is completed, create payload for runCalculations
                        const calculationsPayload = {
                            // imo_number: data.imo_number_id,
                            casper_id: selectedCasperId,
                        };

                        // Dispatch runCalculations
                        await dispatch(runCalculations(calculationsPayload));

                        // Move to the next step
                        onStepChange(2);
                    } catch (error) {
                        // Handle any errors that occurred during dispatches
                        console.error('Error occurred:', error);
                    }
                } else {
                    alert("Select Casper Id");
                }
                break;
            case 2:
                setSelectedCasperId('');
                onStepChange(0);
                setSelectedCardsWithEssentialData({});
                dispatch(deselectAllRecordsInSequentialFlow());
                setShowStepperCard(false)

                break;
            default:
                onStepChange(1);
        }
    };
    const handleBack = () => {
        // console.log(currentStep)
        switch (currentStep) {
            case 0:
                setSelectedCasperId('');
                onStepChange(0);
                break;
            case 1:
                setSelectedCasperId('');
                onStepChange(0);
                break;
            case 2:
                setSelectedCasperId('');
                onStepChange(1);
                break;
            default:
                onStepChange(1);
        }
    };


    return (
        <div className='stepper-footer'>
            <Divider />
            <div className='stepper-footer-buttons'>
                <Button className='stepper-back-button' type="primary" icon={<LeftOutlined />} disabled={currentStep === 0} onClick={handleBack}> Back</Button>
                <Button onClick={handleNext} className='stepper-next-button' type="primary" >{currentStep === 2 ? 'Save & End' : "Save & Next"}<RightOutlined style={{ marginLeft: 8 }} /></Button>
            </div>
        </div>
    );
};

export default StepperFooter;
