import React from 'react'
import VoyageRow from '../voyages/VoyageRow'
import { useEffect } from 'react'
import { fetchVoyages } from '../../features/Voyage/VoyageSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { clearVoyages, searchVoyages } from '../../features/Voyage/VoyageSlice'
import Search from '../../components/Search'
import { DatePicker, Input } from 'antd'
import { useRef } from 'react'
import { selectVoyage } from '../../features/Voyage/VoyageSlice'
import SkeltonTable from '../../components/SkeltonTable'
import { useLocation } from 'react-router-dom';


const Voyage = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const scrollableArea = useRef(null)
  const { voyages, status, page, selectedVoyage } = useSelector((state) => state.voyage);
  const [updatedPage, setUpdatedPage] = useState(page)


  const getVoyagesData = () => {
    if (status !== "loading") {
      dispatch(fetchVoyages(page)).then((result) => {
        if (page === 1 && result.payload) {
          dispatch(selectVoyage(result.payload[0]))
        }
      });
    }
  }

  // useEffect(() => {
  //   dispatch(fetchVoyages(1))
  // }, []);

  useEffect(() => {
    if (voyages.length === 0)
      getVoyagesData()
  }, [updatedPage]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      scrollableArea.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setUpdatedPage(updatedPage + 1)
      getVoyagesData()
    }
  }



  const selectVoyageHanlder = (voyage) => {
    if (voyage.imo_number === selectedVoyage.imo_number) {
      return
    }
    dispatch(selectVoyage(voyage))
  }

  const clearSearchHandler = (setValue) => {
    setValue(null)
    dispatch(clearVoyages())
    dispatch(fetchVoyages(1))
  }

  const searchHandler = (record, setOpen, setValue) => {
    setValue(record.vessel_name)
    const params = {
      vessel_name: record.vessel_name,
      voyage_number: record.voyage_number,
      imo_number: record.imo_number,
      page: 1
    }
    setOpen(false)
    dispatch(searchVoyages(params))
  }

  useEffect(() => {
    dispatch(clearVoyages())
    setUpdatedPage(1)
  }, [location.pathname]);


  return (
    <div className="card voyage-table-card">
      <div className="vessel-card-filter" style={{ padding: "1%", margin: "0" }}>
        <div style={{ width: "50%" }}>
          <Search onClear={clearSearchHandler} onSelect={searchHandler} />
        </div>
      </div>
      <div className='vessel-card-table' onScroll={handleScroll} ref={scrollableArea}>
        {voyages.length === 0 ? <SkeltonTable /> :
          voyages.map((voyage, index) => (
            <VoyageRow key={index} onSelect={() => selectVoyageHanlder(voyage)} voyage={selectedVoyage?.imo_number === voyage.imo_number ? selectedVoyage : voyage} />
          ))}
      </div>
    </div>
  )
}

export default Voyage