import React, { useEffect } from 'react'
import { Descriptions, Table, Modal } from 'antd'
import { useRef } from 'react'
import jsPDF from 'jspdf'
import html2canvas from "html2canvas";
import { ConfigProvider } from 'antd'
import ReportTitle from "../../assets/icons/ReportTitle.svg"
import ReportSymbol from "../../assets/icons/ReportSymbol.svg"
import { useState } from 'react';
import { useSelector } from 'react-redux';

const ReportPdf = ({ icon, label }) => {

    const { statementDocument } = useSelector((state) => state.voyage)

    const list1 = ["voyage_number", "vessel_name", "imo_number", "vessel_type", "summer_dead_weight", "gross_ton"]
    const list2 = ["start_date", "total_fuel_consumption", "end_date", "total_co2_emission", "start_port", "compliance_balance", "end_port", "cii", "total_distance_travelled", "eeoi"]
    const list3 = ["voyage_status", "voyage_tax", "eu_ets", "total_co2_eq_emission", "eua"]
    const style = {
        color: "black",
        fontSize: '13px',
        fontWeight: "400",
        fontStyle: "normal",
        fontFamily: "Noto Sans",
        lineHeight: "110%"
    }

    const [data, setData] = useState([])
    const [items, setItems] = useState([
        {
            key: '1',
            label: 'Voyage No',
            children: '',
        },
        {
            key: '2',
            label: 'Name',
            children: '',
        },
        {
            key: '3',
            label: 'IMO Number',
            children: '',
        },
        {
            key: '4',
            label: 'Type',
            children: '',
        },
        {
            key: '5',
            label: 'Deadweight',
            children: '',
        },
        {
            key: '6',
            label: 'Gross Tonnage',
            children: '',
        },
    ]);
    const [items3, setItems3] = useState([
        {
            key: '1',
            label: 'Voyage Type',
            children: '',
        },
        {
            key: '2',
            label: 'Applicable Tax %',
            children: '',
        },
        {
            key: '3',
            label: 'Total CO₂ Emitted (mt)',
            children: '',
        },
        {
            key: '4',
            label: 'Total CO₂ Equivalent Emitted',
            children: '',
        },
        {
            key: '5',
            label: 'EUA',
            children: '',
        },
    ]);

    const [items2, setItems2] = useState([
        {
            label: 'Start Date',
            children: '',
        },
        {
            label: 'Fuel Consumption (MT)',
            children: '',
        },
        {
            label: 'End Date',
            children: '',
        },
        {
            label: 'Total CO₂ emitted (mt)',
            children: '',
        },
        {
            label: 'From Port',
            children: "",
        },
        {
            label: 'Fuel EU Compliance Balance',
            children: "",
        },
        {
            label: 'To Port',
            children: "",
        },
        {
            label: 'CII* (tonnes CO₂ / tons-nm)',
            children: "",
        },
        {
            label: 'Distance (nm)',
            children: "",
        },
        {
            label: 'EEOI (tonnes CO₂ / tons-nm)',
            children: "",
        },
    ]);

    const columns = [
        {
            title: "Sr No",
            dataIndex: "Fuel No.",
        },
        {
            title: "Fuel",
            dataIndex: "fuel_name",
        },
        {
            title: "Fuel Consumption (MT)",
            dataIndex: "Fuel Consumption (mt)",
        },
        {
            title: "Emission Factor for CO₂",
            dataIndex: "EE for CO₂",
        },
        {
            title: "CO₂ emitted. (mt)",
            dataIndex: "CO₂ emitted. (mt)",
        },
        {
            title: "Emission Factor for CH₄",
            dataIndex: "Ef for CH₄",
        },
        {
            title: "CH₄ emitted (mt)",
            dataIndex: "CH₄ emitted (mt)",
        },
        {
            title: "Emission Factor for N₂O",
            dataIndex: "EF for N₂O",
        },
        {
            title: "N₂O emitted (mt)",
            dataIndex: "N₂O emitted (mt)",
        },
    ];


    const dateFormat = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Array of month names
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Format the date as 'DD-Mon-YYYY'
        const formattedDate = `${day}-${monthNames[monthIndex]}-${year}`;

        return formattedDate;
    };

    useEffect(() => {
        console.log(list2)
        if (statementDocument !== null) {
            setItems(list1.map((value, index) => {
                return { ...items[index], children: statementDocument[value] }
            }))
            setItems2(list2.map((value, index) => {

                if (value === "total_fuel_consumption") {
                    return { ...items2[index], children: `${statementDocument[value].toFixed(2)}` }
                }
                if (value === "total_co2_emission") {
                    return { ...items2[index], children: `${statementDocument[value].toFixed(2)}` }
                }
                if (statementDocument[value] === 0) {
                    return { ...items2[index], children: statementDocument[value] }
                }
                if (value === "start_date" || value === "end_date") {
                    return { ...items2[index], children: `${dateFormat(statementDocument[value])}` }
                }
                return { ...items2[index], children: statementDocument[value] || "" }
            }))
            setItems3(list3.map((value, index) => {
                if (statementDocument[value] === 0) {
                    return { ...items3[index], children: statementDocument[value] }
                }
                return { ...items3[index], children: statementDocument[value] || "" }
            }))
            setData(
                [
                    ...statementDocument.oil.map((value, key) => {
                        return ({
                            "Fuel No.": key + 1,
                            "fuel_name": value.fuel_name.toUpperCase(),
                            "Fuel Consumption (mt)": value.fuel_quantity.toFixed(2),
                            "EE for CO₂": value.ef_for_co2.toFixed(2),
                            "CO₂ emitted. (mt)": value.co2_mt.toFixed(2),
                            "Ef for CH₄": "",
                            "CH₄ emitted (mt)": "",
                            "EF for N₂O": "",
                            "N₂O emitted (mt)": "",
                        })
                    }),
                    {
                        "Fuel No.": "Total",
                        "fuel_name": "",
                        "Fuel Consumption (mt)": statementDocument.total_fuel_consumption.toFixed(2),
                        "EE for CO₂": "",
                        "CO₂ emitted. (mt)": statementDocument.total_co2_emission.toFixed(2),
                        "Ef for CH₄": "",
                        "CH₄ emitted (mt)": "",
                        "EF for N₂O": "",
                        "N₂O emitted (mt)": "",
                    }
                ]
            )
        }
    }, [statementDocument])


    const pdfRef = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const createPDF = async () => {
        const pdf = new jsPDF();
        const data = await html2canvas(document.querySelector("#pdf"));
        const img = data.toDataURL("image/png");
        console.log(data, img)
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        // pdf.save("Emission_Voyage_Statement.pdf");
        // Extract necessary information
        const { voyage_number, imo_number } = statementDocument;
        const currentDate = new Date();
        const day = currentDate.getDate().toString().padStart(2, '0');
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const year = currentDate.getFullYear();

        // Construct filename
        const formattedDate = `${day}${month}${year}`;
        // Construct filename
        const filename = `EVS-${imo_number}-${voyage_number}-${formattedDate}.pdf`;


        pdf.save(filename);
    };



    return (
        <ConfigProvider
            theme={{
                components: {
                    Descriptions: {
                        labelBg: "#F4F7FF",
                    },
                    Table: {
                        headerBg: "#F4F7FF",
                        borderColor: "rgb(0,0,0)",
                        headerBorderRadius: 8,
                    },
                },
                token: {
                    borderRadius: 8,
                }
            }}
        >
            <Modal title="Download Report PDF" style={{ top: 20 }} width={"90%"} open={isModalOpen} onOk={createPDF} onCancel={handleCancel} cancelButtonProps={{ className: 'custom-cancel-btn' }} okButtonProps={{ className: 'custom-OK-btn' }}>
                <div style={{ height: "78vh", overflowY: "scroll" }}>
                    <div id="pdf" ref={pdfRef}>
                        <br />
                        <br />
                        <br />
                        <div style={{ display: "flex", justifyContent: "space-between", margin: "auto", alignItems: "center", width: "90%" }}>
                            <img style={{ height: "100px" }} src={ReportTitle} alt="" />
                            <img style={{ height: "80px" }} src={ReportSymbol} alt="" />
                        </div>
                        <div style={{ display: "flex", marginTop: "50px", flexDirection: "column", gap: "80px", alignItems: "center", }}>
                            <h1>EMISSIONS VOYAGE STATEMENT</h1>
                            <div style={{ width: "90%" }} className="DescriptionTable">
                                <h2>Vessel Particulars</h2>
                                <Descriptions column={6} labelStyle={{ color: "black", fontSize: '18px', fontWeight: "600", fontStyle: "normal" }} contentStyle={{ color: "#000", fontFamily: "Noto Sans", fontSize: "18px", fontStyle: "normal", fontWeight: "400" }} layout="vertical" bordered items={items} />
                            </div>
                            <div style={{ width: "90%" }} className="DescriptionTable">
                                <h2>Period</h2>
                                <Descriptions labelStyle={{ color: "black", fontSize: '18px', fontWeight: "600", fontStyle: "normal" }} contentStyle={{ color: "#000", fontFamily: "Noto Sans", fontSize: "18px", fontStyle: "normal", fontWeight: "400" }}
                                    column={2}
                                    bordered
                                    items={items2} />
                            </div>
                            <div style={{ width: "90%" }} className='ReportTable'>
                                <h2>Monitoring Parameters</h2>
                                <Table
                                    components={{
                                    }}
                                    dataSource={data}
                                    columns={columns}
                                    rowClassName="editable-row"
                                    pagination={false}
                                    bordered
                                    responsive
                                />
                            </div>
                            <div style={{ width: "90%" }} className="DescriptionTable">
                                <Descriptions column={6} layout="vertical" labelStyle={{ color: "black", fontSize: '18px', fontWeight: "600", fontStyle: "normal" }} contentStyle={{ color: "#000", fontFamily: "Noto Sans", fontSize: "18px", fontStyle: "normal", fontWeight: "400" }} bordered items={items3} />
                            </div>
                            <div style={{ width: "90%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div style={{ width: "100%", ...style }}>
                                    <li>* : CII Value is without fuel exclusions</li>
                                    <li>This statement is based on vessel-specific data provided by the client through the web application. Validation checks have been carried out satisfactorily, resulting in the voyage outlined above. </li>
                                    <li>Azolla shall not be liable for any error, omission or deliberate falsification of client-provided data used in the preparation of this statement.</li>
                                    <li>This statement is prepared solely for the purpose specified herein for the named vessel and voyage. It does not release the vessel’s manager from any obligation regarding MARPOL Annex VI and EU regulations. This statement does not substitute the annual verification statements.</li>
                                </div>
                                {/* <span style={{ ...style, fontWeight: "600" }}>
                                    AZOLLA PTE. LTD.
                                </span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            {statementDocument == null ? "" :
                <div onClick={showModal}>
                    <img src={icon} style={{ height: "20px" }} alt="" />
                    <span className='card-icon-span'>{label}</span>
                </div>}
        </ConfigProvider>
    )
}

export default ReportPdf