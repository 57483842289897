import { useState, useEffect } from "react";
import EFDBTable from "../efdb/EFDBTable";
import { efdbData } from "../../features/EFDB/EFDBSlice";
import { useDispatch, useSelector } from "react-redux";

import VoyagePortDetails from "./VoyagePortDetails";
import VoyageTable from "./VoyageTable";
import '../../css/voyageOptimizations.css';


//Ist page:
import FuelTablesPage from "./FuelTablesPage";
import ListOfVessals from "./ListOfVessals";

const VoyageOtpimization = () => {


    //Parent Component:
    const [selectedVessel, setSelectedVessel] = useState(null);
    const [isVesselNew, setIsVesselNew] = useState(false);

    const handleItemClick = (item, isNewVessel) => {
        setSelectedVessel(item);
        setIsVesselNew(isNewVessel);
    };


    return (
        <div className="efdbCard  card voyageOptiContainer">
            {/* <VoyageTable />
            <VoyagePortDetails /> */}



            {/* <FuelTablesPage selectedVessel={selectedVessel} isVesselNew={isVesselNew} /> */}
            <ListOfVessals onItemSelected={handleItemClick} isNewVessel={setIsVesselNew} />
        </div>
    );
};

export default VoyageOtpimization;
