import LoginPage from "./pages/loginpage/LoginPage";
import SignPage from "./pages/signuppage/SignupPage";
import "./css/index.css"
import "./App.css"
import { Routes, Route, Navigate, useLocation } from "react-router";
import Dashboard from "./pages/dashboard/Dashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { restoreLogin } from "./features/Login/UserSlice";
import MainDashboard from "./pages/mainDashboard/MainDashboard";

function App() {

  const dispatch = useDispatch()
  const { login, user } = useSelector((state) => state.user)
  const location = useLocation();
  useEffect(()=>{
    dispatch(restoreLogin())
  },[])

  return (
    <div className="App">
      <Routes>
        <Route path='/*' element={login && user!==null  ?  <Dashboard/> : <MainDashboard />} />
        <Route path='/signup' element={<SignPage />} />
        <Route path='/login' element={login && user!==null ? <><Navigate to="/dashboard" replace/> <Dashboard/></> : <LoginPage/>} />
        <Route path='/' element={login && user!==null ? <Navigate to="/dashboard" replace/> :  <MainDashboard />} />

      </Routes>
      
    </div>
  );
}

export default App;
