import React from "react";
import { Form, Input, Tag, DatePicker, Select } from "antd";
import { ConfigProvider } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import '../../css/freemium.css';
import { postEuaCalculateRequest } from "../../features/Voyage/VoyageSlice";

import { fetchPortList } from "../../features/Voyage/VoyageSlice";
import { fetchFuelList } from "../../features/Voyage/VoyageSlice";
import moment from 'moment';

import FreemiumReport from "./FreemiumReport";

const FreemiumPage = ({ updateData, dataToShow }) => {
    const dispatch = useDispatch()

    //vessel Details:
    const [imoNumber, setImoNumber] = useState();
    const [vesselName, setVesselName] = useState();
    const [vesselType, setVesselType] = useState();
    const [deadWeight, setDeadWeight] = useState();
    const [grossTonnage, setGrossTonnage] = useState();
    const [voyageNumber, setVoyageNumber] = useState();


    //Voyage Details:
    const [voyageType, setVoyageType] = useState();
    const [voyageStartDate, setVoyageStartDate] = useState();
    const [voyageEndDate, setVoyageEndDate] = useState();
    const [chartererVoyageId, setChartererVoyageId] = useState();
    const [initialPort, setInitialPort] = useState();
    const [finalPort, setFinalPort] = useState();
    const [fuelConsumed, setFuelConsumed] = useState();
    const [distSailed, setDistanceSailed] = useState();
    const [cargoCarried, setcargoCarried] = useState();
    const [fuelsSelectedArray, setFuelsSelectedArray] = useState([]);
    const [fuelsQuantitySelectedArray, setFuelsQuantitySelectedArray] = useState([]);
    const [dropDownVisible, setDropDownVisible] = useState(false);
    const [fueldataVisible, setFueldataVisible] = useState(false);
    const [reportGenerate, setReportGenerate] = useState(false);

    const [initialportList, setInitialPortList] = useState()
    const [finalPortList, setFinalPortList] = useState()

    const [fuelListArray, setFuelListArray] = useState(["Hydrogen", "Ammonia", "Diesel/Gas Oil (MGO/MDO)", "Liquefied Natural Gas (LNG)", "Liquefied Petroleum Gas (LPG)", "Methanol", "Ethanol", "Bio-methanol", "Bio-gasoil", "Bio-fuel", "Light Fuel Oil (LFO)", "Heavy Fuel Oil (HFO)"])
    const [vesselTypeArray, setVesselTypeArray] = useState(["BULK CARRIER",
        "GEARED BULK CARRIER",
        "CEMENT CARRIER",
        "LPG CARRIER (REFRI)",
        "LPG CARRIER (PRESS)",
        "OIL TANKER",
        "Tanker",
        "ASPHALT/BITUMEN TANKER",
        "CHEM/OIL PROD TANKER",
        "CHEMICAL TANKER",
        "CONTAINER",
        "General Cargo",
        "SUPPLY VESSEL/TUG BOAT / AHTS",
        "Reefer",
        "Combination Carrier",
        "LNG CARRIER",
        "CAR CARRIER / RO-RO",
        "Ro-Ro Cargo",
        "Ro-Ro Passenger",
        "Ro-Ro High Speed",
        "Cruise"])

    const [data, setData] = useState()
    const { loading, error } = useSelector((state) => state.user)
    const onFinish = () => {

        alert("Done")
    };

    const [isModalVisible, setModalVisible] = useState(false);

    const openModal = () => {
        setModalVisible(true);
    };

    const [searchedInitialPort, setSearchedInitialPort] = useState('');
    const [searchedFinalPort, setSearchedFinalPort] = useState('');


    const voyageTypeArray = ["Between EU", "EU At Berth", "Inbound EU", "Outbound EU"];


    const handlePortSearch = (value, isInitialPort) => {
        if (isInitialPort) {
            setSearchedInitialPort(value);

            dispatch(fetchPortList(value)).then((result) => {
                if (result.payload) {
                    setInitialPortList(result.payload);
                }
            });
        } else {
            setSearchedFinalPort(value);
            dispatch(fetchPortList(value)).then((result) => {
                if (result.payload) {
                    setFinalPortList(result.payload);
                }
            });
        }

    };

    const handleInputChange = (index, quantity) => {
        setFuelsSelectedArray(prevArray => {
            const existingItemIndex = prevArray.findIndex(item => item.fuel_name === fuelListArray[index]);

            if (existingItemIndex !== -1) {
                const updatedArray = [...prevArray];
                updatedArray[existingItemIndex] = {
                    fuel_name: fuelListArray[index],
                    quantity: quantity !== '' ? parseFloat(quantity) : 0,
                };
                return updatedArray;
            } else {
                return [
                    ...prevArray,
                    {
                        fuel_name: fuelListArray[index],
                        quantity: quantity !== '' ? parseFloat(quantity) : 0,
                    }
                ];
            }
        });

    };

    const handleCheckboxChange = (index) => {
        const selectedFuel = fuelListArray[index];

        const existingItemIndex = fuelsQuantitySelectedArray.findIndex(
            item => item.fuel_name === selectedFuel
        );

        if (existingItemIndex !== -1) {
            const updatedArray = fuelsQuantitySelectedArray.filter(
                (item, idx) => idx !== existingItemIndex
            );
            setFuelsQuantitySelectedArray(updatedArray);
        } else {
            setFuelsQuantitySelectedArray(prevArray => [
                ...prevArray,
                {
                    fuel_name: fuelListArray[index],
                    quantity: 0,
                }
            ]);

        }

        // console.log(fuelsQuantitySelectedArray)
    };


    const updateFuelsSelectedArray = () => {
        const updatedSelectedArray = fuelsSelectedArray.filter(selectedItem => {
            return fuelsQuantitySelectedArray.some(
                quantityItem => quantityItem.fuel_name === selectedItem.fuel_name
            );
        });
        setFuelsQuantitySelectedArray(updatedSelectedArray)
        return updatedSelectedArray;
    };
    const getIndex = str => str.indexOf(',');

    const calculateFreemium = () => {

        if (
            !imoNumber || !vesselName || !deadWeight || !voyageType || !initialPort || !finalPort || !distSailed || !cargoCarried || fuelsSelectedArray.length == 0 || !voyageNumber
        ) {
            alert('Please fill in all fields before calculating.');
            return;
        }

        const updatedArray = updateFuelsSelectedArray();


        const parsedDistSailed = parseFloat(distSailed);
        const parsedCargoCarried = parseFloat(cargoCarried);
        const parsedGrossTonnage = parseFloat(grossTonnage);
        const parsedDeadWeight = parseFloat(deadWeight);
        const payloadToSend = {
            imo_number: imoNumber,
            vessel_name: vesselName,
            vessel_type: vesselType,
            dead_weight: parsedDeadWeight,
            gross_tonnage: parsedGrossTonnage,
            voyage_type: voyageType,
            voyage_start_date: voyageStartDate,
            voyage_end_date: voyageEndDate,
            charterer_voyage_id: chartererVoyageId,
            // initial_port: initialPort,
            // final_port: finalPort,
            from_port_info: {
                port_name: initialPort.substring(0, getIndex(initialPort)),
                country: initialPort.substring((getIndex(initialPort) + 2))
            },
            to_port_info: {
                port_name: finalPort.substring(0, getIndex(finalPort)),
                country: finalPort.substring((getIndex(finalPort) + 2))
            },

            voyage_number: voyageNumber,
            // fuel_consumed: fuelConsumed,
            distance_sailed: parsedDistSailed,
            cargo_carried: parsedCargoCarried,
            fuel_list: updatedArray
        };

        setDropDownVisible(false)
        setFueldataVisible(true)

        setReportGenerate(true)
        handlePostRequest(payloadToSend)
        // console.log(payloadToSend.fuel_name_list);
    }

    const handlePostRequest = (finalData) => {
        dispatch(postEuaCalculateRequest(finalData)).then((result) => {
            if (result.payload) {
                setData(result.payload)
                handleDataUpdate(result.payload)
            }
        })
    }

    const handleDataUpdate = (newData) => {
        updateData(newData);
    };

    const handleBackButtonClick = () => {
        resetState();
        handleDataUpdate(null)
        setReportGenerate(false)
        window.location.reload()
    };

    const resetState = () => {
        setImoNumber('');
        setVesselName('');
        setVesselType('');
        setDeadWeight('');
        setGrossTonnage('');
        setVoyageNumber('');
        setVoyageType('');
        setVoyageStartDate('');
        setVoyageEndDate('');
        setChartererVoyageId('');
        setInitialPort('');
        setFinalPort('');
        setFuelConsumed('');
        setDistanceSailed('');
        setcargoCarried('');
        setFuelsSelectedArray([]);
        setFuelsQuantitySelectedArray([]);
        setDropDownVisible(false);
        setData(null);
        setFueldataVisible(false);
    };


    const handleDropDown = async () => {
        // try {
        //     const resultAction = await dispatch(fetchFuelList());
        //     if (fetchFuelList.fulfilled.match(resultAction)) {
        //         const result = resultAction.payload;
        //         if (result) {
        //             const fuelNames = result.map((fuel) => fuel.fuel_name);
        //             setFuelListArray(fuelNames);
        //         }
        //     }
        // } catch (error) {
        //     console.error('Error fetching fuel list:', error);
        // }

        setDropDownVisible(!dropDownVisible);
    };


    const disabledEndDate = (current) => {
        // Disable dates before the selected start date
        return current && current < moment(voyageStartDate).startOf('day');
    };

    const handleStartDateChange = (date, dateString) => {
        // Update the selected start date
        setVoyageStartDate(dateString);
    };
    return (
        <ConfigProvider
            className="FreemiumForm "
            theme={{
                components: {
                    Button: {
                        colorPrimary: "#000",
                        defaultBg: "#000",
                        colorPrimaryHover: "#000"
                    },
                },
            }}
        >
            <div className="progressHeader freemiumHeader" style={{ margin: '18px' }}>
                <div className="progress-header-data">
                    <span className="vessel-data-data" style={{ fontSize: "20px" }}>EUA Estimation</span>
                    {/* <Tag color="#06C270">spot price €80</Tag> */}
                </div>
            </div>
            <Form
                name="normal_login"
                className="freemium-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                size="large"
                layout="vertical"
                style={{
                    width: "100%",
                    height: '80%',
                    overflow: 'auto'
                }}
            >
                <h6>Vessel Details</h6>
                <div className="formContainerElements">

                    <Form.Item
                        name="imoNumber"
                        label="IMO Number"
                        rules={[{ required: true, message: "Please input IMO number!" }, {
                            len: 7,
                            message: "Add a correct IMO number with exactly 7 characters!",
                        },]}
                    >
                        <Input
                            placeholder=""
                            value={imoNumber}
                            onChange={(e) => { setImoNumber(e.target.value) }}

                            maxLength={7}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[0-9]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}

                            disabled={fueldataVisible}
                        />
                    </Form.Item>


                    <Form.Item
                        name="vesselName"
                        label="Vessel Name"
                        rules={[{ required: true, message: "Please input vessel name!" }]}
                    >
                        <Input
                            placeholder=""
                            value={vesselName}
                            onChange={(e) => { setVesselName(e.target.value) }}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[a-zA-Z0-9\s]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            disabled={fueldataVisible}
                        />
                    </Form.Item>
                    <Form.Item
                        name="vesselType"
                        label="Vessel Type"
                        rules={[{ required: true, message: "Please input vessel type!" }]}
                    >
                        <Select
                            onChange={(value) => setVesselType(value)}
                            value={vesselType}
                            disabled={fueldataVisible}
                        >
                            {vesselTypeArray &&
                                vesselTypeArray.map((type, index) => (
                                    <Select.Option key={index} value={type}>
                                        {type}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>


                    <Form.Item
                        name="deadWeight"
                        label="Deadweight"
                        rules={[{ required: true, message: "Please input Deadweight!" }]}
                    >
                        <Input
                            placeholder=""
                            value={deadWeight}
                            onChange={(e) => { setDeadWeight(e.target.value) }}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[0-9.]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            addonAfter="mt"
                            disabled={fueldataVisible}
                        />
                    </Form.Item>
                    <Form.Item
                        name="grossTonnage"
                        label="Gross Tonnage"
                        rules={[{ message: "Please input gross tonnage!" }]}
                    >
                        <Input
                            placeholder=""
                            value={grossTonnage}
                            onChange={(e) => { setGrossTonnage(e.target.value) }}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[0-9.]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            disabled={fueldataVisible}
                        />
                    </Form.Item>

                    <Form.Item
                        name="voyageNumber"
                        label="Voyage Number"
                        rules={[{ required: true, message: "Please input voyage Number!" }]}
                    >
                        <Input
                            placeholder=""
                            value={voyageNumber}
                            onChange={(e) => { setVoyageNumber(e.target.value) }}
                            disabled={fueldataVisible}
                        />
                    </Form.Item>
                </div>

                <h6>Voyage Details</h6>
                <div className="formContainerElements">


                    <Form.Item
                        name="voyageType"
                        label="Voyage Type"
                        rules={[{ required: true, message: "Please input voyage type!" }]}
                    >
                        <Select
                            onChange={(value) => setVoyageType(value)}
                            value={voyageType}
                            disabled={fueldataVisible}
                        >
                            {voyageTypeArray &&
                                voyageTypeArray.map((type, index) => (
                                    <Select.Option key={index} value={type}>
                                        {type}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        name="voyageStartdate"
                        label="Voyage Start Date"
                        rules={[{ required: true, message: "Please input voyage start date!" }]}
                    >

                        <DatePicker onChange={(date, dateString) => setVoyageStartDate(dateString)} disabled={fueldataVisible} />
                    </Form.Item>
                    <Form.Item
                        name="voyageEndDate"
                        label="Voyage End Date"
                        rules={[{ required: true, message: "Please input voyage end date!" }]}
                    >


                        <DatePicker disabledDate={disabledEndDate} onChange={(date, dateString) => setVoyageEndDate(dateString)} disabled={fueldataVisible} />
                    </Form.Item>
                    <Form.Item
                        name="chartererVoyageId"
                        label="Charterer Voyage ID"
                        rules={[{ message: "Please input Charterer Voyage ID!" }]}
                    >
                        <Input
                            placeholder=""
                            value={chartererVoyageId}
                            onChange={(e) => { setChartererVoyageId(e.target.value) }}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[a-zA-Z0-9]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            disabled={fueldataVisible}
                        />
                    </Form.Item>
                    <Form.Item
                        name="initialPort"
                        label="Initial Port"
                        rules={[{ required: true, message: "Please input initial port!" }]}
                    >
                        <Select
                            showSearch
                            onSearch={(value) => handlePortSearch(value, true)}
                            onChange={(value) => setInitialPort(value)}
                            value={initialPort}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[a-zA-Z ]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            disabled={fueldataVisible}
                        >
                            {initialportList &&
                                initialportList.map((port, index) => (
                                    <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                        {port.port_name}, {port.country}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="finalPort"
                        label="Final Port"
                        rules={[{ required: true, message: "Please input final port!" }]}
                    >


                        <Select
                            showSearch
                            onSearch={(value) => handlePortSearch(value, false)}
                            onChange={(value) => setFinalPort(value)}
                            value={finalPort}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[a-zA-Z ]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            disabled={fueldataVisible}
                        >
                            {finalPortList &&
                                finalPortList.map((port, index) => (
                                    <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                        {port.port_name}, {port.country}
                                    </Select.Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="fuelConsumed"
                        label="Fuel Consumed"
                        rules={[{ required: true, message: "Please input fuel consumed!" }]}
                    // placeholder="55"
                    >
                        <Input
                            placeholder={fuelsQuantitySelectedArray.length}
                            value={fuelsQuantitySelectedArray.length}
                            onClick={handleDropDown}
                            readOnly
                            disabled={fueldataVisible}
                        />

                    </Form.Item>
                    <Form.Item
                        name="distanceSailed"
                        label="Distance Sailed"
                        rules={[{ required: true, message: "Please input distance sailed!" }]}
                    >
                        <Input
                            placeholder=""
                            value={distSailed}
                            onChange={(e) => { setDistanceSailed(e.target.value) }}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[0-9.]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            addonAfter="nm"
                            disabled={fueldataVisible}
                        />
                    </Form.Item>
                    <Form.Item
                        name="cargoCarried"
                        label="Cargo Weight"
                        rules={[{ required: true, message: "Please input cargo carried!" }]}
                    >
                        <Input
                            placeholder=""
                            value={cargoCarried}
                            onChange={(e) => { setcargoCarried(e.target.value) }}
                            onKeyDown={(e) => {
                                const allowedKeys = /^[0-9.]$/;
                                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                    e.preventDefault();
                                }
                            }}
                            disabled={fueldataVisible}
                            addonAfter="mt"
                        />
                    </Form.Item>
                </div>


                {dropDownVisible && (
                    <div className="dropDownFuel">
                        {fuelListArray.map((fuel, index) => (
                            index % 3 === 0 && (
                                <div key={`fuel_group_${index / 3}`} className="fuelListItems">
                                    {fuelListArray.slice(index, index + 3).map((item, idx) => {
                                        const isChecked = fuelsQuantitySelectedArray.some(selected => selected.fuel_name === item);

                                        return (
                                            <div key={`fuel_${index + idx}`} className="fuelListItem">
                                                <div>
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox_${index}`}
                                                        onChange={(e) => handleCheckboxChange(index + idx)}
                                                    />
                                                    <label htmlFor={`checkbox_${index}`}>{item}</label>
                                                </div>
                                                <input
                                                    className="inputField"
                                                    type="text"
                                                    placeholder="00.000"
                                                    onChange={(e) => handleInputChange(index + idx, e.target.value)}
                                                    onKeyDown={(e) => {
                                                        const allowedKeys = /^[0-9.]$/;
                                                        if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    disabled={!isChecked}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )
                        ))}
                    </div>
                )}


                {fueldataVisible && (
                    <div className="dropDownFuel" style={{ backgroundColor: '#F4F7FF' }}>
                        <p style={{ padding: '20px 0 0 20px' }}>Fuel Summary</p>
                        {fuelListArray.map((fuel, index) => (
                            index % 3 === 0 && (
                                <div className="fuelListItems">
                                    {fuelsQuantitySelectedArray.slice(index, index + 3).map((fuel, index) => (
                                        <div key={`fuel_${index}`} className="fuelListItem">
                                            <div>
                                                <label htmlFor={`checkbox_${index}`}>{fuel.fuel_name}</label>
                                            </div>
                                            <input
                                                style={{ backgroundColor: '#F4F7FF' }}
                                                className="inputField"
                                                type="text"
                                                value={fuel.quantity}
                                                readOnly
                                            />
                                        </div>
                                    ))}
                                </div>
                            )
                        ))}
                    </div>
                )}
            </Form>

            <div className="bottomHiDiv">
                {reportGenerate ? <button className="backButton" onClick={handleBackButtonClick}>Back</button> : ""}
                {reportGenerate ? <button className="continueButton" onClick={openModal}>Generate Report</button> : <button className="continueButton" onClick={calculateFreemium}>Calculate</button>}
            </div>

            <FreemiumReport isModalVisible={isModalVisible} setModalVisible={setModalVisible} dataToShow={dataToShow} />
        </ConfigProvider>


    );
};

export default FreemiumPage;
