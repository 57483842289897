import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const fetchRawData = createAsyncThunk(
  'rawData/getRawData',
  async (params) => {

    // console.log(params)
    const param = {
      "ship_owner": params.shipOwner,
      "vessel_name": params.vesselName,
      "year": params.year,
      "from": params.from,
      "to": params.to,
      "imo_number": params.imoNumber,
      "content": params.requestedData,
      "casperID": params.casperID,
      "uniqueID": params.uniqueID,
      "docValue": params.docValue,
      "pageNumber": params.pageNumber,
      "start_date": params.start_date,
      'end_date': params.end_date
    }

    const request = await makeRequest("get", `api/ems/get_raw_data/?imo_number=${param.imo_number}&requested_data=${param.content}&year=${param.year}&from_port_identifier=${param.from.toUpperCase()}&to_port_identifier=${param.to.toUpperCase()}&casper_id=${param.casperID}&unique_id=${param.uniqueID}&requested_page=${param.pageNumber}&start_date=${param.start_date}&end_date=${param.end_date}`)
    const response = await request.data
    return response
  }
)

export const fetchCasperRecords = createAsyncThunk(
  'casperAnalysis/fetchCasperRecords',
  async (params) => {

    const param = {
      "casper_id": params.casperID,
      "imo_number": params.imoNumber,
      "year": params.year,
      "pageNumber": params.pageNumber,
    }
    const request = await makeRequest("get", `api/ems/voyages/get_by_casper_id/?imo_number=${param.imo_number}&year=${param.year}&casper_id=${param.casper_id}&requested_page=${param.pageNumber}`, param)
    const response = await request.data

    return response
  }
);

export const patchFuelInfoByUniqueID = createAsyncThunk(
  'rawData/patchFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("patch", `api/ems/patch_fuel_info_by_unique_id/?unique_id=${params.uniqueID}&imo_number=${params.imo_number}&casper_id=${params.casper_id}`, params.payload)
    const response = await request.data
    return response
  }
)

export const postFuelInfoApplyToAll = createAsyncThunk(
  'rawData/postFuelInfoToAll',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("post", `api/ems/post_fuel_info_apply_to_all/?imo_number=${params.imo_number}&casper_id=${params.casper_id}`, params)
    const response = await request.data
    return response
  }
)
export const fetchFuelInfoByUniqueID = createAsyncThunk(
  'rawData/getFuelData',
  async (params) => {

    // console.log(params)
    const request = await makeRequest("get", `/api/ems/get_fuel_info_by_unique_id/?unique_id=${params}`)
    const response = await request.data.data
    return response
  }
)

export const fetchShipOwners = createAsyncThunk(
  'rawData/fetchShipOwners',
  async (param) => {
    const request = await makeRequest("get", `/api/ems/fetch_shipowner_suggestions/?doc=${param}`)
    const response = await request.data.data
    return response
  }
)

export const fetchVessels = createAsyncThunk(
  'rawData/fetchVessels',
  async (shipowner_name) => {
    const request = await makeRequest("get", `api/ems/fetch_vessel_suggestions/?shipowner_name=${shipowner_name}`)
    const response = await request.data.data;
    return response;
  }
)

export const fetchCasperID = createAsyncThunk(
  'rawData/fetchCasperID',
  async (imo_number) => {
    const request = await makeRequest("get", `api/ems/fetch_casperID_suggestions/?imo_number=${imo_number}`)
    const response = await request.data.data;
    return response;
  }
)

export const fetchAuditDates = createAsyncThunk(
  'rawData/fetchAuditDates',
  async (params) => {
    const endpoint = params.casperId
      ? `api/ems/fetch_audit_dates/?casper_id=${params.casperId}`
      : `api/ems/fetch_audit_dates/?unique_id=${params.uniqueId}`;

    const request = await makeRequest('get', endpoint);
    const response = await request.data;
    return response;
  }
);

export const getCasperIds = createAsyncThunk(
  'rawData/getcasperIds',
  async (params) => {
    const request = await makeRequest("get", `api/ems/get_casperid_lookup/?keyword=${params.imo_number}&unique_id=${params.unique_id}&imo_number=${params.imo_number}`)
    const response = await request.data;
    return response;
  }
)

export const putCasperId = createAsyncThunk(
  'rawData/putCasperId',
  async (params) => {
    const request = await makeRequest("put", `api/ems/put_casperid/`, params)
    const response = await request.data;
    return response;
  }
)

export const patchCasperId = createAsyncThunk(
  'rawData/patchCasperId',
  async (params) => {
    const request = await makeRequest("patch", `api/ems/patch_casperid/`, params)
    const response = await request.data;
    return response;
  }
)

export const runCalculations = createAsyncThunk(
  'rawData/runCalculation',
  async (params) => {
    const formData = new FormData();
    formData.append("casper_id", params.casper_id);
    // console.log(params)
    const request = await makeRequest("post", `api/ems/run_calculation/`, formData)
    const response = await request.data;
    return response;
  }
)

export const updateVoyageInfo = createAsyncThunk(
  'rawData/updateVoyageInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("post", `api/ems/update_voyage_info/`, params)
    const response = await request.data;
    return response;
  }
)

export const getCalculationsResults = createAsyncThunk(
  'rawData/getResults',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/casper-id/get_agg_results/?casper_id=${params}`)
    const response = await request.data;
    return response;
  }
)

export const getPortNames = createAsyncThunk(
  'rawData/getPortNames',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/ports/get_port_code_suggestions/?keyword=${params}`)
    const response = await request.data;
    return response;
  }
)

export const getAuditDetails = createAsyncThunk(
  'rawData/getAuditDetails',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/get_audit/${params.id}/?req_type=${params.req_type}`)
    const response = await request.data;
    return response;
  }
)

export const postBlandedFuelInfo = createAsyncThunk(
  'rawData/postBlandedFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("post", `api/ems/post_blended_fuel_info/`, params)
    const response = await request.data;
    return response;
  }
)

export const getBlandedFuelInfo = createAsyncThunk(
  'rawData/getBlandedFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/get_blend_info/?fuel_metadata_id=${params}`)
    const response = await request.data;
    return response;
  }
)

export const patchBlandedFuelInfo = createAsyncThunk(
  'rawData/patchBlandedFuelInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("patch", `api/ems/update_blend_info/`, params)
    const response = await request.data;
    return response;
  }
)


export const postIceClassInfo = createAsyncThunk(
  'rawData/postIceClassInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("post", `api/ems/create_ice_class_consumption/`, params)
    const response = await request.data;
    return response;
  }
)

export const getIceClassInfo = createAsyncThunk(
  'rawData/getIceClassInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("get", `api/ems/get_ice_class_info/?casper_id=${params}`)
    const response = await request.data;
    return response;
  }
)

export const patchIceClassInfo = createAsyncThunk(
  'rawData/patchIceClassInfo',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("patch", `api/ems/update_ice_class_consumption/`, params)
    const response = await request.data;
    return response;
  }
)


export const lockCasperId = createAsyncThunk(
  'rawData/lockCasperId',
  async (params) => {
    // console.log(params)
    const request = await makeRequest("put", `api/ems/put_voyageinfo_lock/?casper_id=${params.casper_id}&imo_number=${params.imo_number}`)
    const response = await request.data;
    return response;
  }
)


const rawDataSlice = createSlice({
  name: "rawData",
  initialState: {
    status: 'idle',
    globalFilters: {},
    rawData: [],
    cardDataToShow: {},
    cardDataTypeIndividual: {},
    fuelDataByUniqueId: [],
    count: null,
    page: 1,
    shipOwners: [],
    shipOwnersStatus: '',
    vesselDetails: [],
    vesselStatus: '',
    casperID: [],
    casperIDStatus: '',
    cardUniqueIDDetails: [],
    dataType: 'VOYAGE_DATA',
    auditDates: [],
    auditDateStatus: '',
    casperIdStatus: '',
    casperIdData: {},
    calculationsStatus: '',
    calculationsData: {},
    conflictingStatus: '',
    conflictingData: {},
    conflictingPayload: {},
    calculationsResult: {},
    portSuggestions: [],
    auditDetails: [],
    pastRecords: false,
    blendedFuelsData: {},
    blendedFuelsGetData: [],
    blendedFuelsPatchData: {},
    fetchCasperRecordsStatus: 'idle',
    casperRecords: [],
    rawDataPagesCount: 1,
    casperAnalysisPagesCount: 1,
    iceClassInfo: [],
    iceClassInfoStatus: true,
    rawDataResponseStatus: 'idle',
    //Selected Records All Data
    selectedRecordsForSequentialFlow: {},
    isAllRecordsSelected: {},
    selectedRecordsPerPageWithData: {},
    selectedRecordsPerPageUniqueIds: {},
    lockStatus : false, 
    updateVoyageStatus: 'idle'
  },
  reducers: {
    resetRawDataState: (state) => {
      state.page = 1;
      state.rawData = [];
    },
    togglePastRecords: (state) => {
      state.pastRecords = !state.pastRecords;
    },
    
    //Set selected data in {uniqueid: data } format (Call if data in selectedRecordsForSequentialFlow is empty)
    setSelectedRecordsForSequentialFlow: (state, action) => { 
      // console.log("In set")
      const pageNumber = state.globalFilters.pageNumber;

    state.selectedRecordsPerPageWithData = {
      ...state.selectedRecordsPerPageWithData, 
      [pageNumber]: action.payload 
    };

    const uniqueIds = Object.keys(action.payload);

    state.selectedRecordsPerPageUniqueIds = {
       ...state.selectedRecordsPerPageUniqueIds, // Keep existing data for other pages
      [pageNumber]: uniqueIds // Set the unique IDs for the current page
    };
      state.selectedRecordsForSequentialFlow = action.payload;


      // console.log(state.selectedRecordsPerPageUniqueIds)
      // console.log(state.selectedRecordsPerPageWithData)
      // console.log(state.selectedRecordsForSequentialFlow)
    },
            
    //Call if data in selectedRecordsForSequentialFlow is present , specifically add new data
    updateSelectedRecordsForSequentialFlow: (state, action) => {
      // console.log("In add");
    
      const pageNumber = state.globalFilters.pageNumber;
    
      // Update selectedRecordsPerPageWithData without duplicates
      state.selectedRecordsPerPageWithData = {
        ...state.selectedRecordsPerPageWithData,
        [pageNumber]: {
          ...state.selectedRecordsPerPageWithData[pageNumber],
          ...action.payload,
        },
      };
    
      const uniqueIds = Object.keys(action.payload);
    
      // Get the existing unique IDs for the current page
      const existingUniqueIds = state.selectedRecordsPerPageUniqueIds[pageNumber] || [];
    
      // Combine existing unique IDs with new unique IDs and filter out duplicates
      const allUniqueIds = Array.from(new Set([...existingUniqueIds, ...uniqueIds]));
    
      // Update selectedRecordsPerPageUniqueIds with unique IDs
      state.selectedRecordsPerPageUniqueIds = {
        ...state.selectedRecordsPerPageUniqueIds,
        [pageNumber]: allUniqueIds,
      };

      state.selectedRecordsForSequentialFlow = Object.values(state.selectedRecordsPerPageWithData).reduce((accumulator, current) => {
        return {
            ...accumulator,
            ...current
        };
    }, {});
    
      // console.log(state.selectedRecordsPerPageUniqueIds);
      // console.log(state.selectedRecordsPerPageWithData);
      // console.log(state.selectedRecordsForSequentialFlow);
    },
    
            
    //Deselect i.e. remove specific data from selectedRecordsForSequentialFlow (if in main raw Data)
    deselectSelectedRecordsForSequentialFlow: (state, action) => {
      const uniqueIDToRemove = action.payload; // The unique ID to remove
      const pageNumber = state.globalFilters.pageNumber; // Current page number
  
      // 1. Remove from selectedRecordsForSequentialFlow immutably
      const newSelectedRecords = { ...state.selectedRecordsForSequentialFlow };
      delete newSelectedRecords[uniqueIDToRemove];
  
      // 2. Remove from selectedRecordsPerPageWithData for the current page immutably
      if (state.selectedRecordsPerPageWithData[pageNumber]) {
          const newPageData = { ...state.selectedRecordsPerPageWithData[pageNumber] };
          delete newPageData[uniqueIDToRemove];
          
          // Check if the new page data is empty, if yes, remove the page number entry
          if (Object.keys(newPageData).length === 0) {
              const { [pageNumber]: removed, ...remainingPages } = state.selectedRecordsPerPageWithData;
              state.selectedRecordsPerPageWithData = remainingPages;
          } else {
              state.selectedRecordsPerPageWithData = {
                  ...state.selectedRecordsPerPageWithData,
                  [pageNumber]: newPageData,
              };
          }
      }
  
      // 3. Remove from selectedRecordsPerPageUniqueIds for the current page immutably
      if (state.selectedRecordsPerPageUniqueIds[pageNumber]) {
          const newUniqueIds = state.selectedRecordsPerPageUniqueIds[pageNumber].filter(id => id !== uniqueIDToRemove);
  
          // Check if the new unique IDs array is empty, if yes, remove the page number entry
          if (newUniqueIds.length === 0) {
              const { [pageNumber]: removed, ...remainingUniqueIds } = state.selectedRecordsPerPageUniqueIds;
              state.selectedRecordsPerPageUniqueIds = remainingUniqueIds;
          } else {
              state.selectedRecordsPerPageUniqueIds = {
                  ...state.selectedRecordsPerPageUniqueIds,
                  [pageNumber]: newUniqueIds,
              };
          }
      }

      
      state.selectedRecordsForSequentialFlow = Object.values(state.selectedRecordsPerPageWithData).reduce((accumulator, current) => {
        return {
            ...accumulator,
            ...current
        };
    }, {});
  
      // console.log(state.selectedRecordsPerPageUniqueIds);
      // console.log(state.selectedRecordsPerPageWithData);
      // console.log(state.selectedRecordsForSequentialFlow);
  },
    //Remove from sequential flow
    deselectFromSequentialFlow: (state, action) => {
      const uniqueIDToRemove = action.payload; // The unique ID to remove
  
      // 1. Remove from selectedRecordsForSequentialFlow immutably
      const newSelectedRecords = { ...state.selectedRecordsForSequentialFlow };
      delete newSelectedRecords[uniqueIDToRemove];
  
      // Update selectedRecordsForSequentialFlow with remaining records
      state.selectedRecordsForSequentialFlow = newSelectedRecords;
  
      // 2. Remove from selectedRecordsPerPageWithData across all pages immutably
      const updatedPageData = Object.keys(state.selectedRecordsPerPageWithData).reduce((accumulator, page) => {
          const newPageData = { ...state.selectedRecordsPerPageWithData[page] };
          delete newPageData[uniqueIDToRemove];
  
          // Only keep non-empty page data
          if (Object.keys(newPageData).length > 0) {
              accumulator[page] = newPageData;
          }
  
          return accumulator;
      }, {});
  
      state.selectedRecordsPerPageWithData = updatedPageData;
  
      // 3. Remove from selectedRecordsPerPageUniqueIds across all pages immutably
      const updatedUniqueIds = Object.keys(state.selectedRecordsPerPageUniqueIds).reduce((accumulator, page) => {
          const newUniqueIds = state.selectedRecordsPerPageUniqueIds[page].filter(id => id !== uniqueIDToRemove);
  
          // Only keep non-empty unique ID arrays
          if (newUniqueIds.length > 0) {
              accumulator[page] = newUniqueIds;
          }
  
          return accumulator;
      }, {});
  
      state.selectedRecordsPerPageUniqueIds = updatedUniqueIds;
  
      // Update selectedRecordsForSequentialFlow based on remaining data
      state.selectedRecordsForSequentialFlow = Object.values(state.selectedRecordsPerPageWithData).reduce((accumulator, current) => {
          return {
              ...accumulator,
              ...current
          };
      }, {});
  
      // console.log(state.selectedRecordsPerPageUniqueIds);
      // console.log(state.selectedRecordsPerPageWithData);
      // console.log(state.selectedRecordsForSequentialFlow);
  },
  
    
  removeObjectFromSelectedRecords: (state, action) => {
    const pageNumber = state.globalFilters.pageNumber;
    
    delete state.selectedRecordsPerPageWithData[pageNumber];
    delete state.selectedRecordsPerPageUniqueIds[pageNumber];

    
    state.selectedRecordsForSequentialFlow = Object.values(state.selectedRecordsPerPageWithData).reduce((accumulator, current) => {
      return {
          ...accumulator,
          ...current
      };
  }, {});
  
    // console.log(state.selectedRecordsPerPageWithData);
    // console.log(state.selectedRecordsPerPageUniqueIds);
    // console.log(state.selectedRecordsForSequentialFlow);
    
  },
    
  newDataInSequentialFlow: (state, action) => {
// console.log(action.payload)
    const uniqueIds = Object.keys(action.payload);
    const pageNumber = state.globalFilters.pageNumber;
    
    uniqueIds.forEach((id) => {
      state.selectedRecordsForSequentialFlow = {
        ...state.selectedRecordsForSequentialFlow,
        [id]: state.rawData[id],
      };

      state.selectedRecordsPerPageWithData = {
        ...state.selectedRecordsPerPageWithData,
        [pageNumber]: {
          ...state.selectedRecordsPerPageWithData[pageNumber],
          [id]: state.rawData[id], // Spread the new data into the existing records for this page
        },
      };
    });

    // console.log(state.selectedRecordsPerPageWithData);
    // console.log(state.selectedRecordsPerPageUniqueIds);
    // console.log(state.selectedRecordsForSequentialFlow);
    
  },
  
    //Deselect all data from selectedRecordsForSequentialFlow
    deselectAllRecordsInSequentialFlow: (state, action) => {
      state.selectedRecordsForSequentialFlow = {};
      state.selectedRecordsPerPageWithData = {};
      state.selectedRecordsPerPageUniqueIds = {};

      
      // console.log(state.selectedRecordsPerPageUniqueIds);
      // console.log(state.selectedRecordsPerPageWithData);
      // console.log(state.selectedRecordsForSequentialFlow);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRawData.pending, (state, action) => {
        // console.log(action.meta.arg.uniqueID)
        if (action.meta.arg.uniqueID === '') {
          state.rawDataResponseStatus = 'loading';
        }
      })
      .addCase(fetchRawData.fulfilled, (state, action) => {
        state.rawDataResponseStatus = 'succeeded';

        if (action.meta.arg.requestedData === "VOYAGE_DATA" && action.meta.arg.uniqueID === "") {
          state.rawData = action.payload.data.reduce((acc, item) => {
            acc[item.unique_id] = [item];
            return acc;
          }, {});
          state.rawDataPagesCount = action.payload.total_pages
        }
        // console.log(state.rawData)

        state.dataType = action.meta.arg
        state.page = action.meta.arg.pageNumber
        state.globalFilters = action.meta.arg
        if (action.meta.arg.requestedData === "VOYAGE_DATA" && action.meta.arg.uniqueID === "") {
          state.cardUniqueIDDetails = action.payload.data;

          // Populate cardDataToShow with unique_id as keys
          state.cardDataToShow = action.payload.data.reduce((acc, item) => {
            acc[item.unique_id] = [item];
            return acc;
          }, {});
          // Populate  cardDataTypeIndividual with unique_id as keys
          state.cardDataTypeIndividual = action.payload.data.reduce((acc, item) => {
            acc[item.unique_id] = action.meta.arg;
            return acc;
          }, {});
        } else if (action.meta.arg.requestedData === "VOYAGE_DATA" && action.meta.arg.uniqueID !== "") {
          state.cardUniqueIDDetails = state.cardUniqueIDDetails.map(item =>
            item.unique_id === action.meta.arg.uniqueID ? action.payload?.data[0] : item
          );

          // console.log(state.cardUniqueIDDetails);
        }

        if (action.meta.arg.uniqueID !== "") {
          const updatedCardDataToShow = {
            ...state.cardDataToShow,
            [action.meta.arg.uniqueID]: action.payload.data
          };
          const updatedDataTypeToShow = {
            ...state.cardDataTypeIndividual,
            [action.meta.arg.uniqueID]: action.meta.arg
          };
          if(action.meta.arg.requestedData === "VOYAGE_DATA"){
           state.rawData = updatedCardDataToShow
          }
          state.cardDataToShow = updatedCardDataToShow;
          state.cardDataTypeIndividual = updatedDataTypeToShow;
        }
        state.page++
      })
      .addCase(fetchRawData.rejected, (state) => {
        state.rawDataResponseStatus = 'failed';
        state.rawData = []
      })
      .addCase(fetchFuelInfoByUniqueID.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchFuelInfoByUniqueID.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.fuelDataByUniqueId = action.payload
      })
      .addCase(fetchFuelInfoByUniqueID.rejected, (state) => {
        state.status = 'failed';
        state.fuelDataByUniqueId = []
      })
      .addCase('rawData/resetRawDataState', (state) => {
        // Reset page to 1 and clear notification array
        state.page = 1;
        state.rawData = [];
      })
      .addCase(fetchShipOwners.pending, (state) => {
        state.shipOwnersStatus = 'loading';
      })
      .addCase(fetchShipOwners.fulfilled, (state, action) => {
        if (action.payload !== "") {
          const uniqueNames = new Set(action.payload.data);
          action.payload.forEach(name => {
            uniqueNames.add(name);
          });
          state.shipOwners = Array.from(uniqueNames);
          state.shipOwnersStatus = 'succeeded';
        }
      })
      .addCase(fetchShipOwners.rejected, (state) => {
        state.shipOwnersStatus = 'failed';
      })

      .addCase(fetchVessels.pending, (state) => {
        state.vesselStatus = 'loading';
      })
      .addCase(fetchVessels.fulfilled, (state, action) => {
        state.vesselStatus = 'succeeded';
        state.vesselDetails = action.payload
      })
      .addCase(fetchVessels.rejected, (state) => {
        state.vesselStatus = 'failed';
      })

      .addCase(fetchCasperRecords.pending, (state) => {
        state.fetchCasperRecordsStatus = 'loading';
      })
      .addCase(fetchCasperRecords.fulfilled, (state, action) => {
        state.fetchCasperRecordsStatus = 'succeeded';
        state.casperRecords = action.payload.data;
        state.casperAnalysisPagesCount = action?.payload?.total_pages ? action.payload.total_pages : 1
        state.globalFilters = action.meta.arg
        // console.log(action.meta.arg)
      })
      .addCase(fetchCasperRecords.rejected, (state, action) => {
        state.fetchCasperRecordsStatus = 'failed';
        state.fetchCasperRecordError = action.payload;
      })

      .addCase(fetchCasperID.pending, (state) => {
        state.casperIDStatus = 'loading';
      })
      .addCase(fetchCasperID.fulfilled, (state, action) => {
        state.casperIDStatus = 'succeeded';
        state.casperID = action.payload
        // console.log(state.casperID)
      })
      .addCase(fetchCasperID.rejected, (state) => {
        state.casperIDStatus = 'failed';
      })

      .addCase(fetchAuditDates.pending, (state) => {
        state.auditDateStatus = 'loading';
      })
      .addCase(fetchAuditDates.fulfilled, (state, action) => {
        state.auditDateStatus = 'succeeded';
        state.auditDates = action.payload
      })
      .addCase(fetchAuditDates.rejected, (state) => {
        state.auditDateStatus = 'failed';
      })

      .addCase(getCasperIds.pending, (state) => {
        state.casperIDStatus = 'loading';
      })
      .addCase(getCasperIds.fulfilled, (state, action) => {
        state.casperIDStatus = 'succeeded';
        state.casperIdData = action.payload.data
      })
      .addCase(getCasperIds.rejected, (state) => {
        state.casperIDStatus = 'failed';
      })
      .addCase(runCalculations.pending, (state) => {
        state.calculationsStatus = 'loading';
      })
      .addCase(runCalculations.fulfilled, (state, action) => {
        state.calculationsStatus = 'succeeded';
        state.calculationsData = action.payload.data
        // console.log(action.payload)
      })
      .addCase(runCalculations.rejected, (state) => {
        state.calculationsStatus = 'failed';
      })
      .addCase(postFuelInfoApplyToAll.pending, (state) => {
        state.conflictingStatus = 'loading';
      })
      .addCase(postFuelInfoApplyToAll.fulfilled, (state, action) => {
        state.conflictingStatus = 'succeeded';
        state.conflictingData = action.payload.data
        state.conflictingPayload = action.meta.arg
        // console.log(action.meta.arg)
      })
      .addCase(postFuelInfoApplyToAll.rejected, (state) => {
        state.conflictingStatus = 'failed';
      })
      .addCase(getCalculationsResults.pending, (state) => {
        // state.conflictingStatus = 'loading';
      })
      .addCase(getCalculationsResults.fulfilled, (state, action) => {
        // state.conflictingStatus = 'succeeded';
        state.calculationsResult = action.payload.data[0]
      })
      .addCase(getCalculationsResults.rejected, (state) => {
        // state.conflictingStatus = 'failed';
      })
      .addCase(getPortNames.pending, (state) => {
        // state.conflictingStatus = 'loading';
      })
      .addCase(getPortNames.fulfilled, (state, action) => {
        // state.conflictingStatus = 'succeeded';
        state.portSuggestions = action.payload.data
      })
      .addCase(getPortNames.rejected, (state) => {
        // state.conflictingStatus = 'failed';
      })
      .addCase(getAuditDetails.pending, (state) => {

      })
      .addCase(getAuditDetails.fulfilled, (state, action) => {

        state.auditDetails = action.payload.data
        // console.log(action.payload)
      })
      .addCase(getAuditDetails.rejected, (state) => {

      })
      .addCase(postBlandedFuelInfo.fulfilled, (state, action) => {

        state.blendedFuelsData = action.payload.final_res
        // console.log(action.payload.final_res)
      })
      .addCase(getBlandedFuelInfo.fulfilled, (state, action) => {

        state.blendedFuelsGetData = action.payload
        // console.log(action.payload)
      })
      .addCase(patchBlandedFuelInfo.fulfilled, (state, action) => {

        state.blendedFuelsData = action.payload.data
        // console.log(action.payload)
      })
      .addCase(getIceClassInfo.rejected, (state) => {
        state.iceClassInfoStatus = 'failed';
      })
      .addCase(getIceClassInfo.fulfilled, (state, action) => {
        state.iceClassInfoStatus = action.payload?.flag == 1 ? true : false;
        state.iceClassInfo = action.payload.data
        // console.log(action)
      })
      .addCase(updateVoyageInfo.rejected, (state) => {
        state.updateVoyageStatus = 'failed';
      })
      .addCase(updateVoyageInfo.fulfilled, (state, action) => {
        state.updateVoyageStatus = 'fulfilled'
        // console.log(action)
      })
      .addCase(lockCasperId.fulfilled, (state, action) => {
        state.lockStatus = true
        // console.log(action)
      })
    },
})

export const { resetRawDataState, togglePastRecords, setSelectedRecordsForSequentialFlow, updateSelectedRecordsForSequentialFlow, deselectSelectedRecordsForSequentialFlow, deselectAllRecordsInSequentialFlow, removeObjectFromSelectedRecords, deselectFromSequentialFlow, newDataInSequentialFlow } = rawDataSlice.actions;

export default rawDataSlice.reducer;