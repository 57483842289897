import { Modal, Button } from 'antd';
import React, { useState } from 'react';
import ArrowRightOutlined from '../../assets/icons/rawdata/Modal_arrow.svg';
import icon1 from '../../assets/icons/rawdata/house 1.svg';
import icon2 from '../../assets/icons/rawdata/warehouse 1.svg';
import icon3 from '../../assets/icons/rawdata/clarity_factory-solid.svg';
import icon4 from '../../assets/icons/rawdata/petroleum 1.svg';
import icon5 from '../../assets/icons/rawdata/shipping 1.svg';
import CertificateDetails from '../../components/CertificateDetails'; // Import the CertificateDetails component

const CertificationModal = ({ isModalVisible, handleCancel }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0); // State to control the active index
  const [fileMap, setFileMap] = useState({}); // State to maintain files for each section

  const dynamicData = [
    {
      id: '9232060',
      description: 'Add the required documents for each stage.',
      filesAdded: '12 Files added',
      icon: icon1,
      heading: 'Feedstock/Point of Origin',
      subheading: 'First gathering/Collection point'
    },
    {
      id: '9232061',
      description: 'Provide all necessary files for the inspection.',
      filesAdded: '10 Files added',
      icon: icon2,
      heading: 'First gathering/Collection point',
      subheading: 'Trader/Storage'
    },
    {
      id: '9232062',
      description: 'Submit all relevant certification documents.',
      filesAdded: 'Files not added',
      icon: icon3,
      heading: 'Trader Storage',
      subheading: 'Production'
    },
    {
      id: '9232063',
      description: 'Upload all required data for transportation.',
      filesAdded: '10 Files added',
      icon: icon4,
      heading: 'Production',
      subheading: 'Bunkering'
    },
    {
      id: '9232064',
      description: 'Complete all documentation for final delivery.',
      filesAdded: '20 Files added',
      icon: icon5,
      heading: 'Bunkering',
      subheading: 'Usage'
    }
  ];

  const handleFileUpload = (updatedFiles, heading) => {
    setFileMap(prevFileMap => ({
      ...prevFileMap,
      [heading]: updatedFiles
    }));
  };

  const activeData = dynamicData[activeIndex];

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
          Add required documentations (Casper ID : {activeData.id})
        </div>
      }
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} style={{ backgroundColor: '#E2E2E2' }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" style={{ backgroundColor: '#678DFF', color: 'white' }}>
          Save
        </Button>,
      ]}
      width={800} // Adjust width as needed
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 1 }}>
          <div style={{ color: '#737373' }}>
            {activeData.description}
          </div>
          {dynamicData.map((item, index) => (
            <div
              key={index}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
              onClick={() => setActiveIndex(index)}
              style={{
                marginTop: '10px',
                borderRadius: '8px',
                padding: '10px',
                display: 'flex',
                alignItems: 'center',
                background: hoveredIndex === index ? '#E6F7FF' : (activeIndex === index ? '#EFF5FF' : 'none'),
                height: '80px',
                border: hoveredIndex === index ? 'none' : (activeIndex === index ? 'none' : '1px solid #005BF3'),
                cursor: 'pointer'
              }}
            >
              <img src={item.icon} alt="icon" style={{ marginRight: '10px', width: '40px', height: '40px' }} />
              <div style={{ flex: 1 }}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#005BF3' }}>
                  <span>{item.heading}</span>
                  <img
                    src={ArrowRightOutlined}
                    alt="arrow icon"
                    style={{ marginRight: '10px', cursor: 'pointer' }}
                  />
                </div>
                <div style={{ color: '#005BF3' }}>{item.subheading}</div>
                <div style={{ color: '#272727', opacity: '0.5' }}>{(fileMap[item.heading]?.length || 0) + ' Files added'}</div>
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginLeft: '20px', width: '380px' }}>
          <CertificateDetails
            heading={activeData.heading}
            subheading={activeData.subheading}
            files={fileMap[activeData.heading] || []}
            onFileUpload={handleFileUpload}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CertificationModal;
