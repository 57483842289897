import React, { useState, useRef, useCallback } from 'react';
import { Form, Input, Button, Checkbox, Modal, Pagination } from 'antd';
import { EditOutlined, PlusOutlined ,ExclamationCircleFilled, CloseCircleOutlined} from '@ant-design/icons';
import '../../../css/dataRecords.css';
import DataRecordsDescription from './DataRecordsDescription';
import { togglePastRecords, fetchFuelInfoByUniqueID, fetchRawData, getCasperIds, patchCasperId } from '../../../features/RawData/RawDataSlice';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '../../../assets/icons/voyages/edit.svg';
import AddAlternativeFuel from '../../../components/Modals/AddAlternativeFuel'; // Import AddAlternativeFuel modal
import EditCasperID from '../../../components/Modals/EditCasperID'; // Import EditCasperID modal
import FuelEditModal from '../sequentialFlow/FuelEditModal';
const { confirm } = Modal;



const DataRecordsTable = ({  handleCardSelect, selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace, selectedCardsWithEssentialData, setSelectedCardsWithEssentialData }) => {
    const [editingStates, setEditingStates] = useState({});
    const [editingValues, setEditingValues] = useState({});
    const { user } = useSelector((state) => state.user);
    const { status, cardUniqueIDDetails, cardDataToShow, cardDataTypeIndividual, selectedRecordsPerPageUniqueIds, globalFilters, rawDataPagesCount } = useSelector((state) => state.rawData);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedFuelUniqueID, setSelectedFuelUniqueID] = useState('');
    const [selectedCasperId, setSelectedCasperId] = useState('');
    const [selectedIMO, setSelectedIMO] = useState('');
    const [selectedUni, setSelectedUni] = useState('');
    const [isEditCasperIDModalVisible, setIsEditCasperIDModalVisible] = useState(false); // State for Edit Casper ID modal
    // console.log(cardDataTypeIndividual)
    const dispatch = useDispatch()
    const handleInputChange = (casperID, e) => {
        setEditingValues({
            ...editingValues,
            [casperID]: e.target.value
        });
    };

    const handleEditClick = (casperID) => {
        setEditingStates({
            ...editingStates,
            [casperID]: true
        });
        setEditingValues({
            ...editingValues,
            [casperID]: cardUniqueIDDetails.find(record => record.casper_id === casperID).casper_id
        });
    };

    const handleSaveClick = (casperID) => {
        setEditingStates({
            ...editingStates,
            [casperID]: false
        });

    };

    const showEditCasperIDModal = (casper__id, unique__id, imo) => {
        setSelectedCasperId(casper__id)
        setSelectedIMO(imo)
        setSelectedUni(unique__id)
        // console.log(unique__id)
        const payload = {
            unique_id: unique__id,
            imo_number: imo,
        };
        dispatch(getCasperIds(payload));
        setIsEditCasperIDModalVisible(true);
    };

    const handleEditCasperIDCancel = () => {
        setSelectedCasperId('');
        setIsEditCasperIDModalVisible(false);
    };

    const handleEditCasperIDSubmit = () => {
        setIsEditCasperIDModalVisible(false);
        // Logic to handle submission of edited Casper ID
    };

    const handlePastRecords = (uniqueId) => {
        setSelectedUniqueIdAuditTrace(uniqueId)
        dispatch(togglePastRecords())
    }

    const showFuelEditModal = (unique_id) => {
        setSelectedFuelUniqueID(unique_id);
        dispatch(fetchFuelInfoByUniqueID(unique_id));
        setIsModalVisible(true);
    }

    const onChange = (pageNumber) => {
        dispatch(fetchRawData({ ...globalFilters, pageNumber: pageNumber, requestedData: "VOYAGE_DATA", uniqueID: ''  }))
    };

    // Modal function for  confirmation
const showDeleteConfirm = (casperId, uniqueId, imoNumberId) => {
    confirm({
      title: 'Are you sure you want to remove this casper ID?',
      icon: <ExclamationCircleFilled />,
      content: `Deleting Casper ID: ${casperId}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const params = {
            imo_number: imoNumberId,
            old_casper_id: casperId,
            new_casper_id: '',
            unique_id: uniqueId,
            user_remarks: ''
          }
          dispatch(patchCasperId(params))
          .then(() => {
            dispatch(fetchRawData(globalFilters)); 
          })
          .catch((error) => {
            console.error('Error patching Casper ID:', error);
          });
      },
      onCancel() {
        console.log('Cancelled');
      },
    });
  };

    return (
        <div style={{ height: "50vh", overflow: "auto" }}>
            {cardUniqueIDDetails.map((record, index) => (
                <div
                    key={index}
                    className={`vessel-description-card ${selectedRecordsPerPageUniqueIds?.[globalFilters?.pageNumber]?.includes(record.unique_id) ? 'selected' : ''}`}
                    style={{ border: selectedRecordsPerPageUniqueIds?.[globalFilters?.pageNumber]?.includes(record.unique_id) ? '2px solid #272727' : '1px solid #D9D9D9', marginBottom: '10px', borderRadius: '16px' }}
                >
                    <div className="vessel-describeHead" style={{ width: '280px', border: '1px solid #D9D9D9', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                        <Form className='dataRecordsForm' layout="vertical">
                            {/* layout="horizontal" */}
                            <Form.Item>
                                {!record?.casper_id && <Checkbox checked={selectedRecordsPerPageUniqueIds?.[globalFilters?.pageNumber]?.includes(record.unique_id)} onChange={() => handleCardSelect(record.unique_id, record.at_port_consumption, record.from_port_code, record.to_port_code)}>Select</Checkbox>}
                            </Form.Item>
                            <Form.Item label="Casper ID" >
                                <Input
                                className='casperIdInput'
                                    value={editingStates[record?.casper_id] ? editingValues[record?.casper_id] : record?.casper_id || ''}
                                    readOnly={!editingStates[record.casper_id]}
                                    onChange={(e) => handleInputChange(record.casper_id, e)}
                                    // addonAfter={
                                    //     <img src={EditIcon} alt="edit" onClick={() => showEditCasperIDModal(record.casper_id, record.unique_id, record.imo_number_id)} style={{ cursor: 'pointer' }} />
                                    // } 
                                    addonAfter={
                                        record.casper_id ? user["is_admin:"] ? (
                                            <CloseCircleOutlined 
                                                onClick={() => showDeleteConfirm(record.casper_id, record.unique_id, record.imo_number_id)} 
                                                style={{ cursor: 'pointer', color: '#678DFF' }} // You can customize the color or style as needed
                                            />) : !record.is_locked_in ? (
                                                <CloseCircleOutlined 
                                                    onClick={() => showDeleteConfirm(record.casper_id, record.unique_id, record.imo_number_id)} 
                                                    style={{ cursor: 'pointer', color: '#678DFF' }} // You can customize the color or style as needed
                                                />)  : null :  null
                                    }
                                />
                            </Form.Item>
                            <Form.Item label="IMO No.">
                                <Input value={record.imo_number_id} readOnly />
                            </Form.Item>
                            {/* <Form.Item
                            //  label="Unique ID"
                            >
                                <Input value={record.unique_id} readOnly />
                            </Form.Item> */}
                            <Form.Item className='dataRecordsButtons'>
                                <Button className='custom-button' onClick={() => handlePastRecords(record.unique_id)}>Past Records</Button>
                                <Button className='custom-button' onClick={() => showFuelEditModal(record.unique_id)}>Fuels <EditOutlined /></Button>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className='vessel-description' style={{ width: '100%', paddingTop: '0', borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
                        <DataRecordsDescription rawDetailsData={cardDataToShow[record.unique_id]} dataType={cardDataTypeIndividual[record.unique_id]} uniqueID={record.unique_id} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />
                    </div>
                </div>
            ))}
            {rawDataPagesCount > 1 &&
                <Pagination align="center" defaultCurrent={globalFilters?.pageNumber ? globalFilters?.pageNumber : 1} total={rawDataPagesCount * 50} pageSize={50} showSizeChanger={false} onChange={onChange} />
            }
            {/* EditCasperID Modal */}
            <EditCasperID
                isVisible={isEditCasperIDModalVisible}
                handleCancel={handleEditCasperIDCancel}
                handleSubmit={handleEditCasperIDSubmit}
                selectedCasperId={selectedCasperId}
                selectedUniqueId={selectedUni}
                selectedIMO={selectedIMO}
            />

            <FuelEditModal
                visible={isModalVisible}
                setIsModalVisible={setIsModalVisible}
                selectedUniqueId={selectedFuelUniqueID}
            />


        </div>
    );
};

export default DataRecordsTable;
