import React, { useEffect } from 'react';
import { Spin } from 'antd';
import EnterCasperId from '../sequentialFlow/EnterCasperId';
import { useDispatch, useSelector } from 'react-redux';
import { getCasperIds } from '../../../features/RawData/RawDataSlice';

const StepperSecond = ({ selectedUniqueId, selectedCasperId, setSelectedCasperId }) => {
  const dispatch = useDispatch();

  const { casperIDStatus, selectedRecordsForSequentialFlow } = useSelector((state) => state.rawData);

  const data = selectedRecordsForSequentialFlow?.[selectedUniqueId][0];
  // console.log(selectedUniqueId);

  useEffect(() => {
    const payload = {
      unique_id: selectedUniqueId,
      imo_number: data.imo_number_id,
    };
    dispatch(getCasperIds(payload));
  }, [dispatch, selectedUniqueId, data.imo_number_id]);

  return (
    <div className="stepper-step">
      <div className="stepper-step-content">
        {casperIDStatus === 'loading' ? (
          <Spin />
        ) : (
          <EnterCasperId selectedCasperId={selectedCasperId} setSelectedCasperId={setSelectedCasperId} />
        )}
      </div>
    </div>
  );
};

export default StepperSecond;
