import React from 'react';
import { Routes, Route } from 'react-router';
import EUA from "./pages/eua/EUA";
import Vessels from "./pages/vessels/Vessels";
import NotificationsPage from "./pages/notifications/NotificationsPage"
import NewsRoom from "./pages/NewsRoom/NewsRoom";
import Voyages from './pages/voyages/Voyages';
import EFDBPage from './pages/efdb/EFDBPage';
import VoyageOptimizationPage from './pages/voyageoptimization/VoyageOptimizationPage';
import Freemium from './pages/freemium/Freemium';
import RawDataPage from './pages/rawdata/RawDataPage';
import CasperAnalysisPage from './pages/casperanalysis/CasperAnalysisPage';
import VesselAnalysis from './pages/vessalAnalysis/VesselAnalysis';
import CreatingVesselsRecord from './pages/vessalAnalysis/creatingVesselsRecord';
import MatrixTables from './container/phase2/MatrixTables';
import { useSelector } from 'react-redux';

const Services = () => {

    const { user } = useSelector((state) => state.user)
    let data = {
        eua: <EUA />,
        vessels: <Vessels />,
        voyages: <Voyages />,
        notification: <NotificationsPage />,
        newsroom: <NewsRoom />,
        efdb: <EFDBPage />,
        VoyageOptimization: <VoyageOptimizationPage />,
        freemium: <Freemium />,
        matrixTables: <MatrixTables />,
        rawdata: <RawDataPage />,
        casperanalysis: <CasperAnalysisPage />,
        vesselanalysis: <VesselAnalysis />,
        creatingVesselsRecord: <CreatingVesselsRecord />
    }

    const getRouteComponent = () => {
        if (user.role === "Freemium") {
            return data.freemium;
        } else if (user.role === "ems") {
            return data.rawdata;
        } else {
            return data.eua;
        }
    };

    return (
        
        <Routes>
            <Route path="/vessels" element={data.vessels} />
            <Route path="/voyages" element={data.voyages} />
            <Route path="/notifications" element={data.notification} />
            <Route path="/newsroom" element={data.newsroom} />
            <Route path="/dashboard" element={getRouteComponent()} />
            <Route path="/voyages" element={data.voyages} />
            <Route path="/efdb" element={data.efdb} />
            <Route path="/voyage-optimization" element={data.VoyageOptimization} />
            <Route path="/freemium" element={data.freemium} />
            <Route path="/matrix-tables" element={data.matrixTables} />
            <Route path="/casper-analysis" element={data.casperanalysis} />
            <Route path="/vessels-analysis" element={data.vesselanalysis} />
            <Route path="/create-vessel" element={data.creatingVesselsRecord} />


        </Routes>
    );
};

export default Services;
