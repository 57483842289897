import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { hostname } from "../../data";
import { makeRequest } from "../../api/api";

export const efdbData = createAsyncThunk(
    'efdb/getefdb',
    async()=>{
        const request = await makeRequest("get","api/fuel_list/")
        const response=await request.data.data
        return response
    }
)


const efdbSlice = createSlice({
    name:"efdb",
    initialState: {
      status: 'idle',
      fuelList: [],
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(efdbData.pending, (state) => {
          state.status = 'loading';
        })
        .addCase(efdbData.fulfilled, (state, action) => {
          state.status = 'succeeded';
          state.fuelList = action.payload;
        })
        .addCase(efdbData.rejected, (state) => {
          state.status = 'failed';
        });
    },
})

export default efdbSlice.reducer;