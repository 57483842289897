import React from "react";
import { ConfigProvider } from "antd";
import EuaOverview from "../../container/euaoverview/EuaOverview";
import EuaDifference from "../../container/euadifference/EuaDifference";
import Voyage from "../../container/voyage/Voyage";

const Voyages = () => {
    return (
        <ConfigProvider
            theme={{
                components: {
                    Progress: {
                        lineBorderRadius: 8,
                    },
                    Descriptions: {
                        itemPaddingBottom: "10px"
                    }
                },
            }}
        >
            <main className="main-board">
                <div className="voyage-dashboard">
                    <div className="voyage-item">
                        <EuaOverview />
                    </div>
                    <div className="voyage-item1">
                        <Voyage />
                    </div>
                    <div className="voyage-item2">
                        <EuaDifference />
                    </div>
                </div>
            </main>
        </ConfigProvider>
    );
};

export default Voyages;
