import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const fetchNotification = createAsyncThunk(
  'notification/getNotifications',
  async (params) => {
    const request = await makeRequest("get", `notification/get_notification/?category=${params.category}&requested_page=${params.page}&keyword=${params.keyword}`)
    const response = await request.data.data
    return response
  }
)

export const fetchNotificationCount = createAsyncThunk(
  'notification/getCount',
  async (params) => {
    const request = await makeRequest("get", `notification/notification_count`)
    const response = await request.data.data
    return response
  }
)
export const updateNotifications = createAsyncThunk(
  'notification/updateCount',
  async (params) => {
    const request = await makeRequest("patch", `notification/notification_status_update/?id=${params.id}&update_type=${params.update_type}`)
    const response = await request.data.data
    return response
  }
)

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    status: 'idle',
    notification: [],
    count: null,
    page: 1,
  },
  reducers: {
    resetNotificationState: (state) => {
      state.page = 1;
      state.notification = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotification.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchNotification.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.notification = [...state.notification, ...action.payload];
        state.page++
      })
      .addCase(fetchNotification.rejected, (state) => {
        state.status = 'failed';
        state.notification = []
      })
      .addCase(fetchNotificationCount.fulfilled, (state, action) => {
        state.count = action.payload;
      })
      .addCase('notification/resetNotificationState', (state) => {
        // Reset page to 1 and clear notification array
        state.page = 1;
        state.notification = [];
      });
  },
})

export const { resetNotificationState } = notificationSlice.actions;

export default notificationSlice.reducer;