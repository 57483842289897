import React from 'react';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toggleModal } from '../features/App/AppSlice';
const ApiStatusAlert = () => {
    const modalInfo = useSelector((state) => state.app.modalInfo);
    const postModalVisible = useSelector((state) => state.app.postModalVisible);
    const dispatch = useDispatch();
    const { result, type, message } = modalInfo;
    const onClose = ()=>{
        dispatch(toggleModal());
    }
    return (
        <div className='alert-modal-wrapper'>
            {
                postModalVisible && (
                    <Alert
                        message={message}
                        description={result}
                        className='alert-modal'
                        type={type}
                        closable
                        onClose={onClose}
                        style={{ position: "absolute", zIndex: "1000" }}
                        showIcon />
                )
            }
        </div>
    );
}
export default ApiStatusAlert;