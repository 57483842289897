import { Button, Divider } from 'antd';
import { useState } from 'react';
import { CloseOutlined, ClockCircleOutlined } from '@ant-design/icons';
import DeletingFuel from '../../../components/Modals/DeletingFuel';
import { useDispatch, useSelector } from 'react-redux';
import { deselectFromSequentialFlow, fetchFuelInfoByUniqueID } from '../../../features/RawData/RawDataSlice';

const StepperHeader = ({ currentStep, selectedUniqueId, setSelectedUniqueId, selectedCardsWithEssentialData, setSelectedCardsWithEssentialData }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { status, rawData, selectedRecordsForSequentialFlow, selectedRecordsPerPageUniqueIds} = useSelector((state) => state.rawData);

  const data = selectedRecordsForSequentialFlow && selectedRecordsForSequentialFlow[selectedUniqueId] ? selectedRecordsForSequentialFlow[selectedUniqueId][0] : null;

  const selectedCards = Object.values(selectedRecordsPerPageUniqueIds).reduce((acc, curr) => acc.concat(curr), []);
  // console.log(selectedCards)
  // console.log(selectedUniqueId)

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleRemove = async () => {
    const newSelectedCards = selectedCards.filter(id => id !== selectedUniqueId);
    const newSelectedUniqueId = newSelectedCards.length > 0 ? newSelectedCards[0] : null;

    setSelectedUniqueId(newSelectedUniqueId);
    setSelectedCardsWithEssentialData(prevState => {
      const { [selectedUniqueId]: _, ...rest } = prevState; // Remove the uniqueId key
      return rest;
    });
    dispatch(deselectFromSequentialFlow(selectedUniqueId))

    if (newSelectedUniqueId) {
      try {
        // Fetch the fuel info for the new selected unique ID
        await dispatch(fetchFuelInfoByUniqueID(newSelectedUniqueId));
      } catch (error) {
        console.error('Error fetching fuel info:', error);
      }
    }

    // console.log(selectedCardsWithEssentialData)
    // Close the modal
    setIsModalVisible(false);
  };
  let title = '';
  let subTitle = '';
  let dateTime = '';
  let event = '';

  switch (currentStep) {
    case 0:
      title = 'Record View';
      subTitle = 'Please save the record before moving on to the next step.';
      dateTime = data?.report_date;
      event = `Event : ${data?.event}`;
      break;
    case 1:
      title = 'Assign Casper ID to all the selected records';
      break;
    case 2:
      title = 'Expected output results';
      break;
    default:
      title = '';
  }

  return (
    <div>
      <div className="stepper-head">
        <div>
          <p className="stepper-head-title">
            {title}{' '}
            {currentStep === 0 && selectedCards.length > 1 && (
              <Button
                className="stepper-cancel-button"
                size="10px"
                type="primary"
                icon={<CloseOutlined />}
                onClick={showModal}
              >
                Deselect Record
              </Button>
            )}
          </p>
          <p className="stepper-head-subTitle">{subTitle}</p>
        </div>
        {currentStep === 0 && (
          <div className="stepper-first-head2">
            <p>
              <ClockCircleOutlined /> {dateTime}
            </p>
            <Button className="stepper-first-detail" size="10px" type="primary">
              {event}
            </Button>
          </div>
        )}
      </div>
      <Divider />
      <DeletingFuel
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        handleRemove={handleRemove}
        title="Deselecting the Record?"
        message="Are you sure, you want to remove this record from selected list?"
      />
    </div>
  );
};

export default StepperHeader;
