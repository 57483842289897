import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button } from 'antd';
import { PDFDocument } from 'pdf-lib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInvoice, fetchOrder } from '../../features/Voyage/VoyageSlice';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Logo from '../../assets/icons/voyages/LogoA.svg'

const DownloadOrder = ({ icon, label, doc }) => {
  const { selectedVoyage, order } = useSelector((state) => state.voyage);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const pdfRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (order !== null) {
      editPDF(doc);
    }
  }, [order]);

  async function editPDF(existingPdfBytes) {
    const response = await fetch(existingPdfBytes);
    const data = await response.arrayBuffer();
    const pdfDoc = await PDFDocument.load(data);
    const pdf_entries = order;
    const form = pdfDoc.getForm();

    for (let [key, value] of Object.entries(pdf_entries)) {
      try {
        const balanceTextField = form.getTextField(key);
        if (balanceTextField) {
          if (typeof value === 'number') {
            value = value.toFixed(2);
          }
          balanceTextField.setText(value.toString());
        } else {
          console.error(`Text field with name '${key}' not found.`);
        }
      } catch {
        console.error(`Text field with name '${key}' not found.`);
      }
    }

    form.flatten();
    const modifiedPdfBytes = await pdfDoc.save();
    const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    setDownloadUrl(url);
  }

  const handleOrderGenerate = () => {
    if (order === null) {
      dispatch(fetchOrder(selectedVoyage)).then((result) => {
        console.log(result);
        dispatch(fetchInvoice(selectedVoyage));
      });
    } else {
      setIsModalOpen(true);
    }
  };

  const handleDownload = async () => {
    const pdf = new jsPDF();
    const data = await html2canvas(pdfRef.current);
    const img = data.toDataURL('image/png');
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, 'PNG', 0, 0, pdfWidth, pdfHeight);

    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    const formattedDate = `${day}${month}${year}`;
    const filename = `order-${selectedVoyage.imo_number}-${selectedVoyage.voyage_number}-${formattedDate}.pdf`;

    pdf.save(filename);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div onClick={handleOrderGenerate}>
        <img src={icon} style={{ height: '20px' }} alt="" />
        <span className="card-icon-span">{label}</span>
      </div>

      <Modal
        title="Download Order"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="download" type="primary" onClick={handleDownload}>
            Download
          </Button>,
        ]}
        width={700}
      >
       <div ref={pdfRef}>
          <div className="order-invoice">
            <div className="order-div">
              <img className="order-picture" src={Logo} alt="Picture 1" />
              <div className="order-text-wrapper">AZOLLA PTE. LTD.</div>
              <div className="order-text-wrapper-2">ets@azolla.sg | www.azolla.sg</div>
              <p className="order-azolla-pte-ltd-kim">
                <span className="order-span">Azolla Pte Ltd<br /></span>
                <span className="order-text-wrapper-3">
                  1 Kim Seng Promenade<br /># 10/11-12 Great World City<br />237944 , Singapore
                </span>
              </p>
              <p className="order-p">TRADE ORDER for EU Emission Trading Scheme, EU Allowances</p>
              <div className="order-frame">
                <div className="order-text-wrapper-4">Buyer’s Nominated Recipient</div>
              </div>
              <div className="order-div-wrapper"><div className="order-text-wrapper-4">Seller</div></div>
              <div className="order-contract-volume-wrapper">
                <div className="order-contract-volume">
                  Contract<br />Volume<br />Allowances<br />(EUA) (t/CO2)
                </div>
              </div>
              <div className="order-frame-2"><div className="order-text-wrapper-5">0.00</div></div>
              <div className="order-frame-3"><div className="order-text-wrapper-6">Product</div></div>
              <div className="order-frame-4"><div className="order-text-wrapper-5">0.00</div></div>
              <div className="order-frame-5">
                <div className="order-text-wrapper-7">Delivery date</div>
              </div>
              <div className="order-frame-6"><div className="order-text-wrapper-8">0.00</div></div>
              <div className="order-allowance-price-wrapper">
                <div className="order-allowance-price">Allowance<br />Price</div>
              </div>
              <div className="order-frame-7">
                <div className="order-text-wrapper-9">Compliance Period</div>
              </div>
              <div className="order-frame-8"><div className="order-text-wrapper-10">0.00</div></div>
              <div className="order-frame-9"><div className="order-text-wrapper-10">0.00</div></div>
              <div className="order-total-purchase-price-wrapper">
                <div className="order-total-purchase-price">Total Purchase<br />Price</div>
              </div>
              <div className="order-frame-10"><div className="order-text-wrapper-10">0.00</div></div>
              <div className="order-frame-11">
                <div className="order-text-wrapper-11">Payment Date</div>
              </div>
              <div className="order-frame-12"><div className="order-text-wrapper-12">0.00</div></div>
              <div className="order-flexcontainer">
                <p className="order-text">
                  <span className="order-text-wrapper-3">Vessel Name: ETOILE<br /></span>
                </p>
                <p className="order-text">
                  <span className="order-text-wrapper-3">Currency:<br /></span>
                </p>
                <p className="order-text"><span className="order-text-wrapper-3">Category:</span></p>
              </div>
              <p className="order-trade-order-azin">
                <span className="order-span">Trade Order<br /></span>
                <span className="order-text-wrapper-3">azin/9232060/05B/20240717</span>
              </p>
              <p className="order-trade-date">
                <span className="order-span">Trade Date:&nbsp;&nbsp;</span>
                <span className="order-text-wrapper-13">2024-07-17</span>
              </p>
              <div className="order-flexcontainer-2">
                <p className="order-text">
                  <span className="order-span">Buyer<br /></span>
                </p>
                <p className="order-text">
                  <span className="order-span"><br /></span>
                </p>
                <p className="order-text">
                  <span className="order-text-wrapper-3">
                    Name<br /><hr />
                  </span>
                </p>
                <p className="order-text">
                  <span className="order-text-wrapper-3">
                    Title<br /><hr />
                  </span>
                </p>
                <p className="order-text">
                  <span className="order-text-wrapper-3">
                    Signature<hr />
                  </span>
                </p>
              </div>
              <div className="order-flexcontainer-3">
                <p className="order-text">
                  <span className="order-span">AZOLLA REPRESENTATIVE<br /></span>
                </p>
                <p className="order-text">
                  <span className="order-span"><br /></span>
                </p>
                <p className="order-text">
                  <span className="order-text-wrapper-3">
                    Name<br /><hr />
                  </span>
                </p>
                <p className="order-text">
                  <span className="order-text-wrapper-3">
                    Title<br /><hr />
                  </span>
                </p>
                <p className="order-text">
                  <span className="order-text-wrapper-3">
                    Signature<hr />
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DownloadOrder;
