// import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import vesselsvg from '../../assets/icons/rawdata/ship-line.svg'; // Adjust the path as needed
import vesselsvgs from '../../assets/icons/rawdata/pointer.svg';
import { useNavigate } from 'react-router-dom';
import { Input, Form } from 'antd';
import Skip from '../../components/Modals/Skip'; // Import the Skip modal component



const CreatingVesselsRecord= () => {
  const navigate = useNavigate();

  const handleSaveClick = () => {
    // Save functionality
  };
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSkip = () => {
    // Handle the skip logic here
    setIsModalVisible(false);
    
  };
  const handleBackClick = () => {
    navigate(-1); // Navigates to the previous page
  };
  const [fields, setFields] = useState([
    { label: 'IMO Number', placeholder: 'Typing..' },
    { label: 'Vessel Name', placeholder: 'Value' },
    { label: 'Registered Owner', placeholder: 'Value' },
    { label: ' Active Owner', placeholder: 'Value' },
    { label: 'Type', placeholder: 'Value' },
    { label: 'Sub Type', placeholder: 'Value' },
    { label: 'Build Date', placeholder: 'Value' },
    { label: 'Ownership Date ', placeholder: 'Value' },
    { label: 'Registered Port', placeholder: 'Value' },
    { label: 'Summer Dead Weight', placeholder: 'Value' },
    { label: 'Gross Ton', placeholder: 'Value' },
    { label: 'Net Ton', placeholder: 'Value' },
    { label: 'Service Speed', placeholder: 'Value' },
    { label: 'Manager Doc Holder', placeholder: 'Value' },
    { label: 'Technical Group', placeholder: 'Value' }, 
    { label: 'Type of Carrier', placeholder: 'Value' },
    { label: 'Technical Managers', placeholder: 'Value' },
    { label: 'Size of Vessel', placeholder: 'Value' },
    { label: 'Previous name', placeholder: 'Value' },
    { label: 'Current Status', placeholder: 'Value' },
    { label: 'Vessel Class', placeholder: 'Value' },
    { label: 'Source', placeholder: 'Value' },
    { label: 'P-Wind', placeholder: 'Value' },
    { label: 'P-Prop', placeholder: 'Value' },


    // ... add up to 24 fields
  ]);



  return (
    <div className='creating-vessels-record-container'>
    <div className='creating-vessels-record'>
    <div className="creating-vessels-records">
      <div className='creating-vessels-records-header'>
      <div className="header">
        <img src={vesselsvg} alt="icon" className="header-icon" />
        <h3 className='header-title'>
          Creating Vessel Record
         </h3>
        </div>
        <div className='progress-container'>
      <span className="progress">01 </span><span className="total-progress">/24 Completed</span>
      </div>
      </div>
      <hr></hr>
      <div className="form-container">
  <Form className="form-fields-container">
    {fields.map((field, index) => (
      <Form.Item
        key={index}
        label={field.label}
        name={`field${index}`}
        className="form-field"
        labelCol={{ span: 24 }}  // Ensure label takes full width in the grid
        wrapperCol={{ span: 24 }}  // Ensure input field takes full width in the grid
      >
        <Input placeholder={field.placeholder} />
      </Form.Item>
    ))}
  </Form>
</div>
      <div className="footer">
        <div className='footer-text'>
        <img src={vesselsvgs} alt="icon" className="footer-icon" />
        <p className='footer-title'>Please fill all the details to create a new vessel positively.</p>
        </div>
        <div className="button-container">
          <button className="back-buttons" onClick={handleBackClick}>Back</button>
          <button className="save-buttons"onClick={showModal}>Save</button>
        </div>
      </div>
    </div>
    </div>
    <Skip
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        handleSkip={handleSkip}
        title="Save this record"
        message="Click on save button to create the vessel record."
         primaryButtonText="Save"
        
      />
    </div>
  )
}

export default CreatingVesselsRecord
