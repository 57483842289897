import React from 'react'
import { Modal, Button } from 'antd';

const Skip = ({ isVisible, handleCancel, handleSkip, title, message,primaryButtonText = 'Skip', }) => {
   return (
        <Modal
          title={title}
          visible={isVisible}
          onCancel={handleCancel}
          footer={[
            <Button
              key="cancel"
              onClick={handleCancel}
              style={{ backgroundColor: '#E2E2E2', color: 'black', border: 'none' }}
              className='cancel-button-stepper'
            >
              Cancel
            </Button>,
            <Button
              key="remove"
              type="primary"
              onClick={handleSkip}
              style={{ backgroundColor: '#005BF3', color: 'white', border: 'none' }}
            >
              {primaryButtonText}
            </Button>,
          ]}
        >
          <p style={{ fontFamily: 'Aeonik TRIAL', fontWeight: 400, fontStyle: 'italic', fontSize: '12px', lineHeight: '13.68px', color: '#737373' }}>
            {message}
          </p>
        </Modal>
      );
    };
    
 
    

export default Skip
