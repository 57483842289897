import React, { useState, useEffect } from 'react';
import { Modal, Input, message } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { postIceClassInfo, patchIceClassInfo } from '../../features/RawData/RawDataSlice';

const IceClass = ({ modal, setModal }) => {
  const [classConditions, setClassConditions] = useState([]);
  const { iceClassInfoStatus, iceClassInfo } = useSelector((state) => state.rawData);
  const [distance, setDistance] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    // Map iceClassInfo to initialClassConditions format
    const initialClassConditions = iceClassInfo?.map((info) => ({
      title: info.fuel_name,
      key: `fuel_id_${info.fuel_id}`, // unique key for each condition
      value: info.total_consumption ? info.total_consumption : 0, // default to 0 if total_consumption is null
      unit: "MT",
      initialValue: info.total_consumption ? info.total_consumption : 0,
      ...info,
    }));
    setClassConditions(initialClassConditions);

    // if (iceClassInfo?.length > 0) {
      setDistance(iceClassInfo[0]?.distance ? iceClassInfo[0]?.distance : 0);
    // }
  }, [iceClassInfo]);

  const handleCancel = () => {
    setModal(false);
  };

  const handleOk = () => {
    let isValid = true;
    const fuelInfo = [];

    classConditions.forEach((condition) => {
      if (condition.value === null || condition.value === "") {
        isValid = false;
      } else {
        fuelInfo.push({
          fuel_id: condition.fuel_id,
          fuel_metadata_id: condition.fuel_metadata_id,
          class_name: condition.class_name,
          fuel_name: condition.fuel_name,
          consumption: parseFloat(condition.value),
        });
      }
    });

    if (!isValid || distance === null || distance === "") {
      message.error("Please input consumption to all the fuels and a valid distance.");
      return;
    }

    const payload = {
      imo_number_id: iceClassInfo[0]?.imo_number_id || "",
      casper_id: iceClassInfo[0]?.casper_id || "",
      total_ice_distance: parseFloat(distance),
      fuel_info: fuelInfo,
    };

    console.log(payload);

    if (!iceClassInfoStatus) {
      dispatch(postIceClassInfo(payload));
    } else {
      const patchPayload = {
        imo_number_id: iceClassInfo[0]?.imo_number_id || "",
        casper_id: iceClassInfo[0]?.casper_id || "",
        total_ice_distance: {
          initial_value: iceClassInfo[0]?.distance || 0,
          final_value: parseFloat(distance),
        },
        fuel_info: classConditions.map((condition) => ({
          fuel_id: condition.fuel_id,
          fuel_metadata_id: condition.fuel_metadata_id,
          class_name: condition.class_name,
          fuel_name: condition.fuel_name,
          consumption: {
            initial_value: parseFloat(condition.initialValue),
            final_value: parseFloat(condition.value),
          },
        })),
      };

      dispatch(patchIceClassInfo(patchPayload));
    }
    setModal(false);
  };

  const handleInputChange = (key, value) => {
    setClassConditions((prevConditions) =>
      prevConditions.map((condition) =>
        condition.key === key ? { ...condition, value } : condition
      )
    );
  };

  const handleKeyDown = (e) => {
    const allowedKeys = ["Backspace", "Tab", "Enter", "Escape", "Delete", ".", "ArrowLeft", "ArrowRight"];
    const isNumber = (e.key >= "0" && e.key <= "9") || allowedKeys.includes(e.key);

    if (!isNumber) {
      e.preventDefault();
    }

    if (e.key === "." && e.target.value.includes(".")) {
      e.preventDefault(); // Prevent multiple dots
    }
  };

  return (
    <div>
      <Modal
        title="Modify Ice Class Conditions"
        open={modal}
        onCancel={handleCancel}
        footer={null}
        width={700}
        className="custom-modal-ice-class"
      >
        <div className='class-conditions-container'>
          <div className='class-conditions'>
            {classConditions.map((condition) => (
              <div className='class-condition' key={condition.key}>
                <div className='class-condition-header'>
                  {condition.title}
                </div>
                <div className='class-condition-content'>
                  <Input
                    type='text'
                    value={condition.value}
                    onChange={(e) => handleInputChange(condition.key, e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <span>{condition.unit}</span>
                </div>
              </div>
            ))}
          </div>

          <div className='distance-container'>
            <div className='distance-header'>Distance (NM)</div>
            <Input
              type="text"
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              className='distance-input'
              onKeyDown={handleKeyDown}
            />
          </div>

          <div className='modal-btns'>
            <div className='cancel-btn' onClick={handleCancel}>Cancel</div>
            <div className='submit-btn' onClick={handleOk}>Submit</div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default IceClass;
