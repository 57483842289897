// import newsImage1 from "../../assets/images/newsroom/newsdisplay/image1.png";
import { useState } from "react";
import { ReactComponent as LikeIcon } from "../../assets/icons/newsroom/newsdisplay/like.svg";
import EditIcon from '../../assets/icons/voyages/edit.svg'
import deleteNotificationIcon from "../../assets/icons/notifications/delete.svg";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal, updateModalInfo } from "../../features/App/AppSlice";
import { newsRoomData, updateLikestatus, setSelectedNewsData, editNews, deleteSelectedNewsRoom, deleteNews } from "../../features/NewsRoom/NewsRoomSlice";
import { Input, DatePicker, Modal, Button, Form, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles


import { useEffect } from "react";
const NewsDisplay = () => {
  const dispatch = useDispatch()
  const { selectedNewsData, newsList, selectedNewsID } = useSelector((state) => state.newsroom)
  const { user } = useSelector((state) => state.user);

  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false)
  const chnageLikestatus = (news_id, status) => {
    const data = {
      "liked": status,
      "news_id": news_id,
    }
    dispatch(updateLikestatus(data)).then((result) => {
      if (result.payload) {
        dispatch(newsRoomData())
      }
    })
  }

  useEffect(() => {
    if (newsList.length !== 0 && selectedNewsID !== null) {
      dispatch(setSelectedNewsData(newsList.filter((value) => value.id === selectedNewsID)[0]))
    }
  }, [newsList])


  if (selectedNewsData === null) {
    return (
      <></>
    )
  }

  const openModal = () => {
    form.resetFields();
    form.setFieldsValue({
      title: selectedNewsData.title,
      description: selectedNewsData.description,
    });
    setModalVisible(true);
  };

  const handleSubmit = async (values) => {
    const { title, description, publishDate, thumbnail } = values;
    const thumbnailFile = thumbnail?.[0]?.originFileObj;
    console.log(thumbnailFile)
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('publish_date', publishDate.format('YYYY-MM-DD'));
    formData.append('id', selectedNewsID)
    if (thumbnailFile) {
      formData.append('thumbnail_file', thumbnailFile);
    }
    dispatch(editNews(formData)).then((result) => {
      if (result.payload) {
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'News Edited Successfully!', type: 'success', message: 'Success!' }));
      } else {
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'News Edit Failed!', type: 'error', message: 'Failed' }));
      }
    })
      .catch((error) => {
        dispatch(updateModalInfo({ show: true, result: error.toString(), type: 'error', message: 'Failed' }))
      });

    setModalVisible(false);
  };

  const showModal = () => {
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    dispatch(deleteSelectedNewsRoom(selectedNewsID))
    dispatch(deleteNews(selectedNewsID)).then((result) => {
      if (!result.payload) {
        dispatch(newsRoomData())
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'News Deleted Successfully!', type: 'success', message: 'Success!' }));
      } else {
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'News Deletion Failed!', type: 'error', message: 'Failed' }));
      }
    })
      .catch((error) => {
        dispatch(updateModalInfo({ show: true, result: error.toString(), type: 'error', message: 'Failed' }))
      });
    setDeleteModal(false)
  };

  const handleCancel = () => {
    setDeleteModal(false);
  };
  return (
    <div className="newsDisplayCard">
      <div className="newsDisplayCard-Image">
        <img src={selectedNewsData.thumbnail_url} alt="news" />
      </div>
      <div>

        <Modal
          title="Edit News"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          className="uploadNewsModal"
          style={{ height: "650px", overflow: "auto" }}
        >
          <Form layout='vertical' form={form} onFinish={handleSubmit}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please input the title' }]}
            >
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true, message: 'Please input the description' }]}
            >
              <ReactQuill placeholder="Description" />
            </Form.Item>
            <Form.Item
              name="publishDate"
              label="Publish Date"
              rules={[{ required: true, message: 'Please select the publish date' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}
              rules={[{ required: true, message: 'Please upload the thumbnail' }]}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          title="Confirm Delete"
          open={deleteModal}
          onOk={handleDelete}
          onCancel={handleCancel}
          footer={[
            <Button className="cancel-button" onClick={handleCancel} style={{ color: 'red', backgroundColor: 'black' }}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" danger onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }}>
              Delete
            </Button>,
          ]}
        >
          <p>Are you sure you want to delete this item?</p>
        </Modal>


      </div>
      <div className="newsDisplayCard-Content">
        <div className="newsDisplayCard-Content-Elem1">
          <div className="newsDisplayCard-Content-Elem1-Heading">
            {/* <h1>BIMCO released their updated template for charter parties.</h1> */}
            <h1>{selectedNewsData.title}</h1>
          </div>
        </div>
        <div className="newsDisplayCard-Content-Elem2">
          <p dangerouslySetInnerHTML={{ __html: selectedNewsData.description }} />
        </div>

        <div className="newsDisplayCard-Content-Elem3" style={{ justifyContent: user.role === 'admin' ? 'space-between' : 'flex-end' }}>
          {user.role === "admin" && (
            <div className="newsDisplayCard-Content-Elem3-edit-delete">
              <img onClick={openModal} src={EditIcon} alt="Edit" />
              <img onClick={showModal} src={deleteNotificationIcon} alt="delete" />
            </div>
          )}
          <div className="newsDisplayCard-Content-Elem3-likes">
            <div className="newsDisplayCard-Content-Elem3-likes-count">
              <p>{selectedNewsData.like}</p>
            </div>


            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <p>{selectedNewsData?.liked_count}</p>
              <LikeIcon onClick={() => { chnageLikestatus(selectedNewsData?.id, !selectedNewsData?.liked_status) }} fill={selectedNewsData?.liked_status === true ? "#00000" : "none"} />
              {/* <img src={likeIcon} alt="like" /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDisplay;
