import React, { useState, useEffect } from 'react';
import { Modal, Input, Button, Select } from 'antd';
import { ReactComponent as EditOutlinedIcon } from '../../assets/icons/rawdata/EditOutlined.svg';
import { EditTwoTone, RollbackOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { patchCasperId } from '../../features/RawData/RawDataSlice';

const EditCasperID = ({ isVisible, handleCancel, handleSubmit, selectedCasperId, selectedUniqueId, selectedIMO }) => {
  const [previousID, setPreviousID] = useState(selectedCasperId ? selectedCasperId : '');
  const [newID, setNewID] = useState('');
  const { casperIdData } = useSelector((state) => state.rawData);
  const [newCasperID, setNewCasperId] = useState('')
  // console.log(selectedCasperId)
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isVisible) {
      setPreviousID('');
      setNewID('');
    }
  }, [isVisible]);


  const options = casperIdData
    ? [
      {
        label: <span>Suggested new Casper Id</span>,
        title: 'Suggestion',
        options: [
          {
            label: (
              <span>
                <EditTwoTone style={{ width: "20px" }} /> {casperIdData.new_casper_id}
              </span>
            ),
            value: casperIdData.new_casper_id
          }
        ]
      },
      {
        label: <span>Already available casper Id's for selected IMO number</span>,
        title: 'Old Records',
        options: casperIdData.casper_id_suggestions
          ? casperIdData.casper_id_suggestions.map((item) => {
            return {
              label: (
                <span>
                  <RollbackOutlined style={{ width: "20px", color: "rgb(22, 119, 255)" }} /> {item}
                </span>
              ),
              value: item
            };
          })
          : []
      }
    ]
    : [];

  const onSubmit = () => {

    const params = {
      imo_number: selectedIMO,
      old_casper_id: selectedCasperId,
      new_casper_id: newCasperID,
      unique_id: selectedUniqueId,
      user_remarks: ''
    }
    dispatch(patchCasperId(params))

    handleSubmit(previousID, newID);
    setPreviousID('');
    setNewID('');
  };


  const handleChange = (value) => {
    setNewCasperId(value);
  };

  return (
    <Modal
      title={
        <div style={{ display: 'flex', alignItems: 'center', color: 'black' }}>
          <EditOutlinedIcon style={{ marginRight: 8 }} />
          Edit Casper ID
        </div>
      }
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} style={{ backgroundColor: '#E2E2E2' }}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={onSubmit} style={{ backgroundColor: '#678DFF', color: 'white' }}>
          Submit
        </Button>,
      ]}
    >
      <div>
        <label style={{ color: '#678DFF' }}>Previous ID</label>
        <Input
          value={selectedCasperId}
          onChange={(e) => setPreviousID(e.target.value)}
        // placeholder="24324234234324"
        />
      </div>
      <div style={{ marginTop: 16 }}>
        <label style={{ color: '#678DFF' }}>Enter New ID</label>
        {casperIdData ? (
          <Select
            style={{ width: '100%' }}
            onChange={handleChange}
            options={options}
            placeholder="Select Casper ID"
          />
        ) : (
          <p>Loading Casper ID options...</p>
        )}
      </div>
    </Modal>
  );
};

export default EditCasperID;
