
import { Tabs } from 'antd';
import EUADistribution from './EUADistribution';
import { HistoricalVoyage } from './HistoricalVoyage';
import BarChart from '../chart/BarGraph';

const items = [
    {
      key: '1',
      label: 'Historical Voyages',
      children: <HistoricalVoyage />,
    },
    {
      key: '2',
      label: 'EUA Distribution',
      children:<EUADistribution/>
    }
  ];
  
  const onChange = (key) => {
  };

const EUATab = () => {
    return(
        <Tabs defaultActiveKey="1" items={items} 
        centered="true"
        indicatorSize="20px"
        style={{
        //   marginBottom: 32,
        //   width: "100%",
          // height: "100%",
          // border: "2px solid black"
        }}
        onChange={onChange} />

    )
}

export default EUATab