import React, { useEffect } from 'react';
import StepperFirstBasicDetails from './StepperFirstBasic';
import StepperFirstEditFuels from './StepperFirstEditFuels';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFuelInfoByUniqueID } from '../../../features/RawData/RawDataSlice';

const StepperFirst = ({ selectedUniqueId, selectedCardsWithEssentialData, setSelectedCardsWithEssentialData }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchFuelInfoByUniqueID(selectedUniqueId));
  }, [dispatch, selectedUniqueId]);
  return (
    <div className='stepper-step'>
      <StepperFirstBasicDetails selectedUniqueId={selectedUniqueId} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />
      <StepperFirstEditFuels selectedUniqueId={selectedUniqueId} />
    </div>
  );
};

export default StepperFirst;