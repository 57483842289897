import React, { useEffect } from 'react'
import { getDues, getTransactions } from '../features/Transaction/TransactionSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Button } from 'antd'
import { downloadCSV } from '../utils/utils'
const SmallTable = ({ type, columns }) => {

  const dispatch = useDispatch()
  const { transactions, dues } = useSelector((state) => state.transactions)
  useEffect(() => {
    switch (type) {
      case "transactions":
        if (transactions.length === 0)
          dispatch(getTransactions(type))
        return
      case "dues":
        if (dues.length === 0)
          dispatch(getDues(type))
        return
      default:
        return
    }
  }, [dispatch, type])

  return (
    <div>
      <div style={{ display: 'flex', alignContent: 'flex-end', marginBottom: '16px', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => downloadCSV(type === 'transactions' ? transactions : dues)}
          type="primary"
        >
          Download
        </Button>
      </div>
      <Table
        dataSource={type === "transactions" ? transactions : dues}
        columns={columns}
        rowClassName="editable-row"
        scroll={{ y: "40vh" }}
        pagination={false}
        responsive
      />
    </div>
  )
}

export default SmallTable