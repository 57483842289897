import React, { useEffect, useState } from 'react';
import { Tabs, List, Modal, Button } from 'antd';
import '../css/notifications.css';
import FreemiumTable from './FreemiumTable';
import Clipboard from "../assets/icons/voyages/clipboard-import.svg";
import FreemiumReport from '../pages/freemium/FreemiumReport';

const FreemiumTabComponent = ({ showModal, setShowModal }) => {

    const [isModalVisible, setModalVisible] = useState(false);
    const [dataToShow, setDataToShow] = useState(null);

    const handleOk = () => {
        setShowModal(false);
    };
    const handleCancel = () => {
        setShowModal(false);
    };


    const freemiumData = [
        {
            title: "IMO Number",
            dataIndex: "imo_number",
        },
        {
            title: "Vessel Name",
            dataIndex: "vessel_name",
        },
        {
            title: "Voyage Number",
            dataIndex: "voyage_number",
        },
        {
            title: "Date Created",
            dataIndex: "date_created",
        },
        {
            title: "Download",
            dataIndex: "",
            render: (_, record) => (
                <Button
                    type="link"
                    icon={<img src={Clipboard} alt="clipboard" />}
                    onClick={() => handleDownload(record)}
                />
            ),
        },

    ];


    const handleDownload = (record) => {
        console.log("Row data:", record);
        setDataToShow(record)
        openModal()
    }
    const openModal = () => {
        setModalVisible(true);
    };

    return (
        <>
            <Modal title="Download Freemium Report" width={"60%"} footer={[]} open={showModal} onOk={handleOk} onCancel={handleCancel}>

                <FreemiumTable closeModal={handleOk} columns={freemiumData} />

            </Modal>

            <FreemiumReport isModalVisible={isModalVisible} setModalVisible={setModalVisible} dataToShow={dataToShow} />
        </>
    );
};
export default FreemiumTabComponent;