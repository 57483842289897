import VoyageOptimization from "../../container/phase2/VoyageOpimization";

const VoyageOptimizationPage = () => {
    return (
        <main className="main-board">
            <VoyageOptimization />
        </main>
    );
};

export default VoyageOptimizationPage;
