import React, { useState, useEffect } from 'react';
import { List, Avatar, Card, Divider, Button } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import listIcon from '../../../assets/icons/rawdata/uim_layer-group.png';
import listIconDisabled from '../../../assets/icons/rawdata/uim_layer-group-disabled.png';
import '../../../css/sequentialFlow.css';

const SequentialFlowList = ({ currentStep, selectedUniqueId, setSelectedUniqueId, selectedCardsWithEssentialData, isNextButtonClicked, setIsNextButtonClicked }) => {

  const [data, setData] = useState([]);

  const [highlightedItems, setHighlightedItems] = useState([]);
  const { status, rawData, selectedRecordsForSequentialFlow, selectedRecordsPerPageUniqueIds } = useSelector((state) => state.rawData);
  const selectedCards = Object.values(selectedRecordsPerPageUniqueIds).reduce((acc, curr) => acc.concat(curr), []);

  useEffect(() => {
    if (!selectedCards.length || !Object.keys(selectedRecordsForSequentialFlow).length) {
      return;
    }
    const filteredData = selectedCards.flatMap(cardId => {
      return selectedRecordsForSequentialFlow[cardId]?.map(item => ({
        id: item.id,
        dateTime: item.utc_date_time,
        event: item.event,
        isSelected: item.unique_id === selectedUniqueId,
        isModified: false,
        unique_id: item.unique_id
      })) || [];
    }).sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime)); // Sort by date
    if (filteredData.length > 0 && !selectedUniqueId) {
      filteredData[0].isSelected = true; // Default to first item if no selectedUniqueId is provided
      setSelectedUniqueId(filteredData[0].unique_id); // Set the default selected unique_id
    }
    setData(filteredData);
    // console.log(filteredData)
  }, [selectedRecordsForSequentialFlow]);

  const handleSelect = (id, unique_id) => {
    if (currentStep === 0) {
      const updatedData = data.map(item =>
        item.id === id ? { ...item, isSelected: true } : { ...item, isSelected: false }
      );
      setSelectedUniqueId(unique_id);
      // console.log(selectedCardsWithEssentialData[unique_id])
      setData(updatedData);
    }
  };

  const disabledStyle = currentStep !== 0 ? {
    color: '#B4B4B4',
    backgroundColor: '#fff',
    pointerEvents: 'none'
  } : {};



  useEffect(() => {
    if (isNextButtonClicked) {
      // Identify items with any false value in essential data
      const itemsToHighlight = data.filter(item => {
        const essentialData = selectedCardsWithEssentialData[item.unique_id];
        return essentialData && Object.values(essentialData).includes(false);
      });
      setHighlightedItems(itemsToHighlight.map(item => item.unique_id));

      console.log(itemsToHighlight.map(item => item.unique_id))

      // Reset the highlightedItems after a short period
      const timer = setTimeout(() => {
        setHighlightedItems([]);
        // Optionally reset the isNextButtonClicked state if needed
        setIsNextButtonClicked(false); // Uncomment if you have a state to reset
      }, 3000000); // Highlight for 3 seconds

      // Cleanup timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [isNextButtonClicked, data, selectedCardsWithEssentialData]);


  return (
    <Card className="Sequential-Flow-List" style={disabledStyle}>
      <div className='Sequential-Flow-List-Header'>
        <div>
          <p className='head-sequenflow'>Report Date & Time</p>
          <p className='subhead-sequenflow'>{selectedCards.length} Records Selected</p>
        </div>
        {currentStep === 0 && <Button className='stepper-modified-button'>Modified</Button>}
      </div>

      <Divider />

      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item
            onClick={() => handleSelect(item.id, item.unique_id)}
            className={item.isSelected ? 'selected' : ''}
            style={{
              border: currentStep !== 0 ? '#fff' : item.isSelected ? '1px solid #d9d9d9' : item.isModified ? '1px solid #9747FF' : '#fff',
              borderRadius: '8px',
              padding: '8px',
              marginBottom: '8px',
              cursor: currentStep === 0 ? 'pointer' : 'default',
              backgroundColor: highlightedItems.includes(item.unique_id)
                ? '#ffdada' : currentStep !== 0 ? '#fff' : item.isSelected ? '#e6f7ff' : item.isModified ? '#9747FF1A' : '#fff',
              ...disabledStyle
            }}
          >
            <List.Item.Meta
              avatar={<Avatar src={currentStep !== 0 ? listIconDisabled : listIcon} />}
              title={<span className='stepper-list-title' style={{ ...disabledStyle }}>{item.dateTime}</span>}
              description={<span className='stepper-list-description' style={{ ...disabledStyle }}>Event : {item.event}</span>}
            />
            {item.isSelected && <CheckOutlined style={{ marginLeft: '10px', color: '#005BF3', ...disabledStyle }} />}
          </List.Item>
        )}
      />
    </Card>
  );
};

export default SequentialFlowList;
