import * as React from 'react';
import { useState, useEffect } from "react"
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import '../../css/offhiremodal.css';
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect from '@mui/material/Select';
import { fetchOffHireData, postOffHireRequest } from '../../features/Voyage/VoyageSlice'
import { useDispatch } from 'react-redux'
import { Form, Input, Select, DatePicker, Button } from 'antd';

import { fetchPortList } from "../../features/Voyage/VoyageSlice";
import { fetchFuelList } from "../../features/Voyage/VoyageSlice";

import moment from 'moment';


const OffHireModal = ({ open, handleClose, voyageNumber, imoNumber, offHireData }) => {


    const dispatch = useDispatch()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '600px',
        height: '80vh',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    // const formattedDate = (dateString) => {
    //     console.log(moment(dateString))
    //     return moment(dateString);
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log(startDateTimme)

        if (!fuelFields[0].fuelName || !fuelFields[0].fuelQuantity || !initialPort || !finalPort || !startDateTimme || !endDateTimme) {

            alert("Please fill all the required fields")
        } else {

            const newList = fuelFields.map(({ fuelName, fuelQuantity }) => ({
                fuel_name: fuelName,
                quantity: fuelQuantity,
            }));
            const finalData = {
                fuel_list: newList,
                voyage_number: voyageNumber,
                imo_number: imoNumber,
                // from_port: initialPort,
                // to_port: finalPort,

                from_port_info: {
                    port_name: initialPort.substring(0, getIndex(initialPort)),
                    country: initialPort.substring((getIndex(initialPort) + 2))
                },
                to_port_info: {
                    port_name: finalPort.substring(0, getIndex(finalPort)),
                    country: finalPort.substring((getIndex(finalPort) + 2))
                },

                start_datetime: startDateTimme.format('YYYY-MM-DD HH:mm:ss'),
                end_datetime: endDateTimme.format('YYYY-MM-DD HH:mm:ss'),
            };

            console.log(finalData)
            await handlePostRequest(finalData);


            setInitialPort(); setFinalPort(); setStartDateTimme(); setEndDateTimme();
            setFuelList([])
            setFuelFields([{ fuelName: '', fuelQuantity: '' }])
            handleClose()
        }
    };


    const getIndex = str => str.indexOf(',');


    const handlePostRequest = (finalData) => {
        dispatch(postOffHireRequest(finalData)).then((result) => {
            if (result.payload) {
                const params = {
                    voyage_number: voyageNumber,
                    imo_number: imoNumber
                }
                dispatch(fetchOffHireData(params))
            }
        })
    }
    //Dropdown
    const theme = useTheme();
    const [personName, setPersonName] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };



    function getStyles(name, personName, theme) {
        return {
            fontWeight:
                personName.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const fuelListmapper = {
        diesel_gas_oil: "Diesel/Gas Oil (MGO/MDO)",
        light_fuel_oil: "Light Fuel Oil (LFO)",
        heavy_fuel_oil: "Heavy Fuel Oil (HFO)",
        liquefied_petroleum_gas: "Liquefied Petroleum Gas (LPG)",
        liquefied_natural_gas: "Liquefied Natural Gas (LNG)",
        methanol: "Methanol",
        ethanol: "Ethanol",
        bio_methanol: "Bio-methanol",
        e_methanol: "e-methanol",
        bio_gasoil: "Bio-gasoil",
        e_gasoil: "e-gasoil",
        e_lng: "e-LNG",
        hydrogen: "Hydrogen",
        e_hydrogen: "e-Hydrogen",
        ammonia: "Ammonia",
        e_ammonia: "e-Ammonia",
        electricity: "Electricity"
    }

    const [fuelFields, setFuelFields] = useState([{ fuelName: '', fuelQuantity: '' }])

    const initializeFuelFields = () => {
        if (offHireData && offHireData.length > 0) {
            const fuelListArray = Object.entries(offHireData[0].fuel_list).map(([fuelName, fuelQuantity]) => ({
                fuelName: fuelListmapper[fuelName],
                fuelQuantity,
            }));
            setFuelFields(fuelListArray);
            setInitialPort(offHireData[0].from_port)
            setFinalPort(offHireData[0].to_port)
            setStartDateTimme(moment(offHireData[0].start_datetime))
            setEndDateTimme(moment(offHireData[0].end_datetime))
        } else {
            setFuelFields([{ fuelName: '', fuelQuantity: '' }]);
            setStartDateTimme()
            setEndDateTimme()
        }

    };

    useEffect(() => {
        initializeFuelFields();
    }, [open, offHireData]);
    const [fuelList, setFuelList] = useState([]);
    const handleChangeFuel = (event, index) => {
        const { value } = event.target;
        const updatedFields = [...fuelFields];
        updatedFields[index] = { ...updatedFields[index], fuelName: value };
        setFuelFields(updatedFields);
    };

    const handleQuantityChange = (event, index) => {
        const { value } = event.target;
        const parsedValue = parseFloat(value);
        const updatedFields = [...fuelFields];
        updatedFields[index] = { ...updatedFields[index], fuelQuantity: parsedValue };
        setFuelFields(updatedFields);
    };


    const addFuelField = () => {

        const isAnyEmpty = fuelFields.some(
            (field) => field.fuelName === '' || field.fuelQuantity === ''
        );

        if (isAnyEmpty) {
            return;
        }


        setFuelFields((prevFields) => [
            ...prevFields,
            { fuelName: '', fuelQuantity: '' },
        ]);
        updateFuelList("no");
    };

    const updateFuelList = () => {
        const newList = fuelFields.map(({ fuelName, fuelQuantity }) => ({
            fuel_name: fuelName,
            quantity: fuelQuantity,
        }));
        setFuelList(newList);
    };
    const [startDateTimme, setStartDateTimme] = useState();
    const [endDateTimme, setEndDateTimme] = useState();
    const [initialPort, setInitialPort] = useState();
    const [finalPort, setFinalPort] = useState();
    const [initialportList, setInitialPortList] = useState()
    const [finalPortList, setFinalPortList] = useState()
    const handlePortSearch = (value, isInitialPort) => {
        if (isInitialPort) {
            dispatch(fetchPortList(value)).then((result) => {
                if (result.payload) {
                    setInitialPortList(result.payload);
                }
            });
        } else {
            dispatch(fetchPortList(value)).then((result) => {
                if (result.payload) {
                    setFinalPortList(result.payload);
                }
            });
        }

    };


    const [fuelListArray, setFuelListArray] = useState([])
    const handleDropDown = async () => {
        try {
            const resultAction = await dispatch(fetchFuelList());
            if (fetchFuelList.fulfilled.match(resultAction)) {
                const result = resultAction.payload;
                if (result) {
                    const fuelNames = result.map((fuel) => fuel.fuel_name);
                    setFuelListArray(fuelNames);
                }
            }
        } catch (error) {
            console.error('Error fetching fuel list:', error);
        }

    };

    useEffect(() => {
        handleDropDown();
    }, []);

    const disabledEndDate = (current) => {
        // Check if startDateTimme is defined before accessing startOf('day')
        return current && startDateTimme && current < startDateTimme.startOf('day');
    };
    const disabledStartDate = (current) => {
        // Disable dates after the selected end date
        return current && endDateTimme && current > endDateTimme.startOf('day');
    };


    return (


        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Off Hire Data
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    <form className='OffHireForm'
                        name="OffHireForm"
                        onFinish={handleSubmit}
                    // initialValues={{ remember: true }}
                    >
                        <div className='OffhireContainer'>
                            <div className='OffHireFormElement'>
                                <p>General Details</p>
                                <div className='OffHireFormElement'>
                                    <div className='offhirefields'>
                                        <div className='offhirefieldsInputs'>
                                            <Form.Item label="Voyage Number" name="voyageNumber">
                                                <Input placeholder={voyageNumber} disabled />
                                            </Form.Item>
                                        </div>
                                        <div className='offhirefieldsInputs'>
                                            <Form.Item label="IMO Number" name="imoNumber">
                                                <Input placeholder={imoNumber} disabled />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div className='offhirefields'>
                                        <div className='offhirefieldsInputs'>


                                            <Form.Item
                                                name="fromPort"
                                                label="From Port"
                                                rules={[{ required: true, message: "Please input initial port!" }]}
                                            >
                                                <Select
                                                    showSearch
                                                    onSearch={(value) => handlePortSearch(value, true)}
                                                    onChange={(value) => setInitialPort(value)}
                                                    value={initialPort}
                                                    defaultValue={offHireData && offHireData.length > 0 ? `${offHireData[0].from_port_info.port_name}, ${offHireData[0].from_port_info.country}` : ""}
                                                    onKeyDown={(e) => {
                                                        const allowedKeys = /^[a-zA-Z ]$/;
                                                        if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    {initialportList &&
                                                        initialportList.map((port, index) => (
                                                            <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                                                {port.port_name}, {port.country}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>




                                        </div>
                                        <div className='offhirefieldsInputs'>


                                            <Form.Item
                                                name="toPort"
                                                label="To Port"
                                                rules={[{ required: true, message: "Please input final port!" }]}
                                            >


                                                <Select
                                                    showSearch
                                                    onSearch={(value) => handlePortSearch(value, false)}
                                                    onChange={(value) => setFinalPort(value)}
                                                    defaultValue={offHireData && offHireData.length > 0 ? `${offHireData[0].to_port_info.port_name}, ${offHireData[0].to_port_info.country}` : ""}
                                                    value={finalPort}
                                                    onKeyDown={(e) => {
                                                        const allowedKeys = /^[a-zA-Z ]$/;
                                                        if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                >
                                                    {finalPortList &&
                                                        finalPortList.map((port, index) => (
                                                            <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                                                {port.port_name}, {port.country}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>


                                        </div>

                                    </div>
                                    <div className='offhirefields'>
                                        <div className=' offhirefieldsInputs offHireDateTime'>
                                            <Form.Item
                                                name="startDateTime"
                                                label="Start Date-Time"
                                                rules={[{ required: true, message: "Please input start date-time!" }]}
                                            >
                                                <DatePicker defaultValue={startDateTimme} disabledDate={disabledStartDate} onChange={(value) => setStartDateTimme(value)} showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                                            </Form.Item>

                                            <Form.Item
                                                name="endDateTime"
                                                label="End Date-Time"
                                                rules={[{ required: true, message: "Please input end date-time!" }]}
                                            >
                                                <DatePicker defaultValue={endDateTimme} disabledDate={disabledEndDate} onChange={(value) => setEndDateTimme(value)} showTime={{ format: 'HH:mm:ss' }} format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='OffHireFormElement scrollOFFHire' >
                                <p>Fuel Details</p>
                                {fuelFields.map((field, index) => (
                                    <div key={index} className='offhirefields'>
                                        <div className='offhirefieldsInputs'>
                                            <label>Fuel Name</label>
                                            <FormControl>
                                                <MuiSelect
                                                    displayEmpty
                                                    value={field.fuelName}
                                                    onChange={(e) => handleChangeFuel(e, index)}
                                                    input={<OutlinedInput />}
                                                    renderValue={(selected) => {
                                                        if (!selected) {
                                                            return <em>Select Fuel</em>;
                                                        }
                                                        return selected;
                                                    }}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ "aria-label": "Without label" }}
                                                >
                                                    {fuelListArray.map((name) => (
                                                        <MenuItem
                                                            key={name}
                                                            value={name}
                                                            style={getStyles(name, personName, theme)}
                                                        >
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                                </MuiSelect>
                                            </FormControl>
                                        </div>
                                        <div className='offhirefieldsInputs'>
                                            <label>Fuel Quantity</label>
                                            <input
                                                type="number"
                                                step="any"
                                                value={field.fuelQuantity}
                                                onChange={(e) => handleQuantityChange(e, index)}
                                                autoComplete='off'
                                            />
                                        </div>
                                    </div>
                                ))}

                                {fuelFields.some(
                                    (field) => field.fuelName === '' || field.fuelQuantity === ''
                                ) ? (
                                    <span className='plusicomn untouchable'>+</span>
                                ) : (
                                    <span className='plusicomn' onClick={addFuelField}>+</span>
                                )}

                            </div>
                        </div>
                        <div className='submitOffButton'>
                            {/* <Form.Item>
                                <Button type="primary" htmlType="submit" style={{ marginTop: "18px" }}>
                                    Submit
                                </Button>
                            </Form.Item> */}
                            <button onClick={handleSubmit}>Submit</button>

                        </div>
                    </form>
                </Typography>
            </Box>
        </Modal >
    );
};

export default OffHireModal;
