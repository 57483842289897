import React, { useState, useEffect } from 'react';
import { ReactComponent as ChangeIcon } from "../../assets/icons/rawdata/icon-park-outline_change.svg";
import { ReactComponent as Arrow } from "../../assets/icons/rawdata/iconoir_arrow-up.svg";
import { useDispatch } from 'react-redux';
import { getAuditDetails } from '../../features/RawData/RawDataSlice';

const ChangesMade = ({ auditDetails, selectedRecordDateId }) => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("Voyage");
    useEffect(() => {
        setActiveTab("Voyage");
    }, [selectedRecordDateId]);

    useEffect(() => {
        const activeTabOption = dataOptions.find(option => option.name === activeTab);
        if (selectedRecordDateId && activeTabOption) {
            const payload = {
                id: selectedRecordDateId,
                req_type: activeTabOption.requestType
            };
            dispatch(getAuditDetails(payload));
        }
    }, [activeTab]);

    const dataOptions = [
        { name: "Voyage", drop_down: false, requestType: "VOYAGE_DATA" },
        { name: "Vessel", drop_down: false, requestType: "VESSEL_DATA" },
        { name: "Cargo", drop_down: false, requestType: "CARGO_DATA" },
        { name: "Fuel Consumption", drop_down: false, requestType: "FUEL_CONSUMPTION_DATA" },
        { name: "RoB", drop_down: false, requestType: "ROB_DATA" },
        { name: "Emission Factor(TTW)", drop_down: true, requestType: "EF_TTW_DATA" },
        { name: "Emission Factor(WTT)", drop_down: true, requestType: "EF_WTT_DATA" },
        { name: "Bunker Survey", drop_down: true, requestType: "BUNKER_SURVEY_DATA" },
        { name: "Bunker Quantity", drop_down: true, requestType: "BUNKER_QTY_DATA" },
        { name: "Lower Calorific Value", drop_down: false, requestType: "LCV_DATA" },
        { name: "Cslip", drop_down: false, requestType: "CSLIP_DATA" },
    ];

    const handleTabClick = (name) => {
        setActiveTab(name);
    };


    const toTitleCase = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
    };

    return (
        <div className='changes-made'>
            <div className='changes-made-title'>
                <ChangeIcon style={{ width: "4vh", height: "4vh" }} />
                <span>Changes Made</span>
            </div>
            <div className='changes-made-options'>
                {dataOptions.map((option, index) => (
                    <div
                        key={index}
                        className={`${activeTab === option.name ? 'changes-made-option-true' : 'changes-made-option-false'}`}
                        onClick={() => handleTabClick(option.name)}
                        style={{ cursor: 'pointer' }}
                    >
                        <span>{option.name}</span>
                    </div>
                ))}
            </div>
            <div className='changes-made-footer'>
                {auditDetails?.compare_data?.length > 0 ? auditDetails?.compare_data.map((data, index) => (
                    <>
                        <div key={index} className='changes-made-ports'>
                            <h4>{toTitleCase(data.key)}</h4>
                            <div className='port-data-container'>
                                <div className='port-data'>
                                    <div className='header' style={{ padding: '10px 0' }}>Previous</div>
                                    <div className='data'>{data.previous}</div>
                                </div>
                                <Arrow style={{ width: "2vh", height: "2vh" }} />
                                <div className='port-data'>
                                    <div className='header' style={{ padding: '10px 0' }}>After</div>
                                    <div className='data'>{data.after}</div>
                                </div>
                            </div>
                        </div>
                        <hr /></>
                ))
                    :
                    <div className='changes-made-ports'>
                        <h4>No Changes Made</h4>
                    </div>
                }
            </div>
        </div>
    );
};

export default ChangesMade;
