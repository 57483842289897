import React from 'react'
import FreemiumPage from './FreemiumPage'
import FreemiumResult from './FreemiumResult'
import { useState } from 'react'

const Freemium = () => {

    const [data, setData] = useState(null);
    const updateData = (newData) => {
        setData(newData);
    };

    return (
        <main className="main-board">
            <div className="voyage-dashboard">
                <div className="voyage-item2 freemiumPage">
                    <FreemiumPage updateData={updateData} dataToShow={data} />
                </div>
                <div className="voyage-item">
                    <FreemiumResult data={data} />
                </div>
            </div>
        </main>
    )
}

export default Freemium