import React from "react";
import SingleProgress from "../../container/cards/SingleProgress";
import Vessel from "../../container/vessel/Vessel";
import VesselOverview from "../../container/vessel-overview/VesselOverview";
import { ConfigProvider } from "antd";
import { useSelector } from "react-redux";

const Vessels = () => {

  const selectedVessel = useSelector((state) => state.vessel.selectedVessel)
  const eua_current_percent = ((selectedVessel?.eua_current) / (selectedVessel?.eua_due + selectedVessel?.eua_current)) * 100
  const eua_due_percent = ((selectedVessel?.eua_due) / (selectedVessel?.eua_due + selectedVessel?.eua_current)) * 100
  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            lineBorderRadius: 8,
          },
          Descriptions: {
            itemPaddingBottom: "10px"
          }
        },
      }}
    >
      <main className="main-board">
        <div className="vessal-dashboard">
          <SingleProgress
            title={"EUA Current"}
            description={selectedVessel?.eua_current !== null ? selectedVessel?.eua_current : "0.0"}
            percent={eua_current_percent}
            color={"#55D99F"}
            textColor={"#377E36"}
            tagColor={"#E0F0E4"}
          />
          <SingleProgress
            title={"EUA Due"}
            description={selectedVessel?.eua_due}
            percent={eua_due_percent}
            color={"#FFAAAA"}
            textColor={"#FF8585"}
            tagColor={"rgba(255, 133, 133, 0.20)"}
          />
          <div className="vessel-item1">
            <Vessel />
          </div>
          <div className="vessel-item2">
            <VesselOverview />
          </div>
        </div>
      </main>
    </ConfigProvider>
  );
};

export default Vessels;
