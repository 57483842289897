import { createSlice } from "@reduxjs/toolkit";

const appslice = createSlice({
    name:"appslice",
    initialState: {
      modalInfo: {
        show: false, 
        result: '', 
        message: '',
        type: '' 
      },
      postModalVisible: false
    },
    reducers: {
        updateModalInfo: (state, action) => {
          const { show, result, message, type } = action.payload;
          state.modalInfo = { show, result, message, type };
        },
        toggleModal: (state) => {
          state.postModalVisible = !state.postModalVisible
        }
      }
})

export const { updateModalInfo, toggleModal } = appslice.actions;

export default appslice.reducer;