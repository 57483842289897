import React from 'react'
import { Skeleton } from 'antd'
const SkeltonTable = () => {
  return (
    <>
            <div className="card">
              <Skeleton active />
            </div>
            <div className="card">
              <Skeleton active />
            </div>
            <div className="card">
              <Skeleton active />
            </div>
          </>
  )
}

export default SkeltonTable