import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Table, Button } from 'antd'
import { formatDate, downloadCSV } from "../utils/utils"
import { getEUPortsEnteringVessels } from '../features/EUA/EuaSlice'

const EUPortEnterTable = (downloadButtonVisible) => {
  console.log(downloadButtonVisible.downloadButtonVisible)
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [formatedVesselsData, setFormattedVesselsData] = useState([]);
  const euportsenteringvessels = useSelector((state) => state.eua.euportsenteringvessels)
  const [hasMoreData, setHasMoreData] = useState(true);
  useEffect(() => {
    const updatedVessels = euportsenteringvessels.map(vessel => ({
      ...vessel,
      as_of_date: formatDate(vessel.as_of_date)
    }));
    setFormattedVesselsData(updatedVessels);
  }, [euportsenteringvessels]);

  useEffect(() => {
    const handleScroll = (e) => {
      const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 5;
      if (bottom && hasMoreData) {
        const nextPage = currentPage + 1;
        dispatch(getEUPortsEnteringVessels(nextPage))
          .then((action) => {

            if (!action.payload.hasMore) {
              setHasMoreData(false);
            }
          })
          .catch((error) => {
            console.error("Failed to fetch data:", error);
          });

        setCurrentPage(nextPage);
      }
    };

    const tableElement = tableRef.current;
    if (tableElement) {
      tableElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (tableElement) {
        tableElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dispatch, currentPage, hasMoreData]);

  const euportsenteringvessels_fields = [
    {
      dataIndex: "imo_number",
      title: "IMO Number",
    },
    {
      dataIndex: "vessel_name",
      title: "Vessel Name",
    },
    {
      dataIndex: "voyage_number",
      title: "Voyage Number",

    },
    {
      dataIndex: "eu_port",
      title: "EU Port",
    },
    {
      dataIndex: "event",
      title: "Event",
    },
    {
      dataIndex: "as_of_date",
      title: 'Voyage Date'
    }
  ]


  return (
    <div className={downloadButtonVisible.downloadButtonVisible ? "" : "notificationContainer"}>
      {downloadButtonVisible.downloadButtonVisible ? <div style={{ display: 'flex', alignContent: 'flex-end', marginBottom: '16px', justifyContent: 'flex-end' }}>
        <Button
          onClick={() => downloadCSV(euportsenteringvessels)}
          type="primary"
        >
          Download
        </Button>
      </div> : ''}

      <div ref={tableRef} style={{ overflowY: 'auto', overflowX: "hidden", height: downloadButtonVisible.downloadButtonVisible ? '70vh' : '25vh' }}>
        <Table
          dataSource={formatedVesselsData}
          columns={euportsenteringvessels_fields}
          rowKey={record => `${record.imo_number}-${record.voyage_number}`}
          rowClassName="editable-row"
          pagination={false}
          responsive
        />
      </div>
    </div>

  )
}

export default EUPortEnterTable