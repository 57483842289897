import React, { useEffect, useState } from 'react'
import Online from "../../assets/icons/voyages/Online.svg"
import Clipboard from "../../assets/icons/voyages/clipboard-import.svg"
import Invoice from "../../assets/icons/voyages/Invoice.svg"
import ClipboardText from "../../assets/icons/voyages/clipboard-text.svg"
import ClipboardExport from "../../assets/icons/voyages/clipboard-export.svg"
import ClipboardDelete from "../../assets/icons/voyages/clipboard-delete.svg"
import format from 'date-fns/format'
import { DatePicker, Tag } from 'antd'
import Report from "../../assets/icons/voyages/Report.svg"
import Stats from '../../components/Statistics'
import { fetchInvoice, fetchStatementDocument, fetchVoyageEuaOverview } from '../../features/Voyage/VoyageSlice'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import EmissionTemplate from "../../assets/files/Emission.pdf"
import InvoiceTemplate from "../../assets/files/Invoice_Template.pdf"
import OrderTemplate from "../../assets/files/Trade-order_template.pdf"
import DownloadReport from '../Invoice/DownloadReport'
import ReportPdf from '../pdfcontainer/ReportPdf'
import DownloadInvoice from '../Invoice/DownloadInvoice'
import DownloadOrder from '../Invoice/DownloadOrder'
import DownloadDocument from '../Invoice/DownloadDocument'
import DeleteDocument from '../Invoice/DeleteDocument'
const { RangePicker } = DatePicker

const EuaOverview = () => {

    const dispatch = useDispatch()
    const [customDate, setCutomDate] = useState(null)
    const [data, setData] = useState([])
    const [spot, setSpot] = useState("")
    const { selectedVoyage, statementDocument, order, downloadDocument, invoice } = useSelector((state) => state.voyage)
    const [downloadUrl, setVoyageRes] = useState(false)
    const { user } = useSelector((state) => state.user)
    // console.log(user?.role)
    useEffect(() => {
        if (selectedVoyage !== null) {
            dispatch(fetchVoyageEuaOverview(selectedVoyage)).then((result) => {
                const temp = result.payload
                setSpot(` €${temp.spot_price}`)
                setVoyageRes(temp?.url_verified_statement_uploaded_by_admin)
                const tempdata = [
                    {
                        label: "Voyage No.",
                        value: temp.voyage_number,
                    },
                    {
                        label: "EUA Required",
                        value: temp.eua_outstanding,
                    },
                    {
                        label: "EUA Due",
                        value: temp.eua_due,
                    },
                    {
                        label: "Reported Date",
                        value: temp.due_date,
                    },
                    {
                        label: "Off Hire EUA",
                        value: temp.off_hire,
                    },
                    // {
                    //     label: "Charterer EUA",
                    //     value: temp.charterer_eua,
                    // },
                    {
                        label: "Cost of EUA in Euros",
                        value: "€" + temp.cost.toFixed(2),
                    },
                    {
                        label: "Spot Price",
                        value: "€" + temp.spot_price
                    }
                ]
                setData(tempdata)
            })
            dispatch(fetchStatementDocument({ ...selectedVoyage, start_date: "", end_date: "" }))
            dispatch(fetchInvoice({ ...selectedVoyage }))
        }
    }, [selectedVoyage, dispatch])



    const DateChangeHandler = (date) => {
        setCutomDate(date)
        if (date !== null) {
            const temp = {
                ...selectedVoyage,
                start_date: format(new Date(date[0]), "yyyy-MM-dd"),
                end_date: format(new Date(date[1]), "yyyy-MM-dd")
            }
            dispatch(fetchStatementDocument(temp))
        }
        else {
            const temp = {
                ...selectedVoyage,
                start_date: "",
                end_date: ""
            }
            dispatch(fetchStatementDocument(temp))
        }
    }


    const handleDownload = () => {
        alert("Download")
    }

    return (
        <div className='card eua-overview-card'>
            <div className="eua-overview-head">
                <div className="eua-overview-head-title">
                    {downloadDocument !== null ? <img src={Online} alt="online" /> : ''}
                    <span className='card-title-span'>EUA Overview</span>
                    <Tag color={"#06C270"}>Spot price {spot}</Tag>
                </div>
                {selectedVoyage === null ? "" :
                    <div className="eua-overview-head-icons">
                        {user?.role === "admin" && selectedVoyage !== null ? <DownloadOrder icon={ClipboardText} doc={OrderTemplate} label={"Order"} /> : ''}
                        {downloadDocument !== null ? <DownloadDocument icon={Clipboard} label={"Download"} type={"Download"} /> : <DownloadDocument icon={ClipboardExport} label={"Upload"} type={"Upload"} />}
                        {user?.role === "admin" && invoice !== null ? <DownloadInvoice icon={Invoice} label={"Invoice"} doc={InvoiceTemplate} /> : ""}
                        {statementDocument === null ? "" : <ReportPdf icon={Report} label={"Report"} doc={EmissionTemplate} />}
                        {user?.role === "admin" && downloadDocument ? <DeleteDocument icon={ClipboardDelete} label={"Delete"} type={"Delete"} /> : ''}
                    </div>}
            </div>
            <div className="eua-overview-details">
                <div className='eua-overview-data'>
                    <Stats data={data} />
                </div>
                <div className='eua-overview-contoller'>
                    <RangePicker format="YYYY-MM-DD" value={customDate} style={{ width: 250 }} onChange={DateChangeHandler} />
                </div>
            </div>
        </div>
    )
}

export default EuaOverview