import Notifications from "../../container/notifications/Notifications";

const NotificationsPage = () => {
    return (
        <main className="main-board">
        <Notifications />
        </main>
    )
}

export default NotificationsPage