import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { hostname } from "../../data";
import { makeRequest } from "../../api/api";
export const newsRoomData = createAsyncThunk(

  'newsroom/getnewsroom',
  async () => {
    const request = await makeRequest("get", "/api/get_news/")
    const response = await request.data.data
    return response
  }
)

export const uploadNews = createAsyncThunk(
  'newsRoom/uploadnews',
  async (params) => {
    const request = await makeRequest("post", `api/upload_news/`, params)
    const response = await request.data.data
    return response
  }
)

export const deleteNews = createAsyncThunk(
  'newsRoom/deletenews',
  async (id) => {
    const request = await makeRequest("delete", `api/delete_news/?id=${id}`)
    const response = await request.data.data
    return response
  }
)

export const editNews = createAsyncThunk(
  'newsRoom/editnews',
  async (params) => {
    const request = await makeRequest("put", `api/edit_news/`, params)
    const response = await request.data.data
    return response
  }
)

export const spotPriceGraphData = createAsyncThunk(

  'newsroom/getspotPrice',
  async () => {

    const request = await makeRequest("get", "/api/get_spot/")
    const response = await request.data.data
    return response
  }
)

export const newsRoomSortingData = createAsyncThunk(
  'sortednewsroom/getsortednews',
  async () => {
    const request = await makeRequest("get", "/api/get_news/?sort=asc")
    const response = await request.data.data
    return response
  }
)

export const newsRoomSearchData = createAsyncThunk(
  'searchnewsroom/getsearchednews',
  async (input_keyword) => {
    const request = await makeRequest("get", `api/get_news/?keyword=${input_keyword}`)
    const response = await request.data.data
    return response
  }
)

export const updateLikestatus = createAsyncThunk(
  'newsRoom/likenews',
  async (params) => {
    const request = await makeRequest("post", `api/like/`, params)
    const response = await request.data.data
    return response
  }
)
export const spotPricePost = createAsyncThunk(
  'newsRoom/spotPrice',
  async (params) => {
    const request = await makeRequest("post", `api/update_spot_price/`, params)
    const response = await request.data.data
    return response
  }
)



const newsRoomSlice = createSlice({
  name: "newsRoom",
  initialState: {
    status: 'idle',
    newsList: [],
    selectedNewsData: null,
    selectedNewsID: null,
    sortedNewsStatus: 'idle',
    searchedNewsStatus: 'idle'
  },
  reducers: {
    setSelectedNewsData: (state, action) => {
      state.selectedNewsData = action.payload
    },
    selectedNewsRoomID: (state, action) => {
      state.selectedNewsID = action.payload
    },
    deleteSelectedNewsRoom: (state, action) => {
      const idToDelete = action.payload;
      state.newsList = state.newsList.filter(news => news.id !== idToDelete);
      if (state.selectedNewsID === idToDelete) {
        state.selectedNewsData = null;
        state.selectedNewsID = null;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(newsRoomData.pending, (state) => {
        state.status = 'loading';
        state.newsList = []
      })
      .addCase(newsRoomData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.newsList = action.payload;
      })
      .addCase(newsRoomData.rejected, (state) => {
        state.status = 'failed';
        state.newsList = []
      })

      .addCase(newsRoomSortingData.pending, (state) => {
        state.sortedNewsStatus = 'loading';
      })
      .addCase(newsRoomSortingData.fulfilled, (state, action) => {
        state.sortedNewsStatus = 'succeeded';
        state.newsList = action.payload;
      })
      .addCase(newsRoomSortingData.rejected, (state) => {
        state.sortedNewsStatus = 'failed';
      })

      .addCase(newsRoomSearchData.pending, (state) => {
        state.searchedNewsStatus = 'loading';
      })
      .addCase(newsRoomSearchData.fulfilled, (state, action) => {
        state.searchedNewsStatus = 'succeeded';
        state.newsList = action.payload;
      })
      .addCase(newsRoomSearchData.rejected, (state) => {
        state.searchedNewsStatus = 'failed';
      })


  },
})

// Reducer variables
export const { setSelectedNewsData, selectedNewsRoomID, deleteSelectedNewsRoom,  } = newsRoomSlice.actions;

export default newsRoomSlice.reducer;