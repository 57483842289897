import React from "react";
import ProgessCard from "../../container/cards/ProgressCard";
import Notification from "../../container/notification/Notification";
import Sattlement from "../../container/settlementcontainer/Settlement";
import { ConfigProvider } from "antd";
const EUA = () => {

  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            lineBorderRadius: 8,
          },
          Descriptions: {
            itemPaddingBottom: "10px"
          },
          Table: {
            headerSplitColor: "#fffff",
            headerBg: "#fffff",
          }
        },
      }}
    >
      <main className="main-board">
        <div className="MainHeader">
          <ProgessCard />
          <Notification />
        </div>
        <div className="MainTable">
          <Sattlement />
        </div>
      </main>
    </ConfigProvider>
  );
};

export default EUA;
