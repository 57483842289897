import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const getTransactions = createAsyncThunk(
  'user/getTransactions',
  async (settlementParams) => {
    const request = await makeRequest("get", "super_admin/get_txns/")
    const response = await request.data.data
    return response;
  }
)

export const getDues = createAsyncThunk(
  'user/getDues',
  async (settlementParams) => {
    const request = await makeRequest("get", "super_admin/get_submission_due/")
    const response = await request.data.data
    return response;
  }
)

export const submitTransactions = createAsyncThunk(
  "user/postTransactions",
  async (params) => {
    try {
      const request = await makeRequest("post", "super_admin/post_txn/", params)
      const response = await request.data.data
      return response;
    } catch (error) {
      throw new Error(error.response ? error.response.data?.message : 'An error occurred');
    }
  }
)

export const submitDues = createAsyncThunk(
  "user/postDues",
  async (params) => {
    const request = await makeRequest("post", "super_admin/post_submission_due/", params)
    const response = await request.data.data
    return response;
  }
)

const TransactionSlice = createSlice({
  name: 'transactions',
  initialState: {
    transactions: [],
    dues: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getTransactions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.transactions = action.payload;
      })
      .addCase(getTransactions.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getDues.pending, (state) => {
        state.status = 'pending'
      })
      .addCase(getDues.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dues = action.payload;
      })
      .addCase(getDues.rejected, (state) => {
        state.status = 'failed';
      })
  },
});


export default TransactionSlice.reducer