import React, { useEffect } from 'react';
import OutputResults from '../sequentialFlow/OutputResults';
import { useDispatch, useSelector } from 'react-redux';
import { runCalculations } from '../../../features/RawData/RawDataSlice';
import { Spin, Alert } from 'antd';

const StepperThird = ({ selectedUniqueId }) => {
  const dispatch = useDispatch();
  const { selectedRecordsForSequentialFlow, calculationsData } = useSelector((state) => state.rawData);

  const data = selectedRecordsForSequentialFlow[selectedUniqueId][0];
  return (
    <div className='stepper-step'>
      {calculationsData && <OutputResults />}
    </div>
  );
};

export default StepperThird;
