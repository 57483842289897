import EFDB from "../../container/efdb/EFDB";

const EFDBPage = () => {
  return (
    <main className="main-board">
      <EFDB />
    </main>
  );
};

export default EFDBPage;
