import React from 'react'
import VesselRow from './VesselRow'
import { useLocation } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clearVessels, getVesssels, searchVessels } from '../../features/Vessels/VesselSlice'
import Search from '../../components/Search'
import { DatePicker } from 'antd'
import { selectVessel } from '../../features/Vessels/VesselSlice'
import SkeltonTable from '../../components/SkeltonTable'
import format from 'date-fns/format'
import Papa from 'papaparse';
import { fetchCSVDocumentforVessels } from '../../features/EUA/EuaSlice'
import Clipboard from "../../assets/icons/voyages/clipboard-import.svg"
const { RangePicker } = DatePicker

const Vessel = () => {

  const dispatch = useDispatch()
  const location = useLocation();
  const { status, vessels, page } = useSelector((state) => state.vessel)
  const { selectedVessel } = useSelector((state) => state.vessel)
  const [updatedPage, setUpdatedPage] = useState(page)
  const scrollableArea = useRef(null)
  const { csvDocument } = useSelector((state) => state.eua)
  const [customDate, setCutomDate] = useState(null)
  const [IMO, setIMO] = useState(selectedVessel?.imo_number)

  // useEffect(() => {
  //   dispatch(getVesssels(1))
  // }, []);

  const DateChangeHandler = (date) => {
    setCutomDate(date)
    if (date !== null) {
      const temp = {
        start_date: format(new Date(date[0]), "yyyy-MM-dd"),
        end_date: format(new Date(date[1]), "yyyy-MM-dd"),
        imo_number: selectedVessel.imo_number
      }
      dispatch(fetchCSVDocumentforVessels(temp))
    }
  }

  const downloadCsv = () => {
    const rearrangedData = [];
    let prevVesselName = '';

    csvDocument.forEach((row) => {
      const { vessel_name, ...rest } = row;

      if (!vessel_name || vessel_name === prevVesselName) {
        rearrangedData.push({ vessel_name: '', ...rest });
      } else {
        rearrangedData.push({ vessel_name, ...rest });
        prevVesselName = vessel_name;
      }

    });

    const result = rearrangedData.map((originalData, key) => {
      return {
        "Vessel Name": originalData.vessel_name,
        "Owner": originalData.active_owner,
        "IMO Number": originalData.imo_number,
        "Voyage Number": originalData.voyage_number,
        "CII": originalData.cii,
        "EU Identifier": originalData.eu_identifier,
        "EUA Due": originalData.eua_due,
        "EUA Released": originalData.eua_released,
        "Start Date": originalData.min_as_of_date_voyage_data,
        "End Date": originalData.max_as_of_date_voyage_data,
        "Start Port": originalData.start_port,
        "End Port": originalData.end_port,
        "Voyage Status": originalData.voyage_status,
      }
    })
    const csv = Papa.unparse(result);
    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };



  const getVessselsData = () => {
    if (status !== 'loading') {
      dispatch(getVesssels(page)).then((result) => {
        if (page === 1 && result.payload) {
          const payload = {
            imo_number: result.payload[0].imo_number,
            voyage_number: result.payload[0].voyage_number
          }
          dispatch(selectVessel(payload))
        }
      })
    }
  }

  useEffect(() => {
    if (vessels.length === 0) {
      getVessselsData()
    }
  }, [updatedPage])

  const onChange = (date, dateString) => {
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      scrollableArea.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setUpdatedPage(updatedPage + 1)
      getVessselsData()
    }
  }
  const handleRowSelection = (voyage, number) => {

    setIMO(number)
    const payload = {
      imo_number: number,
      voyage_number: voyage.voyage_number
    }
    dispatch(selectVessel(payload));
  };

  const searchHandler = (record, setOpen, setValue) => {
    setValue(record.vessel_name)
    const params = {
      vessel_name: record.vessel_name,
      voyage_number: record.voyage_number,
      imo_number: record.imo_number,
      page: 1
    }
    setOpen(false)
    dispatch(clearVessels())
    dispatch(searchVessels(params))
  }

  const clearSearchHandler = (setValue) => {
    setValue(null)
    dispatch(clearVessels())
    dispatch(getVesssels(1))
  }

  useEffect(() => {
    // Clear search when user navigates to another page
    // setValue(null)
    dispatch(clearVessels())
    setUpdatedPage(1)
  }, [location.pathname]);

  return (
    <div className="card">
      <div className="vessel-card-filter">
        <div style={{ width: "50%" }}>
          <Search onClear={clearSearchHandler} onSelect={searchHandler} />
        </div>


        {/* <DatePicker size='large' onChange={onChange} /> */}
        <RangePicker format="YYYY-MM-DD" value={customDate} style={{ width: 250 }} onChange={DateChangeHandler} />
        {csvDocument !== null && customDate !== null ?
          <div onClick={downloadCsv} style={{ display: "flex", flexDirection: "column" }}>
            <img src={Clipboard} style={{ height: "20px", cursor: "pointer" }} alt="" />
            <span className='card-icon-span'>{"Download Report"}</span>
          </div> : ""
        }

      </div>
      <div onScroll={handleScroll} ref={scrollableArea} className="vessel-card-table" style={{ height: "60vh" }}>
        {vessels.length === 0 ?
          <SkeltonTable />
          :
          vessels.map((voyage, index) => {
            return (
              <VesselRow key={index} voyage={voyage} name={voyage.vessel_name} number={voyage.imo_number} onSelect={() => handleRowSelection(voyage, voyage.imo_number)} />
            )
          })
        }
      </div>
    </div>
  )
}

export default Vessel
