import React from 'react';
import { Col, Row, Skeleton, Statistic } from 'antd';
const formatter = (value) => <span>{value}</span>;
const Stats = ({ data }) => {
    if (data.length === 0) {
        return (
            <Skeleton active />
        )
    }
    else {
        return (
            <Row gutter={[8, 16]}>
                {data.map((value, key) => {
                    return (
                        <Col span={6}>
                            <div className='statistics'>
                                <span className='stat-label'>{value.label}</span>
                                <span className='stat-data'>{value.value}</span>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        )
    }
}
export default Stats;