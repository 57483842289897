import React, { useState } from 'react'
import informationIcon from '../assets/icons/info_icon.svg';

import { Tooltip } from "antd";

const Disclaimer = () => {
    const text = <span>CII Value is without fuel exclusions</span>;

    return (<><Tooltip placement="top" title={text}>
        <img style={{ width: "15px", opacity: "0.5" }} src={informationIcon}></img>
    </Tooltip></>)
}

export default Disclaimer;