import sortByIcon from "../../assets/icons/newsroom/newscard/sortby.svg";
// import newsImage1 from "../../assets/images/newsroom/newscard/image1.png";
// import newsImage2 from "../../assets/images/newsroom/newscard/image2.png";

import { useEffect, useState } from "react";
import { newsRoomData, newsRoomSortingData, newsRoomSearchData, uploadNews } from "../../features/NewsRoom/NewsRoomSlice";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedNewsData, selectedNewsRoomID } from "../../features/NewsRoom/NewsRoomSlice";
import { toggleModal, updateModalInfo } from "../../features/App/AppSlice";
import React from "react";
import { Input, Space, DatePicker, Modal, Button, Form, Upload, message } from "antd";
import { UploadOutlined } from '@ant-design/icons';
const { Search } = Input;

// import { Link } from "react-router-dom";

// const newsData = [
//   {
//     newsImg: newsImage1,
//     heading: "BIMCO released their updated template for charter parties template for charter partiestemplate for charter parties",
//     content:
//       "BIMCO has just launched their latest charter party template, providing the maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes. maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes.maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes.maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes.maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes.maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes.maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes.",
//     date: "17 Nov, 2023",
//     time: "04:02",
//     published_by: "bimco.com",
//   },
//   {
//     newsImg: newsImage2,
//     heading: "Next EUA auction will take place on 02.02 2024 at 15:00",
//     content:
//       "The upcoming EUA auction is scheduled for February 2, 2024, at 15:00. Don't miss this important event for those involved in emissions trading and environmental policy.",
//     date: "23 Feb, 2023",
//     time: "09:36",
//     published_by: "flstudio.com",
//   },
//   {
//     newsImg: newsImage1,
//     heading: "BIMCO released their updated template for charter parties",
//     content:
//       "BIMCO has just launched their latest charter party template, providing the maritime industry with an up-to-date, reliable tool for smoother and more efficient chartering processes.",
//     date: "17 Nov, 2023",
//     time: "04:02",
//     published_by: "bimco.com",
//   },
//   {
//     newsImg: newsImage2,
//     heading: "Next EUA auction will take place on 02.02 2024 at 15:00",
//     content:
//       "The upcoming EUA auction is scheduled for February 2, 2024, at 15:00. Don't miss this important event for those involved in emissions trading and environmental policy.",
//     date: "23 Feb, 2023",
//     time: "09:36",
//     published_by: "flstudio.com",
//   }
// ];

const NewsCard = () => {
  const { status, newsList, selectedNewsID } = useSelector((state) => state.newsroom)
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const [form] = Form.useForm();

  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    if (status === 'idle') {
      dispatch(newsRoomData()).then((result) => {
        if (result.payload) {
          dispatch(setSelectedNewsData(result.payload[0]))
          dispatch(selectedNewsRoomID(result.payload[0]["id"]))
        }
      })
    }
  }, [dispatch, status])


  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const newsData = []
  newsList.map((news, i) => (
    newsData.push(
      {
        id: news.id,
        newsImg: news.thumbnail_url,
        heading: news.title,
        content: news.description,
        // date: news.publish_date,
        date: `${new Date(`${news.publish_date}`).getDate()}, ${months[new Date(`${news.publish_date}`).getMonth()]} ${new Date(`${news.publish_date}`).getFullYear()}`,
        // time: news.publish_date,
        time: `${new Date("2023-02-23T12:34:00Z").getUTCHours()}:${new Date("2023-02-23T12:34:00Z").getUTCMinutes()}`,
        published_by: news.publish_by,
      }
    )
  ))


  const newsClickHandler = (news_id) => {
    dispatch(setSelectedNewsData(newsList.filter((value) => value.id === news_id)[0]))
    dispatch(selectedNewsRoomID(news_id))
  }

  const newsSortingClickHandler = () => {
    dispatch(newsRoomSortingData()).then((result) => {
      if (result.payload) {
      }
    })
  }

  const onSearch = (e) => {
    const val = e.target.value.trim()
    if (val.length >= 3) {
      dispatch(newsRoomSearchData(val)).then((result) => {
        if (result.payload) {
          dispatch(setSelectedNewsData(null))
        }
      })
    }
    else {
      dispatch(newsRoomData())

    }
  }

  const openModal = () => {
    form.resetFields();
    setModalVisible(true);
  };

  const handleSubmit = async (values) => {
    const { title, description, publishDate, thumbnail } = values;
    const thumbnailFile = thumbnail?.[0]?.originFileObj;
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('publish_date', publishDate.format('YYYY-MM-DD'));
    if (thumbnailFile) {
      formData.append('thumbnail_file', thumbnailFile);
    }
    dispatch(uploadNews(formData)).then((result) => {
      if (result.payload) {
        dispatch(newsRoomData());
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'News Post Successfully!', type: 'success', message: 'Success!' }));
      } else {
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'News Post Failed!', type: 'error', message: 'Failed' }));
      }
    })
    .catch((error) => {
      dispatch(updateModalInfo({ show: true, result: error.toString(), type: 'error', message: 'Failed' }))
    });
  setModalVisible(false);
  };

  const [remainingWords, setRemainingWords] = useState(2000);

  const handleDescriptionChange = (e) => {
    const description = e.target.value;
    const words = description.trim().split(/\s+/);
    const remaining = 2000 - words.length;
    setRemainingWords(Math.max(0, remaining));
  };

  const validateWordCount = (value, maxWords) => {
    const wordCount = value.trim().split(/\s+/).length;
    if (wordCount > maxWords) {
      return Promise.reject(`Description must not exceed ${maxWords} words`);
    }
    return Promise.resolve();
  };

  return (
    <div className="newsRoomCard newsCard">
      <div className="newsCard-Elem1">
        <div className="newsCard-Elem1-SearchBar">
          <Space direction="vertical">
            <Search
              placeholder="Search News, Topics"
              bordered={true}
              onChange={onSearch}
              style={{
                width: 280,
              }}
            />
          </Space>
        </div>

        <div className="newCard-Elem1-btn-sort">
          <div className="newCard-Elem1-UploadNews">
            {user.role === "admin" && (
              <div onClick={openModal}>
                <span className='genericButton' style={{ cursor: "pointer" }}>Upload News</span>
              </div>
            )}

          </div>

          <div onClick={newsSortingClickHandler} className="newsCard-Elem1-SortBy">
            <p>Sort By</p>
            <img src={sortByIcon} alt="Sort By" />
          </div>
        </div>
      </div>


      <div className="newsCard-Elem2">

        <Modal
          title="Upload News"
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          footer={null}
          className="uploadNewsModal"
        >
          <Form layout='vertical' form={form} onFinish={handleSubmit}>
            <Form.Item
              name="title"
              label="Title"
              rules={[{ required: true, message: 'Please input the title' }]}
            >
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                { required: true, message: 'Please input the description' },
                { validator: (_, value) => validateWordCount(value, 2000) }
              ]}
            >
              <Input.TextArea onChange={handleDescriptionChange} placeholder="Description" />
            </Form.Item>
            <p className="wordCountNewsRoom">{remainingWords}/2000 words remaining</p>
            <Form.Item
              name="publishDate"
              label="Publish Date"
              rules={[{ required: true, message: 'Please select the publish date' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e && e.fileList;
              }}
              rules={[{ required: true, message: 'Please upload the thumbnail' }]}
            >
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <p className="wordCountNewsRoom wordCountNewsRoomPhoto">Upload a photo up to 2 MB</p>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {
          newsData.map((news, i) => (
            <div onClick={() => newsClickHandler(news.id)} className={news.id === selectedNewsID ? "newsCard-Elem2-Card  newsCard-Elem2-Card-active" : "newsCard-Elem2-Card"} key={news.id}>
              <div className="newsCard-Elem2-Image">
                <img src={news.newsImg} alt="news{i}" />
              </div>
              <div className="newsCard-Elem2-Content">
                <div className="newsCard-Elem2-Content-Elem1">
                  <h1>{news.heading}</h1>
                </div>
                <div className="newsCard-Elem2-Content-Elem2">
                  <p>{news.content}</p>
                </div>
                <div className="newsCard-Elem2-Content-Elem3">
                  <p>
                    {news.date}
                  </p>
                  <p>{news.published_by}</p>
                </div>
              </div>
            </div>
          ))
        }

      </div>
    </div>
  );
};

export default NewsCard;
