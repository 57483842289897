import React from 'react'
import EUATab from './EUATab'
import LineArea from '../chart/LineArea'



const EuaDifference = () => {
  return (
    <div className='card euaDifferenceCard'>
      <div className='euaDifferenceCard-Elem1'>
        <span>EUA Difference</span>
        <LineArea />
      </div>
      <div className='euaDifferenceCard-Elem2'>
        <EUATab />
      </div>
    </div>
  )
}

export default EuaDifference