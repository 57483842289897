import React, { useState } from 'react';
import { Steps, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import Skip from '../../../components/Modals/Skip'; // Import the Skip modal component

const steps = [
  {
    title: '',
    content: 'First-content',
  },
  {
    title: '',
    content: 'Second-content',
  },
  {
    title: '',
    content: 'Last-content',
  },
];

const StepperCard = ({ onBack, currentStep, onStepChange }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSkip = () => {
    // Handle the skip logic here
    setIsModalVisible(false);
    onStepChange(1)
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  return (
    <div className='Stepper-Card-Container'>
      <div className='Stepper-Card-Container-Header'>
        <div className="Stepper-Card-Backarrow">
          <ArrowLeftOutlined onClick={onBack} />
        </div>
        <div style={{ width: "40%" }}>
          <span className='Stepper-Card-Title'>Complete the Process</span>
          <p className='Stepper-Card-Sub-Title'>Assigning Casper ID</p>
        </div>
      </div>
      <Steps className='stepper-progress-bar' current={currentStep} items={items} />

      <div className='stepper-card-flex'>
        <div>
          <span className='Stepper-Card-Title'>Process 0{currentStep + 1}/03</span>
          <p className='Stepper-Card-Sub-Title'>
            {currentStep === 0 ?
              "Please complete the process to proceed." :
              currentStep === 1 ?
                "Please Assign a Casper ID to all the records." :
                "Please view the expected output results."
            }
          </p>
        </div>
        {/* {currentStep === 0 && (
          <Button className='stepper-skip-button' onClick={showModal}>
            Skip All
          </Button>
        )} */}
      </div>

      <Skip
        isVisible={isModalVisible}
        handleCancel={handleCancel}
        handleSkip={handleSkip}
        title="Skip First Step"
        message="Skip the process of assigning fuel values with other modifications?"
        onStepChange={onStepChange}
        primaryButtonText="Skip"
      />
    </div>
  );
};

export default StepperCard;
