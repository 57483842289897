
import { Table } from "antd";
import "../../css/tableStyle.css";

// const columns = [
//   {
//     title: "Fuel Grades MEPC308.(73)",
//     dataIndex: "1",
//     render: (text) => <a>{text}</a>,
//   },
//   {
//     title: "Reported Fuel",
//     className: "column-money",
//     dataIndex: "2",
//     align: "right",
//     // render: (text) => <a>{text}</a>,
//     render: (text) => (
//         <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
//       ),
//   },
//   {
//     title: "Carbon Factor (tC02/tfuel)",
//     dataIndex: "3",
//     align: "right",
//     render: (text) => (
//         <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
//       ),
//   },
//   {
//     title: "Carbon Factor (tCH4/tfuel)",
//     dataIndex: "4",
//     align: "right",
//     render: (text) => (
//         <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
//       ),
//   },
//   {
//     title: "Carbon Factor (tN2O/tfuel)",
//     dataIndex: "5",
//     align: "right",
//     render: (text) => (
//         <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
//       ),
//   },
//   {
//     title: "Carbon Factor (tN2O/tfuel)",
//     dataIndex: "6",
//     align: "right",
//     render: (text) => (
//         <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
//       ),
//   },
//   {
//     title: "Carbon Factor (tC02/tfuel)",
//     dataIndex: "7",
//     align: "right",
//     render: (text) => (
//         <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
//       ),
//   },
//   {
//     title: "Carbon Factor (tN2O/tfuel)",
//     dataIndex: "8",
//     align: "right",
//     render: (text) => (
//         <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
//       ),
//   },
// ];

// const rows = [
//   {
//     key: "1",
//     1: "Conventional Fuels",
//     2: "Diesel/Gas Oil (MGO/MDO)",
//     3: "3.206",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "2",
//     1: "",
//     2: "Light Fuel Oil (LFO)",
//     3: "3,151",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "3",
//     1: "",
//     2: "Heavy Fuel Oil (HFO)",
//     3: "3.114",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "4",
//     1: "",
//     2: "Liquified Petroleum Gas (LPG)",
//     3: "3",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "5",
//     1: "",
//     2: "Liquified Petroleum Gas (LPG)",
//     3: "3.03",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "6",
//     1: "",
//     2: "Liquified Natural Gas (LNG)",
//     3: "2.75",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "7",
//     1: "",
//     2: "Methanol",
//     3: "1.375",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "8",
//     1: "",
//     2: "Ethanol",
//     3: "1.913",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },


//   {
//     key: "9",
//     1: "Alternative Fuels",
//     2: "Bio-methanol",
//     3: "3.206",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "10",
//     1: "",
//     2: "e-methanol",
//     3: "3.206",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "11",
//     1: "",
//     2: "Bio-gasoil",
//     3: "3.206",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "12",
//     1: "",
//     2: "e-gasoil",
//     3: "3.206",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "13",
//     1: "",
//     2: "e-LNG",
//     3: "2.75",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "14",
//     1: "",
//     2: "Methanol",
//     3: "3.206",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "15",
//     1: "",
//     2: "Hydrogen (from natural gas)",
//     3: "-",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "16",
//     1: "",
//     2: "e-Hydrogen",
//     3: "-",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "17",
//     1: "",
//     2: "Ammonia (from natural gas)",
//     3: "-",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "18",
//     1: "",
//     2: "e-Ammonia",
//     3: "-",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   },
//   {
//     key: "19",
//     1: "",
//     2: "Electricity",
//     3: "-",
//     4: "-",
//     5: "-",
//     6: "-",
//     7: "-",
//     8: "-",
//   }

// ];

const EFDBTable = ({ data }) => {

  const columns = [
    {
      title: "Fuel Grades MEPC308.(73)",
      dataIndex: "1",
      render: (text, row) => ({
        children: <a href="/#" onClick={(e) => e.preventDefault()}>{text}</a>,
        props: { rowSpan: row.rowSpan },
      }),
    },
    {
      title: "Reported Fuel",
      className: "column-money",
      dataIndex: "2",
      align: "right",
      // render: (text) => <a>{text}</a>,
      render: (text) => (
        <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
      ),
    },
    {
      title: "Carbon Factor (tCO₂/tfuel)",
      dataIndex: "3",
      align: "right",
      render: (text) => (
        <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
      ),
    },
    {
      title: "Carbon Factor (tCH₄/tfuel)",
      dataIndex: "4",
      align: "right",
      render: (text) => (
        <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
      ),
    },
    {
      title: "Carbon Factor (tN₂O/tfuel)",
      dataIndex: "5",
      align: "right",
      render: (text) => (
        <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
      ),
    },
    {
      title: "Carbon Factor (tCO₂/tfuel)",
      dataIndex: "7",
      align: "right",
      render: (text) => (
        <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
      ),
    },
    {
      title: "Carbon Factor (tCH₄/tfuel)",
      dataIndex: "6",
      align: "right",
      render: (text) => (
        <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
      ),
    },
    {
      title: "Carbon Factor (tN₂O/tfuel)",
      dataIndex: "8",
      align: "right",
      render: (text) => (
        <div style={{ fontSize: '90%', width: "100%" }}>{text}</div>
      ),
    },
  ];



  const groupedData = {};
  data.forEach((fuelData) => {
    const fuelGrade = fuelData.fuel_grade;
    if (!groupedData[fuelGrade]) {
      groupedData[fuelGrade] = [];
    }
    groupedData[fuelGrade].push({
      key: groupedData[fuelGrade].length + 1,
      1: fuelData.fuel_grade,
      2: fuelData.fuel_name,
      3: fuelData.carbon_factor_co2,
      4: fuelData.carbon_factor_ch4,
      5: fuelData.carbon_factor_n2o,
      6: "-",
      7: "-",
      8: "-",
    });
  });

  const rows = [];
  Object.values(groupedData).forEach((group) => {
    let rowCount = 0;
    group.forEach((row) => {
      rowCount++;
      row.rowSpan = rowCount === 1 ? group.length : 0;
      rows.push(row);
    });
  });


  return (
    <Table
      className="efdbTable"
      columns={columns}
      dataSource={rows}
      pagination={false}
      // bordered
      // rowClassName={() => 'fixed-width-row'}
      rowClassName={(record, index) => (index % 2 === 1 ? 'non-padded-row' : 'padded-row')}
      // size="small"
      style={{ width: '100%', height: "100%", overflowY: "auto" }}
    // scroll={{ y: 'calc(100vh - 200px)' }}
    />
  )
};

export default EFDBTable;
