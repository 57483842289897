import Papa from 'papaparse';
import "../css/notifications.css"
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  
export const downloadCSV = (dataToDownload) => {
    if (!Array.isArray(dataToDownload) || dataToDownload.length === 0) {
      console.error('Invalid data format or empty data array');
      return;
    }

    const headers = Object.keys(dataToDownload[0]);

    const filename = 'eu-port-entering-vessels';

    const csv = Papa.unparse({
      fields: headers,
      data: dataToDownload,
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error('Download not supported in this browser');
    }
  };

export const highlightSearchTerm = (content, searchKey) => {
  if (!searchKey.trim()) return content; 
  const regex = new RegExp(`(${searchKey})`, 'gi'); 
  return content.replace(regex, '<span class="highlight">$1</span>');
};
