import { Form, Input, Button, Modal, DatePicker, Select } from 'antd';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { fetchPortList2 } from '../../features/Voyage/VoyageSlice';
import dayjs from 'dayjs';

import { getCIIInfo } from '../../features/VoyageOptimization/VoyageOptimizationSlice';

import '../../css/voyageOptimizations.css'

const CIIDetailsInput = ({ selectedVessels, showCIIModal, setShowCIIModal, isEditFieldsOn }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const { voyageCiiData, ciiStatus } = useSelector(state => state.voyageOptimization)
    const [form] = Form.useForm();
    const [formData, setFormData] = useState({}); // State to store form data
    const [portList, setPortList] = useState()
    const [waypoints1, setWaypoints1] = useState([]);
    const [waypoints2, setWaypoints2] = useState([]);

    // Load stored form data when isEditFieldsOn becomes true
    // useEffect(() => {
    //     if (isEditFieldsOn && localStorage.getItem('formData')) {
    //         const storedFormData = JSON.parse(localStorage.getItem('formData'));
    //         setFormData(storedFormData.formData); // Accessing the nested formData object
    //     }
    // }, [isEditFieldsOn]);

    // const laycanDate = new Date(JSON.parse(localStorage.getItem('formData'))['Laycan'])
    // const voaygeDate = new Date(JSON.parse(localStorage.getItem('formData'))['Start of Voyage'])
    // console.log(laycanDate)
    // console.log(isEditFieldsOn)

    // console.log(ciiStatus)
    const onFinish = (values) => {
        // Convert moment objects to Date objects
        console.log(values)
        const formattedValues = {
            ...values,
            'Laycan': values['Laycan'] ? dayjs(values['Laycan']).toDate() : '',
            'Start of Voyage': dayjs(values['Start of Voyage']).toDate(),
        };

        // Format the laycan date before logging
        formattedValues['Laycan'] = formattedValues['Laycan'] ? dayjs(formattedValues['Laycan']).format('YYYY-MM-DD') : '';
        formattedValues['Start of Voyage'] = dayjs(formattedValues['Start of Voyage']).format('YYYY-MM-DD');

        setFormData(formattedValues);
        console.log("Form Data:", formattedValues);
        // alert("DONE");

        formatData(formattedValues)


        setShowCIIModal(false);
        localStorage.setItem('formData', JSON.stringify(values));
        navigate('/matrix-tables');
    };

    // console.log(selectedVessels)

    const formatData = (data) => {
        const payload = {
            imo_numbers: selectedVessels.map(vessel => vessel.imo_number),

            start_port_info: {
                port_name: data["Start Port"].substring(0, getIndex(data["Start Port"])),
                country: data["Start Port"].substring((getIndex(data["Start Port"]) + 2))
            },
            load_port_info: {
                port_name: data["Load Port"].substring(0, getIndex(data["Load Port"])),
                country: data["Load Port"].substring((getIndex(data["Load Port"]) + 2))
            },
            discharge_port_info: {
                port_name: data["Discharge Port"].substring(0, getIndex(data["Discharge Port"])),
                country: data["Discharge Port"].substring((getIndex(data["Discharge Port"]) + 2))
            },

            waypoint_1_info: [
                data.Waypoint1_1 ? {
                    port_name: data.Waypoint1_1.substring(0, getIndex(data.Waypoint1_1)),
                    country: data.Waypoint1_1.substring((getIndex(data.Waypoint1_1) + 2))
                } : '',
                data.Waypoint1_2 ? {
                    port_name: data.Waypoint1_2.substring(0, getIndex(data.Waypoint1_2)),
                    country: data.Waypoint1_2.substring((getIndex(data.Waypoint1_2) + 2))
                } : '',
                data.Waypoint1_3 ? {
                    port_name: data.Waypoint1_3.substring(0, getIndex(data.Waypoint1_3)),
                    country: data.Waypoint1_3.substring((getIndex(data.Waypoint1_3) + 2))
                } : '',
            ],

            waypoint_2_info: [
                data.Waypoint2_1 ? {
                    port_name: data.Waypoint2_1.substring(0, getIndex(data.Waypoint2_1)),
                    country: data.Waypoint2_1.substring((getIndex(data.Waypoint2_1) + 2))
                } : '',
                data.Waypoint2_2 ? {
                    port_name: data.Waypoint2_2.substring(0, getIndex(data.Waypoint2_2)),
                    country: data.Waypoint2_2.substring((getIndex(data.Waypoint2_2) + 2))
                } : '',
                data.Waypoint2_3 ? {
                    port_name: data.Waypoint2_3.substring(0, getIndex(data.Waypoint2_3)),
                    country: data.Waypoint2_3.substring((getIndex(data.Waypoint2_3) + 2))
                } : '',
            ],
            load_port_stay: data["Load Port Stay (Hours)"] ? parseInt(data["Load Port Stay (Hours)"]) : 0,
            discharge_port_stay: data["Discharge Port Stay (Hours)"] ? parseInt(data["Discharge Port Stay (Hours)"]) : 0,
            laycan_date: data.Laycan,
            start_date: data["Start of Voyage"]
        }

        // console.log(data)


        dispatch(getCIIInfo(payload));
    }


    const getIndex = str => str.indexOf(',');



    const handlePortSearch = (value) => {

        dispatch(fetchPortList2(value)).then((result) => {
            if (result.payload) {
                setPortList(result.payload);
            }
        });


    };

    const addWaypoint1 = () => {
        if (waypoints1.length < 3) {
            setWaypoints1([...waypoints1, `Waypoint1_${waypoints1.length + 1}`]);
        }
    };
    const addWaypoint2 = () => {
        if (waypoints2.length < 3) {
            setWaypoints2([...waypoints2, `Waypoint2_${waypoints2.length + 1}`]);
        }
    };

    return (
        <Modal
            visible={showCIIModal}
            onCancel={() => {
                setShowCIIModal(false);
                if (!isEditFieldsOn) {
                    form.resetFields(); // Reset the fields only if isEditFieldsOn is false
                }
            }}
            footer={null}
        >
            <div>
                <h3>Selected Vessels:</h3>
                {selectedVessels.map((vessel) => (
                    <div key={vessel.imo_number}>
                        <span>{vessel.vessel_name} </span>
                    </div>
                ))}

                <Form
                    form={form}
                    name="voyageDetailsForm"
                    onFinish={onFinish}
                    initialValues={{ fuels: [] }}
                    style={{ height: '60vh', overflow: 'auto' }}
                >
                    <div className='voyageDetailsFormComponent'>
                        <div className='voyageDetailsFormComponentItems'>

                            <Form.Item
                                name='Start Port'
                                label='Start Port'
                                rules={[{ required: true, message: `Please input Start Port!` }]}
                                initialValue={isEditFieldsOn ? JSON.parse(localStorage.getItem('formData'))["Start Port"] : ''}
                            >
                                <Select
                                    showSearch
                                    onSearch={(value) => handlePortSearch(value)}
                                    // onChange={(value) => setInitialPort(value)}
                                    // value={initialPort}
                                    onKeyDown={(e) => {
                                        const allowedKeys = /^[a-zA-Z ]$/;
                                        if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {portList &&
                                        portList.map((port, index) => (
                                            <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                                {port.port_name}, {port.country}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>



                            {waypoints1.map((waypoint, index) => (
                                <Form.Item
                                    key={index}
                                    name={waypoint}
                                    label={waypoint}
                                    initialValue={isEditFieldsOn ? JSON.parse(localStorage.getItem('formData'))[waypoint] : ''}
                                >
                                    <Select
                                        showSearch
                                        onSearch={(value) => handlePortSearch(value)}
                                    >
                                        {portList &&
                                            portList.map((port, index) => (
                                                <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                                    {port.port_name}, {port.country}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            ))}

                            <Button type="dashed" className='waypointButton' onClick={addWaypoint1} disabled={waypoints1.length === 3}>
                                Add Waypoint
                            </Button>


                            <Form.Item
                                name='Load Port'
                                label='Load Port'
                                rules={[{ required: true, message: `Please input Load Port!` }]}
                                initialValue={isEditFieldsOn ? JSON.parse(localStorage.getItem('formData'))["Load Port"] : ''}
                            >
                                <Select
                                    showSearch
                                    onSearch={(value) => handlePortSearch(value)}
                                    // onChange={(value) => setInitialPort(value)}
                                    // value={initialPort}
                                    onKeyDown={(e) => {
                                        const allowedKeys = /^[a-zA-Z ]$/;
                                        if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {portList &&
                                        portList.map((port, index) => (
                                            <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                                {port.port_name}, {port.country}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>


                            {waypoints2.map((waypoint, index) => (
                                <Form.Item
                                    key={index}
                                    name={waypoint}
                                    label={waypoint}
                                    initialValue={isEditFieldsOn ? JSON.parse(localStorage.getItem('formData'))[waypoint] : ''}
                                >
                                    <Select
                                        showSearch
                                        onSearch={(value) => handlePortSearch(value)}
                                    >
                                        {portList &&
                                            portList.map((port, index) => (
                                                <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                                    {port.port_name}, {port.country}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            ))}
                            <Button type="dashed" className='waypointButton' onClick={addWaypoint2} disabled={waypoints2.length === 3}>
                                Add Waypoint
                            </Button>


                            <Form.Item
                                name='Discharge Port'
                                label='Discharge Port'
                                rules={[{ required: true, message: `Please input Discharge Port!` }]}
                                initialValue={isEditFieldsOn ? JSON.parse(localStorage.getItem('formData'))["Discharge Port"] : ''}
                            >
                                <Select
                                    showSearch
                                    onSearch={(value) => handlePortSearch(value)}
                                    // onChange={(value) => setInitialPort(value)}
                                    // value={initialPort}
                                    onKeyDown={(e) => {
                                        const allowedKeys = /^[a-zA-Z]$/;
                                        if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    }}
                                >
                                    {portList &&
                                        portList.map((port, index) => (
                                            <Select.Option key={index} value={`${port.port_name}, ${port.country}`}>
                                                {port.port_name}, {port.country}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>

                        </div>
                        <div className='voyageDetailsFormComponentItems'>

                            {/* Number Inputs */}
                            {['Load Port Stay (Hours)', 'Discharge Port Stay (Hours)'].map((field) => (
                                <Form.Item
                                    key={field}
                                    label={field}
                                    name={field}
                                    initialValue={isEditFieldsOn ? JSON.parse(localStorage.getItem('formData'))[field] : ''}
                                    rules={[{ required: true, message: `Please input Port Stay!` }]}
                                >
                                    <Input type="number" onKeyDown={(e) => {
                                        const allowedKeys = /^[0-9.]$/;
                                        if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                                            e.preventDefault();
                                        }
                                    }} />
                                </Form.Item>
                            ))}

                            {/* Date Input */}
                            {['Laycan', 'Start of Voyage'].map((field) => (
                                <Form.Item
                                    // initialValue={isEditFieldsOn ? (field === "Laycan" ? laycanDate : (field === "Start of Voyage" ? voaygeDate : '')) : ''}
                                    label={field}
                                    name={field}
                                    rules={[{ required: field === 'Start of Voyage', message: `Please input start of voyage!` }]}
                                >
                                    <DatePicker />
                                </Form.Item>
                            ))}

                        </div>
                    </div>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ marginTop: "18px" }}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>

            </div>
        </Modal>
    );
};

export default CIIDetailsInput;
