import React, { useEffect, useState } from 'react';
import { ReactComponent as EpBack } from "../../assets/icons/rawdata/ep_back_alt.svg";
import DateTab from './DateTab';
import { Select, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuditDates, togglePastRecords } from '../../features/RawData/RawDataSlice';

const { Option } = Select;

const PastRecordsDate = ({ selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace, selectedRecordDateId, setSelectedRecordDateId, selectedCasperIdAuditTrace }) => {
  const dispatch = useDispatch();
  const [filterOption, setFilterOption] = useState("manual_automatic");

  useEffect(() => {
    const payload = {
      uniqueId: selectedUniqueIdAuditTrace,
      casperId: selectedCasperIdAuditTrace
    }
    dispatch(fetchAuditDates(payload));
  }, [dispatch]);

  const { auditDates, auditDateStatus } = useSelector(state => state.rawData);

  useEffect(() => {
    if (auditDates?.data?.length > 0) {
      const initialDate = auditDates?.data[0]?.id;
      setSelectedRecordDateId(initialDate);
    }
  }, [auditDates]);

  const handleDateTabClick = (id) => {
    setSelectedRecordDateId(id)
  };

  const handleFilterChange = (value) => {
    setFilterOption(value);
  };

  const selectStyle = {
    width: 120,
    fontSize: "2vh",
  };

  const optionStyle = {
    color: '#005BF3',
    fontSize: "1.5vh",
  };

  const filteredAuditDates = auditDates?.data?.filter(data => {
    if (filterOption === "manual") {
      return data?.is_system_generated === false;
    } else if (filterOption === "automatic") {
      return data?.is_system_generated === true;
    }
    return true;
  });

  const handleBack = () => {
    dispatch(togglePastRecords());
  };

  return (
    <div className='past-record-date'>
      <div className='past-record-date-header'>
        <div className='past-record-date-header-left'>
          <div className='past-record-date-header-left-back'>
            <div className='back-icon-container' style={{ cursor: "pointer" }}>
              <EpBack className='back-icon' onClick={handleBack} />
            </div>
            <div className='past-record-date-header-left-back-dates'>
              <h4>Dates</h4>
              {auditDateStatus === "loading" ? (
                <Spin />
              ) : (
                <p>{filteredAuditDates?.length} Dates found</p>
              )}
            </div>
          </div>
        </div>
        <div className='past-record-date-header-right'>
          <div className='select'>
            <Select
              defaultValue="manual_automatic"
              style={selectStyle}
              onChange={handleFilterChange}
            >
              <Option value="manual_automatic" style={optionStyle}>Manual + Automatic</Option>
              <Option value="manual" style={optionStyle}>Manual</Option>
              <Option value="automatic" style={optionStyle}>Automatic</Option>
            </Select>
          </div>
        </div>
      </div>
      <hr style={{ opacity: "0.7" }} />
      {auditDateStatus === "loading" ? (
        <Spin />
      ) : (
        <div className='past-record-date-content'>
          {filteredAuditDates?.map((data, index) => (
            <DateTab
              key={index}
              date={data?.date_created}
              type={data?.is_system_generated}
              id={data?.id}
              state={selectedRecordDateId === data?.id ? "active" : "inactive"}
              handleDateTabClick={handleDateTabClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PastRecordsDate;
