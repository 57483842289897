import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { hostname } from "../../data";
import { makeRequest } from "../../api/api";

export const getVesssels = createAsyncThunk(
  'user/getVesssels',
  async (page) => {
    const request = await makeRequest("get", `${hostname}api/vessel_details/?requested_page=${page}`)
    const response = await request.data.data
    return response
  }
)

export const searchVessels = createAsyncThunk('voyages/searchVessels', async (params) => {
  const { imo_number, voyage_number } = params
  try {
    const response = await makeRequest("get", `api/vessel_details/?imo_number=${imo_number}&voyage_number=${voyage_number}`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

export const fetchGoogleMapDetails = createAsyncThunk(
  'voyage/getGoogleMap',
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("get", `api/get_marine_api_data/?voyage_number=${voyage_number}&imo_number=${imo_number}`)
    const response = await request.data.data
    return response
  }
)

export const selectVessel = createAsyncThunk(
  'user/selectedVessel',
  async (requestedData) => {
    try {
      const request = await makeRequest("get", `${hostname}api/vessel_info/?imo_number=${requestedData.imo_number}&voyage_number=${requestedData.voyage_number}`)
      // const request = await axios({
      //   url: `${hostname}api/vessel_info/?imo_number=${requestedData.imo_number}&voyage_number=${requestedData.voyage_number}`,
      //   method: 'get',
      // });
      const response = await request.data.data;
      if (response) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching selected vessel:', error);
      return null;
    }
  }
)

const VesselSlice = createSlice({
  name: 'vessels',
  initialState: {
    status: 'idle',
    selectedVessel: null,
    vessels: [],
    avgSpeed: null,
    page: 1,
  },
  reducers: {
    clearVessels: (state, action) => {
      state.page = 1;
      state.vessels = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVesssels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getVesssels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.vessels = [...state.vessels, ...action.payload];
        state.page++
      })
      .addCase(getVesssels.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(selectVessel.fulfilled, (state, action) => {
        state.selectedVessel = action.payload
      })
      .addCase(searchVessels.fulfilled, (state, action) => {
        state.vessels = action.payload
      })

  },
});

export const { clearVessels } = VesselSlice.actions


export default VesselSlice.reducer