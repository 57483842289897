import React, { useState } from 'react';
import axios from 'axios';
import qs from 'qs';
import { Select, Table } from 'antd';
import { hostname } from '../data';
import { searchVoyages } from '../features/Voyage/VoyageSlice';
import { useDispatch } from 'react-redux';
import "../css/search.css"
let timeout;
let currentValue;
const fetch = (value, callback) => {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
  }
  currentValue = value;
  const fake = () => {
    const str = qs.stringify({
      code: 'utf-8',
      q: value,
    });
    const url = `${hostname}api/get_suggestions/?registered_owner=CLDN Cobelfret Pte. Ltd.&keyword=${value}`
    axios({
      url: url,
      method: "get",
      headers: {
        "Authorization": localStorage.getItem("Authorization")
      }
    })
      .then((response) => {
        if (currentValue === value) {
          const result = response.data.data;
          if (result === null) {
            callback(null)
          }
          else {
            const data = result.map((item) => ({
              value: item,
              text: item,
            }));
            callback(data);
          }
        }
      });
  };
  if (value) {
    timeout = setTimeout(fake, 300);
  } else {
    callback([]);
  }
};


const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(null);
  const [open, setOpen] = useState(false)
  const handleSearch = (newValue) => {
    fetch(newValue, setData);
  };
  const dispatch = useDispatch()

  const customRender = (props) => {
    const columns = [
      {
        title: 'Imo Number',
        dataIndex: 'imo_number',
        key: 'imo_number',
      },
      {
        title: 'Voyage Number',
        dataIndex: 'voyage_number',
        key: 'voyage_number',
      },
      {
        title: 'Vessel Name',
        dataIndex: 'vessel_name',
        key: 'vessel_name',
      },
    ];


    const searchHandler = (record) => {
      setValue(record.vessel_name)
      const params = {
        vessel_name: record.vessel_name,
        voyage_number: record.voyage_number,
        imo_number: record.imo_number,
        page: 1
      }
      setOpen(false)
      dispatch(searchVoyages(params))
    }

    return (
      <div>
        <Table
          dataSource={(data || []).map((d) => ({
            vessel_name: d.value.vessel_name,
            voyage_number: d.value.voyage_number,
            imo_number: d.value.imo_number
          }))}
          onRow={(record) => ({
            onClick: () => { props.onSelect(record, setOpen, setValue) },
            style: { cursor: 'pointer' },
          })}
          columns={columns}
          pagination={false}
        />
      </div>
    )
  }

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Select
      showSearch
      value={value}
      placeholder={props.placeholder}
      dropdownClassName="hidden-scrollbar"
      dropdownStyle={{ height: '300px', overflow: 'auto' }}
      style={props.style}
      defaultActiveFirstOption={false}
      filterOption={false}
      size='large'
      onSearch={handleSearch}
      onChange={handleChange}
      dropdownRender={() => customRender(props)}
      open={open}
      popupMatchSelectWidth={true}
      onClear={() => { props.onClear(setValue) }}
      allowClear
      onDropdownVisibleChange={(visible) => setOpen(visible)}
    // options={[{}]}
    />
  );
};
const Search = (props) => (
  <SearchInput
    placeholder="Search Vessel, Voyage"
    style={{
      width: "100%",
    }}
    {...props}
  />
);
export default Search;