import React, { useState } from 'react';
import ChartsComponent from './ChartComponent';

const VoyageMatrixTable = ({ index, voyageMatrixData }) => {


    console.log(voyageMatrixData.laycan_speed)
    const [ciiCellDatas, setCIICellData] = useState(voyageMatrixData.data[0])
    const [selectedCellIndex, setSelectedCellIndex] = useState({ rowIndex: 0, colIndex: 1 });

    const [selectedChartData, setSelectedChartData] = useState(voyageMatrixData.data[0]);

    const handleClick = (value, ciiCellData, rowIndex, colIndex) => {
        setSelectedChartData(ciiCellData);
        setSelectedCellIndex({ rowIndex, colIndex });
    };

    // const handleClick = (value, ciiCellData) => {
    //     console.log(ciiCellData)
    //     updateChartData(ciiCellData)
    //     setCIICellData(ciiCellData)
    // };
    const renderTable = () => {
        const numRows = voyageMatrixData.list_of_laden_speed.length;
        const numCols = voyageMatrixData.list_of_ballast_speed.length + 2;

        const ladenSpeedList = voyageMatrixData.list_of_laden_speed.filter(speed => typeof speed === 'number').sort((a, b) => a - b)
        const ballastSpeedList = voyageMatrixData.list_of_ballast_speed.filter(speed => typeof speed === 'number').sort((a, b) => a - b)

        // const data = [
        //     ['28.4 (2.1)', '27.2 (2.2)', '26.3 (2.2)', '25.6 (2.3)', '25.1 (2.4)', '24.6 (2.5)', '24.2 (2.7)', '23.8 (2.8)'],
        //     ['26.4 (2.2)', '25.3 (2.3)', '24.5 (2.3)', '23.8 (2.4)', '23.2 (2.5)', '22.7 (2.6)', '22.3 (2.8)', '22 (2.9)'],
        //     ['25 (2.3)', '23.9 (2.4)', '23 (2.5)', '22.3 (2.5)', '21.8 (2.6)', '21.3 (2.7)', '20.9 (2.9)', '20.5 (3)'],
        //     ['23.8 (2.5)', '22.7 (2.5)', '21.9 (2.6)', '21.2 (2.7)', '20.6 (2.8)', '20.1 (2.9)', '19.7 (3)', '19.4 (3.2)'],
        //     ['22.9 (2.7)', '21.8 (2.7)', '20.9 (2.8)', '20.2 (2.9)', '19.7 (2.9)', '19.2 (3.1)', '18.8 (3.2)', '18.4 (3.4)'],
        //     ['22.1 (2.9)', '21 (2.9)', '20.1 (3)', '19.4 (3.1)', '18.9 (3.2)', '18.4 (3.3)', '18 (3.4)', '17.6 (3.6)'],
        //     ['21.4 (3.1)', '20.3 (3.2)', '19.5 (3.2)', '18.8 (3.3)', '18.2 (3.4)', '17.7 (3.5)', '17.3 (3.7)', '17 (3.8)'],
        // ];

        return (
            <> <table className='voyageMatrixTable' border="1">
                <thead>
                    <tr>
                        <th rowSpan={2} colSpan={2} >AER</th>
                        <th colSpan={8}>Ballast Speed</th>
                    </tr>
                    <tr>
                        {voyageMatrixData.list_of_ballast_speed
                            .filter(speed => typeof speed === 'number') // Filter out non-numeric values if any
                            .sort((a, b) => a - b) // Sort the array in ascending order
                            .map((data, index) => (
                                <th style={{ background: selectedCellIndex.colIndex === index + 1 ? "grey" : "" }} key={index}>{data}</th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {[...Array(numRows)].map((_, rowIndex) => (
                        <tr key={rowIndex}>
                            {rowIndex === 0 && (
                                <th rowSpan={ladenSpeedList.length} class="ladenSpeedCell" style={{ position: 'relative' }}>
                                    <span style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) rotate(-90deg)', width: '135px', textAlign: 'center' }}>Laden Speed</span>
                                </th>
                            )}
                            {[...Array(numCols - 1)].map((_, colIndex) => {
                                let className = '';
                                let value = '';

                                if (rowIndex < numRows && colIndex === 0) {
                                    value = `${ladenSpeedList[rowIndex]}`;
                                    return <th style={{ background: selectedCellIndex.rowIndex === rowIndex ? "grey" : "" }} key={colIndex}>{value}</th>;
                                }

                                if (rowIndex >= 0 && colIndex >= 1) {
                                    const matchedData = voyageMatrixData.data.find(item => item.L_speed === ladenSpeedList[rowIndex] && item.B_speed === ballastSpeedList[colIndex - 1]);
                                    if (matchedData) {
                                        value = matchedData.cii_y_axis[matchedData.cii_y_axis.length - 1];

                                        let className;
                                        switch (matchedData.final_grade) {
                                            case "A":
                                                className = 'classgreen';
                                                break;
                                            case "B":
                                                className = 'classSkyBlue';
                                                break;
                                            case "C":
                                                className = 'classOrange';
                                                break;
                                            case "D":
                                                className = 'classPink';
                                                break;
                                            default:
                                                className = 'classRed';
                                                break;
                                        }

                                        // Add conditional style to change font color to red for the selected cell
                                        const isSelected = selectedCellIndex && selectedCellIndex.rowIndex === rowIndex && selectedCellIndex.colIndex === colIndex;
                                        const style = isSelected ? { color: 'blue' } : {};

                                        return <td
                                            style={{ ...style, cursor: "pointer", textAlign: "center" }}
                                            onClick={() => handleClick(value, matchedData, rowIndex, colIndex)}
                                            key={colIndex}
                                            className={className}>
                                            {voyageMatrixData.laycan_speed <= matchedData.B_speed ? `(${value})` : value}
                                        </td>;
                                    }
                                }

                                return <td key={colIndex}></td>;
                            })}
                        </tr>
                    ))}

                    {/* Third last row */}
                    {/* <tr>
                        <td colSpan={10}></td>
                    </tr> */}
                    {/* Second last row */}
                    {/* <tr>
                        <th colSpan={2}>Key:</th>
                        {[...Array(5)].map((_, index) => (
                            <td key={index}
                                className={String.fromCharCode(65 + index) === 'A' ? 'classgreen' : String.fromCharCode(65 + index) === 'B' ? 'classSkyBlue' : String.fromCharCode(65 + index) === 'C' ? 'classOrange' : String.fromCharCode(65 + index) === 'D' ? 'classPink' : "classRed"}
                            >{String.fromCharCode(65 + index)}</td>
                        ))}
                        <td colSpan={3}></td>
                    </tr> */}
                    {/* Last row */}
                    {/* <tr>
                        <td colSpan={10}>Legend: (1) Each cell shows total voyage duration in days & (AER) / (2) Cells with BOLD font show conditions where Laycan is possible</td>
                    </tr> */}
                </tbody>
            </table>

            </>
        );
    };

    return (
        <div className="voyage-matrix-table-container">
            <div className='voyage-matrix-table-container-Table'>
                {renderTable()}
            </div>
            <div className='voyageMatrixGraph'>

                <ChartsComponent chartId={index} chartData={selectedChartData} voyageGradient={voyageMatrixData.gradient_vector} />
            </div>
        </div>
    );
};

export default VoyageMatrixTable;
