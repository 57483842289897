import React, { useState, useEffect } from "react";
import { Form, Table, Tag, Progress, Empty } from "antd";
import arrowDown from "../assets/icons/eua/arrow-down.svg";
import { useSelector } from "react-redux";
const ExpandedRowRender = (props) => {
  const { user } = useSelector((state) => state.user);

  const isAdmin = user.role === "admin" || user.role === "doc";
  const columns = [
    {
      title: "Vessel",
      dataIndex: "Vessel",
    },
    // {
    //   title: "Charterer",
    //   dataIndex: "Charterer",
    // },
    {
      title: "DOC",
      dataIndex: "DOC",
    },
    isAdmin && {
      title: "Technical Gr",
      dataIndex: "Technical Gr",
    },
    // {
    //   title: "Status",
    //   dataIndex: "Status",
    // },
    {
      title: "EUA Due",
      dataIndex: "EUA Due",
    },
    {
      title: "EUA Procured",
      dataIndex: "EUA Procured",
      editable: false,
    },
    {
      title: "EUA Outstanding",
      dataIndex: "EUA Outstanding",
    },
    {
      title: "Outstanding Cost",
      dataIndex: "Outstanding Cost",
    },
    {
      title: "Settlement %",
      dataIndex: "Settlement %",
    },

  ].filter(Boolean);
  const [data, setData] = useState([])
  useEffect(() => {
    setData(
      props.data.map((value, index) => ({
        key: index.toString(),
        Vessel: value.vessel_name,
        Charterer: value.charterer_name,
        DOC: value.doc,
        "Technical Gr": value.technical_group,
        Status: <Tag color="rgba(255, 186, 7, 0.20)" style={{ color: "black" }}>{value.status}</Tag>,
        "EUA Due": value.eua_due,
        "EUA Procured": value.eua_released,
        "EUA Outstanding": value.eua_outstanding,
        "Outstanding Cost": `€ ${value.outstanding_cost}`,
        "Settlement %": <Progress trailColor="#000508" percent={Math.round(value.eua_settled_percent)} />,
      }))
    );
  }, [props.data]);


  return <Table scroll={{ y: "38vh" }} showHeader={true} columns={columns} dataSource={data} pagination={false} />;
};


const ExpandableRow = (props) => {
  const { activeCard, setActiveCard } = props;

  const handleClick = () => {
    setActiveCard(props.title === activeCard ? null : props.title);
  };
  return (
    <div className="admin-table">
      <div onClick={handleClick} className="ExpandRow-Sattlement">
        <img src={arrowDown} alt="" />
        <span>{props.title}</span>
      </div>
      <div
        className="content"
        style={{
          maxHeight: props.title === activeCard ? '45vh' : '0',
          overflow: "hidden",
          transition: 'max-height 0.4s ease-out',
        }}
      >
        <ExpandedRowRender data={props.data} />
      </div>
    </div>

  )
}

const ExpandableTable = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([])
  const [activeCard, setActiveCard] = useState("");


  useEffect(() => {
    const ownerVessels = {};
    props.data.forEach(entry => {
      const owner = entry.active_owner;
      if (!ownerVessels[owner]) {
        ownerVessels[owner] = [];
      }
      ownerVessels[owner].push(entry);
    });
    setActiveCard(Object.keys(ownerVessels)[0])
    setData(ownerVessels);
  }, [props.data]);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;



  const columns = [
    {
      title: "Vessel",
      dataIndex: "Vessel",
    },
    {
      title: "Charterer",
      dataIndex: "Charterer",
    },
    {
      title: "DOC",
      dataIndex: "DOC",
    },
    {
      title: "Technical Gr",
      dataIndex: "Technical Gr",
    },
    {
      title: "Status",
      dataIndex: "Status",
    },
    {
      title: "EUA Due",
      dataIndex: "EUA Due",
    },
    {
      title: "EUA Procured",
      dataIndex: "EUA Procured",
      editable: false,
    },
    {
      title: "EUA Outstanding",
      dataIndex: "EUA Outstanding",
    },
    {
      title: "Outstanding Cost",
      dataIndex: "Outstanding Cost",
    },
    {
      title: "Settlement %",
      dataIndex: "Settlement %",
    },

  ];



  return (
    <Form form={form} component={false}>
      <div style={{ height: "48vh", overflow: "auto" }}>
        {Object.keys(data).length === 0 ? <Empty /> : ""}
        {Object.keys(data).map((value, index) => {
          return <ExpandableRow title={value} activeCard={activeCard} setActiveCard={setActiveCard} data={data[value]} />
        })}
      </div>
    </Form>
  );
};

export default ExpandableTable;
