import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { makeRequest } from '../../api/api';

export const fetchVoyages = createAsyncThunk('voyages/fetchVoyages', async (page) => {
  try {
    const response = await makeRequest("get", `api/get_voyage/?registered_owner=CLDN Cobelfret Pte. Ltd.&requested_page=${page}`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});
export const fetchPortList = createAsyncThunk('voyages/fetchPortList', async (page) => {
  try {
    const response = await makeRequest("get", `/freemium/port_list/?port_name=${page}`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});
export const fetchPortList2 = createAsyncThunk('voyages/fetchPortList', async (page) => {
  try {
    const response = await makeRequest("get", `/freemium/port_list/?port_name=${page}&requested_type=port_distance`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});
export const fetchFreemiumReportData = createAsyncThunk('voyages/fetchFreemiumReportList', async () => {
  try {
    const response = await makeRequest("get", `/freemium/freemium_report_history/`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});
export const fetchFuelList = createAsyncThunk('voyages/fetchFuelList', async () => {
  try {
    const response = await makeRequest("get", `/api/fuel_list`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

export const searchVoyages = createAsyncThunk('voyages/searchVoyages', async (params) => {
  const { page, voyage_number, imo_number } = params
  try {
    const response = await makeRequest("get", `api/get_voyage/?registered_owner=CLDN Cobelfret Pte. Ltd.&requested_page=${page}&imo_number=${imo_number}&voyage_number=${voyage_number}`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});


export const fetchOffHireData = createAsyncThunk('voyages/offhireData', async (params) => {
  const { voyage_number, imo_number } = params
  try {
    const response = await makeRequest("get", `super_admin/get_off_hire/?imo_number=${imo_number}&voyage_number=${voyage_number}`)
    return response.data.data;
  } catch (error) {
    throw error;
  }
});

export const fetchVoyagesHistory = createAsyncThunk(
  'voyage/getHistory',
  async (params) => {
    const { imo_number, page } = params
    const request = await makeRequest("get", `api/get_voyage_history/?registered_owner=CLDN Cobelfret Pte. Ltd.&requested_page=${page}&imo_number=${imo_number}`)
    const response = await request.data.data
    return response
  }
)

export const fetchEuaDifferenceChart = createAsyncThunk(
  'voyage/euaDifferenceChart',
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("get", `api/get_eua_difference_chart_data/?registered_owner=CLDN Cobelfret Pte. Ltd.&voyage_number=${voyage_number}&imo_number=${imo_number}`)
    const response = await request.data.data
    return response
  }
)


export const fetchEuaDistribution = createAsyncThunk(
  'voyage/euaDistributionChart',
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("get", `api/get_eua_distribution/?registered_owner=CLDN Cobelfret Pte. Ltd.&voyage_number=${voyage_number}&imo_number=${imo_number}`)
    const response = await request.data.data
    return response
  }
)


export const fetchVoyageEuaOverview = createAsyncThunk(
  'voyage/euaOverview',
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("get", `api/voyage_eua_overview/?registered_owner=CLDN Cobelfret Pte. Ltd.&voyage_number=${voyage_number}&imo_number=${imo_number}`)
    const response = await request.data.data
    return response
  }
)


export const updateSelectedVoayage = createAsyncThunk(
  'voyage/updateSelectedVoyage',
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("get", `api/get_voyage/?registered_owner=CLDN Cobelfret Pte. Ltd.&voyage_number=${voyage_number}&imo_number=${imo_number}`)
    const response = await request.data.data[0]
    return response
  }
)

export const fetchStatementDocument = createAsyncThunk(
  "voyage/generateStatementDocument",
  async (params, date) => {
    const { voyage_number, imo_number, start_date, end_date } = params
    const request = await makeRequest("get", `api/generate_document/?voyage_number=${voyage_number}&imo_number=${imo_number}&doc_type=statement&start_date=${start_date}&end_date=${end_date}`)
    const response = await request.data.data
    return response
  }
)

export const fetchInvoice = createAsyncThunk(
  "voyage/generateInvoice",
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("get", `api/generate_document/?voyage_number=${voyage_number}&imo_number=${imo_number}&doc_type=invoice`)
    const response = await request.data.data
    return response
  }
)

export const fetchOrder = createAsyncThunk(
  "voyage/generateOrder",
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("get", `api/generate_document/?voyage_number=${voyage_number}&imo_number=${imo_number}&doc_type=order`)
    const response = await request.data.data
    return response
  }
)
export const deleteVoyageDetails = createAsyncThunk(
  "voyage/deleteVoyage",
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("delete", `super_admin/delete_off_hire/?imo_number=${imo_number}&voyage_number=${voyage_number}`)
    const response = await request.data.data
    return response
  }
)

export const uploadDocument = createAsyncThunk(
  "voyage/uploadDocument",
  async (params) => {
    const request = await makeRequest("post", `super_admin/upload_verified_statement/`, params)
    const response = await request.data.data
    return response
  }
)
export const deleteDocument = createAsyncThunk(
  "voyage/deleteDocument",
  async (params) => {
    const { voyage_number, imo_number } = params
    const request = await makeRequest("post", `api/del_verified_emission_rep/`, params)
    const response = await request.data.data
    return response
  }
)


export const postOffHireRequest = createAsyncThunk(
  'voyage/offhireRequest',
  async (params) => {
    const finalData = params;
    console.log(finalData)
    try {
      const response = await makeRequest("post", `/super_admin/off_hire/`,
        finalData
      );

      return response.data.data;
    } catch (error) {
      throw new Error("Failed to update voyage");
    }
  }
);


export const postEuaCalculateRequest = createAsyncThunk(
  'voyage/euaCalculateRequest',
  async (params) => {
    const finalData = params;
    console.log(finalData)
    try {
      const response = await makeRequest("post", `/freemium/eua_calculation/`,
        finalData
      );
      console.log(response.data.data)
      return response.data.data;

    } catch (error) {
      throw new Error("Failed to calcualte eua");
    }
  }
);

const VoyageSlice = createSlice({
  name: 'voyage',
  initialState: {
    voyages: [],
    status: 'idle',
    selectedVoyage: null,
    page: 1,
    euaOverview: null,
    euaDistribution: null,
    euaDifference: null,
    fuelList: [],
    historyVoyages: {
      data: [],
      error: null,
      page: 1,
      status: "idle"
    },
    statementDocument: null,
    downloadDocument: null,
    invoice: null,
    order: null,
    historyVoyagesSelected: false,
    offHireData: null,
    freemiumReportData: [],
    error: null,
  },
  reducers: {
    selectVoyage: (state, action) => {
      state.selectedVoyage = action.payload
      state.historyVoyages = {
        data: [],
        fuelList: null,
        error: null,
        page: 1,
        status: "idle"
      }
    },
    clearVoyages: (state, action) => {
      state.page = 1;
      state.voyages = [];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoyages.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVoyages.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.voyages = [...state.voyages, ...action.payload];
        state.page++
      })
      .addCase(fetchVoyages.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchVoyagesHistory.pending, (state) => {
        state.historyVoyages.status = 'loading';
      })
      .addCase(fetchVoyagesHistory.fulfilled, (state, action) => {
        state.historyVoyages.status = 'succeeded';
        state.historyVoyages.data = [...action.payload, ...state.historyVoyages.data];
        state.historyVoyages.page++
      })
      .addCase(fetchVoyagesHistory.rejected, (state, action) => {
        state.historyVoyages.status = 'failed';
        state.historyVoyages.error = action.error.message;
      })
      .addCase(fetchVoyageEuaOverview.fulfilled, (state, action) => {
        state.euaOverview = action.payload
        state.downloadDocument = action.payload?.url_verified_statement_uploaded_by_admin
      })
      .addCase(fetchEuaDifferenceChart.fulfilled, (state, action) => {
        state.euaDifference = action.payload
      })
      .addCase(fetchEuaDistribution.fulfilled, (state, action) => {
        state.euaDistribution = action.payload
      })
      .addCase(updateSelectedVoayage.fulfilled, (state, action) => {
        state.selectedVoyage = action.payload
      })
      .addCase(searchVoyages.fulfilled, (state, action) => {
        state.historyVoyages.data = []
        state.page = 1
        state.voyages = action.payload
        state.selectedVoyage = action.payload[0]
        state.historyVoyages.page = 1
      })
      .addCase(fetchStatementDocument.fulfilled, (state, action) => {
        state.statementDocument = action.payload
      })
      .addCase(fetchStatementDocument.rejected, (state, action) => {
        state.statementDocument = null
      })
      .addCase(fetchInvoice.fulfilled, (state, action) => {
        state.invoice = action.payload
      })
      .addCase(fetchInvoice.rejected, (state, action) => {
        state.invoice = null
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.order = action.payload
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.order = null
      })
      .addCase(fetchOffHireData.rejected, (state, action) => {
        state.offHireData = null
      })
      .addCase(fetchOffHireData.pending, (state, action) => {
        state.offHireData = null
      })
      .addCase(fetchOffHireData.fulfilled, (state, action) => {
        state.offHireData = action.payload
      })
      .addCase(fetchFuelList.fulfilled, (state, action) => {
        console.log(action.payload)
        state.fuelList = action.payload
      })
      .addCase(fetchFuelList.rejected, (state, action) => {
        state.fuelList = null
      })
      .addCase(fetchFuelList.pending, (state, action) => {
        state.fuelList = null
      })
      .addCase(fetchFreemiumReportData.fulfilled, (state, action) => {
        console.log(action.payload)
        state.freemiumReportData = action.payload
      })
      .addCase(fetchFreemiumReportData.rejected, (state, action) => {
        state.freemiumReportData = null
      })
      .addCase(fetchFreemiumReportData.pending, (state, action) => {
        state.freemiumReportData = null
      })
  },
});

export const { selectVoyage, clearVoyages } = VoyageSlice.actions

export default VoyageSlice.reducer;
