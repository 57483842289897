import React from 'react';
import Heading from './Heading';
import ticket from "../../../assets/icons/rawdata/ticket-plus.png";
import { useSelector } from 'react-redux';

const StepperFirstEditFuels = ({ selectedUniqueId }) => {
    const { fuelDataByUniqueId } = useSelector((state) => state.rawData)


    const standardFuels = ['MDO', 'LFO', 'HFO', 'LNG-ME', 'LNG-BOILER', 'LNG-AE', 'LNG-OTHERS', 'LPG-PROPANE', 'LPG-BUTANE'];
    const data = {
        "Standard Fuels": fuelDataByUniqueId.filter(item => standardFuels.includes(item.fuel_name)),
        "Alternate Fuels": fuelDataByUniqueId.filter(item => !standardFuels.includes(item.fuel_name))
    };

    const renderFuelData = (fuelData) => {
        return fuelData.map(fuel => (
            <div key={fuel.id} className='fuel-data-cards-details'>
                <p className='fuel-data-cards-fuel-name'>{fuel.fuel_name ? fuel.fuel_name : fuel.class_name}</p>
                <div className="fuels-info-main-container">
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">Consumption</p>
                        <p className="fuel-info-value">{!fuel.consumption ? '-' : fuel.consumption} MT</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">LCV</p>
                        <p className="fuel-info-value">{!fuel.fuel_lcv ? '-' : fuel.fuel_lcv} MJ/MT</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">Bunker Quantity</p>
                        <p className="fuel-info-value">{!fuel.bunker_quantity ? '-' : fuel.bunker_quantity} MT</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">Bunker Survey</p>
                        <p className="fuel-info-value">{!fuel.bunker_survey ? '-' : fuel.bunker_survey} MT</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">ROB</p>
                        <p className="fuel-info-value">{!fuel.rob ? '-' : fuel.rob} MT</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">CSlip</p>
                        <p className="fuel-info-value">{!fuel.fuel_cslip ? '-' : fuel.fuel_cslip} MT</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">EF TTW (MJfuel)</p>
                        <p className="fuel-info-value">{!fuel.fuel_ef_ttw_MJfuel ? '-' : fuel.fuel_ef_ttw_MJfuel?.toFixed(2)} gCO₂eq /MJ</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">EF WTT (MJfuel)</p>
                        <p className="fuel-info-value">{!fuel.fuel_ef_wtt_MJfuel ? '-' : fuel.fuel_ef_wtt_MJfuel?.toFixed(2)} gCO₂eq /MJ</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">EF TTW (gfuel)</p>
                        <p className="fuel-info-value">{!fuel.fuel_ef_ttw_gfuel ? '-' : fuel.fuel_ef_ttw_gfuel?.toFixed(2)} gCO₂eq /MJ</p>
                    </div>
                    <div className="fuels-info-main-div">
                        <p className="fuel-info-label">EF WTT (gfuel)</p>
                        <p className="fuel-info-value">{!fuel.fuel_ef_wtt_gfuel ? '-' : fuel.fuel_ef_wtt_gfuel?.toFixed(2)} gCO₂eq /MJ</p>
                    </div>
                </div>
            </div>
        ));
    };

    return (
        <div className='stepper-first-basic-details'>
            <Heading title="Add details" iconImg={ticket} selectedUniqueId={selectedUniqueId} />
            <div className='fuel-data-container-stepper' style={{ marginTop: "10px" }}>
                <div className='fuel-data-stepper-cards'>
                    <p className='fuel-stepper-header-type'>Standard Fuels</p>
                    {renderFuelData(data['Standard Fuels'])}
                </div>
                <div className='fuel-data-stepper-cards'>
                    <p className='fuel-stepper-header-type'>Alternate Fuels</p>
                    {renderFuelData(data['Alternate Fuels'])}
                </div>
            </div>
        </div>
    );
};

export default StepperFirstEditFuels;
