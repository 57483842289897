import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { deleteFuelInfo, fetchVesselsList } from "../../features/VoyageOptimization/VoyageOptimizationSlice";
import { toggleModal, updateModalInfo } from "../../features/App/AppSlice";
import deleteNotificationIcon from "../../assets/icons/notifications/delete.svg";
import { Modal, Button, List } from "antd";
import FuelTablesPage from "./FuelTablesPage";
import CIIDetailsInput from "./CIIDetailsInput";


const ListOfVessals = ({ onItemSelected, isNewVessel }) => {
    const dispatch = useDispatch();
    const { vesselsJSON } = useSelector(state => state.voyageOptimization)
    const [selectedVessels, setSelectedVessels] = useState([]);
    const [selectedVessel, setSelectedVessel] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCIIMatrix, setShowCIIMatrix] = useState(false);
    const [showButtons, setShowButtons] = useState({});
    const [prevSelectedItem, setPrevSelectedItem] = useState();
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedIMO, setSelectedIMO] = useState();


    // console.log(vesselsJSON)

    const [showCIIModal, setShowCIIModal] = useState(false);

    const { user } = useSelector((state) => state.user);

    useEffect(() => {
        dispatch(fetchVesselsList("")).then((result) => {
            if (result.payload) {
                // const vesselNames = result.payload.map((vessel) => vessel.vessel_name);
                // setVesselsList(vesselNames);

                // console.log(result.payload)
            }
        });
    }, []);

    const showDeleteModal = ()=>{
        setDeleteModal(true);
    }

    const handleDelete = () => {
        dispatch(deleteFuelInfo(selectedIMO)).then((result) => {
            if (result.payload) {
                dispatch(fetchVesselsList(""));
                dispatch(toggleModal())
                dispatch(updateModalInfo({ show: true, result: 'Deleted Successfully!', type: 'success', message: 'Success!' }));
            } else {
                dispatch(toggleModal())
                dispatch(updateModalInfo({ show: true, result: 'Delete Failed!', type: 'error', message: 'Failed' }));
            }
        })
            .catch((error) => {
                dispatch(updateModalInfo({ show: true, result: error.toString(), type: 'error', message: 'Failed' }))
            });
            setDeleteModal(false)
    }

    const handleCancel = () => {
        setDeleteModal(false);
      };

    const handleAlreadyAddedSelection = (item) => {
        // Assuming selectedVessels contains objects similar to item
        const selectedIndex = selectedVessels.findIndex(selectedItem => selectedItem.imo_number === item.imo_number);
        let newSelected = [...selectedVessels];

        if (selectedIndex === -1 && newSelected.length < 3) {
            newSelected.push(item);
        } else if (selectedIndex !== -1) {
            newSelected.splice(selectedIndex, 1);
        }

        console.log(newSelected)
        setSelectedVessels(newSelected);
    };

    const isAlreadyAddedSelected = (item) => {
        // Check if any item in selectedVessels has the same imo_number as the current item
        return selectedVessels.some(selectedItem => selectedItem.imo_number === item.imo_number);
    };

    const toggleButton = (item) => {
        setShowButtons((prevState) => ({
            ...prevState,
            [prevSelectedItem]: false, // Close previously selected item's button
            [item]: !prevState[item], // Toggle button for the current item
        }));

        setPrevSelectedItem(item); // Set the current item as the previously selected item
    };

    // console.log(selectedVessels)
    return (
        <>
            <div className="voyageTablecontainer">
                <div className="vesselsListContainer">
                    <div className="vesselsListContainerItems">
                        <p>Already added Vessels: </p>
                        <List
                            size="large"
                            bordered
                            dataSource={vesselsJSON.list_of_added_vessel}
                            renderItem={(item) => (
                                <List.Item
                                    onClick={() => {
                                        handleAlreadyAddedSelection(item);
                                        setSelectedVessel(item);
                                        onItemSelected(item);
                                        isNewVessel(false);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: isAlreadyAddedSelected(item)
                                            ? "#ececff"
                                            : "inherit",
                                    }}
                                >
                                    <div className="added-vessels-element">
                                        <span>{item.vessel_name}</span>
                                        <div className="added-vessels-element-actions">
                                            {user.role === "admin" && (
                                                <img onClick={() =>{showDeleteModal(); setSelectedIMO(item.imo_number)}} src={deleteNotificationIcon} alt="delete" />

                                            )}
                                            <input
                                                type="checkbox"
                                                checked={isAlreadyAddedSelected(item)}
                                                onChange={() => { }} // Empty onChange to prevent default behavior
                                            />
                                        </div>
                                    </div>

                                </List.Item>
                            )}
                        />

                        <Modal
                            title="Confirm Delete"
                            open={deleteModal}
                            onOk={handleDelete}
                            onCancel={handleCancel}
                            footer={[
                                <Button className="cancel-button" onClick={handleCancel} style={{ color: 'red', backgroundColor: 'black' }}>
                                    Cancel
                                </Button>,
                                <Button key="submit" type="primary" danger onClick={handleDelete} style={{ backgroundColor: 'red', color: 'white' }}>
                                    Delete
                                </Button>,
                            ]}
                        >
                            <p>Are you sure you want to delete this fuel table?</p>
                        </Modal>

                    </div>

                    <div className="vesselsListContainerItems">
                        <p>New Vessels: </p>
                        <List
                            size="large"
                            // header={<div>Header</div>}
                            // footer={<div>Footer</div>}
                            bordered
                            dataSource={vesselsJSON.list_of_not_added}
                            renderItem={(item) =>
                                <List.Item
                                    onClick={() => {
                                        setSelectedVessel(item);
                                        onItemSelected(item);
                                        isNewVessel(true);
                                        // setShowModal(true);
                                        toggleButton(item);
                                    }}
                                    style={{
                                        cursor: "pointer",
                                        backgroundColor: selectedVessel === item ? "#ececff" : "inherit",
                                    }}
                                >{item.vessel_name}
                                    {showButtons[item] && selectedVessel === item && (
                                        <div className="FuelTablesButton"> <button onClick={() => {
                                            setShowModal(true);
                                        }}>Add Fuel Tables</button></div>
                                    )}
                                </List.Item>}
                        />
                    </div>
                </div>

                <button
                    className="vesselsListContainer-Button"
                    disabled={selectedVessels.length === 0}
                    onClick={() => {
                        setShowCIIMatrix(true)
                        setShowCIIModal(true)
                    }}
                >
                    Voyage Optimization
                </button>

                <FuelTablesPage
                    selectedVessel={selectedVessel}
                    isVesselNew={isNewVessel}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />



            </div>

            <CIIDetailsInput
                selectedVessels={selectedVessels}
                showCIIModal={showCIIModal}
                setShowCIIModal={setShowCIIModal}
                isEditFieldsOn={false}
            />
        </>
    );
};

export default ListOfVessals;
