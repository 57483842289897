import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function FuelTable() {

    const selectedVessel = useSelector((state) => state.vessel.selectedVessel)
    const [data, setData] = useState([])
    useEffect(() => {
        if (selectedVessel !== null) {
            let items=[]
            selectedVessel.oil.map((value,index)=>{
                items.push({
                    fuel:value.fuel_name,
                    quantity:value.quantity
                })
            })
            setData(items)
        }
    }, [selectedVessel])
    const columns = [
        {
            header: 'Fuel',
            key: 'fuel',
        },
        {
            header: 'Quantity (mt)',
            key: 'quantity',
        },
        {
            header: 'WTT (t)',
            key: 'wtt',
        },
    ];



    return (
        <div className="fuel-table">
            <div className='fuel-table-header'>
                {columns.map((column) => (
                    <span style={{ width: "20%" }} className='vessel-data-label' key={column.key}>{column.header}</span>
                ))}
            </div>
            {data.map((row) => {
                if(row?.quantity>0)
                return(
                <div className='fuel-table-data'>
                    <span className='vessel-data-data' style={{ opacity: "0.7", width: "20%", textTransform:"uppercase" }}>{row.fuel}</span>
                    <span className='vessel-data-data' style={{ width: "20%" }}>{row.quantity}</span>
                    <span className='vessel-data-data' style={{ width: "20%" }}>{row.wtt}</span>
                </div>
                )
                })}
        </div>
    );
}

export default FuelTable;