import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { deleteDocument, uploadDocument } from '../../features/Voyage/VoyageSlice'
import { fetchVoyageEuaOverview } from '../../features/Voyage/VoyageSlice'
import { Popconfirm, message, Tooltip } from 'antd';
const DeleteDocument = ({ label, icon, type }) => {

    const { user } = useSelector((state) => state.user)
    const { selectedVoyage, downloadDocument } = useSelector((state) => state.voyage)
    const dispatch = useDispatch()



    const handleDelete = () => {
        message.loading({ content: 'Deleting...', key: 'deleteLoading' });
        setTimeout(() => {
            dispatch(deleteDocument(selectedVoyage)).then((result) => {
                console.log(result);
                message.success({ content: 'Deleted successfully!', key: 'deleteLoading', duration: 2 });
                if (result) {
                    dispatch(fetchVoyageEuaOverview(selectedVoyage))
                }
            });
        }, 1000);
    }

    return (
        <div>
            <Tooltip title="Delete Verified Report">
                <div onClick={handleDelete} style={{ display: "flex", flexDirection: "column" }}>
                    <img src={icon} style={{ height: "20px", cursor: "pointer" }} alt="" />
                    <span className='card-icon-span'>{label}</span>
                </div>
            </Tooltip>
        </div>
    )

}

export default DeleteDocument