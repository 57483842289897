import React from 'react';
import { Select } from 'antd';
import { EditTwoTone, RollbackOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const EnterCasperId = ({ selectedCasperId, setSelectedCasperId }) => {
  const { casperIdData } = useSelector((state) => state.rawData);

  const options = casperIdData
    ? [
        {
          label: <span>Suggested new Casper Id</span>,
          title: 'Suggestion',
          options: [
            {
              label: (
                <span>
                  <EditTwoTone style={{ width: "20px" }} /> {casperIdData.new_casper_id}
                </span>
              ),
              value: casperIdData.new_casper_id
            }
          ]
        },
        {
          label: <span>Already available casper Id's for selected IMO number</span>,
          title: 'Old Records',
          options: casperIdData.casper_id_suggestions
            ? casperIdData.casper_id_suggestions.map((item) => {
                return {
                  label: (
                    <span>
                      <RollbackOutlined style={{ width: "20px", color: "rgb(22, 119, 255)" }} /> {item}
                    </span>
                  ),
                  value: item
                };
              })
            : []
        }
      ]
    : [];

  const handleChange = (value) => {
    setSelectedCasperId(value);
  };

  return (
    <div>
      <label className='Casper-label-style'>
        Enter Casper ID
      </label>
      {casperIdData ? (
        <Select
          style={{ width: '100%' }}
          onChange={handleChange}
          options={options}
          placeholder="Select Casper ID"
        />
      ) : (
        <p>Loading Casper ID options...</p>
      )}
    </div>
  );
};

export default EnterCasperId;
