import { useEffect, useState } from "react";
import expandIcon from "../../assets/icons/newsroom/carbonpricingchart/maximize.svg";
import ApexCharts from 'react-apexcharts';
// import { LineChart } from '@mui/x-charts';
// DatePicker import from AntDesign
// import { DatePicker, Space } from "antd";
import { DatePicker, Space, Modal, Button, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { spotPriceGraphData, spotPricePost } from "../../features/NewsRoom/NewsRoomSlice";
import { toggleModal, updateModalInfo } from "../../features/App/AppSlice";

const onChange = (date, dateString) => {
};

const CarbonPricingChart = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [xData, setXData] = useState([null])
  const [graphData, setGraphData] = useState([null])
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);

  const getspotPriceData = () => {
    dispatch(spotPriceGraphData()).then((result) => {


      // console.log(result.payload)

      if (result.payload && result.payload.length > 0) {
        // Extract data from the API response
        const xData = result.payload.map((item) => new Date(item.publish_date).getTime());
        const graphData = result.payload.map((item) => item.title);

        // console.log(xData)
        // console.log(graphData)

        setXData(xData);
        setGraphData(graphData);
      } else {
        console.error("No data available.");
        // Optionally, you can set default values for xData and graphData
        setXData([0]);
        setGraphData([null]);
      }


    })
  }

  useEffect(() => {
    getspotPriceData();
  }, [])



  const options = {
    series: [
      {
        name: 'End Of Day',
        data: graphData,
        type: 'line'
      },
    ],
    chart: {
      type: 'line'
    },
    legend: {
      position: 'bottom',
      offsetY: 10,
      horizontalAlign: 'left', // Adjust the alignment to 'left'
      itemMargin: {
        vertical: 5,
      },
      markers: {
        width: 12,
        height: 12,
      },
      itemStyle: {
        fontSize: '12px',
        fontFamily: 'Aeonik, sans-serif',
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: [2, 2, 2],
    },
    xaxis: {
      type: 'datetime',
      categories: xData
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy'
      }
    },
    colors: ['#06C270', '#FF8585', '#265CFF'],
  };

  const openModal = () => {
    form.resetFields();
    setModalVisible(true);
  };

  // Function to handle form submission
  const handleFormSubmit = (values) => {
    // console.log('Submitted values:', values);

    const date = new Date(values.date).toLocaleDateString('en-CA');
    const payload = {
      spot_price: parseFloat(values.spotPrice),
      date: date
    }

    // console.log(payload)
    dispatch(spotPricePost(payload)).then((result) => {
      if (result.payload) {
        getspotPriceData();
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'Spot Price Added Successfully!', type: 'success', message: 'Success!' }));
      } else {
        dispatch(toggleModal())
        dispatch(updateModalInfo({ show: true, result: 'Spot price Update Failed!', type: 'error', message: 'Failed' }));
      }
    })
    .catch((error) => {
      dispatch(updateModalInfo({ show: true, result: error.toString(), type: 'error', message: 'Failed' }))
    });



    setModalVisible(false);
  };

  return (
    <div className="newsRoomCard">
      <div className="carbonPricingCard-Elem1">
        <div className="carbonPricingCard-Elem1-Heading">
          <h1>Spot EUA Carbon Pricing</h1>
        </div>
        <div className="carbonPricingCard-Elem1-Detail">
          <div className="carbonPricingCard-Elem1-Children">
            {/* <p>1D</p> */}
          </div>
          {/* <div className="carbonPricingCard-Elem1-Children">
            <p>3M</p>
          </div>
          <div className="carbonPricingCard-Elem1-Children carbonPricingCard-Elem1-Children-active">
            <p>7M</p>
          </div>
          <div className="carbonPricingCard-Elem1-Children">
            <p>1Y</p>
          </div>
          <div className="carbonPricingCard-Elem1-Children">
            <p>5Y</p>
          </div>
          <div className="carbonPricingCard-Elem1-DatePicker">
            <Space direction="vertical">
              <DatePicker onChange={onChange} />
            </Space>
          </div>
          <div className="">
            <img src={expandIcon} alt="Expand" />
          </div> */}
          {user.role === "admin" ?
            <div className="" onClick={openModal}>

              <span className='genericButton' style={{ cursor: "pointer" }}>Spot Price</span>

            </div> : ''}
        </div>
      </div>

      <div className="carbonPricingCard-Chart">
        {/* <LineChart
          //   xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
          xAxis={[{ scaleType: 'time', data: xData }]}
          series={[
            {
              //   data: [2, 5.5, 2, 8.5, 1.5, 5],
              data: graphData,
            },
          ]}
          width={770}
          height={270}
        /> */}

        <ApexCharts options={options} series={options.series} type="line" height="100%" />
      </div>


      <Modal
        title="Spot Price Details"
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        // style={{ width: '300px' }}
        className="spotPriceModal"
      >
        <Form layout='vertical' className="spotPriceForm" form={form} onFinish={handleFormSubmit}>
          <div className="spotPriceInputs">
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: 'Please select a date' }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="spotPrice"
              label="Spot Price"
              rules={[{ required: true, message: 'Please input spot price' }]}
            >
              <Input placeholder="Spot Price" onKeyDown={(e) => {
                const allowedKeys = /^[0-9.]$/;
                if (!allowedKeys.test(e.key) && e.key !== 'Backspace') {
                  e.preventDefault();
                }
              }} />
            </Form.Item>
          </div>
          <Form.Item className="formButtonSpot">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>

    </div>
  );
};

export default CarbonPricingChart;
