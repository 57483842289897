import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Select, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { postFuelInfoApplyToAll } from '../../../features/RawData/RawDataSlice';

const { Option } = Select;

const ConflictingDataModal = ({ visible, setIsModalVisible, onClose }) => {
    const { conflictingData, conflictingPayload } = useSelector((state) => state.rawData);
    const dispatch = useDispatch();

    const [editingData, setEditingData] = useState([]);

    useEffect(() => {
        if (conflictingData) {
            setEditingData(conflictingData.conflicting_data);
        }
    }, [conflictingData, visible]);

    const handleApplyToAllRecord = () => {
        const updatedConflictingData = editingData.map(item => {
            const selectedOption = conflictingData.available_options_to_resolve_conflict.find(option => option.fuel_name === item.selected_option);
            return {
                current_fuel_id: item.fuel_id,
                new_fuel_id: selectedOption ? selectedOption.fuel_id : null
            };
        });

        // Check if any new_fuel_id is null
        const hasNullFuelId = updatedConflictingData.some(item => item.new_fuel_id === null);
        if (hasNullFuelId) {
            alert("Select fuels");
            return;
        }

        const payloadToSend = {
            ...conflictingPayload,
            conflicting_data: updatedConflictingData
        };

        // console.log(payloadToSend);

        dispatch(postFuelInfoApplyToAll(payloadToSend)).then((result) => {
            // console.log(result.payload.status);
            if (result.payload.status === 200) {
                message.success("Applied to all successfully!");
                handleClose();
                onClose();
            }
        });

        handleClose();
    };


    const handleSelectChange = (value, record) => {

        const isOptionRepeated = editingData.some(item => item.selected_option === value && item.fuel_id !== record.fuel_id);
        if (isOptionRepeated) {
            message.error({
                content: "Both options can't be the same",
                style: { zIndex: 10000 }
            });
            return;
        }

        const newData = [...editingData];
        const index = newData.findIndex((item) => record.fuel_id === item.fuel_id);
        const item = newData[index];
        newData[index] = { ...item, selected_option: value };
        setEditingData(newData);
    };

    const handleClose = () => {
        setEditingData([]);
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: 'Conflicting Data',
            dataIndex: 'fuel_name',
            key: 'fuel_name',
            render: (text, record) => (
                <span style={{ paddingLeft: "5px" }}>{record.fuel_name}</span>
            ),
        },
        {
            title: 'Available Options',
            key: 'available_options',
            render: (text, record) => (
                <Select
                    className='fuel-class-custom-select'
                    placeholder="Select data"
                    value={record.selected_option}
                    onChange={(value) => handleSelectChange(value, record)}
                >
                    {conflictingData.available_options_to_resolve_conflict.map(option => (
                        <Option key={option.fuel_id} value={option.fuel_name}>
                            {option.fuel_name}
                        </Option>
                    ))}
                </Select>
            )
        }
    ];

    return (
        <Modal
            title="Here is some conflicting data"
            visible={visible}
            onCancel={handleClose}
            footer={[
                <Button key="back" onClick={handleClose} icon={<ArrowLeftOutlined />} className='cancel-button-stepper'>
                    Close
                </Button>,
                <Button type="primary" onClick={handleApplyToAllRecord} className='stepper-next-button'>
                    Apply to all record
                </Button>,
            ]}
            style={{ top: 50 }}
        >
            <Table
                dataSource={editingData}
                columns={columns}
                rowKey="fuel_id"
                pagination={false}
                scroll={{ x: true }}
                className='fuel-data-stepper-table'
                bordered
            />
        </Modal>
    );
};

export default ConflictingDataModal;
