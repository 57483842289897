// import React from "react";
// import warningIcon from "../../assets/icons/voyages/euadifference/warning.svg";

// // import { PieChart } from '@mui/x-charts/PieChart';
// import Chart from "react-apexcharts";

// import { PieChart } from "react-minimal-pie-chart";

// // import { Line } from '@ant-design/charts';

// const options = {
//   chart: {
//     type: "bar",
//     height: 350,
//   },
//   plotOptions: {
//     bar: {
//       borderRadius: 4,
//       horizontal: true,
//     },
//   },
//   dataLabels: {
//     enabled: false,
//   },
//   xaxis: {
//     categories: ["At Sea", "Anchor", "In Port", "Drifting", "Manoeuvring"],
//   },
// };

// const series = [
//   {
//     name: "series-1",
//     data: [300, 100, 500, 200, 600],
//   },
// ];

// const dataMock = [
//   { title: 'One', value: 10, color: '#E38627' },
//   { title: 'Two', value: 15, color: '#C13C37' },
//   { title: 'Three', value: 20, color: '#6A2135' },
// ];

// export const EUADistribution = () => {
//   return (
//     <div className="euaDistribution">
//       <div className="euaDistribution-Element1">
//         <div className="euaDistribution-Chart">
//           <PieChart
//             data={dataMock}
//             lineWidth={25}
//             paddingAngle={18}
//             rounded
//             label={({ dataEntry }) => dataEntry.value}
//             labelStyle={(index) => ({
//               fill: dataMock[index].color,
//               fontSize: "8px"
//             })}
//             labelPosition={60}
//           />
//         </div>
//         {/* <div className="euaDistribution-Content">Content</div> */}
//       </div>
//       <div className="euaDistribution-Element2">
//         <Chart
//           options={options}
//           series={series}
//           type="bar"
//           width="400"
//           height="160"
//         />
//       </div>
//       <div className="euaDistribution-Element3">
//         <div className="euaDistribution-Element3-Warning">
//           <img src={warningIcon} alt="warning" />
//           <p>Shown Data is according to your selection</p>
//         </div>
//         <div className="euaDistribution-Element3-Data">
//           <p>Total EUA</p>
//           <h1>8350</h1>
//         </div>
//       </div>
//     </div>
//   );
// };



import React from 'react'
import BarGraph from '../chart/BarGraph'
import PieChart from '../chart/PieChart'
import warningIcon from "../../assets/icons/voyages/euadifference/warning.svg";


const EUADistribution = () => {
  return (
    <div style={{display:"flex", flexDirection:"column", height:"50vh"}}>
      {/* <div style={{height:"40%"}}>
      <PieChart/>
      </div> */}
      <div style={{height:"80%"}}>
      <BarGraph/>
      </div>
    </div>
  )
}

export default EUADistribution