import * as React from 'react';
import { useState, useEffect } from "react"
import { ArrowRightOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from "react-redux"
import FuelConsuptionsComponent from "./FuelConsuptionsComponent"
import GeneralDetailsComponent from "./GeneralDetailsComponent"
import OffHireDataComponent from "./OffHireDataComponent"
import { fetchOffHireData } from '../../features/Voyage/VoyageSlice';

import '../../css/offhiremodal.css';


const VoyageRow = ({ voyage, onSelect }) => {

    const [category, setCategory] = useState("General Details")
    const { selectedVoyage } = useSelector((state) => state.voyage);
    const dispatch = useDispatch()

    const renderComponent = () => {
        switch (category) {
            case 'General Details':
                return <GeneralDetailsComponent data={voyage.general_detail} />;
            case 'Fuel Consumptions':
                return <FuelConsuptionsComponent data={voyage.fuel_consumption} />;
            case 'Off Hire Data':
                return <OffHireDataComponent data={voyage.off_hire_data} voyageData={voyage} />;
            default:
                return null;
        }
    };




    const fetchOffhire = () => {
        const params = {
            voyage_number: selectedVoyage.voyage_number,
            imo_number: selectedVoyage.imo_number
        }
        dispatch(fetchOffHireData(params)).then((result) => {
            if (result.payload) {
            }
        })
    }


    useEffect(() => {
        setCategory("General Details")
    }, [selectedVoyage])






    return (
        <div className="voyageRowCard" onClick={onSelect}>
            <div className="voyageRow" id={selectedVoyage?.imo_number === voyage.imo_number ? "card-active" : ""}>
                <div className="voyageRowElem1">
                    <div className="voyageRowElem1-Children">
                        <div className="voyageRowElem1-Children-Element1">
                            <span className="vessel-data-label">IMO: {voyage.imo_number}</span>
                            <span className="vessel-data-data">{voyage.vessel_name}</span>
                        </div>
                        <div className="voyageRowElem1-Children-Element1">
                            <span className="vessel-data-label">Voyage no.</span>
                            <span className="vessel-data-data">{voyage.voyage_number}</span>
                        </div>
                    </div>
                    <div className="voyageRowElem1-Children">
                        <div className="voyageRowElem1-Children-Element1">
                            <span className="vessel-data-label">Departure</span>
                            <span className="vessel-data-data">{voyage.departure}</span>
                        </div>
                        <div className={voyage.voyage_status === "completed" ? '' : 'voyage-arrow-colored'}>
                            <ArrowRightOutlined />
                        </div>
                        <div className="voyageRowElem1-Children-Element1">
                            <span className="vessel-data-label">Arrival</span>
                            <span className="vessel-data-data">{voyage.arrival}</span>
                        </div>
                    </div>
                </div>

                <div className="voyageRowElem2">
                    <div className="voyageRowElem2-Heading">
                        <span
                            onClick={() => setCategory('General Details')}
                            className={category === 'General Details' ? 'active-tab' : ''}
                        >
                            General Details
                        </span>
                        <span
                            onClick={() => setCategory('Fuel Consumptions')}
                            className={category === 'Fuel Consumptions' ? 'active-tab' : ''}
                        >
                            Fuel Consumptions
                        </span>
                        <span
                            onClick={() => { fetchOffHireData(); setCategory('Off Hire Data') }}
                            className={category === 'Off Hire Data' ? 'active-tab' : ''}
                        >
                            Off Hire Data
                        </span>
                    </div>
                    <div className="voyageRowElem2-VoyageData">
                        {renderComponent()}
                    </div>
                </div>

            </div>


        </div>
    )
}

export default VoyageRow