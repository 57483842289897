import React from 'react'
import { Tag } from 'antd'
import Disclaimer from '../../components/Disclaimer'
const GeneralDetailsComponent = ({ data }) => {

    return (
        <div className="voyageData-Card">
            <div className="voyageData-Card-Elements">
                <div className="voyageData-Card-Children">
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Cargo Weight</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.cargo_weight.quantity} {data.cargo_weight.unit}</p>
                        </div>
                    </div>
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Condition</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.condition}</p>
                        </div>
                    </div>
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Distance</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.distance.quantity} {data.distance.unit}</p>
                        </div>
                    </div>
                </div>

                <div className="voyageData-Card-Children">
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>EEOI</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.eeoi}</p>
                        </div>
                    </div>
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>EUA</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.eua}</p>
                        </div>
                    </div>
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>EU Identifier</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.eu_identifier}</p>
                        </div>
                    </div>
                </div>

                <div className="voyageData-Card-Children">
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Cll*</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">

                            <Tag
                                color={
                                    data.technical_grade === 'A'
                                        ? 'rgb(113, 189, 126)'
                                        : data.technical_grade === 'B'
                                            ? 'rgb(214, 233, 201)'
                                            : data.technical_grade === 'C'
                                                ? 'rgb(249, 226, 143)'
                                                : data.technical_grade === 'D'
                                                    ? 'rgb(251, 212, 193)'
                                                    : data.technical_grade === 'E'
                                                        ? 'rgb(255, 133, 133)'
                                                        : 'rgba(12, 200, 118, 0.20)'
                                }
                            >
                                {data.technical_grade} {data.cii}
                            </Tag>

                            <Disclaimer />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default GeneralDetailsComponent