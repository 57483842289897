import React from 'react'
import Describe from '../../components/Description';
import { useSelector } from 'react-redux';
const VesselRow = (props) => {
    const {selectedVessel} = useSelector((state)=>state.vessel)
    return (
        <div className="card vessel-card" id={selectedVessel?.imo_number===props.number?"card-active":""}>
            <Describe {...props}/>
        </div>
    )
}

export default VesselRow


