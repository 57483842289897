import React, { useEffect, useState } from 'react'
import TableComponent from '../../components/Table'
import { duesCleaner, fetchCSVDocument, getSettlementDues } from '../../features/EUA/EuaSlice'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Skeleton } from 'antd'
import format from 'date-fns/format'
import Clipboard from "../../assets/icons/voyages/clipboard-import.svg"
import Papa from 'papaparse';
import ExpandableTable from '../../components/ExpandableTable'
import { Input, Space } from "antd";

const { RangePicker } = DatePicker
const { Search } = Input;
const RenderTable = () => {
  const { user } = useSelector((state) => state.user)
  const { status, dues } = useSelector((state) => state.eua)
  if (user === null) {
    return null
  }
  switch (user.role) {
    case "Ship Owner":
      if (dues === null) {
        return <Skeleton />
      }
      return <TableComponent data={dues} loading={status === "loading"} />
    default:
      if (dues === null) {
        return <Skeleton />
      }
      return <ExpandableTable data={dues} loading={status === "loading"} />
  }
}

const Sattlement = () => {

  const dispatch = useDispatch()
  const { status, csvDocument } = useSelector((state) => state.eua)
  useEffect(() => {
    if (status === 'idle') {
      dispatch(getSettlementDues("")).then((result) => {
      })
    }
  }, [dispatch, status])
  const [customDate, setCutomDate] = useState(null)
  const DateChangeHandler = (date) => {
    console.log(date)
    setCutomDate(date)
    if (date !== null) {
      const temp = {
        start_date: format(new Date(date[0]), "yyyy-MM-dd"),
        end_date: format(new Date(date[1]), "yyyy-MM-dd"),
        imo_number: ''
      }
      dispatch(fetchCSVDocument(temp))
    }
  }

  const downloadCsv = () => {
    const rearrangedData = [];
    let prevVesselName = '';

    csvDocument.forEach((row) => {
      const { vessel_name, ...rest } = row;

      if (!vessel_name || vessel_name === prevVesselName) {
        rearrangedData.push({ vessel_name: '', ...rest });
      } else {
        rearrangedData.push({ vessel_name, ...rest });
        prevVesselName = vessel_name;
      }
    });




    const result = rearrangedData.map((originalData, key) => {
      return {
        "Vessel Name": originalData.vessel_name,
        "Owner": originalData.active_owner,
        "IMO Number": originalData.imo_number,
        "Voyage Number": originalData.voyage_number,
        "CII": originalData.cii,
        "EU Identifier": originalData.eu_identifier,
        "EUA Due": originalData.eua_due,
        "EUA Released": originalData.eua_released,
        "Start Date": originalData.min_as_of_date_voyage_data,
        "End Date": originalData.max_as_of_date_voyage_data,
        "Start Port": originalData.start_port,
        "End Port": originalData.end_port,
        "Voyage Status": originalData.voyage_status,
      }
    })
    const csv = Papa.unparse(result);
    const blob = new Blob([csv], { type: 'text/csv' });

    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = 'data.csv';
    document.body.appendChild(link);

    link.click();
    document.body.removeChild(link);
  };

  let timeoutId;

  const onSearch = (event) => {
    dispatch(duesCleaner)
    clearTimeout(timeoutId);
  
    // Set a new timeout
    timeoutId = setTimeout(() => {
      dispatch(getSettlementDues(event.target.value));
    }, 500); // Adjust the delay (in milliseconds) according to your needs
  };



  return (
    <div className='card'>
      <div className="vessel-card-filter">
        <div style={{ width: "60%" }}>
          <span className='Sattlement-Title'>Settlement Dues</span>

        </div>
        {/* <DatePicker size='large' /> */}
        <Space direction="vertical">
          <Search
            className="notifications-top-searchBox"
            placeholder="Search Vessel, Voyage"
            bordered={true}
            onChange={onSearch}
            allowClear
            style={{
              width: 310,
              marginRight: 50,
            }}
          />
        </Space>

        <RangePicker format="YYYY-MM-DD" value={customDate} style={{ width: 250 }} onChange={DateChangeHandler} />
        {csvDocument !== null && customDate !== null ?
          <div onClick={downloadCsv} style={{ display: "flex", flexDirection: "column" }}>
            <img src={Clipboard} style={{ height: "20px", cursor: "pointer" }} alt="" />
            <span className='card-icon-span'>{"Download Report"}</span>
          </div> : ""
        }
      </div>
      {RenderTable()}
    </div>
  )
}

export default Sattlement
