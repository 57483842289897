import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Popconfirm, Table, Tag, Typography, Progress } from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TableComponent = (props) => {
  const [form] = Form.useForm();

  const [data, setData] = useState([])
  useEffect(() => {
    setData(
      props.data.map((value, index) => ({
        key: index.toString(),
        Vessel: value.vessel_name,
        Charterer: value.charterer_name,
        DOC: value.doc,
        Status: <Tag color="rgba(255, 186, 7, 0.20)" style={{ color: "black" }}>{value.status}</Tag>,
        "EUA Due": value.eua_due,
        "EUA Procured": value.eua_released,
        "EUA Outstanding": value.eua_outstanding,
        "Outstanding Cost": `€ ${value.outstanding_cost}`,
        "Settlement %": <Progress trailColor="#000508" percent={value.eua_settled_percent} />,
      }))
    );
  }, [props.data]);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;



  const columns = [
    {
      title: "Vessel",
      dataIndex: "Vessel",
    },
    // {
    //   title: "Charterer",
    //   dataIndex: "Charterer",
    // },
    {
      title: "DOC",
      dataIndex: "DOC",
    },
    // {
    //   title: "Status",
    //   dataIndex: "Status",
    // },
    {
      title: "EUA Due",
      dataIndex: "EUA Due",
    },
    {
      title: "EUA Procured",
      dataIndex: "EUA Procured",
      editable: false,
    },
    {
      title: "EUA Outstanding",
      dataIndex: "EUA Outstanding",
    },
    {
      title: "Outstanding Cost",
      dataIndex: "Outstanding Cost",
    },
    {
      title: "Settlement %",
      dataIndex: "Settlement %",
    },

  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === "age" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <div>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          scroll={{ y: "40vh" }}
          pagination={false}
          responsive
        />
      </div>
    </Form>
  );
};

export default TableComponent;
