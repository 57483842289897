import React, { useState } from 'react'
import "../../css/data-report-tab.css"
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import { ReactComponent as Arrow } from "../../assets/icons/rawdata/arrow.svg"
import { togglePastRecords, getIceClassInfo, fetchCasperRecords } from '../../features/RawData/RawDataSlice';

import VoyageData from './VoyageData';
import { deleteCasperID } from '../../features/VesselsAnalysis/VesselsAnalysisSlice';

const { confirm } = Modal;
const DataRecordTab = ({ setModal, record, setSelectedCasperIdAuditTrace }) => {
    const [selectedOption, setSelectedOption] = useState("Fuel Consumption")
    const { user } = useSelector((state) => state.user);
    const {globalFilters } = useSelector((state) => state.rawData)
  
    const [classtoSet, setClass] = useState("data-record-option-false")
    const [selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace] = useState('')

    const disabled = user["is_admin:"] ? false : record?.is_locked_in;
    const dispatch = useDispatch()
// console.log(disabled)
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const onChecked = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };


    const dataOptions = [
        { name: "Fuel Consumption", drop_down: false, state: true },
        { name: "Casper Output", drop_down: false, state: false },
    ]

    const handleClick = () => {
        dispatch(getIceClassInfo(record?.casper_id))
        setModal(true)
    }

    const handleSelectedOption = (value) => {
        setSelectedOption(value);
    }

    const handlePastRecords = (uniqueId, casperId) => {
        setSelectedCasperIdAuditTrace(casperId)
        setSelectedUniqueIdAuditTrace(uniqueId)
        dispatch(togglePastRecords())
    }

        // Modal function for  confirmation
const showDeleteConfirm = (casperId, imoNumberId) => {
    confirm({
      title: 'Are you sure you want to remove this casper ID?',
      icon: <ExclamationCircleFilled />,
      content: `Deleting Casper ID: ${casperId}`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        const params = {
            imo_number: imoNumberId,
            casper_id: casperId
          }
          dispatch(deleteCasperID(params))
          .then(() => {
            dispatch(fetchCasperRecords(globalFilters)); 
          })
          .catch((error) => {
            console.error('Error patching Casper ID:', error);
          });

        console.log("deleted")
      },
      onCancel() {
        console.log('Cancelled');
      },
    });
  };

    return (
        <div className='data-record-tab-container'>
            <div className='data-record-tab-left'>
                <div className='data-record-tab-left-ids-container'>
                    <div className='data-record-tab-left-ids'>
                        <div className='data-record-tab-id'>
                            <div className='data-record-tab-id-type'>
                                Casper ID
                            </div>
                            <div style={{ fontSize: "13px" }} className='data-record-tab-id-number'>
                                <span>{record?.casper_id}</span> 
                                {!disabled &&
                                <DeleteOutlined
                                    style={{ marginLeft: '10px', cursor: 'pointer' }} 
                                    onClick={() => showDeleteConfirm(record?.casper_id, record?.imo_number)}
                                />
                                }
                            </div>
                        </div>
                        <div className='data-record-tab-id'>
                            <div className='data-record-tab-id-type'>
                                IMO No.
                            </div>
                            <div style={{ fontSize: "13px" }} className='data-record-tab-id-number'>
                                {record?.imo_number}
                            </div>
                        </div>
                    </div>
                    <div className='data-record-tab-btns'>
                        <div className='data-record-tab-btn' onClick={() => handlePastRecords(record?.unique_id, record?.casper_id)}>
                            Audit Traces
                        </div>
                        <div className='data-record-tab-btn' style={{ backgroundColor: "#629af8" }} onClick={handleClick}>
                            Ice Sailing Exemptions
                        </div>
                    </div>
                </div>
                <hr />
                <div className='data-record-tab-details'>
                    <div className='data-record-tab-details-title'>
                        General Details
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", gap: "2vh", paddingTop: "2vh" }}>
                        <div className='data-record-general-details'>
                            <div className='data-record-general-details-single'>
                                <p style={{ color: "#A4A4A4", fontWeight: "400", fontSize: "11px" }}>Start Date</p>
                                <p style={{ fontSize: "12px", lineHeight: "13.4px", color: "black" }}>{formatDate(record?.start_date)}</p>
                            </div>
                            <Arrow />
                            <div className='data-record-general-details-single'>
                                <p style={{ color: "#A4A4A4", fontWeight: "400", fontSize: "11px" }}>End Date</p>
                                <p style={{ fontSize: "12px", lineHeight: "13.4px", color: "black" }}>{formatDate(record?.end_date)}</p>
                            </div>
                        </div>

                        <div className='data-record-general-details'>
                            <div className='data-record-general-details-single'>
                                <p style={{ color: "#A4A4A4", fontWeight: "400", fontSize: "11px" }}>Start Port Name</p>
                                <p style={{ fontSize: "12px", color: "black" }}>{record?.from_port_name}</p>
                            </div>
                            <Arrow />
                            <div className='data-record-general-details-single'>
                                <p style={{ color: "#A4A4A4", fontWeight: "400", fontSize: "11px" }}>End Port Name</p>
                                <p style={{ fontSize: "12px", color: "black" }}>{record?.to_port_name}</p>
                            </div>
                        </div>

                        <div className='data-record-general-details'>
                            <div className='data-record-general-details-single'>
                                <p style={{ color: "#A4A4A4", fontWeight: "400", fontSize: "11px" }}>Start Port Code</p>
                                <p style={{ fontSize: "12px", color: "black" }}>{record?.from_port_code}</p>
                            </div>
                            <Arrow />
                            <div className='data-record-general-details-single'>
                                <p style={{ color: "#A4A4A4", fontWeight: "400", fontSize: "11px" }}>End Port Code</p>
                                <p style={{ fontSize: "12px", color: "black" }}>{record?.to_port_code}</p>
                            </div>
                        </div>

                        <div className='data-record-general-details'>
                            <div style={{ width: "5vw" }} className='data-record-general-details-single'>
                                <p style={{ width: "10vw", fontSize: "11px", fontWeight: "400", color: "#A4A4A4" }}>Total Distance Covered</p>
                                <p style={{ fontSize: "12px", color: "black" }}>{`${record?.total_distance || record?.total_distance == 0 ? record?.total_distance?.toFixed(3) : '-'} nm`}</p>
                            </div>
                            <div className='data-record-general-details-single'>
                                <p style={{ fontSize: "11px", fontWeight: "400", color: "#A4A4A4" }} >Total Cargo Discharged</p>
                                <p style={{ fontSize: "12px", color: "black" }}>{`${record?.total_cargo || record?.total_cargo == 0 ? record?.total_cargo?.toFixed(3) : '-'} T`}</p>
                 
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='data-record-tab-right'>
                <div className='data-record-options'>{
                    dataOptions.map((value, key) => {
                        return (
                            <div style={{ marginLeft: "-2vh" }} key={key} className={`data-record-option-${selectedOption === value.name}`}
                                onClick={() => handleSelectedOption(value.name)}
                            >
                                <span style={{ cursor: "pointer", fontSize: "13px" }} >{value.name}</span>

                            </div>
                        )
                    })
                }</div>
                <div>
                    <VoyageData selectedOption={selectedOption} record={record} />
                </div>
            </div>
        </div>
    )
}

export default DataRecordTab