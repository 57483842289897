import { useState, useEffect, useRef } from "react";
import toggleOffIcon from "../../assets/icons/notifications/toggle-off-circle.svg";
import unReadNotificationIcon from "../../assets/icons/notifications/circular-green.svg";
import unReadNotification from "../../assets/icons/notifications/unreadICon.svg";
import { highlightSearchTerm } from "../../utils/utils";
import readNotificationIcon from "../../assets/icons/notifications/notification-read.svg";
import userIcon from "../../assets/icons/notifications/user.svg";
import deleteNotificationIcon from "../../assets/icons/notifications/delete.svg";
import { useLocation } from 'react-router-dom';
import { Button, Divider } from "antd";
import { Fragment } from "react";
import { getEUPortsEnteringVessels } from '../../features/EUA/EuaSlice';
import EUPortEnterTable from '../../components/EUPortEnterTable';

// Ant Design - for Search Bar
import { AudioOutlined } from "@ant-design/icons";
import React from "react";
import { Input, Space, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotification, fetchNotificationCount } from "../../features/Notification/NotificationSlice";
import { resetNotificationState } from "../../features/Notification/NotificationSlice";
import SkeltonTable from "../../components/SkeltonTable";
import { updateNotifications } from "../../features/Notification/NotificationSlice";
import { Popconfirm, message } from 'antd';

const { Search } = Input;
const suffix = (
  <AudioOutlined
    style={{
      fontSize: 16,
      color: "#1677ff",
    }}
  />
);


const Notifications = () => {
  const [activeTab, setActiveTab] = useState('all');
  const location = useLocation();
  const [tabs, setTabs] = useState([
    { key: "all_count", prop: "all_count", name: 'all', count: 0 },
    { key: "unread_count", prop: "unread_count", name: 'unread', count: 0 },
    { key: "read_count", prop: "read_count", name: 'read', count: 0 },
    { key: "order", prop: "order", name: 'order', count: 0 },
    { key: "dues", prop: "dues", name: 'dues', count: 0 },
    { key: "voyage", prop: "voyage", name: 'voyages', count: 0 },
    { key: "others", prop: "other", name: 'other', count: 0 },
  ]);
  const dispatch = useDispatch()
  const { count, notification, page, status } = useSelector((state) => state.notification)

  const [updatedPage, setUpdatedPage] = useState(page)
  const [searchKey, setSearchKey] = useState('')
  const [isAllread, setAllRead] = useState(false)
  const scrollableArea = useRef(null)
  const [open, setOpen] = useState(false);
  const [isEUModalOpen, setIsEUModalOpen] = useState(false);
  const handleOpen = () => setOpen(true);


  const showEUModal = () => {
    setIsEUModalOpen(true);
  };
  const handleEUOk = () => {
    setIsEUModalOpen(false);
  };
  const handleEUCancel = () => {
    setIsEUModalOpen(false);
  };

  const getNotificationCount = () => {
    dispatch(fetchNotificationCount()).then((result) => {
      // dispatch(fetchNotification({ category: activeTab, page: updatedPage }))
    })
  }
  const getNotificationData = (keyword) => {
    if (status !== 'loading') {
      if (activeTab === "voyages") {
        dispatch(fetchNotification({ category: "voyage", page: page, keyword: keyword }))
      }
      else {
        dispatch(fetchNotification({ category: activeTab, page: page, keyword: keyword }))
      }

    }
  }

  const onSearch = (value, _e, info) => {
    setSearchKey(value)
    dispatch(resetNotificationState());
    dispatch(fetchNotification({ category: activeTab, page: 1, keyword: value }));
  }
  useEffect(() => {
    getNotificationData(searchKey)
    getNotificationCount()
  }, [activeTab])

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      scrollableArea.current;
    if (scrollTop + clientHeight >= scrollHeight - 10) {
      setUpdatedPage(updatedPage + 1)
      getNotificationData(searchKey)
    }
  }

  useEffect(() => {
    // Clear search when user navigates to another page
    // setValue(null)
    // dispatch(clearVessels())
    setUpdatedPage(1)
  }, [location.pathname]);

  useEffect(() => {
    if (count !== null) {
      const tempData = tabs.map((value, key) => {
        if (count[value.prop])
          return {
            ...value,
            count: count[value?.prop]
          }
        else
          return { ...value }
      })

      console.log(count)
      setTabs(tempData)
    }

  }, [count, activeTab])

  useEffect(() => {
    if (notification && Array.isArray(notification) && notification.length > 0) {
      const allRead = notification.every(notif => notif.date_read !== null);
      console.log(notification)

      setAllRead(allRead);
    }
  }, [notification]);


  const RenderEUEnterPorts = () => {
    return <EUPortEnterTable downloadButtonVisible={true} closeModal={handleEUOk} />
  }

  const handleTabClick = async (tabName) => {

    dispatch(resetNotificationState());
    tabName = tabName === "Voyages" ? "Voyage" : tabName;
    if (tabName === "Voyages") {
      console.log("done")
    }
    setActiveTab(tabName);
  };

  useEffect(() => {
    // Fetch initial data for 'All' tab when component mounts
    handleTabClick('all');
  }, []);


  function formatDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const diff = Math.floor((now - date) / 1000); // Difference in seconds

    const seconds = diff % 60;
    const minutes = Math.floor(diff / 60) % 60;
    const hours = Math.floor(diff / 3600) % 24;
    const days = Math.floor(diff / (3600 * 24));

    if (diff < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (diff < 3600) {
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (diff < 86400) {
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (diff < 2592000) {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return `${date.toLocaleDateString('en-US', options)} | ${days} day${days !== 1 ? 's' : ''} ago`;
    } else {
      const options = { day: 'numeric', month: 'short', year: 'numeric' };
      return `${date.toLocaleDateString('en-US', options)} | More than a month ago`;
    }
  }


  const updateNotificationStatus = (updateStatus, notifiId) => {
    console.log(notifiId)
    console.log(updateStatus)

    dispatch(updateNotifications({ update_type: updateStatus, id: notifiId }));
    dispatch(resetNotificationState());
    getNotificationCount()
    dispatch(fetchNotification({ category: activeTab, page: 1, keyword: searchKey }));
  }




  const RenderNotificatiion = () => {
    if (activeTab === "voyages") {
      return <EUPortEnterTable downloadButtonVisible={true} />;
    }
    // const voyagesTabContent = activeTab === "voyages" ? (
    //   <Button key="voyages-button" onClick={() => { showEUModal() }}>View</Button>
    // ) : null;

    if (notification === null) {
      return (
        <>
          {/* {voyagesTabContent} */}
          <SkeltonTable />
        </>
      );
    }

    return (
      <>
        {/* <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "3vh", marginRight: "1.4vw" }}>
          {voyagesTabContent}
        </div> */}
        {Array.isArray(notification) && notification.map((notification, index) => (
          <div key={index}>
            <Divider />
            <div className="notifications-bottom">

              <div className="notifications-bottom-dateTime">
                <div>
                  <img src={unReadNotificationIcon} alt="notification" />&nbsp;&nbsp;
                </div>
                <p>{formatDate(notification.date_created)}</p>
              </div>
              <div className="notifications-bottom-mainContent">
                <div className="notifications-bottom-mainContent-userIcon">
                  {/* <img src={userIcon} alt="user" /> */}
                  <span className="category_notification_Data">{notification.category.charAt(0).toUpperCase()}</span>
                </div>
                <div className="notifications-bottom-mainContent-content">
                  <h1>
                    {notification.title}
                  </h1>
                  <p dangerouslySetInnerHTML={{ __html: highlightSearchTerm(notification.content, searchKey) }}>
                  </p>
                </div>
              </div>
              <div className="notifications-bottom-Icons">
                {notification.date_read ?
                  <div>
                    <img style={{ cursor: 'not-allowed' }} src={toggleOffIcon} alt="Toggle" />
                  </div>
                  :
                  <div onClick={() => updateNotificationStatus('read', notification.id)}>
                    <img className="notifications-action-Icon" src={unReadNotification} alt="Toggle" />

                  </div>
                }
                <div >

                  <Popconfirm
                    title="Delete offhire Data"
                    description="Are you sure to delete this data?"
                    onConfirm={() => updateNotificationStatus('delete', notification.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <img onClick={handleOpen} src={deleteNotificationIcon} alt="Toggle" />
                  </Popconfirm>
                </div>
              </div>
            </div>
            <Divider />
          </div>
        ))}
      </>
    );
  };
  return (
    // <div className="notifications">
    <div className="main-board">
      <div className="card">
        <div className="notifications-top">
          <div className="notifications-top-Para">
            <h1>Notifications</h1>
            <p>
              {/* <span>04</span> unread messages */}
            </p>
          </div>
          <div className="notifications-top-search-actions-wrapper">
            <div className="notifications-top-searchBox">
              <Space direction="vertical">
                <Search
                  className="notifications-top-searchBox"
                  placeholder="Search Vessel, Voyage"
                  bordered={true}
                  onSearch={onSearch}
                  style={{
                    width: 200,
                  }}
                />
              </Space>
            </div>
            <div className="notifications-top-action">
              {isAllread ?
                <div className="notifications-top-action-toggleIcon">
                  <img style={{ cursor: 'not-allowed' }} src={toggleOffIcon} alt="Toggle" />
                </div>
                :
                <div onClick={() => updateNotificationStatus('mark_all_as_read', '')} className="notifications-top-action-toggleIcon">
                  <img className="notifications-action-Icon" src={unReadNotification} alt="Toggle" />
                </div>}

              <div className="notifications-top-action-Para">
                <p>Mark all read</p>
              </div>
            </div>
          </div>

        </div>

        <div className="notifications-middle">
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{ textTransform: "capitalize" }}
              className={`notifications-middle-elements ${activeTab === tab.name ? 'active' : ''}`}
              onClick={() => handleTabClick(tab.name)}
            >
              {tab.name === "other" ? `${tab.key} (${tab.count})` : `${tab.name} (${tab.count})`}
            </div>
          ))}
        </div>

        <Modal
          title="EU Ports Entering Vessels"
          width={"60%"}
          footer={[]}
          open={isEUModalOpen}
          onCancel={handleEUCancel}
        >
          {RenderEUEnterPorts()}
        </Modal>

        <div onScroll={handleScroll} ref={scrollableArea} className="notifications-bottom-main">
          {RenderNotificatiion()}
          {/* <RenderNotificatiion/> */}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
