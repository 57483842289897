import React, { useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../assets/icons/rawdata/arrow.svg";
import { ReactComponent as Edit } from "../../assets/icons/rawdata/akar-icons_edit.svg";
import { Button, Col, Spin, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { runCalculations, getCalculationsResults } from "../../features/RawData/RawDataSlice";
import { ca } from "date-fns/locale";


const VoyageData = ({ selectedOption, record }) => {
  const dispatch = useDispatch();
  const { calculationsStatus, calculationsResult } = useSelector((state) => state.rawData);

  const [isResultSynced, setIsResultSynced] = useState(record?.casper_output.is_result_synced);

  // maintaining flag so that the calculations's api data could be displayed on successful response
  let rerunApiFlag = false;

  const [loading, setLoading] = useState(false);
  const calculationsPayload = {
    casper_id: record?.casper_id,
  };

  // Rerun calculations api call when the rerun button is clicked
  const handleRerunClick = async () => {
    setLoading(true);
    await dispatch(runCalculations(calculationsPayload));
    dispatch(getCalculationsResults(calculationsResult?.casper_id));
    if (calculationsStatus !== 'failed') {
      rerunApiFlag = true;
      setIsResultSynced(true);
    } else {
      console.log("Api call failed")
    }
  };

  
  useEffect(() => {
    if (calculationsResult?.is_result_synced && calculationsStatus === "succeeded") {
      setLoading(false);
    }
  }, [calculationsResult, calculationsStatus]);

  return (
    <div className="voyage-data-container">
      {selectedOption === "Fuel Consumption" ? (
        <div className="voyage-data-multi" style={{ width: "25vw" }}>
          <div style={{ marginLeft: "-20px" }}>Standard Fuels (MT)</div>
          <Row
            style={{ marginLeft: "-19px" }}
            gutter={{
              xs: 8,
              sm: 16,
              md: 24,
              lg: 32,
            }}
          >
            {record?.fuel_details.map((fuel, index) => (
              <Col
                key={index}
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <div
                  className="voyage-data-header"
                  style={{
                    background: "white",
                    marginLeft: "-1rem",
                    fontSize: "10.5px",
                    fontWeight: "400",
                    lineHeight: "11.4px",
                  }}
                >
                  {fuel.name}
                </div>
                <div
                  className="voyage-data-content"
                  style={{ background: "white" }}
                >
                  {`${fuel?.total_fuel == 0 ? 0 : fuel?.total_fuel?.toFixed(3)}`}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div
          className="voyage-data-multi"
          style={{ width: "100%", position: "relative" }}
        >
           {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Spin tip="Loading..." />
        </div>
      ) : isResultSynced === false ? (
            <>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "1rem", marginTop: "3rem", }}>
                <p>
                  Your casper results are not synced. Please rerun calculations.
                </p>
                <div onClick={handleRerunClick} className="reset-btn">
                  Rerun Calculation
                </div>
              </div>
            </>
          ) : (
            <Row
              style={{
                marginLeft: "-13px",
                lineHeight: "15.4px",
                marginBottom: "12px",
              }}
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    CO₂ eq
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output?.co2eq_emissions || record?.casper_output?.co2eq_emissions == 0 ? record?.casper_output.co2eq_emissions.toFixed(3) : '-'} MT
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    EEOI
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output?.eeoi ? record?.casper_output?.eeoi?.toFixed(3) : '-'} gCO₂/Tonne-nm
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    CO₂ eq ETS
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.co2eq_emissions_ets || record?.casper_output.co2eq_emissions_ets == 0 ? record?.casper_output.co2eq_emissions_ets.toFixed(3) : '-'} MT
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    Identifier
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.identifier ? record?.casper_output.identifier : '-'}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    AER Ratio
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.cii || record?.casper_output.cii == 0 ? record?.casper_output.cii.toFixed(3) : '-'}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    CII
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{
                      background: record?.casper_output.technical_grade === 'A'
                        ? 'rgb(113, 189, 126)'
                        : record?.casper_output.technical_grade === 'B'
                          ? 'rgb(214, 233, 201)'
                          : record?.casper_output.technical_grade === 'C'
                            ? 'rgb(249, 226, 143)'
                            : record?.casper_output.technical_grade === 'D'
                              ? 'rgb(251, 212, 193)'
                              : record?.casper_output.technical_grade === 'E'
                                ? 'rgb(255, 133, 133)'
                                : 'rgba(12, 200, 118, 0.20)',
                      marginLeft: "-1rem",
                      padding: "4px 9px",
                      width: "24px",
                    }}
                  >
                    {record?.casper_output.technical_grade}
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    GHG Intensity
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.total_ghg || record?.casper_output.total_ghg == 0 ? record?.casper_output.total_ghg.toFixed(3) : '-'} gCO₂/MJ
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    Compliance Balance
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.compliance_balance || record?.casper_output.compliance_balance == 0 ? record?.casper_output.compliance_balance.toFixed(3) : '-'} gCO₂eq
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    Penalty
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.penalty || record?.casper_output.penalty == 0 ? record?.casper_output.penalty.toFixed(3) : '-'} Euros
                  </div>
                </>
              </Col>
              <Col
                className="gutter-row"
                style={{ marginTop: "12px" }}
                span={6}
              >
                <>
                  <div
                    className="voyage-data-header"
                    style={{
                      background: "white",
                      marginLeft: "-1rem",
                      fontSize: "14px",
                      fontWeight: "400",
                      lineHeight: "16px",
                    }}
                  >
                    EUA
                  </div>
                  <div
                    className="voyage-data-content"
                    style={{ background: "white", marginLeft: "-1rem" }}
                  >
                    {record?.casper_output.eua || record?.casper_output.eua == 0 ? record?.casper_output?.eua?.toFixed(3) : '-'}
                  </div>
                </>
              </Col>
            </Row>
          )}
        </div>
      )}

      {selectedOption === "Fuel Consumption" ? (
        <>
          <hr></hr>
          <div className="voyage-data-multi" style={{ width: "18vw" }}>
            Alternate Fuels (MT)
            <Row
              style={{ marginLeft: "1px" }}
              gutter={{
                xs: 8,
                sm: 16,
                md: 24,
                lg: 32,
              }}
            >
              {record?.fuel_details.map(
                (fuel, index) =>
                  fuel.fuel_type === "ALTERNATE" && (
                    <Col
                      key={index}
                      className="gutter-row"
                      style={{ marginTop: "12px" }}
                      span={12}
                    >
                      <div
                        className="voyage-data-header"
                        style={{
                          background: "white",
                          marginLeft: "-1rem",
                          fontSize: "10px",
                          fontWeight: "400",
                          lineHeight: "11.4px",
                        }}
                      >
                        {fuel.name}
                      </div>
                      <div
                        className="voyage-data-content"
                        style={{ background: "white" }}
                      >
                        {`${fuel.total_fuel}`}
                      </div>
                    </Col>
                  )
              )}
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default VoyageData;
