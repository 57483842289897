import React, { useState } from "react";
import { login_background_img_url } from "../../data";
import { ReactComponent as Title } from "../../assets/images/Title.svg";
import LoginContainer from "../../container/logincontainer/LoginContainer";

const LoginPage = ({ setLogin }) => {



  return (
    <main>
      <div className="login">
        <div className="login-image">
          <Title style={{ position: "fixed", top: "10px", left: "20px" }} />
          <img src={login_background_img_url} alt="login-background" className="carousel" />
        </div>
        <div className="login-Content">
          <h1>CASPER</h1>
          <LoginContainer setLogin={setLogin} />
        </div>
      </div>
    </main>
  );
};

export default LoginPage;
