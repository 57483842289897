import React from 'react'
import { Descriptions } from 'antd'
import pathIcon from "../assets/icons/vessels/path.svg";
import { Tag } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons'
import Disclaimer from './Disclaimer';
const Describe = (props) => {

    const item1 = [
        {
            key: '1',
            label: `IMO: ${props.number}`,
            span: 2,
            children: props.name
        },
        {
            key: '2',
            label: "Charterer details",
            span: 2,
            children: props.voyage.charter_name
        },
        {
            key: '3',
            label: ["Voyage", " EEOI"],
            icon: <Tag color={"#0CC876"}>{props.voyage.voyage_status}</Tag>,
            children: [props.voyage.voyage_number, props.voyage.eeoi]
        },
        {
            key: '4',
            label: ["Departure", "Arrival"],
            icon: <ArrowRightOutlined />,
            children: [props.voyage.from_port, props.voyage.to_port]
        }
    ]
    const items = [
        {
            key: '1',
            label: 'CO₂',
            children: `${props.voyage.co2} t`,
        },
        {
            key: '2',
            label: 'CO₂ ETS Scope',
            children: `${props.voyage.co2_ets} t`,
        },
        {
            key: '3',
            label: 'EUA',
            children: props.voyage.eua,
        },
        {
            key: '4',
            label: 'CII*',
            children:
                <> <Tag
                    color={
                        props.voyage.technical_grade === 'A'
                            ? 'rgb(113, 189, 126)'
                            : props.voyage.technical_grade === 'B'
                                ? 'rgb(214, 233, 201)'
                                : props.voyage.technical_grade === 'C'
                                    ? 'rgb(249, 226, 143)'
                                    : props.voyage.technical_grade === 'D'
                                        ? 'rgb(251, 212, 193)'
                                        : props.voyage.technical_grade === 'E'
                                            ? 'rgb(255, 133, 133)'
                                            : 'rgba(12, 200, 118, 0.20)'
                    }
                >{props.voyage.technical_grade} {props.voyage.cii}</Tag><Disclaimer /></>,
        },
        {
            key: '5',
            label: 'EU Identifier',
            children: props.voyage.eu_identifer,
        },
        {
            key: '6',
            label: 'Cargo Carried',
            children: `${props.voyage.total_cargo_mt} mt`,
        },
        {
            key: '7',
            label: 'Avg. Speed',
            children: `${(props.voyage.avg_speed / 10).toFixed(2)} knots`,
        },
        {
            key: '8',
            label: 'Time',
            children: props.voyage.time,
        },
        {
            key: '9',
            label: 'Distance',
            children: `${props.voyage.distance_travelled} nm`,
        },
    ];
    return (
        <div className='vessel-description-card' onClick={props.onSelect}>
            <div className="vessel-describeHead">
                {item1.map((item, index) => {
                    if (index < 2) {
                        return (
                            <div className='vessel-data'>
                                <span className='vessel-data-label'>{item.label}</span>
                                <span className='vessel-data-data'>{item.children}</span>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className='vessel-path-data'>
                                <div className="vessel-data">
                                    <span className='vessel-data-label'>{item.label[0]}</span>
                                    <span className='vessel-data-data'>{item.children[0]}</span>
                                </div>
                                <div className="vessel-pathIcon">
                                    {item.icon}
                                </div>
                                <div className="vessel-data">
                                    <span className='vessel-data-label'>{item.label[1]}</span>
                                    <span className='vessel-data-data'>{item.children[1]}</span>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
            <div className='vessel-description'>
                <Descriptions layout="vertical" size={'samll'} labelStyle={{ fontSize: "12px", fontWeight: 500, paddingBottom: "0px" }} contentStyle={{ fontSize: "14px", fontWeight: 500 }} items={items} />
            </div>
        </div>
    )
}

export default Describe