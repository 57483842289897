import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider } from 'antd';
import { deselectAllRecordsInSequentialFlow, fetchShipOwners, fetchVessels } from '../../features/RawData/RawDataSlice';
import RawDataFilters from '../../components/RawDataFilters';
import DataRecordsContainer from '../../container/rawData/rawDataComponents/DataRecordsContainer';
// import DeletingFuel from '../../components/Modals/DeletingFuel';

import "../../css/rawdata.css"

import '../../css/rawDataPage.css'; // Adjust as needed for your actual CSS path
import StepperCard from '../../container/rawData/sequentialFlow/StepperCard';
import SequentialRecordsContainer from '../../container/rawData/sequentialFlow/SequentialFlowContainer';

import PastRecords from '../../components/PastRecords/PastRecords'
const RawDataPage = () => {
  const dispatch = useDispatch();
  const { shipOwners, shipOwnersStatus, pastRecords, globalFilters } = useSelector(state => state.rawData);
  const [showStepperCard, setShowStepperCard] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCasperId, setSelectedCasperId] = useState('')
  const [selectedCasperIdInFilters, setSelectedCasperIdInFilters] = useState('')
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedCardsWithEssentialData, setSelectedCardsWithEssentialData] = useState({});
  const [selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace] = useState('');
  const [iMONumber, setIMONumber] = useState(globalFilters?.imoNumber || ""); // Initialize with default value
  const [voyageYear, setVoyageYear] = useState('2024');

  const handleAssignCasperID = () => {
    setShowStepperCard(true);
  };

  const handleBack = () => {
    setCurrentStep(0)
    setShowStepperCard(false);
  };

  const handleStepChange = (step) => {
    setCurrentStep(step);
  };


  const renderContent = () => {
    if (showStepperCard) {
      return (
        <>
          <div className='card' style={{ width: '100%', height: '25vh' }}>
            <StepperCard onBack={handleBack} currentStep={currentStep} onStepChange={handleStepChange} />
          </div>
          <SequentialRecordsContainer currentStep={currentStep} onStepChange={handleStepChange} setShowStepperCard={setShowStepperCard} selectedCasperId={selectedCasperId} setSelectedCasperId={setSelectedCasperId} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />
        </>
      );
    }

    if (pastRecords) {
      return <PastRecords selectedUniqueIdAuditTrace={selectedUniqueIdAuditTrace} setSelectedUniqueIdAuditTrace={setSelectedUniqueIdAuditTrace} selectedCasperIdAuditTrace='' />;
    }

    return (
      <>
        <RawDataFilters type={"Raw Data"} setSelectedCasperIdInFilters={setSelectedCasperIdInFilters} iMONumber={iMONumber} setIMONumber={setIMONumber} voyageYear={voyageYear} setVoyageYear={setVoyageYear} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />
        <div className="MainTable" style={{ width: '98%' }}>
          <DataRecordsContainer onAssignCasperID={handleAssignCasperID} selectedCasperIdInFilters={selectedCasperIdInFilters} selectedUniqueIdAuditTrace={selectedUniqueIdAuditTrace} setSelectedUniqueIdAuditTrace={setSelectedUniqueIdAuditTrace} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />

        </div>

      </>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Progress: {
            lineBorderRadius: 8,
          },
          Descriptions: {
            itemPaddingBottom: '10px',
          },
          Table: {
            headerSplitColor: '#fffff',
            headerBg: '#fffff',
          },
        },
      }}
    >
      <div className="raw-data-page">
        {renderContent()}
      </div>
    </ConfigProvider>
  );
};

export default RawDataPage;
