import { configureStore } from "@reduxjs/toolkit";
import UserReducer from "../features/Login/UserSlice";
import EuaReducer from "../features/EUA/EuaSlice"
import VesselReducer from "../features/Vessels/VesselSlice";
import EFDBReducer from "../features/EFDB/EFDBSlice"
import VoyageReducer from "../features/Voyage/VoyageSlice";
import NewsRoomReducer from "../features/NewsRoom/NewsRoomSlice"
import NotificationReducer from "../features/Notification/NotificationSlice";
import TransactionSlice from "../features/Transaction/TransactionSlice";
import VoyageOptimizationSlice from "../features/VoyageOptimization/VoyageOptimizationSlice";
import AppSlice from "../features/App/AppSlice";
import RawDataSlice from "../features/RawData/RawDataSlice";
import VesselsAnalysisSlice from "../features/VesselsAnalysis/VesselsAnalysisSlice";
import CasperAnalysisSlice from "../features/CasperAnalysis/CasperAnalysisSlice";

const store = configureStore({
    reducer: {
        app: AppSlice,
        user: UserReducer,
        eua: EuaReducer,
        vessel: VesselReducer,
        voyage: VoyageReducer,
        efdb: EFDBReducer,
        newsroom: NewsRoomReducer,
        notification: NotificationReducer,
        transactions: TransactionSlice,
        voyageOptimization: VoyageOptimizationSlice,
        rawData: RawDataSlice,
        vesselsAnalysis: VesselsAnalysisSlice, // Corrected to match the slice name
        casperAnalysis: CasperAnalysisSlice,
    }
});

export default store;
