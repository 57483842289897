import React from "react";
import Sidebar from "../../components/Sidebar";
import Services from "../../Routes"
import ApiStatusAlert from "../../components/ApiStatusAlert";
import { ConfigProvider } from "antd";

const Dashboard = () => {

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            // colorPrimary:"#000",
            // defaultBg: "#000",
            // colorPrimaryHover: "#000"
          },
        },
      }}
    >
    <div className="Dashboard">
        <Sidebar />
        <ApiStatusAlert/>
      <Services/>
    </div>
    </ConfigProvider>
  );
};

export default Dashboard; 
