import React from "react";
import { Progress, Tag } from "antd";
import refreshIcon from "../../assets/icons/progressbar/refresh.svg";

const SingleProgress = ({ percent, color, tagColor, textColor, title, description }) => {
  return (
    <div className="card singleprogress">
      <div className="card-data">
        <div className="card-header">
          <span className="card-header-title">{title}</span>
          <span className="vessel-data-label" style={{ opacity: "0.8", fontSize: "13px" }}>{description}</span>
        </div>
        {/* <div className="card-refresh-icon">
          <img src={refreshIcon} alt="" />
        </div> */}
      </div>
      {/* <div className="card-tag">
        <Tag color={tagColor} style={{color:`${textColor}`, fontWeight:"500"}}>+0.75%</Tag>
      </div> */}
      <div className="progress">
        <Progress percent={percent} strokeColor={color} strokeWidth={70} showInfo={false} />
      </div>
    </div>
  );
};

export default SingleProgress;
