import React, { useState } from "react";
import '../../css/freemium.css';
import FreemiumTabComponent from "../../components/FreemiumTab";
import Disclaimer from "../../components/Disclaimer";


const FreemiumResult = ({ data }) => {

    const [showModal, setShowModal] = useState(false)

    const resultsArray = [
        {
            heading: "Total CO₂ Emission",
            value: data ? data.total_emission : "000.00",
            dimension: "tCO₂"
        },
        {
            heading: "CO₂ Emission Under EU ETS",
            value: data ? data.eu_ets : "000.00",
            dimension: "tCO₂"
        },
        {
            heading: "EU Identifier",
            value: data ? data.eu_identifier : "-",
            dimension: ""
        },
        {
            heading: <>CII*< Disclaimer /></>,
            value: data ? data.cii : "000.00",
            dimension: "gCO₂/tnm",
            name: "CII"
        },
        {
            heading: "CO₂ Intensity (EEOI)",
            value: data ? data.eeoi : "000.00",
            dimension: "gCO₂/tnm"
        },
        {
            heading: "Total EUA",
            value: data ? data.eua : "000.00",
            dimension: ""
        },
    ];

    const openModal = () => {
        setShowModal(true)
    }
    return (
        <><span className="freemium_Historical_span"><button onClick={() => openModal()} className="freemium_Historical_Button">Download Freemium Historical Report</button></span>
            <div style={{ marginTop: "5px" }} className='card freemiumResultSection'>
                <div className='freemiumCard freemiumCard1'>
                    <span>Results</span>
                    {/* <LineArea /> */}
                </div>
                <div className='freemiumCard freemiumCard2'>
                    {/* <EUATab /> */}
                </div>
                <div className='freemiumCard freemiumCard3'>
                    {resultsArray.map((result, index) => (

                        <div className="emmissionsData" key={index}>
                            <div className="freemiumLabel">
                                <p>{result.heading}</p>
                            </div>
                            <div className="freemiumData">
                                {result.name ? <h6 className={result.value.charAt(result.value.length - 1) === "A" ? 'classgreen' : result.value.charAt(result.value.length - 1) === "B" ? 'classSkyBlue' : result.value.charAt(result.value.length - 1) === "C" ? 'classOrange' : result.value.charAt(result.value.length - 1) === "D" ? 'classPink' : result.value.charAt(result.value.length - 1) === "E" ? 'classRed' : ""} style={{ padding: "3px", borderRadius: '5px' }}>{result.value}</h6> : <h6>{result.value}</h6>}
                                <p>{result.dimension}</p>
                            </div>
                        </div>
                    ))}

                    {/* <div className="emmissionsData" >
                    <div className="freemiumLabel freemiumLabelResult">
                        <p>Total EUA</p>
                    </div>
                    <div className="freemiumData freemiumLabelResult">
                        <h6>{data ? data.total_eua : "0.00000"}</h6>
                    </div>
                </div> */}
                </div>

                {showModal && <FreemiumTabComponent showModal={showModal} setShowModal={setShowModal} />}

            </div></>
    );
};

export default FreemiumResult;