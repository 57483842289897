import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEuaDistribution } from '../../features/Voyage/VoyageSlice';
import warningIcon from "../../assets/icons/voyages/euadifference/warning.svg";


const BarGraph = () => {

    const dispatch = useDispatch()
    const { euaDistribution, selectedVoyage } = useSelector(state => state.voyage)

    useEffect(() => {
        if (selectedVoyage !== null) {
            dispatch(fetchEuaDistribution(selectedVoyage))
        }
    }, [selectedVoyage, dispatch])

    if (euaDistribution === null) {
        return ("Loading...")
    }

    const mappedData = {
        'At sea': euaDistribution.at_sea || 0,
        'Anchor': euaDistribution.anchor || 0,
        'In port': euaDistribution.in_port || 0,
        'Drifting': euaDistribution.drifting || 0,
        'Manoeuvring': euaDistribution.maneuvering || 0,
    };

    const chartData = {
        series: [{
            data: Object.values(mappedData)
        }],
        options: {
            chart: {
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    borderRadius: 2,
                    borderRadiusApplication: "end",
                    horizontal: true,
                }
            },
            dataLabels: {
                enabled: false
            },
            xaxis: {
                categories: ['At sea', 'Anchor', 'In port', 'Drifting', 'Manoeuvring'],
                axisBorder: {
                    show: false
                }
            },
            colors: ["#265CFF"],
            tooltip: {
                enabled: false
            }
        },
    };

    return (
        <div id="chart" style={{ height: "90%" }}>
            <ReactApexChart height="100%" options={chartData.options} series={chartData.series} type="bar" />
            <div className='Warning-entry' style={{ padding: "10px" }}>
                <div className='Warning-wntry-template'>
                    <img src={warningIcon} alt="warning" />
                    <span className='warning-alert'>Shown Data is according to your selection</span>
                </div>
                <div className='Warning-wntry-value'>
                    <span className='title'>Total EUA</span>
                    <span className='value'>{euaDistribution.total_eua}</span>
                </div>
            </div>
        </div>
    );
};

export default BarGraph;
