import { Form, Select, Button, Modal, Input, Table } from 'antd';
import { useState, useEffect } from 'react';
import FuelTableInput from './FuelTableInput';
import { fetchFuelList } from '../../features/Voyage/VoyageSlice';
import { useDispatch, useSelector } from 'react-redux';



const FuelTablesPage = ({ selectedVessel, isVesselNew, showModal, setShowModal }) => {
    const [form] = Form.useForm();

    const dispatch = useDispatch()
    const { fuelList } = useSelector((state) => state.voyage)

    const [showFuelSelection, setShowFuelSelection] = useState(true)

    const [selectedFuels, setSelectedFuels] = useState(null);
    const [fuelListArray, setFuelListArray] = useState([])
    const handleDropDown = () => {
        try {
            dispatch(fetchFuelList()).then((result) => {
                if (result) {
                    setFuelListArray(result.payload.map((fuel) => fuel.fuel_name))
                }
            });
        } catch (error) {
            console.error('Error fetching fuel list:', error);
        }

    };

    useEffect(() => {
        if (selectedFuels === null) {
            handleDropDown()
        }
    }, []);


    const onFinish = (values) => {
        setShowFuelSelection(false)
    };


    const data = [
        { id: 1, fuelType: 'Ballast' },
        { id: 2, fuelType: 'Laden' },
    ];

    // console.log(selectedVessel)

    return (
        <Modal
            open={showModal}
            onCancel={() => { setShowModal(false); setShowFuelSelection(true); setSelectedFuels([]); form.resetFields(); }}
            footer={null}
            className={showFuelSelection ? 'FuelTableContent' : 'FuelTableContents'}

        >
            <div>
                <h3>Selected Vessel: {selectedVessel.vessel_name}</h3>
                {showFuelSelection ?
                    <Form
                        form={form}
                        name="fuelSelectionForm"
                        onFinish={onFinish}
                        initialValues={{ fuels: [] }}

                    >
                        <Form.Item
                            name="fuels"
                            label="Select Fuels (Maximum 3)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select atleast 1 or upto 3 fuels!',
                                    type: 'array',
                                    min: 1,
                                    max: 3,
                                },
                            ]}

                        >
                            <Select mode='multiple' placeholder="Please select fuels" onChange={(values) => setSelectedFuels(values)}>
                                {fuelListArray.map((fuel, index) => (
                                    <Select.Option key={index} value={fuel}>
                                        {fuel}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" style={{ marginTop: "18px" }}>
                                Next
                            </Button>
                        </Form.Item>
                    </Form> :

                    <>
                        <p className='disclaimerNotes'>
                            <sapn style={{ fontWeight: "600" }}>Note:</sapn>
                            <ol type='a'>
                                <li>
                                    Speed in 'knots' and consumption in 'MT/day'.
                                </li>
                                <li>
                                    Non-steaming consumption to be added with speed mentioned as 0 knots.
                                </li>
                            </ol>
                        </p>
                        <FuelTableInput setShowModal={setShowModal} selectedFuels={selectedFuels} selectedVessel={selectedVessel} setSelectedFuels={setSelectedFuels} setShowFuelSelection={setShowFuelSelection} />
                    </>}
            </div>
        </Modal>
    );
};

export default FuelTablesPage;
