import React, { useState } from 'react';
import { Radio, Tabs } from 'antd';
import ListComponent from './List';

const { TabPane } = Tabs;

const TabComponent = () => {
  const [size, setSize] = useState('large');

  const onChange = (e) => {
    setSize(e.target.value);
  };

  return (
    <div style={{ height: "100%" }}>
      <Tabs defaultActiveKey="1" type="card" size={size}>
        <TabPane tab="Notifications" key="1">
          <ListComponent category={"voyage"} />
        </TabPane>
        <TabPane tab="EUA Transactions" key="2">
          <ListComponent category={"order"} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TabComponent;
