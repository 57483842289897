import React, { useState, useEffect } from 'react';
import uploadIcon from '../assets/icons/rawdata/uploadIcon.svg';
import ArrowRightOutlined from '../assets/icons/rawdata/Modal_arrow.svg';
import dynamicIcon from '../assets/icons/rawdata/file.svg'; // Example dynamic icon
import endIcon from '../assets/icons/rawdata/delete.svg'; // Example end icon

const CertificateDetails = ({ heading, subheading, files, onFileUpload }) => {
  const [fileList, setFileList] = useState(files);

  useEffect(() => {
    setFileList(files);
  }, [files]);

  const handleFileUpload = (event) => {
    const newFiles = Array.from(event.target.files);
    const now = new Date();
    const formattedDate = `${now.toLocaleTimeString()} ${now.toLocaleDateString()}`;

    const fileData = newFiles.map(file => ({
      name: file.name,
      uploadDate: formattedDate
    }));

    const updatedFileList = [...fileList, ...fileData];
    setFileList(updatedFileList);
    onFileUpload(updatedFileList, heading);
  };

  const handleFileRemove = (indexToRemove) => {
    const updatedFileList = fileList.filter((_, index) => index !== indexToRemove);
    setFileList(updatedFileList);
    onFileUpload(updatedFileList, heading);
  };

  const dynamicDatas = {
    icon: dynamicIcon, // Replace with dynamic icon path
    endIcon: endIcon, // Replace with dynamic end icon path
  };

  return (
    <div className="cert-container">
      <div className="cert-header">
        <div className="cert-headerText">
          <span>{heading}</span>
          <img
            src={ArrowRightOutlined}
            alt="arrow icon"
            className="cert-arrowIcon"
          />
        </div>
      </div>
      <div className="cert-subheading">
        {subheading}
      </div>
      <hr className="cert-separator" />
      <div className="cert-dynamicDiv" style={{ maxHeight: '200px', overflowY: 'scroll' }}>
        {fileList.map((file, index) => (
          <div key={index} className="cert-fileEntry" style={{ marginBottom: '10px' }}>
            <img src={dynamicDatas.icon} alt="dynamic icon" className="cert-dynamicIcon" />
            <div className="cert-dynamicContent">
              <div className="cert-dynamicHeading">{file.name}</div>
              <div className="cert-dynamicSubheading">{file.uploadDate}</div>
            </div>
            <img 
              src={dynamicDatas.endIcon} 
              alt="end icon" 
              className="cert-dynamicEndIcon" 
              onClick={() => handleFileRemove(index)} // Handle the click event
              style={{ cursor: 'pointer' }}
            />
          </div>
        ))}
      </div>
     
      <div className="cert-footer">
        <div className="cert-fileCount">{fileList.length} Files added</div>
        <input
          type="file"
          id="fileUpload"
          style={{ display: 'none' }}
          onChange={handleFileUpload}
          multiple
        />
        <label htmlFor="fileUpload" className="cert-uploadButton">
          <img src={uploadIcon} alt="upload icon" className="cert-uploadIcon" />
          Upload
        </label>
      </div>
    </div>
  );
};

export default CertificateDetails;
