import React from 'react'
import { ReactComponent as Arrow } from "../../assets/icons/rawdata/iconoir_arrow-up.svg"

const Indicators = ({ key, title, previous, after }) => {

    const titleStyle = {
        fontSize: title === "Identifier" || title === "Compliance Balance" ? "1.5vh" : "initial",
    };

    return (
        <div className='output-indicators'>
            <div className='output-indicators-header'>
                {title}
            </div>
            <div className='output-indicators-indicator'>
                <div className='output-indicators-indicator-col'>
                    <div>
                        Previous
                    </div>
                    <p style={titleStyle}>
                        {previous}
                    </p>
                </div>
                <Arrow />
                <div className='output-indicators-indicator-col'>
                    <div>
                        After
                    </div>
                    <p style={titleStyle}>
                        {after}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Indicators