import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";

export const getSettlementDues = createAsyncThunk(
  'user/getSettlementDues',
  async (settlementParams) => {
    const request = await makeRequest("get", `api/get_settlement_dues/?keyword=${settlementParams}`)
    const response = await request.data.data
    return response
  }
)


export const getEuaOutstanding = createAsyncThunk(
  'user/getEuaOutstanding',
  async () => {

    const request = await makeRequest("get", "api/get_cumulative_eua_info/?registered_owner=CLDN Cobelfret Pte. Ltd.")
    const response = await request.data.data
    return response
  }
)


export const fetchCSVDocument = createAsyncThunk(
  "voyage/generateStatementDocument",
  async (params, date) => {
    const { start_date, end_date } = params
    const request = await makeRequest("get", `api/generate_document/?doc_type=csv&start_date=${start_date}&end_date=${end_date}`)
    const response = await request.data.data
    return response
  }
)


export const fetchCSVDocumentforVessels = createAsyncThunk(
  "voyage/generateStatementDocument",
  async (params, date) => {
    const { start_date, end_date, imo_number } = params
    const request = await makeRequest("get", `api/generate_document/?imo_number=${imo_number}&doc_type=csv&start_date=${start_date}&end_date=${end_date}`)
    const response = await request.data.data
    return response
  }
)

export const getEUPortsEnteringVessels = createAsyncThunk(
  'user/getEUPortsEnteringVessels',
  async(page)=>{
    const request = await makeRequest("get", `api/get_eu_ports_entering_vessels/?requested_page=${page}`)
    const response = await request.data.data;
    return response;
  }
)


const EuaSlice = createSlice({
  name: 'dues',
  initialState: {
    status: 'idle',
    dues: null,
    euportsenteringvessels: [],
    cummulativeInfo: {
      status: 'idle',
      data: null,
    },
    csvDocument: null,
  },
  reducers: {
    duesCleaner: (state) => {
      state.dues=null
      state.status = 'loading'
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettlementDues.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSettlementDues.fulfilled, (state, action) => {
        state.status = 'succeeded';
        if (action.payload === null) {
          state.dues = []
        }
        else {
          state.dues = action.payload;
        }
      })
      .addCase(getSettlementDues.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getEuaOutstanding.pending, (state) => {
        state.cummulativeInfo.status = 'loading';
      })
      .addCase(getEuaOutstanding.fulfilled, (state, action) => {
        state.cummulativeInfo.status = 'succeeded';
        state.cummulativeInfo.data = action.payload;
      })
      .addCase(getEuaOutstanding.rejected, (state) => {
        state.cummulativeInfo.status = 'failed';
      })
      .addCase(fetchCSVDocument.fulfilled, (state, action) => {
        state.csvDocument = action.payload
      })
      .addCase(fetchCSVDocument.rejected, (state, action) => {
        state.csvDocument = null
      })
      .addCase(getEUPortsEnteringVessels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getEUPortsEnteringVessels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.euportsenteringvessels = [...state.euportsenteringvessels, ...action.payload];;
      })
      .addCase(getEUPortsEnteringVessels.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const {duesCleaner}=EuaSlice.actions

export default EuaSlice.reducer