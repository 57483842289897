import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DatePicker, Skeleton } from 'antd'
import { Input, Button, Divider, message } from "antd";
import { fetchRawData, setSelectedRecordsForSequentialFlow, updateSelectedRecordsForSequentialFlow, deselectSelectedRecordsForSequentialFlow, deselectAllRecordsInSequentialFlow , removeObjectFromSelectedRecords} from '../../../features/RawData/RawDataSlice';
import DataRecordsHeader from './DataRecordsHeader'
import DataRecordsTable from './DataRecordsTable'

const { RangePicker } = DatePicker
const { Search } = Input;


const DataRecordsContainer = ({ onAssignCasperID, selectedCasperIdInFilters, selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace, selectedCardsWithEssentialData, setSelectedCardsWithEssentialData }) => {

  const dispatch = useDispatch()
  const { rawDataResponseStatus, rawData, cardUniqueIDDetails, selectedRecordsForSequentialFlow, selectedRecordsPerPageUniqueIds, globalFilters } = useSelector((state) => state.rawData)
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    if (rawDataResponseStatus === 'loading') {
      setShowSkeleton(true);
    } else {
      // Introduce a delay before hiding the skeleton
      const timer = setTimeout(() => {
        setShowSkeleton(false);
      }, 2000); // 2000 ms = 2 seconds

      // Cleanup the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [rawDataResponseStatus]);

  const handleCardSelect = (uniqueId, at_port_consumption, from_port_code, to_port_code) => {

    const pageNumber = globalFilters.pageNumber;  
    const selectedRecordsForPage = selectedRecordsPerPageUniqueIds[pageNumber] || []; 
    const isSelectedOnPage = selectedRecordsForPage.includes(uniqueId);

    if (isSelectedOnPage) {
      setSelectedCardsWithEssentialData(prevState => {
        const { [uniqueId]: _, ...rest } = prevState; 
        return rest;
      });

      dispatch(deselectSelectedRecordsForSequentialFlow(uniqueId));
    } else {
      setSelectedCardsWithEssentialData(prevState => ({
        ...prevState,
        [uniqueId]: {
          at_port_consumption: !!at_port_consumption,
          from_port_code: !!from_port_code,
          to_port_code: !!to_port_code
        }
      }));

      if (Object.keys(selectedRecordsPerPageUniqueIds).length > 0) {
        dispatch(updateSelectedRecordsForSequentialFlow({[uniqueId]: rawData[uniqueId]}));
      } else {
        dispatch(setSelectedRecordsForSequentialFlow({[uniqueId]: rawData[uniqueId]}));
      }
    }
  };
  const resetSelection = () => {
    setSelectedCardsWithEssentialData({});

    dispatch(deselectAllRecordsInSequentialFlow());
  };

  const handleCheckAll = (e) => {
    if (e.target.checked) {
      const updatedSelectedCards = [];
      const updatedSelectedCardsWithEssentialData = {};
      const data = {};
      // Map through cardUniqueIDDetails once and update both selectedCards and selectedCardsWithEssentialData
      cardUniqueIDDetails?.forEach(record => {
        const { unique_id, casper_id, at_port_consumption, from_port_code, to_port_code } = record;
        if (!casper_id) {
          // Add the unique_id to the selectedCards array
          updatedSelectedCards.push(unique_id);


          // Update the selectedCardsWithEssentialData object
          updatedSelectedCardsWithEssentialData[unique_id] = {
            at_port_consumption: !!at_port_consumption,
            from_port_code: !!from_port_code,
            to_port_code: !!to_port_code,
          };

          data[unique_id] = rawData[unique_id];
        }
      });

       if (Object.keys(selectedRecordsForSequentialFlow).length > 0) {
      dispatch(updateSelectedRecordsForSequentialFlow(data));
    } else {
      dispatch(setSelectedRecordsForSequentialFlow(data));
    }

      setSelectedCardsWithEssentialData(prevState => ({
        ...prevState,
        ...updatedSelectedCardsWithEssentialData
      }));
    } else {
      // Deselect all records
      dispatch(removeObjectFromSelectedRecords())
    }
  };
  return (
    <div className='card'>


      <DataRecordsHeader resetSelection={resetSelection} onAssignCasperID={onAssignCasperID} selectedCasperIdInFilters={selectedCasperIdInFilters} handleCheckAll={handleCheckAll} />

      <Divider />

      {showSkeleton ? (
        <Skeleton active />
      ) :
        <DataRecordsTable handleCardSelect={handleCardSelect} selectedUniqueIdAuditTrace={selectedUniqueIdAuditTrace} setSelectedUniqueIdAuditTrace={setSelectedUniqueIdAuditTrace} selectedCardsWithEssentialData={selectedCardsWithEssentialData} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />
      }
    </div>
  )
}

export default DataRecordsContainer
