import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Table } from 'antd';

const EUIdentifierData = () => {
    const { voyageCiiData, ciiStatus } = useSelector(state => state.voyageOptimization)


    console.log(voyageCiiData)

    // Define columns for the outer table
    const columns = [
        {
            title: 'Start Port',
            dataIndex: 'start_port',
        },
        {
            title: 'Load Port',
            dataIndex: 'load_port',
        },
        {
            title: 'Discharge Port',
            dataIndex: 'discharge_port',
        },
    ];

    // Prepare data for the outer table
    const data = voyageCiiData.length > 0 ? [
        {
            key: '1',
            start_port: voyageCiiData[0].start_port,
            load_port: voyageCiiData[0].load_port,
            discharge_port: voyageCiiData[0].discharge_port,
        }
    ] : [];

    // Define columns for the inner table
    const insideColumns = [
        {
            title: 'Ballast Leg Identifier',
            dataIndex: 'startTOLoad',
        },
        {
            title: 'Laden Leg Identifier',
            dataIndex: 'loadTODischarge',
        },
    ];

    // Define columns for the inner table
    const insideColumns2 = [
        {
            title: 'Ballast Leg Distance',
            dataIndex: 'startTOLoad',
        },
        {
            title: 'Laden Leg Distance',
            dataIndex: 'loadTODischarge',
        },
    ];

    // Prepare data for the inner table
    const insideTableData = voyageCiiData.length > 0 ? [
        {
            key: '2',
            startTOLoad: voyageCiiData[0].eu_iden_start_to_load,
            loadTODischarge: voyageCiiData[0].eu_iden_load_to_discharge,
        }
    ] : [];
    // Prepare data for the inner table
    const insideTableData2 = voyageCiiData.length > 0 ? [
        {
            key: '3',
            startTOLoad: voyageCiiData[0].distance_between_start_port_load_port,
            loadTODischarge: voyageCiiData[0].load_port_to_discharge_port,
        },
    ] : [];

    // Render function for expanded rows (nested table)
    const expandedRowRender = () => {
        return (<>
            <Table columns={insideColumns} dataSource={insideTableData} pagination={false} />
            <Table columns={insideColumns2} dataSource={insideTableData2} pagination={false} /></>
        );
    };

    return (
        <div className='MatrixTablesInfoContainer'>
            <div style={{ width: "fit-content" }}>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    bordered
                    expandable={{ expandedRowRender }}
                />


            </div>
            <p className='disclaimerNotes'>
                <sapn style={{ fontWeight: "600" }}>Disclaimer:</sapn>
                <ol type='a'>
                    <li>
                        The distance between ports is derived from an external service. Azolla bears no responsibility to its accuracy. In case the distance between ports is shown as 300 NM, the user is requested to include waypoints to recall accurate values from the API.
                    </li>
                    <li>
                        Although all possible efforts have been made to ensure the correctness and completeness of this program, Azolla is not responsible for any errors or omissions made herein, nor be held liable for any actions taken by any party as a result of the outcome of this program. This program may contain information that is confidential and subject to legal restrictions and penalties regarding its unauthorized disclosure or use. The users are prohibited from copying, distributing, or otherwise using this program.
                    </li>
                </ol>
            </p>
        </div>
    );
};

export default EUIdentifierData;