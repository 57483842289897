import React, { useState } from 'react';
import { Table, Input, InputNumber, Button } from 'antd';
import MatrixDetailsInput from './MatrixDetailsInput';
import { useDispatch, useSelector } from 'react-redux';
import { postFuelTables, fetchVesselsList } from '../../features/VoyageOptimization/VoyageOptimizationSlice';

const FuelTableInput = ({ setShowModal, selectedFuels, selectedVessel, setSelectedFuels, setShowFuelSelection }) => {

    const { fuelList } = useSelector(state => state.voyage)
    const dispatch = useDispatch()
    const initialFuelRow = {
        speed: '',
        ...selectedFuels.reduce((acc, fuel) => ({ ...acc, [fuel]: '' }), {}),
        carbonFactor: 'Carbon Factor',
    };

    //Laden
    const initialLadenRows = Array.from({ length: 5 }, (_, index) => ({
        key: `${index + 1}`,
        condition: "LADEN",
        ...initialFuelRow,
    }));
    const [ladenData, setLadenData] = useState(initialLadenRows);

    const ladenColumns = [
        {
            title: 'Speed',
            dataIndex: 'speed',
            editable: true,
            render: (text, record) => renderEditableLadenCell(text, record, 'speed'),
        },
        ...selectedFuels.map((fuel) => ({
            title: fuel,
            dataIndex: fuel,
            editable: true,
            render: (text, record) => renderEditableLadenCell(text, record, fuel),
        })),
    ];

    const renderEditableLadenCell = (text, record, dataIndex) => {
        return (
            <InputNumber
                min={0}
                value={text}
                onChange={(value) => handleLadenCellChange(value, record.key, dataIndex)}
            />
        );
    };

    const handleLadenCellChange = (value, key, dataIndex) => {
        const newData = [...ladenData];
        const target = newData.find((item) => item.key === key);
        if (target) {
            target[dataIndex] = value;
            setLadenData(newData);
        }
    };

    const handleLadenAddRow = () => {
        const newData = [...ladenData];
        newData.push({
            key: `${newData.length + 1}`,
            condition: "LADEN",
            ...selectedFuels.reduce((acc, fuel) => ({ ...acc, [fuel]: '' }), {}),
        });
        setLadenData(newData);
    };






    //Ballast

    const initialBallastRows = Array.from({ length: 5 }, (_, index) => ({
        key: `${index + 1}`,
        condition: "BALLAST",
        ...initialFuelRow,
    }));


    const [ballastData, setBallastData] = useState(initialBallastRows);
    const ballastColumns = [
        {
            title: 'Speed',
            dataIndex: 'speed',
            editable: true,
            render: (text, record) => renderEditableBallastCell(text, record, 'speed'),
        },
        ...selectedFuels.map((fuel) => ({
            title: fuel,
            dataIndex: fuel,
            editable: true,
            render: (text, record) => renderEditableBallastCell(text, record, fuel),
        })),
    ];

    const renderEditableBallastCell = (text, record, dataIndex) => {
        return (
            <InputNumber
                min={0}
                value={text}
                onChange={(value) => handleBallastCellChange(value, record.key, dataIndex)}
            />
        );
    };

    const handleBallastCellChange = (value, key, dataIndex) => {
        const newData = [...ballastData];
        const target = newData.find((item) => item.key === key);
        if (target) {
            target[dataIndex] = value;
            setBallastData(newData);
        }
    };

    const handleBallastAddRow = () => {
        const newData = [...ballastData];
        newData.push({
            key: `${newData.length + 1}`,
            condition: "BALLAST",
            ...selectedFuels.reduce((acc, fuel) => ({ ...acc, [fuel]: '' }), {}),
        });
        setBallastData(newData);
    };

    //Carbon Factor
    const [carbonFactors, setCarbonFactors] = useState({});
    const handleCarbonFactorChange = (value, fuel) => {
        setCarbonFactors((prevCarbonFactors) => ({
            ...prevCarbonFactors,
            [fuel]: value,
        }));
    };

    //handle Data:
    const fuel_list_mapper = {
        "Diesel/Gas Oil (MGO/MDO)": "total_consumption_mdo",
        "Light Fuel Oil (LFO)": "total_consumption_lfo",
        "Heavy Fuel Oil (HFO)": "total_consumption_hfo",
        "Liquefied Petroleum Gas (LPG)": "total_consumption_lpg",
        "Liquefied Natural Gas (LNG)": "total_consumption_lng",
        "Methanol": "total_consumption_methanol",
        "Ethanol": "total_consumption_ethanol",
        "Bio-methanol": "total_consumption_bio_methanol",
        "e-methanol": "total_consumption_e_methanol",
        "Bio-gasoil": "total_consumption_bio_gasoil",
        "bio-fuel": "total_consumption_bio_fuel",
        "e-gasoil": "total_consumption_e_gasoil",
        "e-LNG": "total_consumption_e_lng",
        "Hydrogen": "total_consumption_hydrogen",
        "e-Hydrogen": "total_consumption_e_hydrogen",
        "Ammonia": "total_consumption_ammonia",
        "e-Ammonia": "total_consumption_e_ammonia",
        "Electricity": "total_consumption_electricity"
    }
    const handleAddTables = () => {
        const payloadToSend = { data: [] }


        ladenData.forEach(item => {
            let shouldPush = true;
            if (item.speed === '') {
                shouldPush = false;
            }
            const newItem = {
                "imo_number": selectedVessel.imo_number,
                "speed": item.speed,
                "condition": item.condition,

            };

            // Add fuel consumption data
            selectedFuels.forEach(fuel => {
                const fuelKey = fuel_list_mapper[fuel];
                if (fuelKey && item.hasOwnProperty(fuel)) {
                    if (item[fuel] === '') {
                        item[fuel] = 0;
                        // return; // Exit the forEach loop early
                    }
                    newItem[fuelKey] = item[fuel];
                }
            });

            // Only push if shouldPush is true
            if (shouldPush) {
                payloadToSend.data.push(newItem);
            }
        });
        ballastData.forEach(item => {
            let shouldPush = true;
            if (item.speed === '') {
                shouldPush = false;
            }
            const newItem = {
                "imo_number": selectedVessel.imo_number,
                "speed": item.speed,
                "condition": item.condition,

            };

            // Add fuel consumption data
            selectedFuels.forEach(fuel => {
                const fuelKey = fuel_list_mapper[fuel];
                if (fuelKey && item.hasOwnProperty(fuel)) {
                    if (item[fuel] === '') {
                        item[fuel] = 0;
                        // return; // Exit the forEach loop early
                    }
                    newItem[fuelKey] = item[fuel];
                }
            });

            // Only push if shouldPush is true
            if (shouldPush) {
                payloadToSend.data.push(newItem);
            }
        });

        // console.log(payloadToSend)
        dispatch(postFuelTables(payloadToSend)).then((result) => {
            console.log(result.payload.status)
            if (result.payload.status == 200) {
                dispatch(fetchVesselsList(""))
                setShowModal(false)
                window.location.reload();
            }
        });
    };

    const wholeTableData = [
        {
            name: "Laden",
            dataSources: ladenData,
            columnData: ladenColumns,
            addRowFunction: handleLadenAddRow
        },
        {
            name: "Ballast",
            dataSources: ballastData,
            columnData: ballastColumns,
            addRowFunction: handleBallastAddRow
        }
    ]

    return (
        <>
            <div className='fuelTableComponent'>

                {wholeTableData.map(tableData => (
                    <div>
                        <h3>{tableData.name}</h3>
                        <Table
                            dataSource={tableData.dataSources}
                            columns={tableData.columnData}
                            bordered
                            pagination={false}
                            rowClassName={(record) => (record.editable ? 'editable-row' : '')}
                            footer={() => (
                                <Button onClick={tableData.addRowFunction} style={{ marginTop: 16 }}>
                                    Add Row
                                </Button>
                            )}
                            summary={() => (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>Carbon Factor</Table.Summary.Cell>
                                    {selectedFuels.map((fuel) => (
                                        <Table.Summary.Cell className='inputFieldCarbon' key={fuel} index={selectedFuels.indexOf(fuel) + 1} >
                                            <InputNumber
                                                value={fuelList.find(item => item.fuel_name === fuel)?.carbon_factor_co2}
                                                // value={carbonFactors[fuel]}
                                                onChange={(value) => handleCarbonFactorChange(value, fuel)}
                                            />
                                        </Table.Summary.Cell>
                                    ))}
                                </Table.Summary.Row>
                            )}
                        />
                    </div>
                ))}
            </div>

            <div className='nextButtonFuel'>
                <button type="primary" onClick={handleAddTables} style={{ marginTop: "18px" }}>
                    Add Tables
                </button>
            </div>

        </>
    );
};

export default FuelTableInput;
