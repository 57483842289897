import React from 'react'
import AuditTracesHeader from './AuditTracesHeader'
import ChangesMade from './ChangesMade'
import OutputValue from './OutputValue'
import { useSelector } from 'react-redux';
const AuditTraces = ({ selectedRecordDateId }) => {

  const { auditDetails } = useSelector((state) => state.rawData);

  return (
    <div className='audit-traces'>

      <div className='audit-traces-header'>
        <AuditTracesHeader auditDetails={auditDetails} />
      </div>
      <hr />
      <div className='audit-traces-body'>
        <ChangesMade auditDetails={auditDetails} selectedRecordDateId={selectedRecordDateId} />
        <OutputValue auditDetails={auditDetails} selectedRecordDateId={selectedRecordDateId} />
      </div>
    </div>
  )
}

export default AuditTraces