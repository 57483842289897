import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import PastRecordsDate from './PastRecordsDate'
import AuditTraces from './AuditTraces'
import "../../css/pastrecords.css"
import { getAuditDetails } from '../../features/RawData/RawDataSlice';
const PastRecords = ({ selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace, selectedCasperIdAuditTrace }) => {
  const dispatch = useDispatch();
  const [selectedRecordDateId, setSelectedRecordDateId] = useState('')


  useEffect(() => {
    const payload = {
      id: selectedRecordDateId,
      req_type: "VOYAGE_DATA"
    }
    if (selectedRecordDateId) {
      dispatch(getAuditDetails(payload));
    }
  }, [selectedRecordDateId]);
  return (
    <div className='past-records'>
      <PastRecordsDate selectedUniqueIdAuditTrace={selectedUniqueIdAuditTrace} setSelectedUniqueIdAuditTrace={setSelectedUniqueIdAuditTrace} selectedRecordDateId={selectedRecordDateId} setSelectedRecordDateId={setSelectedRecordDateId} selectedCasperIdAuditTrace={selectedCasperIdAuditTrace} />
      <AuditTraces selectedRecordDateId={selectedRecordDateId} />
    </div>
  )
}

export default PastRecords