import React, { useState } from 'react';
import { Typography, Button } from 'antd';
import FuelEditModal from './FuelEditModal';

const { Title } = Typography;

const Heading = ({ title, iconImg, selectedUniqueId }) => {

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  return (
    <div className='stepper-card-header'>
      <span className='stepper-card-header-icon'>
        <img src={iconImg} alt="icon" className="header-icon-img" />
      </span>
      <p className='stepper-card-header-title'>
        {title}
      </p>
      {/* {title === 'Add details' && <Button className='stepper-card-header-button-All'>Add to all records</Button>} */}
      {title === 'Add details' && <Button className='stepper-next-button' type="primary" style={{ marginLeft: 'auto' }} onClick={showModal} >Edit Details</Button>}

      <FuelEditModal visible={isModalVisible} setIsModalVisible={setIsModalVisible} selectedUniqueId={selectedUniqueId} />
    </div>
  );
};

export default Heading;