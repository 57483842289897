import React, { useEffect } from 'react'
import Expand from '../../components/Expand'
import { fetchVoyagesHistory } from '../../features/Voyage/VoyageSlice'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useRef } from 'react'
import { useState } from 'react'
import { updateSelectedVoayage } from '../../features/Voyage/VoyageSlice'
import { Skeleton, Empty } from 'antd'

export const HistoricalVoyage = () => {

  const dispatch = useDispatch()
  const scrollableArea = useRef(null)
  const { historyVoyages, selectedVoyage } = useSelector(state => state.voyage)

  const { page, status, data } = historyVoyages
  const [updatedPage, setUpdatedPage] = useState(page)



  useEffect(() => {
    if (status !== "loading" && selectedVoyage !== null) {
      const params = {
        imo_number: selectedVoyage.imo_number,
        page: page
      }
      dispatch(fetchVoyagesHistory(params))
    }
  }, [updatedPage, dispatch, selectedVoyage]);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } =
      scrollableArea.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setUpdatedPage(updatedPage + 1)
    }
  }

  const historySelectHandler = (voyage) => {
    if (selectedVoyage.voyage_number === voyage.voyage_number) {
      return
    }
    const params = {
      imo_number: selectedVoyage.imo_number,
      voyage_number: voyage.voyage_number
    }
    dispatch(updateSelectedVoayage(params))
  }

  if (data.length === 0 && historyVoyages.status === "loading") {
    return (
      <>
        <div className="card">
          <Skeleton active />
        </div>
        <div className="card">
          <Skeleton active />
        </div>
      </>
    )
  }

  else if (data.length === 0 && historyVoyages.status === "succeeded") {
    return (
      <Empty />
    )
  }


  return (
    <div className='historyVoyage' onScroll={handleScroll} ref={scrollableArea}>
      {
        data.map((value, index) => {
          return (
            <Expand data={value} key={index} onSelect={() => { historySelectHandler(value) }} />
          )
        })
      }
    </div>
  )
}
