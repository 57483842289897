import React from 'react'
import { useState, useEffect } from "react"
import EditIcon from '../../assets/icons/voyages/edit.svg'
import deleteNotificationIcon from "../../assets/icons/notifications/delete.svg";
import { useSelector } from "react-redux"

import OffHireModal from './OffHireModal'
import { fetchOffHireData } from '../../features/Voyage/VoyageSlice';
import { useDispatch } from 'react-redux';

import { deleteVoyageDetails } from '../../features/Voyage/VoyageSlice';

import { Popconfirm, message } from 'antd';

const OffHireDataComponent = ({ data, voyageData }) => {
    const dispatch = useDispatch()

    const { user } = useSelector((state) => state.user)
    const { offHireData } = useSelector((state) => state.voyage)
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const { selectedVoyage } = useSelector((state) => state.voyage);
    const handleClose = () => setOpen(false);

    const fetchOffhire = () => {
        const params = {
            voyage_number: voyageData.voyage_number,
            imo_number: voyageData.imo_number
        }
        dispatch(fetchOffHireData(params)).then((result) => {
            if (result.payload) {
            }
        })
    }

    useEffect(() => {
        if (selectedVoyage !== null) {
            fetchOffhire()
        }
    }, [selectedVoyage]);


    const handleDelete = () => {
        // Replace the handleDelete logic with the Popconfirm logic
        message.loading({ content: 'Deleting...', key: 'deleteLoading' });
        setTimeout(() => {
            dispatch(deleteVoyageDetails(selectedVoyage)).then((result) => {
                console.log(result);
                message.success({ content: 'Deleted successfully!', key: 'deleteLoading', duration: 2 });
                fetchOffhire();
            });
        }, 1000);
    };
    return (
        <div className="voyageData-Card">
            <div className="voyageData-Card-Elements">
                <div className="voyageData-Card-Children">
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Time</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{offHireData && offHireData.length > 0 ? offHireData[0].time : ''}</p>
                        </div>
                    </div>
                </div>

                {/* <div className="voyageData-Card-Children">
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Fuel 01</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.fuel_01.fuel_name}-----{data.fuel_01.fuel_quantity} {data.fuel_01.fuel_unit}</p>
                        </div>
                    </div>
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Fuel 02</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.fuel_01.fuel_name}-----{data.fuel_01.fuel_quantity} {data.fuel_01.fuel_unit}</p>
                        </div>
                    </div>
                    <div className="voyageData-Card-Children-Data">
                        <div className="voyageData-Card-Children-Data1">
                            <h1>Fuel 03</h1>
                        </div>
                        <div className="voyageData-Card-Children-Data2">
                            <p>{data.fuel_01.fuel_name}-----{data.fuel_01.fuel_quantity} {data.fuel_01.fuel_unit}</p>
                        </div>
                    </div>
                </div> */}

                <div className="voyageData-Card-Children">
                    {offHireData && offHireData.length > 0 ? (
                        Object.entries(offHireData[0].fuel_list).map(([key, value]) => (

                            <div className="voyageData-Card-Children-Data" key={key}>
                                <div className="voyageData-Card-Children-Data1">
                                    <h1>{key}</h1>
                                </div>
                                <div className="voyageData-Card-Children-Data2">
                                    <p>{value.toFixed(2)} mt</p>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className="voyageData-Card-Children-Data">
                            <div className="voyageData-Card-Children-Data1">
                                <h1>No Data</h1>
                            </div>
                            <div className="voyageData-Card-Children-Data2">
                                <p></p>
                            </div>
                        </div>
                    )}

                </div>


                {user.role === 'admin' && <div className='Offhire-edit-delete'>
                    <img onClick={handleOpen} src={EditIcon} alt="Edit" />
                    <Popconfirm
                        title="Delete offhire Data"
                        description="Are you sure to delete this data?"
                        onConfirm={handleDelete}
                        okText="Yes"
                        cancelText="No"
                    >
                        {offHireData && offHireData.length > 0 ? <img src={deleteNotificationIcon} alt="Toggle" /> : ''}
                    </Popconfirm>
                </div>}


            </div>




            <OffHireModal
                open={open}
                handleClose={handleClose}
                voyageNumber={voyageData.voyage_number}
                imoNumber={voyageData.imo_number}
                offHireData={offHireData}
            />
        </div>
    )
}

export default OffHireDataComponent