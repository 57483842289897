import CarbonPricingChart from "../../container/newsroom/CarbonPricingChart";
import NewsCard from "../../container/newsroom/NewsCard";
import NewsDisplay from "../../container/newsroom/NewsDisplay";


const NewsRoom = () => {

  return (
    <main className="newsRoom-main-board">
      <div className="newsRoom-MainHeader">
        <CarbonPricingChart />
        <NewsCard />
      </div>
      <NewsDisplay />
    </main>
  );
};

export default NewsRoom;
