import React, { useEffect, useState } from 'react'
import DataRecordTab from './DataRecordsTab/DataRecordTab'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCasperRecords } from '../features/RawData/RawDataSlice'
import { Pagination, Skeleton } from 'antd';

const DataRecords = ({ setModal, setSelectedCasperIdAuditTrace }) => {
  const dispatch = useDispatch()
  const { fetchCasperRecordError } = useSelector((state) => state.casperAnalysis)
  const { fetchCasperRecordsStatus, casperRecords, casperAnalysisPagesCount, globalFilters } = useSelector((state) => state.rawData)
  const [showSkeleton, setShowSkeleton] = useState(true);

  useEffect(() => {
    if (fetchCasperRecordsStatus === 'loading') {
      setShowSkeleton(true);
    } else {
      // Introduce a delay before hiding the skeleton
      const timer = setTimeout(() => {
        setShowSkeleton(false);
      }, 2000); // 2000 ms = 2 seconds

      // Cleanup the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [fetchCasperRecordsStatus]);

  if (fetchCasperRecordsStatus === 'loading') {
    return <div>Loading...</div>  // to do (add spinner/loader)
  }



  const onChange = (pageNumber) => {
    dispatch(fetchCasperRecords({ ...globalFilters, pageNumber: pageNumber }))
    // console.log(pageNumber);
  };
  return (
    <div className='data-record'>
      <div className='data-record-header'>
        <div className='data-record-title'>
          <span style={{ fontSize: "20px", fontWeight: "500", color: "414141", lineHeight: "normal" }} >Casper Records </span>
          {fetchCasperRecordsStatus === 'succeeded' ? <p style={{ fontSize: "12px", color: "#414141" }} >{casperRecords?.length} Records found</p> : <p style={{ fontSize: "12px", color: "#414141" }}>0 Records Found</p>}
        </div>
        <hr />
      </div>
      {showSkeleton ? (
        <Skeleton active />
      ) :
        <div className='data-record-tabs'>

          {fetchCasperRecordsStatus === 'succeeded' && casperRecords?.length > 0 &&

            <>
              {casperRecords.map((record, index) => (

                <DataRecordTab key={index} setModal={setModal} record={record} setSelectedCasperIdAuditTrace={setSelectedCasperIdAuditTrace} />

              ))}
            </>


          }

          {fetchCasperRecordsStatus === 'succeeded' && casperRecords?.length > 0 && casperAnalysisPagesCount > 1
            ?
            <Pagination align="center" defaultCurrent={1} total={casperAnalysisPagesCount * 50} pageSize={50} showSizeChanger={false} onChange={onChange} />

            : ''

          }



        </div>
      }

    </div>
  )
}

export default DataRecords