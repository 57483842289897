import { useEffect } from "react";
import EFDBTable from "./EFDBTable";
import { efdbData } from "../../features/EFDB/EFDBSlice";
import { useDispatch, useSelector } from "react-redux";




const EFDB = () => {
    const {status, fuelList} = useSelector((state)=>state.efdb)

    const dispatch = useDispatch()

    useEffect(() => {
        if (status === 'idle') {
          dispatch(efdbData()).then((result) => {
          })
        }
      }, [dispatch, status])
    
      
  return (
    <div className="efdbCard  card">
      <div className="efdb-Element1">
        <h1>Emission Factors</h1>
      </div>
      <div className="efdb-Element2">
        <EFDBTable data={fuelList}/> 
      </div>
    </div>
  );
};

export default EFDB;
