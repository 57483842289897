import React from 'react'
import { ReactComponent as DateIcon } from "../../assets/icons/rawdata/uim_layer-group.svg";
import { ReactComponent as TickIcon } from "../../assets/icons/rawdata/charm_tick.svg";

const DateTab = ({ date, type, state, handleDateTabClick, id }) => {
    return (
        <div className={`datetab-${state}`} onClick={()=>handleDateTabClick(id)}>
            <div className='datetab-left'>
                <DateIcon />
                <div className='datetab-left-text'>
                    <h3>{date}</h3>
                    <p>{type ? "Automatic":"Manual"}</p>
                </div>
            </div>
            {
                state === "active" && (
                    <div className='datatab-right'>
                        <TickIcon />
                    </div>
                )
            }

        </div>
    )
}

export default DateTab