import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Select } from 'antd';
import EditIcon from '../../../assets/icons/voyages/edit.svg';
import { getPortNames } from '../../../features/RawData/RawDataSlice';

const { Option } = Select;

const consumptionTypeOptions = ["Sea", "Port", "Anchor", "Bunkering", "Drifting", "At Port"]

const DataRecordsDetails = ({ rawDetailsData, dataType, onEdit }) => {
    const dispatch = useDispatch();
    const { portSuggestions } = useSelector((state) => state.rawData);
    const { user } = useSelector((state) => state.user);
    const [editingField, setEditingField] = useState(null);
    const [editableData, setEditableData] = useState(rawDetailsData);
    const [searchValue, setSearchValue] = useState('');
    const [fromPortName, setFromPortName] = useState(null);
    const [toPortName, setToPortName] = useState(null);
    // console.log(portSuggestions)

    let data = [];

    const handleDropdownChange = (field, value, originalValue) => {
        handleInputChange(field, value, originalValue);
    };

    const handleEditClick = (field) => {
        setEditingField(field);
    };

    const handleInputChange = (field, value, oldValue) => {
        const selectedPort = portSuggestions.find(port => port.port_code === value);
        // console.log(selectedPort?.port_name || "")
        setEditableData((prevState) => ({
            ...prevState,
            [field]: value,
            ...(field === 'from_port_code' && { from_port_name: selectedPort?.port_name || "" }),
            ...(field === 'to_port_code' && { to_port_name: selectedPort?.port_name || "" }),
        }));

        switch (field) {
            case 'from_port_code':
                setFromPortName(selectedPort?.port_name || "");
                onEdit(field, value, oldValue, "from_port_name", selectedPort?.port_name, rawDetailsData?.from_port_name);
                // console.log(fromPortName)
                break;
            case 'to_port_code':
                setToPortName(selectedPort?.port_name || "");
                onEdit(field, value, oldValue, "to_port_name", selectedPort?.port_name, rawDetailsData?.to_port_name);
                break;
            // case 'consumption_type':
            //     // onEdit(field, value, oldValue);
            //     break;
            default:

                onEdit(field, value, oldValue);
                break;
        }
    };

    const handleBlur = () => {
        setEditingField(null);
    };

    useEffect(() => {
        data = [
            { label: 'Time Lapsed From Last Report Hrs.', value: !rawDetailsData?.time_lapsed_from_last_report_hrs ? '-' : rawDetailsData?.time_lapsed_from_last_report_hrs || 'N/A' },
            // { label: 'Vessel name', value: rawDetailsData?.vessel_name || 'N/A' },
            { label: 'Voyage No.', value: !rawDetailsData?.voyage_number ? '-' : rawDetailsData?.voyage_number || 'N/A' },
            { label: 'Report Date', value: !rawDetailsData?.report_date ? '-' : rawDetailsData?.report_date.replace('T', ' T') || 'N/A' },
            { label: 'Event', value: !rawDetailsData?.event ? '-' : rawDetailsData?.event || 'N/A' },
            { label: 'UTC', value: !rawDetailsData?.utc_date_time ? '-' : rawDetailsData?.utc_date_time || 'N/A' },
            { label: 'Location', value: !rawDetailsData?.location ? '-' : rawDetailsData?.location || 'N/A' },
            { label: 'From Port Code', value: !rawDetailsData?.from_port_code ? '-' : rawDetailsData?.from_port_code || 'N/A', field: 'from_port_code', isLocked :  rawDetailsData?.is_locked_in},
            { label: 'From Port Name', value: fromPortName },
            // { label: 'Report no.', value: rawDetailsData?.report_no || 'N/A' },
            { label: 'Vessel Activity', value: !rawDetailsData?.vessel_activity ? '-' : rawDetailsData?.vessel_activity || 'N/A' },
            { label: 'To Port Code', value: !rawDetailsData?.to_port_code ? '-' : rawDetailsData?.to_port_code || 'N/A', field: 'to_port_code', isLocked :  rawDetailsData?.is_locked_in },
            { label: 'To Port Name', value: toPortName },
            { label: 'Observed Distance', value: !rawDetailsData?.observerd_distance ? '-' : `${rawDetailsData?.observerd_distance?.toFixed(3)} NM` || 'N/A' },
            { label: 'Vessel Status', value: !rawDetailsData?.at_port_consumption ? '-' : rawDetailsData?.at_port_consumption || 'N/A', field: 'at_port_consumption', isLocked :  rawDetailsData?.is_locked_in },
            {
                label: 'Time Zone',
                value: !rawDetailsData?.time_zone
                  ? '-'
                  : isNaN(parseFloat(rawDetailsData?.time_zone))
                  ? 'N/A'
                  : parseFloat(rawDetailsData?.time_zone)?.toFixed(3)
            },
            { label: 'Dist. Traveled During HS', value: !rawDetailsData?.distance_travelled_during_hs ? '-' : `${rawDetailsData?.distance_travelled_during_hs?.toFixed(3)} NM` },
            { label: 'Steaming Time', value: !rawDetailsData?.steaming_time_hrs ? '-' : `${rawDetailsData?.steaming_time_hrs} Hrs` },
            { label: 'Anchorage Duration', value: !rawDetailsData?.time_spent_at_anchorage ? '-' : `${rawDetailsData?.time_spent_at_anchorage} Hrs` },
            { label: 'Drifting Duration', value: !rawDetailsData?.time_spent_at_drifting_hrs ? '-' : `${rawDetailsData?.time_spent_at_drifting_hrs} Hrs` },
        ];
    }, [fromPortName, toPortName]);

    switch (dataType.requestedData) {
        case 'VOYAGE_DATA':
            data = [
                { label: 'Time Lapsed From Last Report Hrs.', value: !rawDetailsData?.time_lapsed_from_last_report_hrs ? '-' : rawDetailsData?.time_lapsed_from_last_report_hrs || 'N/A' },
                // { label: 'Vessel name', value: rawDetailsData?.vessel_name || 'N/A' },
                { label: 'Voyage No.', value: !rawDetailsData?.voyage_number ? '-' : rawDetailsData?.voyage_number || 'N/A' },
                { label: 'Report Date', value: !rawDetailsData?.report_date ? '-' : rawDetailsData?.report_date.replace('T', ' T') || 'N/A' },
                { label: 'Event', value: !rawDetailsData?.event ? '-' : rawDetailsData?.event || 'N/A' },
                { label: 'UTC', value: !rawDetailsData?.utc_date_time ? '-' : rawDetailsData?.utc_date_time || 'N/A' },
                { label: 'Location', value: !rawDetailsData?.location ? '-' : rawDetailsData?.location || 'N/A' },
                { label: 'From Port Code', value: editableData?.from_port_code !== null ? editableData?.from_port_code || '-' : !rawDetailsData?.from_port_code ? '-' : rawDetailsData?.from_port_code || 'N/A', field: 'from_port_code', isLocked :  rawDetailsData?.is_locked_in },
                { label: 'From Port Name', value: fromPortName !== null ? fromPortName || '-' : !rawDetailsData?.from_port_name ? '-' : rawDetailsData?.from_port_name || 'N/A' },
                // { label: 'Report no.', value: rawDetailsData?.report_no || 'N/A' },
                { label: 'Vessel Activity', value: !rawDetailsData?.vessel_activity ? '-' : rawDetailsData?.vessel_activity || 'N/A' },
                { label: 'To Port Code', value: editableData?.to_port_code !== null ? editableData?.to_port_code || '-' : !rawDetailsData?.to_port_code ? '-' : rawDetailsData?.to_port_code || 'N/A', field: 'to_port_code', isLocked :  rawDetailsData?.is_locked_in },
                { label: 'To Port Name', value: toPortName !== null ? toPortName || '-' : !rawDetailsData?.to_port_name ? '-' : rawDetailsData?.to_port_name || 'N/A' },
                { label: 'Observed Distance', value: !rawDetailsData?.observerd_distance ? '-' : `${rawDetailsData?.observerd_distance?.toFixed(3)} NM` || 'N/A' },
                { label: 'Vessel Status', value: !rawDetailsData?.at_port_consumption ? '-' : rawDetailsData?.at_port_consumption || 'N/A', field: 'at_port_consumption', isLocked :  rawDetailsData?.is_locked_in },
                {
                    label: 'Time Zone',
                    value: !rawDetailsData?.time_zone
                      ? '-'
                      : isNaN(parseFloat(rawDetailsData?.time_zone))
                      ? 'N/A'
                      : parseFloat(rawDetailsData?.time_zone)?.toFixed(3)
                },
                { label: 'Dist. Traveled During HS', value: !rawDetailsData?.distance_travelled_during_hs ? '-' : `${rawDetailsData?.distance_travelled_during_hs?.toFixed(3)} NM` },
                { label: 'Steaming Time', value: !rawDetailsData?.steaming_time_hrs ? '-' : `${rawDetailsData?.steaming_time_hrs} Hrs` },
                { label: 'Anchorage Duration', value: !rawDetailsData?.time_spent_at_anchorage ? '-' : `${rawDetailsData?.time_spent_at_anchorage} Hrs` },
                { label: 'Drifting Duration', value: !rawDetailsData?.time_spent_at_drifting_hrs ? '-' : `${rawDetailsData?.time_spent_at_drifting_hrs} Hrs` },
            ];
            break;
        case 'VESSEL_DATA':
            data = [
                { label: 'Vessel Type', value: !rawDetailsData?.vessel_type ? '-' : rawDetailsData?.vessel_type || 'N/A' },
                { label: 'Vessel Name', value: !rawDetailsData?.vessel_name ? '-' : rawDetailsData?.vessel_name || 'N/A' },
                { label: 'Technical Managers', value: !rawDetailsData?.technical_group ? '-' : rawDetailsData?.technical_group || 'N/A' },
                { label: 'Deadweight', value: !rawDetailsData?.summer_dead_weight ? '-' : `${rawDetailsData?.summer_dead_weight} T` || 'N/A' },
                { label: 'Type Of Carrier', value: !rawDetailsData?.type_of_carrier ? '-' : rawDetailsData?.type_of_carrier || 'N/A' },
                { label: 'Size Of Vessel', value: !rawDetailsData?.size_of_vessels ? '-' : rawDetailsData?.size_of_vessels || 'N/A' },
                { label: 'Net Tonnage', value: !rawDetailsData?.net_ton ? '-' : `${rawDetailsData?.net_ton} T` || 'N/A' },
                { label: 'Gross Tonnage', value: !rawDetailsData?.gross_ton ? '-' : `${rawDetailsData?.gross_ton} T` || 'N/A' },
                { label: 'Flag', value: !rawDetailsData?.registration_flag ? '-' : rawDetailsData?.registration_flag || 'N/A' },
                { label: 'DOC', value: !rawDetailsData?.managers_doc_holder ? '-' : rawDetailsData?.managers_doc_holder || 'N/A' },
                { label: 'Current Status', value: !rawDetailsData?.current_status ? '-' : rawDetailsData?.current_status || 'N/A' },
                { label: 'Class', value: !rawDetailsData?.vessel_classe ? '-' : rawDetailsData?.vessel_class || 'N/A' },
                { label: 'Built', value: !rawDetailsData?.build_date ? '-' : new Date(rawDetailsData?.build_date).toLocaleDateString() || 'N/A' },
                { label: 'Actual Owners', value: !rawDetailsData?.active_owner ? '-' : rawDetailsData?.active_owner || 'N/A' },
                { label: 'Pwind', value: !rawDetailsData?.pwind ? '-' : rawDetailsData?.pwind || 'N/A' },
                { label: 'Pprop', value: !rawDetailsData?.pprop ? '-' : rawDetailsData?.pprop || 'N/A' }
            ];
            break;

        case 'CARGO_DATA':
            data = [
                {
                    label: 'Total Cargo Loaded',
                    value: rawDetailsData?.total_cargo_loaded_mt === null || rawDetailsData?.total_cargo_loaded_mt === undefined
                        ? '-'
                        : `${rawDetailsData.total_cargo_loaded_mt} MT`
                },
                {
                    label: 'Total Cargo Discharged',
                    value: rawDetailsData?.total_cargo_discharged_mt === null || rawDetailsData?.total_cargo_discharged_mt === undefined
                        ? '-'
                        : `${rawDetailsData.total_cargo_discharged_mt} MT`
                },
                {
                    label: 'Total Cargo Onboard',
                    value: rawDetailsData?.total_cargo_on_board_mt === null || rawDetailsData?.total_cargo_on_board_mt === undefined
                        ? '-'
                        : `${rawDetailsData.total_cargo_on_board_mt} MT`
                },
                {
                    label: 'Cargo Activity',
                    value: rawDetailsData?.cargo_activity === null || rawDetailsData?.cargo_activity === undefined
                        ? '-'
                        : `${rawDetailsData.cargo_activity}`
                }
            ];
            break;
        default:
            break;
    }

    const maxRowsPerColumn = 3;
    const columns = [];

    // Initialize columns based on data length
    for (let i = 0; i < Math.ceil(data.length / maxRowsPerColumn); i++) {
        columns.push(data.slice(i * maxRowsPerColumn, (i + 1) * maxRowsPerColumn));
    }

    const handleSearch = (value) => {
        const uppercasedValue = value.toUpperCase();
        setSearchValue(uppercasedValue);
        if (uppercasedValue.length >= 3) {
            dispatch(getPortNames(uppercasedValue));
        }
    };

    const handleKeyDown = (event) => {
        if (event.key.length === 1 && /^[a-zA-Z]$/.test(event.key)) {
            event.preventDefault();
            const uppercasedKey = event.key.toUpperCase();
            const newValue = searchValue + uppercasedKey;
            setSearchValue(newValue);
            if (newValue.length >= 3) {
                dispatch(getPortNames(newValue));
            }
        } else if (event.key === 'Backspace') {
            const newValue = searchValue.slice(0, -1);
            setSearchValue(newValue);
        }
    };

    return (
        <div style={{ display: 'flex', marginTop: '15px', flexDirection: 'row', justifyContent: dataType.requestedData === "CARGO_DATA" ? 'flex-start' : 'space-between' }}>
            {columns.map((column, columnIndex) => (
                <div key={columnIndex} style={{ display: 'flex', flexDirection: 'column', marginRight: '15px' }}>
                    {column.map((item, index) => (
                        <div key={index} style={{ marginBottom: '15px' }}>
                            <p className='data_labels'>
                                {item.label} {item.field ? user["is_admin:"] || !item.isLocked ?  (
                                    <img
                                        src={EditIcon}
                                        alt="Edit"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => handleEditClick(item.field)}
                                    />
                                ) : "" : ""}
                            </p>
                            {editingField === item.field ? (
                                item.field === 'at_port_consumption' ? (
                                    <Select
                                        value={editableData[item.field]}
                                        onChange={(value) => handleInputChange(item.field, value, item.value)}
                                        onBlur={handleBlur}
                                        style={{ width: 130 }}
                                    >
                                        {consumptionTypeOptions.map((option) => (
                                            <Option key={option} value={option}>
                                                {option}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : item.field === 'from_port_code' || item.field === 'to_port_code' ? (
                                    <Select
                                        showSearch
                                        value={editableData[item.field]}
                                        onSearch={handleSearch}
                                        onChange={(value) => handleInputChange(item.field, value, item.value)}
                                        onBlur={handleBlur}
                                        filterOption={false}
                                        style={{ width: 130 }}
                                        searchValue={searchValue}
                                        onInputKeyDown={handleKeyDown}
                                        onClear={() => handleInputChange(item.field, '', item.value)}
                                        allowClear
                                    >
                                        {portSuggestions.map((port) => (
                                            <Option key={port.port_code} value={port.port_code}>
                                                {port.port_code}
                                            </Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <input
                                        type="text"
                                        value={editableData[item.field]}
                                        onChange={(e) => handleInputChange(item.field, e.target.value, item.value)}
                                        onBlur={handleBlur}
                                        style={{ width: 130 }}
                                    />
                                )
                            ) : (
                                <p className='data_values' style={{ display: 'block', marginRight: '10px' }}>
                                    {editableData[item.field] ? editableData[item.field] : item.value}
                                </p>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default DataRecordsDetails;
