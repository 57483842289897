import React, { useState, useEffect } from 'react';
import { Modal, Table, Button, Input, Select, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { ArrowLeftOutlined, SettingOutlined } from '@ant-design/icons';
import { patchFuelInfoByUniqueID, fetchFuelInfoByUniqueID,fetchRawData, postFuelInfoApplyToAll, getBlandedFuelInfo } from '../../../features/RawData/RawDataSlice';
import ConflictingDataModal from './ConflictingDataModal';
import BioFuelEditModal from './BioFuelEditModal';

const { Option } = Select;

const FuelEditModal = ({ visible, setIsModalVisible, selectedUniqueId }) => {
    // console.log(selectedUniqueId)
    const fuelDataByUniqueId = useSelector((state) => state.rawData.fuelDataByUniqueId);

    const { status, rawData, blendedFuelsData, selectedRecordsPerPageUniqueIds, cardDataTypeIndividual, globalFilters } = useSelector((state) => state.rawData);

    // console.log(rawData[selectedUniqueId]?.[0]?.is_locked_in)
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);

    const [isTableEdited, setIsTableEdited] = useState(false)
    const [editingData, setEditingData] = useState([]);
    const [isConflictingDataModalVisible, setConflictingDataModalVisible] = useState(false);
    const [userRemark, setUserRemark] = useState('');
    const [remarkError, setRemarkError] = useState(false);
    const [bioFuelModalVisibile, setBioFuelModalVisibile] = useState(false);
    const [bioFuelData, setBioFuelData] = useState({});
    const selectedCards = Object.values(selectedRecordsPerPageUniqueIds).flat()
    // console.log(blendedFuelsData)
    const disabled = user["is_admin:"] ? false : rawData[selectedUniqueId]?.[0]?.is_locked_in;
    useEffect(() => {
        if (fuelDataByUniqueId) {
            setEditingData(fuelDataByUniqueId);
        }
    }, [fuelDataByUniqueId, visible]);
    useEffect(() => {
        // console.log(blendedFuelsData)
        if (selectedUniqueId === blendedFuelsData?.general_info?.unique_id) {
            let recordData = {};
            const fuelExists = fuelDataByUniqueId?.some((fuel) => {
                if (fuel?.id === blendedFuelsData?.general_info?.fuel_consumption_id && fuel?.class_name === "Biofuel") {
                    recordData = { ...fuel }; // Set the matched fuel as recordData
                    return true;
                }
                return false;
            });

            if (fuelExists) {

                // console.log(editingData)
                // Iterate through fuelDataByUniqueId and update matching records
                // debugger
                setEditingData((prevData) =>
                    prevData.map((fuel) => {
                        // Check if the fuel's metadata ID matches the one in blendedFuelsData
                        if (fuel?.id === blendedFuelsData?.general_info?.fuel_consumption_id) {
                            return {
                                ...fuel,
                                blended_id: blendedFuelsData?.blend_id,
                                fuel_name: blendedFuelsData?.general_info?.fuel_name,
                                fuel_ef_ttw_gfuel: blendedFuelsData?.general_info?.ef_ttw_gfuel,
                                fuel_ef_ttw_MJfuel: blendedFuelsData?.general_info?.ef_ttw_MJfuel,
                                // consumption: blendedFuelsData?.general_info?.consumption,
                                bunker_survey: blendedFuelsData?.general_info?.bunker_survey,
                                bunker_quantity: blendedFuelsData?.general_info?.bunker_quantity,
                                fuel_ef_wtt_gfuel: blendedFuelsData?.general_info?.ef_wtt_gfuel,
                                fuel_ef_wtt_MJfuel: blendedFuelsData?.general_info?.ef_wtt_MJfuel,
                                fuel_lcv: blendedFuelsData?.general_info?.lcv,

                                modified: {
                                    fuel_name: blendedFuelsData?.general_info?.fuel_name === fuel?.fuel_name,
                                    fuel_ef_ttw_gfuel: blendedFuelsData?.general_info?.ef_ttw_gfuel !== fuel?.fuel_ef_ttw_gfuel,
                                    fuel_ef_ttw_MJfuel: blendedFuelsData?.general_info?.ef_ttw_MJfuel !== fuel?.fuel_ef_ttw_MJfuel,
                                    // consumption: blendedFuelsData?.general_info?.consumption !== fuel?.consumption,
                                    bunker_survey: blendedFuelsData?.general_info?.bunker_survey !== fuel?.bunker_survey,
                                    bunker_quantity: blendedFuelsData?.general_info?.bunker_quantity !== fuel?.bunker_quantity,
                                    fuel_ef_wtt_gfuel: blendedFuelsData?.general_info?.ef_wtt_gfuel !== fuel?.fuel_ef_wtt_gfuel,
                                    fuel_ef_wtt_MJfuel: blendedFuelsData?.general_info?.ef_wtt_MJfuel !== fuel?.fuel_ef_wtt_MJfuel,
                                    fuel_lcv: blendedFuelsData?.general_info?.lcv !== fuel?.fuel_lcv,
                                }
                            };
                        }
                        // If there's no match, return the fuel object unchanged
                        return fuel;
                    })
                );

                setIsTableEdited(true)
                // alert("Values have been updated.");
            }
        }
    }, [blendedFuelsData])


    const createPayload = ({ oldData, newData }) => {
        const specifiedFields = [
            "name",
            "consumption",
            "cslip",
            "rwdi",
            "class_name",
            "lcv",
            "ef_ttw_MJfuel",
            "ef_ttw_gfuel",
            "ef_wtt_MJfuel",
            "ef_wtt_gfuel",
            "rob",
            "bunker_survey",
            "bunker_quantity"
        ];

        let changedFieldsSet = new Set();

        const changes = newData.reduce((acc, newRecord) => {
            const oldRecord = oldData.find(record => record.id === newRecord.id);
            if (!oldRecord) return acc;

            const changedFields = Object.keys(newRecord).reduce((fields, key) => {
                if (newRecord[key] !== oldRecord[key]) {
                    fields.push(key);
                    switch (key) {
                        case "consumption":
                            changedFieldsSet.add("FUEL_CONSUMPTION_DATA");
                            break;
                        case "cslip":
                            changedFieldsSet.add("CSLIP_DATA");
                            break;
                        case "ef_ttw_gfuel":
                            changedFieldsSet.add("EF_TTW_DATA");
                            break;
                        case "ef_ttw_MJfuel":
                            changedFieldsSet.add("EF_TTW_DATA");
                            break;
                        case "ef_wtt_gfuel":
                            changedFieldsSet.add("EF_WTT_DATA");
                            break;
                        case "ef_wtt_MJfuel":
                            changedFieldsSet.add("EF_WTT_DATA");
                            break;
                        case "lcv":
                            changedFieldsSet.add("LCV_DATA");
                            break;
                        case "bunker_survey":
                            changedFieldsSet.add("BUNKER_SURVEY_DATA");
                            break;
                        case "bunker_quantity":
                            changedFieldsSet.add("BUNKER_QTY_DATA");  
                            break;
                        case "rob":
                            changedFieldsSet.add("ROB_DATA");
                            break;
                        default:
                            changedFieldsSet.add(key);
                            break;
                    }
                    changedFieldsSet.add(key);
                }
                return fields;
            }, []);

            // console.log(newRecord)


            if (changedFields.length > 0) {
                acc.push({
                    fuel_consumption_id: newRecord.id,
                    fuel_id: newRecord.fuel_id,
                    fuel_metadata_id: newRecord.fuel_metadata_id,
                    class_name: oldRecord.class_name,
                    fields: specifiedFields.reduce((fields, key) => {
                        switch (key) {
                            case "name":
                                fields[key] = newRecord["fuel_name"];
                                break;
                            case "ef_ttw_gfuel":
                                fields[key] = parseFloat(newRecord["fuel_ef_ttw_gfuel"]);
                                break;
                            case "ef_ttw_MJfuel":
                                fields[key] = parseFloat(newRecord["fuel_ef_ttw_MJfuel"]);
                                break;
                            case "ef_wtt_gfuel":
                                fields[key] = parseFloat(newRecord["fuel_ef_wtt_gfuel"]);
                                break;
                            case "ef_wtt_MJfuel":
                                fields[key] = parseFloat(newRecord["fuel_ef_wtt_MJfuel"]);
                                break;
                            case "lcv":
                                fields[key] = parseFloat(newRecord["fuel_lcv"]);
                                break;
                            case "cslip":
                                fields[key] = parseFloat(newRecord["fuel_cslip"]);
                                break;
                            case "rwdi":
                                fields[key] = parseFloat(newRecord["fuel_rwdi"]);
                                break;
                            default:
                                fields[key] = newRecord[key];
                                break;
                        }
                        return fields;
                    }, {
                        blended_id: blendedFuelsData?.general_info?.unique_id === selectedUniqueId ? blendedFuelsData?.blend_id : []
                    }),
                    old_impacted_fields: changedFields.reduce((fields, key) => {
                        fields[key] = oldRecord[key];
                        return fields;
                    }, {
                        fuel_name: oldRecord["fuel_name"],
                        change_to: newRecord["fuel_name"]
                    }),
                    new_impacted_fields: changedFields.reduce((fields, key) => {
                        const value = newRecord[key];
                        fields[key] = !isNaN(value) && value !== "" ? parseFloat(value) : value;
                        return fields;
                    }, {
                        fuel_name: newRecord["fuel_name"],
                        change_from: oldRecord["fuel_name"]
                    })
                });
            }
            // console.log(acc)

            return acc;
        }, []);
        const changedFields = Array.from(changedFieldsSet); // Convert the Set to an array for the unique changed fields
        // console.log("Changed Fields:", changedFields); 
        return {
            data: changes,
            user_remark: userRemark,
            changed_fields: changedFields
        };
    };

    const handleApplyToThisRecord = async () => {

        if (isTableEdited && !userRemark) {
            setRemarkError(true);
            return;
        }

        // List of required fields
        const requiredFields = [
            'fuel_lcv', 'fuel_cslip', 'fuel_ef_ttw_MJfuel', 'fuel_ef_ttw_gfuel',
            'fuel_ef_wtt_MJfuel', 'fuel_ef_wtt_gfuel', 'fuel_rwdi'
        ];
        const payload = createPayload({ oldData: fuelDataByUniqueId, newData: editingData });

        if (payload.data.length === 0) {
            handleClose();
            return;
        }


        const hasBiofuelWithoutBlendedID = payload?.data?.some(record => {
            return record?.class_name === "Biofuel" && (record?.fields?.blended_id?.length > 0) && !record?.fields?.name;
        });

        if (hasBiofuelWithoutBlendedID) {
            message.error("Biofuel Name is mandatory");
            return;
        }



        // console.log(payload)
        const payloadToSend = {
            payload: payload,
            uniqueID: selectedUniqueId,
            imo_number: fuelDataByUniqueId[0].imo_number,
            casper_id:
                rawData[selectedUniqueId]?.[0]?.casper_id ||
                ''
        };
        // console.log(payload)

        try {
            const response = await dispatch(patchFuelInfoByUniqueID(payloadToSend)).unwrap();

            if (response && response.status === 200) {
                setIsTableEdited(false)
                dispatch(fetchFuelInfoByUniqueID(selectedUniqueId));
            if (payload.changed_fields.includes(cardDataTypeIndividual[selectedUniqueId].requestedData)) {
                // console.log("Success");
                dispatch(fetchRawData({ ...globalFilters, requestedData: cardDataTypeIndividual[selectedUniqueId].requestedData, uniqueID: selectedUniqueId }));
            }
            } else {
                console.error('First API call failed:', response);
            }
        } catch (error) {
            console.error('Error during API calls:', error);
        } finally {
            setUserRemark('')
            handleClose();
        }
    };


    const handleApplyToAllRecord = () => {


        if (!userRemark) {
            setRemarkError(true);
            return;
        }
        const payloadToSend = {
            referenced_unique_id: selectedUniqueId,
            referenced_data: fuelDataByUniqueId,
            apply_to: selectedCards.filter(item => item !== selectedUniqueId),
            conflicting_data: [],
            user_remarks: userRemark,
            imo_number: fuelDataByUniqueId[0].imo_number,
            casper_id:
                rawData[selectedUniqueId]?.[0]?.casper_id ||
                ''
        };

        // console.log(payloadToSend)

        dispatch(postFuelInfoApplyToAll(payloadToSend)).then((result) => {
            // console.log(result)
            if (result.payload?.data?.available_options_to_resolve_conflict?.length > 0) {
                setConflictingDataModalVisible(true);
            } else {
                handleClose();
            }
        })
        // alert("Applied to All record");
        // handleClose();
    };

    const handleInputChange = (e, record, key) => {
        // console.log(e.target.value)
        const newValue = key === 'fuel_name' || key === 'class_name'
            ? e.target.value
            : !isNaN(e.target.value) || e.target.value === '.'
                ? e.target.value
                : null;
        // console.log(key)
        const newData = [...editingData];
        const index = editingData.findIndex((item) => record.id === item.id);
        // console.log(index)
        const item = newData[index];
        newData[index] = {
            ...item,
            [key]: newValue,
            modified: {
                ...item.modified,
                [key]: item[key] !== newValue
            }
        };
        // console.log(newData[index])
        setEditingData(newData);
        // console.log(editingData)

        const hasChanged = !fuelDataByUniqueId.every((fuelItem, idx) => {
            return Object.keys(fuelItem).every(key => fuelItem[key] === newData[idx][key]);
        });

        // console.log(hasChanged)
        if (hasChanged) {
            setIsTableEdited(true);

        }
    };


    const handleSelectChange = (value, record, key) => {
        const newData = [...editingData];
        const index = newData.findIndex((item) => record.id === item.id);
        const item = newData[index];
        newData[index] = {
            ...item,
            [key]: value,
            modified: {
                ...item.modified,
                [key]: item[key] !== value
            }
        };

        setEditingData(newData);
        // Compare newData with fuelDataByUniqueId
        const hasChanged = !fuelDataByUniqueId.every((fuelItem, idx) => {
            return Object.keys(fuelItem).every(key => fuelItem[key] === newData[idx][key]);
        });

        // Set isTableEdited based on the comparison
        if (hasChanged) {
            setIsTableEdited(true);
        }

    };

    const handleClose = () => {
        setEditingData([]);
        setIsTableEdited(false)
        setUserRemark('');
        setRemarkError(false);
        setIsModalVisible(false);
    };

    const formatNumber = (value) => {
        if (typeof value === 'number') {
            return value.toFixed(Math.min(6, (value.toString().split('.')[1] || '').length));
        }
        return value;
    };

    const columns = [
        {
            title: 'Fuel name',
            dataIndex: 'fuel_name',
            key: 'fuel_name',
            width: 150,
            fixed: 'left',
            render: (text, record) => {
                // console.log('record.modified:', record.modified);
                // console.log('record', record);
                return (
                    <Input className={record.modified?.fuel_name ? 'fuel-class-custom-select custom-cell modified-cell' : 'custom-cell'}
                        value={text}
                        onChange={(e) => handleInputChange(e, record, 'fuel_name')} disabled={disabled}/>
                );
            }

        },
        {
            title: 'Fuel Class',
            dataIndex: 'class_name',
            key: 'class_name',
            width: 100,
            render: (text, record) => {
                // console.log('record.modified:', record.modified);
                return (
                    <Select disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.class_name ? 'fuel-class-custom-select modified-cell' : 'fuel-class-custom-select'}
                        value={text} onChange={(value) => handleSelectChange(value, record, 'class_name')}>
                        <Option value="Fossil">Fossil</Option>
                        <Option value="RFNBO">RFNBO</Option>
                        {!record?.fuel_name && !record?.fuel_id && <Option value="Biofuel">Biofuel</Option>}
                    </Select>
                )
            }
        },
        {
            title: 'Consumption(MT)',
            dataIndex: 'consumption',
            key: 'consumption',
            width: 150,
            render: (text, record) => <Input className={record.modified?.consumption ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'consumption')} disabled={disabled}/>
        },
        {
            title: 'LCV(MJ/MT)',
            dataIndex: 'fuel_lcv',
            key: 'fuel_lcv',
            width: 150,
            render: (text, record) => <Input disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.fuel_lcv ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'fuel_lcv')} />
        },
        {
            title: 'Bunker Quantity(MT)',
            dataIndex: 'bunker_quantity',
            key: 'bunker_quantity',
            width: 150,
            render: (text, record) => <Input disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.bunker_quantity ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'bunker_quantity')} />

        },
        {
            title: 'Bunker Survey(MT)',
            dataIndex: 'bunker_survey',
            key: 'bunker_survey',
            width: 150,
            render: (text, record) => <Input disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.bunker_survey ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'bunker_survey')} />
        },
        {
            title: 'RoB (MT)',
            dataIndex: 'rob',
            key: 'rob',
            width: 150,
            render: (text, record) => <Input className={record.modified?.rob ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'rob')} disabled={disabled}/>
        },
        {
            title: 'Cslip',
            dataIndex: 'fuel_cslip',
            key: 'fuel_cslip',
            width: 150,
            render: (text, record) => <Input className={record.modified?.fuel_cslip ? 'modified-cell' : ''} value={formatNumber(text)} disabled={disabled} onChange={(e) => handleInputChange(e, record, 'fuel_cslip')} />
        },
        {
            title: 'EF-TTW (MJfuel)',
            dataIndex: 'fuel_ef_ttw_MJfuel',
            key: 'fuel_ef_ttw_MJfuel',
            width: 150,
            render: (text, record) => <Input disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.fuel_ef_ttw_MJfuel ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'fuel_ef_ttw_MJfuel')} />
        },
        {
            title: 'EF-TTW (Gfuel)',
            dataIndex: 'fuel_ef_ttw_gfuel',
            key: 'fuel_ef_ttw_gfuel',
            width: 150,
            render: (text, record) => <Input disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.fuel_ef_ttw_gfuel ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'fuel_ef_ttw_gfuel')} />
        },
        {
            title: 'EF-WTT (MJfuel)',
            dataIndex: 'fuel_ef_wtt_MJfuel',
            key: 'fuel_ef_wtt_MJfuel',
            width: 150,
            render: (text, record) => <Input disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.fuel_ef_wtt_MJfuel ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'fuel_ef_wtt_MJfuel')} />
        },
        {
            title: 'EF-WTT (Gfuel)',
            dataIndex: 'fuel_ef_wtt_gfuel',
            key: 'fuel_ef_wtt_gfuel',
            width: 150,
            render: (text, record) => <Input disabled={record?.class_name === 'Biofuel' || disabled} className={record.modified?.fuel_ef_wtt_gfuel ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'fuel_ef_wtt_gfuel')} />
        },
        {
            title: 'RWDi',
            dataIndex: 'fuel_rwdi',
            key: 'fuel_rwdi',
            width: 50,
            render: (text, record) => <Input className={record.modified?.fuel_rwdi ? 'modified-cell' : ''} value={formatNumber(text)} onChange={(e) => handleInputChange(e, record, 'fuel_rwdi')} disabled={disabled}/>
        },
        {
            title: ' Edit ',
            key: 'settings',
            align: 'center',
            width: 50,
            render: (text, record) => {
                return record?.class_name === "Biofuel" ? <SettingOutlined onClick={() => { dispatch(getBlandedFuelInfo(record?.fuel_metadata_id)); setBioFuelData(record); setBioFuelModalVisibile(true); }} style={{ cursor: 'pointer' }} /> : <Input value='' disabled />;
            },
        },
    ];

    return (
        <Modal
            title="Edit Details"
            visible={visible}
            onCancel={handleClose}
            footer={!disabled && [
                <div className='fuel-edit-modal-footer'>
                    <div className='fuel-edit-modal-footer-remark'>
                        <Input
                            key="userRemark"
                            value={userRemark}
                            onChange={(e) => {
                                setUserRemark(e.target.value);
                                setRemarkError(false);
                            }}
                            required
                            placeholder="User Remark *"
                            className='user-remark-input'
                            style={{ width: '70%', marginRight: '10px' }}
                        // disabled={!isTableEdited}
                        />
                        {remarkError && <span key="remarkError" style={{ color: 'red', marginLeft: '10px' }}>Remark is required.</span>}
                    </div>
                    <div className='fuel-edit-modal-footer-buttons'>
                        <Button key="back" onClick={handleClose} icon={<ArrowLeftOutlined />} className='cancel-button-stepper'>
                            Close
                        </Button>
                        <Button key="submit" type="primary" onClick={handleApplyToThisRecord} className='stepper-next-button' disabled={!isTableEdited}>
                            Apply to this record
                        </Button>
                        <Button type="primary" onClick={handleApplyToAllRecord} className='stepper-next-button' disabled={isTableEdited}>
                            Apply to all record
                        </Button>
                    </div>
                </div>
            ]}
            className='fuel-edit-modal'
            style={{ top: 50 }}
        >
            <Table
                dataSource={editingData}
                columns={columns}
                rowKey="id"
                pagination={false}
                scroll={{ x: 2100 }}
                className='fuel-data-stepper-table'
                bordered
            />
            <ConflictingDataModal visible={isConflictingDataModalVisible} setIsModalVisible={setConflictingDataModalVisible} selectedUniqueId={selectedUniqueId} onClose={handleClose} />

            <BioFuelEditModal visible={bioFuelModalVisibile} setIsModalVisible={setBioFuelModalVisibile} selectedUniqueId={selectedUniqueId} bioFuelData={bioFuelData} />

        </Modal>
    );
};

export default FuelEditModal;
