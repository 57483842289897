import React from 'react';
import rightArrow from "../assets/icons/voyages/voyagearrowright.svg"
import { useState } from 'react';
import { Timeline } from 'antd';

import { ArrowRightOutlined } from '@ant-design/icons'

const Expand = ({ data, onSelect }) => {

  const [isActive, setIsActive] = useState(false);

  const toggleCollapsible = () => {
    setIsActive(!isActive);
    onSelect()
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    return formattedDate;
  };

  const Item = ({ text }) => {
    const formattedDate = formatDate(text.as_of_date);
    return (
      <div className='history-route-item'>
        <span className='vessel-data-data'>{text.to_port}</span>
        <span className='vessel-data-label' style={{ opacity: 1 }}>{formattedDate}</span>
      </div>
    )
  }

  const PulsatingGreenDot = () => (
    <div className="PulsatingGreenDot"></div>
  );
  return (
    <div className={`card collapsible`} style={{ padding: "0", borderRadius: "8px" }}
      onClick={toggleCollapsible}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "100%", display: "flex", padding: "18px 10px" }}>
          {/* <div className="expand-item1">
            <span className='expand-date-title'>Date-Date</span>
            <span className='expand-date'>{data.start_date}</span>
            <span className='expand-date-title'>End Date</span>
            <span className='expand-date'>{data.end_date}</span>
          </div> */}
          <div className="expand-item4">
            <div className='voyageBoxx'>
              {/* <span className='expand-date'>Voyage No.</span><br /> */}
              <span className='expand-date'>{data.voyage_number}</span>
            </div>

          </div>
          <div className="expand-item1">
            <span className='expand-place-title'>{data.from_port.charAt(0).toUpperCase() + data.from_port.slice(1).toLowerCase()}</span>
            <span className='expand-date'>({data.start_date})</span>
          </div>
          {/* <div className="expand-item3">
            <img src={rightArrow} alt="right-arrow" />
          </div> */}
          <div className={data.voyage_status === "completed" ? 'expand-item3' : 'voyage-arrow-colored expand-item3'}>
            <ArrowRightOutlined />
          </div>
          <div className="expand-item1">
            <span className='expand-place-title'>{data.to_port.charAt(0).toUpperCase() + data.to_port.slice(1).toLowerCase()}</span>
            <span className='expand-date'>({data.end_date})</span>
          </div>
        </div>
        <div className="expand-icon">
          <img src={rightArrow} alt="right-arrow" />
        </div>
      </div>
      <div
        className="content"
        style={{
          maxHeight: isActive ? '40vh' : '0',
          transition: 'max-height 0.4s ease-out',
        }}
      >
        <div style={{ padding: "30px", paddingBottom: "0px" }}>
          <Timeline
            items={data?.new_intermediate_ports.map((value, key) => {
              return {
                dot: (<PulsatingGreenDot />),
                children: <Item text={value} />
              }
            })}
          />
        </div>
      </div>
    </div>
  )
}
export default Expand;