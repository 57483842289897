import { Progress, Skeleton, Tag, Tabs } from "antd";
import refreshIcon from "../../assets/icons/progressbar/refresh.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getEuaOutstanding } from "../../features/EUA/EuaSlice";
import { useEffect, useState } from "react";
import { Modal, Button, Form } from 'antd';
import SmallTable from "../../components/SmallTable";
import AddTransaction from "../../components/AddTransaction";
import { useNavigate } from "react-router-dom";

const ProgessCard = () => {

  const dispatch = useDispatch()
  const [duesDetails, setDuesDetails] = useState("View")
  const { cummulativeInfo } = useSelector((state) => state.eua)
  const { user } = useSelector((state) => state.user)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { status, data } = cummulativeInfo;
  let progressData = [
    {
      label: "EUA in Account",
      value: "eua_in_account"
    },
    {
      label: "EUA Outstanding",
      value: "eua_outstanding"
    },
    {
      label: "Total EUA Required",
      value: "eua_required"
    },
    {
      label: "Ouststanding Cost",
      value: "outstanding_cost"
    }
  ]
  useEffect(() => {
    if (status === 'idle') {
      dispatch(getEuaOutstanding()).then((result) => {
      })
    }
  }, [dispatch, status])

  const renderTabs = () => {
    return (
      <div style={{ height: "100%" }}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"Large"}
          items={new Array(2).fill(null).map((_, i) => {
            const id = String(i + 1);
            if (i === 0)
              return {
                label: `Transactions`,
                key: id,
                children: <RenderTransactions columns={transactions} type={"transactions"} fields={transaction_fields} />,
              };
            else
              return {
                label: `Dues`,
                key: id,
                children: <RenderTransactions columns={dues} type={"dues"} fields={dues_fields} />,
              }
          })}
        />
      </div>
    )
  }


  const transactions = [
    {
      title: "Imo Number",
      dataIndex: "imo_number",
    },
    {
      title: "Vessel Name",
      dataIndex: "vessel_name",
    },
    {
      title: "Voyage Number",
      dataIndex: "voyage_number",
    },
    {
      title: "Trade Order Confirmation",
      dataIndex: "trace_order_confirmation",
    },
    {
      title: "Transaction Id",
      dataIndex: "txn_number",
    },
    {
      title: "EUA Volume",
      dataIndex: "eua_volume",
    },

  ];


  const dues = [
    {
      title: "Imo Number",
      dataIndex: "imo_number",
    },
    {
      title: "Voyage Number",
      dataIndex: "voyage_number",
    },
    {
      title: "EU Trading Account No.",
      dataIndex: "eua_holding_account_number",
    },
    {
      title: "Operator Holding Account No.",
      dataIndex: "operator_holding_account",
    },
    {
      title: "Reference Number",
      dataIndex: "reference_number",
    },
    {
      title: "eua",
      dataIndex: "eua",
    },
    {
      title: "Submission Date",
      dataIndex: "submission_date",
    },
  ]

  const transaction_fields = [
    {
      name: "imo_number",
      label: "IMO Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "voyage_number",
      label: "Voyage Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "trace_order_confirmation",
      label: "Trade Order Confirmation",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "txn_amt",
      label: "Transaction Amount",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "txn_number",
      label: "Transaction Id",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "eua_volume",
      label: "EUA Volume",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "txn_date",
      label: "Transaction Date",
      message: [{ required: true, message: `Please enter value` }]
    }
  ]

  const dues_fields = [
    {
      name: "imo_number",
      label: "IMO Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "voyage_number",
      label: "Voyage Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "eua_holding_account_number",
      label: "EUA Holding Account Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "operator_holding_account",
      label: "Operator Holding Account Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "reference_number",
      label: "Reference Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "eua",
      label: "EUA",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "submission_date",
      label: "Submission Date",
      message: [{ required: true, message: `Please enter value` }]
    },
  ]

  const navigate = useNavigate()

  const RenderTransactions = (props) => {
    switch (duesDetails) {
      case "View":
        return <SmallTable closeModal={handleOk}  {...props} />
      case "Add":
        return <AddTransaction closeModal={handleOk} {...props} />
      default:
        return ""
    }
  }


  if (data === null) {
    return (
      <div className="card eua-outstanding">
        <Skeleton active />
      </div>
    )
  }

  else {
    const percent = (data.eua_in_account / (data.eua_outstanding + data.eua_in_account)) * 100
    return (
      <div className="card eua-outstanding">
        <div className="progressHeader">
          <div className="progress-header-data">
            <span className="vessel-data-data">EUA Overview</span>
            <Tag className="vessel-spot-price-tag" color="#06C270">Spot Price €{data.spot_price}</Tag>
          </div>
          <div onClick={() => { navigate("/freemium") }}>
            <span className='stat-label euaEsti-button' style={{ cursor: "pointer" }}>EUA Estimation</span>
          </div>
          <Modal title={duesDetails} width={"60%"} footer={[]} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            {renderTabs()}
          </Modal>
        </div>
        <div className="progressBar">
          <div style={{ display: "flex", alignItems: 'center' }}>
            <span className="vessel-data-label" style={{ display: "block", width: `${percent}%`, overflow: "hidden", opacity: "0.8" }}>In Account</span>
            <span className="vessel-data-label" style={{ display: "block", width: `${100 - percent}%`, opacity: "0.8" }}>Outstanding</span>
          </div>
          <Progress
            showInfo={false}
            strokeColor="#55D99F"
            trailColor="#FFAAAA"
            stroke="pink"
            strokeWidth={40}
            percent={percent}
          />
          <span className="vessel-data-label" style={{ display: "block", width: "100%", opacity: "0.8" }}>Total</span>
        </div>
        <div className="progressData">
          {progressData.map((value, key) => {
            return (
              <div className="progressData-item">
                <span className="vessel-data-label">{value.label}</span>
                <span className="vessel-data-data" style={{ fontWeight: "600" }}>{value.label === "Ouststanding Cost" ? "€" : ""} {data[value.value]}</span>
              </div>
            )
          })}
        </div>
      </div>
    );
  };
}

export default ProgessCard;
