import React, { useEffect, useState } from 'react';
import VirtualList from 'rc-virtual-list';
import { Tabs, List, Modal, Button } from 'antd';
import SmallTable from './SmallTable';
import AddTransaction from './AddTransaction';
import '../css/notifications.css';
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotification, updateNotifications } from '../features/Notification/NotificationSlice';
import { getEUPortsEnteringVessels } from '../features/EUA/EuaSlice';
import EUPortEnterTable from './EUPortEnterTable';

const ContainerHeight = "25vh";
const ListComponent = ({ category }) => {
  const [data, setData] = useState([]);
  const [duesDetails, setDuesDetails] = useState("View")
  const { user } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const appendData = () => {
    const params = {
      category: category
    }
    dispatch(fetchNotification(params))
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEUModalOpen, setIsEUModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEUModal = () => {
    setIsEUModalOpen(true);
  };
  const handleEUOk = () => {
    setIsEUModalOpen(false);
  };
  const handleEUCancel = () => {
    setIsEUModalOpen(false);
  };

  const transaction_fields = [
    {
      name: "imo_number",
      label: "IMO Number",
      placeholder: "IMO Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "voyage_number",
      label: "Voyage Number",
      placeholder: "Voyage Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "trace_order_confirmation",
      label: "Trade Order Confirmation",
      placeholder: "Trade Order Confirmation",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "txn_amt",
      label: "Transaction Amount",
      placeholder: "Transaction Amount",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "txn_number",
      label: "Transaction Id",
      placeholder: "Transaction Id",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "eua_volume",
      label: "EUA Volume",
      placeholder: "EUA Volume",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "txn_date",
      label: "Transaction Date",
      placeholder: "Transaction Date",
      message: [{ required: true, message: `Please enter value` }]
    }
  ]

  const dues_fields = [
    {
      name: "imo_number",
      label: "IMO Number",
      placeholder: "IMO Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "voyage_number",
      label: "Voyage Number",
      placeholder: "Voyage Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "eua_holding_account_number",
      label: "EU Trading Account No.",
      placeholder: "EU Trading Account",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "operator_holding_account",
      label: "Operator Holding Account Number",
      placeholder: "Operator Holding Account Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "reference_number",
      label: "Reference Number",
      placeholder: "Reference Number",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "eua",
      label: "EUA",
      placeholder: "EUA",
      message: [{ required: true, message: `Please enter value` }, { type: 'string', message: "Only numeric value allowed" }]
    },
    {
      name: "submission_date",
      label: "Submission Date",
      placeholder: "Submission Date",
      message: [{ required: true, message: `Please enter value` }]
    },
  ]

  useEffect(() => {
    const params = {
      category: category,
      page: 1,
      keyword: ''
    }
    dispatch(fetchNotification(params)).then((result) => {
      if (result.payload) {
        setData(result.payload)
        // console.log(result.payload)
      }
    })

    // console.log(data)
  }, [dispatch, category])

  // console.log(data)

  const onScroll = (e) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop === ContainerHeight) {
      appendData();
    }
  };

  const timeAgo = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) {
      return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    } else if (seconds < 3600) {
      const minutes = Math.floor(seconds / 60);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  }

  const RenderTransactions = (props) => {
    switch (duesDetails) {
      case "View":
        return <SmallTable closeModal={handleOk}  {...props} />
      case "Add":
        return <AddTransaction closeModal={handleOk} {...props} />
      default:
        return ""
    }
  }

  useEffect(() => {
    dispatch(getEUPortsEnteringVessels(1))
  }, [])

  const RenderEUEnterPorts = () => {
    return <EUPortEnterTable closeModal={handleEUOk} />
  }


  const transactions = [
    {
      title: "IMO Number",
      dataIndex: "imo_number",
    },
    {
      title: "Vessel Name",
      dataIndex: "vessel_name",
    },
    {
      title: "Voyage Number",
      dataIndex: "voyage_number",
    },
    {
      title: "Trade Order Confirmation",
      dataIndex: "trace_order_confirmation",
    },
    {
      title: "Transaction Id",
      dataIndex: "txn_number",
    },
    {
      title: "EUA Volume",
      dataIndex: "eua_volume",
    },

  ];


  const dues = [
    {
      title: "IMO Number",
      dataIndex: "imo_number",
    },
    {
      title: "Voyage Number",
      dataIndex: "voyage_number",
    },
    {
      title: "EU Trading Account No.",
      dataIndex: "eua_holding_account_number",
    },
    {
      title: "Operator Holding Account No.",
      dataIndex: "operator_holding_account",
    },
    {
      title: "Reference Number",
      dataIndex: "reference_number",
    },
    {
      title: "EUA",
      dataIndex: "eua",
    },
    {
      title: "Submission Date",
      dataIndex: "submission_date",
    },
  ]

  const renderTabs = () => {
    return (
      <div style={{ height: "100%" }}>
        <Tabs
          defaultActiveKey="1"
          type="card"
          size={"Large"}
          items={new Array(2).fill(null).map((_, i) => {
            const id = String(i + 1);
            if (i === 0)
              return {
                label: `Transactions`,
                key: id,
                children: <RenderTransactions columns={transactions} type={"transactions"} fields={transaction_fields} />,
              };
            else
              return {
                label: `Dues`,
                key: id,
                children: <RenderTransactions columns={dues} type={"dues"} fields={dues_fields} />,
              }
          })}
        />
      </div>
    )
  }

  const handleReadNotification = (updateType, updateID) => {
    const params = {
      update_type: updateType,
      id: updateID
    }
    dispatch(updateNotifications(params)).then((result) => {
      category = category;
    })
  }

  return (
    <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
      {user?.role === "admin" && category === "order" ?
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Button onClick={() => { setDuesDetails("Add"); showModal() }}>Add</Button>
          <Button onClick={() => { setDuesDetails("View"); showModal() }}>View</Button>
          {/* <img src={refreshIcon} alt="" /> */}
        </div> : ""}
      <Modal title={duesDetails} width={"60%"} footer={[]} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        {renderTabs()}
      </Modal>

      <Modal
        title="EU Ports Entering Vessels"
        width={"60%"}
        footer={[]}
        open={isEUModalOpen}
        onCancel={handleEUCancel}
      >
        {RenderEUEnterPorts()}
      </Modal>
      {/* <div>
        {
          category === "order" ? "" : <Button onClick={() => { showEUModal() }}>View</Button>
        }

      </div> */}

      {category === "voyage"
        ?
        <EUPortEnterTable downloadButtonVisible={false} />
        :
        data.length == 0 ?
          <List>
          </List>
          :
          <>
            <List>
              <VirtualList
                data={data}
                height={ContainerHeight}
                itemHeight={47}
                itemKey="email"
                onScroll={onScroll}
              >

                {(item) => (
                  <List.Item key={item.email}>
                    <List.Item.Meta
                      title={<><span className={item.date_read ? "greenCircle" : "circle"}>&nbsp;&nbsp;&nbsp; </span><a>&nbsp; &nbsp;{item.title}{item.content}</a></>}
                      description={<>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {timeAgo(item.date_created)}</>}
                    />

                    {item.date_read ?
                      '' : <div onClick={() => handleReadNotification('read', item.id)} style={{ color: "rgba(0, 0, 0, 0.45)", cursor: "pointer" }}>Mark read</div>}

                  </List.Item>
                )}
              </VirtualList>
            </List>
          </>

      }

      {/* {category === "order" ?
        ''
        : <div className='linkNotification'><p>For all notifications <a onClick={() => navigate('/notifications')}>Click Here</a></p></div>}     */}

    </div>
  );
};
export default ListComponent;