import React, { useEffect, useState } from 'react'
import { fetchFreemiumReportData } from '../features/Voyage/VoyageSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from 'antd'

const FreemiumTable = ({ columns }) => {

    const dispatch = useDispatch()
    const { freemiumReportData, state } = useSelector((state) => state.voyage)
    const [formattedData, setFormattedData] = useState([])
    useEffect(() => {

        dispatch(fetchFreemiumReportData())

    }, [dispatch])
    useEffect(() => {
        if (freemiumReportData && freemiumReportData.length > 0) {
            const formattedData = freemiumReportData.map(item => ({
                ...item,
                date_created: formatDate(item.date_created)
            }));
            setFormattedData(formattedData);
        }
    }, [freemiumReportData])

    // console.log(freemiumReportData)

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const formattedDate = `${date.getFullYear()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        return formattedDate;
    };

    // console.log(dataSource)

    // Map over freemiumReportData and format date_created

    return (
        <div>
            <Table
                dataSource={formattedData}
                columns={columns}
                rowClassName="editable-row"
                scroll={{ y: "40vh" }}
                pagination={false}
                responsive
            />
        </div>
    )
}

export default FreemiumTable