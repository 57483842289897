import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uploadDocument } from '../../features/Voyage/VoyageSlice'
import { fetchVoyageEuaOverview } from '../../features/Voyage/VoyageSlice'
const DownloadDocument = ({ label, icon, type }) => {

    const { user } = useSelector((state) => state.user)
    const { selectedVoyage, downloadDocument } = useSelector((state) => state.voyage)
    const dispatch = useDispatch()
    const handleUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const formData = new FormData()
            formData.append('statement_file', file);
            formData.append('file_name', file.name);
            formData.append('imo_number', selectedVoyage?.imo_number);
            formData.append('voyage_number', selectedVoyage?.voyage_number);
            dispatch(uploadDocument(formData)).then((result) => {
                if (result.payload === "successfully uploaded") {
                    dispatch(fetchVoyageEuaOverview(selectedVoyage))
                }
            })
        }
    }
    const handleDownload = () => {
        const anchor = document.createElement('a');
        anchor.href = downloadDocument;
        anchor.download = 'document.pdf';
        anchor.click();
    };

    if (type === "Upload" && user.role !== "admin") {
        return null
    }

    if (type === "Download") {
        return (
            <div onClick={handleDownload}>
                <img src={icon} style={{ height: "20px", cursor: "pointer" }} alt="" />
                <span className='card-icon-span'>{label}</span>
            </div>
        )
    }

    else {
        return (
            <div>
                <label for="file">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <img src={icon} style={{ height: "20px", cursor: "pointer" }} alt="" />
                        <span className='card-icon-span'>{label}</span>
                    </div>
                    <input type="file" id="file" onChange={handleUpload} style={{ display: "none" }} name="image" multiple="" data-original-title="upload photos" />
                </label>
            </div>
        )
    }

    // else {
    //     return (
    //         <div>
    //             <img src={icon} style={{ height: "20px", cursor: "pointer" }} alt="" />
    //             <span className='card-icon-span'>{label}</span>
    //         </div>
    //     )

    // }
}

export default DownloadDocument