import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import VoyageMatrixTable from './VoyageMatrixTable';
import EditIcon from '../../assets/icons/voyages/edit.svg';
import backButton from '../../assets/icons/voyages/backButton.webp';
import CIIDetailsInput from './CIIDetailsInput';

import { useNavigate } from "react-router";
import { Spin } from 'antd';
import EUIdentifierData from './EUIdentifierData';
import TableDataStaticInfo from './TableDataStaticInfo';

const MatrixTables = () => {

    const { voyageCiiData, ciiStatus } = useSelector(state => state.voyageOptimization)
    const navigate = useNavigate()
    const [detailsComponent, setDetailsComponent] = useState(false);
    const [chartData, setChartData] = useState();
    const [showCIIModal, setShowCIIModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const selectedVessels = voyageCiiData.map((data, index) => ({
        imo_number: data.imo_number,
        vessel_name: data.vessel_name
    }));

    console.log(ciiStatus)
    useEffect(() => {
        if (ciiStatus === "succeeded") {
            setDetailsComponent(true);
            setLoading(false);
        }
        if (ciiStatus === "loading") {
            setDetailsComponent(false);
            setLoading(true);
        }
    }, [ciiStatus]);

    // console.log(voyageCiiData.length)

    return (
        <Spin spinning={loading}>
            <div className='matrixTableContainer'>

                <div className='CII_Edit_Container'>
                    <h3>CII Matrix Table: </h3>


                    <img style={{ right: '60px' }} onClick={() => {
                        navigate("/voyage-optimization")
                    }} src={backButton} alt="Back" />
                    <img onClick={() => {
                        setShowCIIModal(true)
                    }} src={EditIcon} alt="Edit" />
                </div>



                {detailsComponent && (
                    <div className='matrixDataMainParent'>
                        <EUIdentifierData />
                        <TableDataStaticInfo />

                        <div className='matrixDataMain'>
                            {voyageCiiData.map((data, index) => (
                                <div className='matrixTabless'>
                                    <h4 style={{ marginBottom: "2px" }}>Vessel Name: {data.vessel_name}<br />IMO Number: {data.imo_number}</h4>
                                    <VoyageMatrixTable index={index} voyageMatrixData={data} />

                                </div>
                            ))}
                        </div>
                    </div>
                )}


                <CIIDetailsInput
                    selectedVessels={selectedVessels}
                    showCIIModal={showCIIModal}
                    setShowCIIModal={setShowCIIModal}
                    isEditFieldsOn={true}
                />


            </div>
        </Spin>
    )
}

export default MatrixTables