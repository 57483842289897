import { useNavigate } from "react-router-dom";

function MainDashboard() {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login");
  };

  return (
    <div>
      <h1>Main Dashboard</h1>
      <button onClick={handleLoginRedirect}>Go to Login</button>
    </div>
  );
}

export default MainDashboard;
