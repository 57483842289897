import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { CloseOutlined, LockOutlined } from '@ant-design/icons';
import Results from '../../../components/Modals/Results'; // Adjust the path as per your project structure
import { DatePicker, Modal, Checkbox, Spin, message } from 'antd'
import { Button } from "antd";
import { ReactComponent as PlayOutlineIcon } from '../../../assets/icons/PlayOutlineIcon.svg';
import biCalculator from '../../../assets/icons/rawdata/bi_calculator.png';
import { getCalculationsResults, fetchRawData, lockCasperId } from '../../../features/RawData/RawDataSlice';

const { RangePicker } = DatePicker;

const DataRecordsHeader = ({  resetSelection, onAssignCasperID, selectedCasperIdInFilters, handleCheckAll }) => {
  // console.log(selectedCasperIdInFilters)
  const dispatch = useDispatch()
  const { globalFilters, cardUniqueIDDetails, selectedRecordsPerPageUniqueIds, rawData } = useSelector((state) => state.rawData);
  const selectedCount = Object.values(selectedRecordsPerPageUniqueIds)
  .reduce((sum, currentArray) => sum + currentArray.length, 0)
  const [loading, setLoading] = useState(false);
  const [customDate, setCustomDate] = useState(null);
  const [resultsVisible, setResultsVisible] = useState(false);
  const [lockModalVisible, setLockModalVisible] = useState(false); 
  const firstKey = Object.keys(rawData)[0]
  // Initialize customDate based on globalFilters
  useEffect(() => {
    if (globalFilters.start_date && globalFilters.end_date) {
      setCustomDate([dayjs(globalFilters.start_date), dayjs(globalFilters.end_date)]);
    } else {
      setCustomDate(null);
    }
  }, [globalFilters.start_date, globalFilters.end_date]);
  const dateChangeHandler = (dates) => {

    if (!dates) {
      setCustomDate('');
      dispatch(fetchRawData({ ...globalFilters, start_date: '', end_date: '', pageNumber: 1, requestedData: "VOYAGE_DATA", uniqueID: '' }));
    }
    if (dates && dates.length === 2) {
      setCustomDate(dates);
      const startDate = dayjs(dates[0]).format('YYYY-MM-DD');
      const endDate = dayjs(dates[1]).format('YYYY-MM-DD');

      dispatch(fetchRawData({ ...globalFilters, start_date: startDate, end_date: endDate, pageNumber: 1, requestedData: "VOYAGE_DATA", uniqueID: ''  }));
    }
  };

  const simulateHandler = () => {
    dispatch(getCalculationsResults(selectedCasperIdInFilters));
    setResultsVisible(true);
  };

  const closeModal = () => {
    setResultsVisible(false);
  };

  const handleLock = async () => {
    const params = {
      casper_id : selectedCasperIdInFilters,
      imo_number : rawData?.[firstKey]?.[0]?.imo_number_id
    }
    setLoading(true); // Show loading spinner
    try {
      await dispatch(lockCasperId(params)).unwrap(); 
      message.success('Successfully locked the Casper ID!');
      dispatch(fetchRawData(globalFilters));
    } catch (error) {
      message.error('Failed to lock the Casper ID.'); 
    } finally {
      setLoading(false); 
      setLockModalVisible(false); 
    }
  };


  const showLockModal = () => {
    setLockModalVisible(true);
  };

  const closeLockModal = () => {
    setLockModalVisible(false);
  };


  return (
    <div className="vessel-card-filter">
      <div style={{ width: "40%" }}>
        
        <span className='Sattlement-Title'>Data Records</span>
        <p style={{ marginLeft: 2, marginTop:5 }} className='rawData-count'>{cardUniqueIDDetails?.length} Records Found</p>
        <Checkbox checked={cardUniqueIDDetails?.length > 0 && selectedRecordsPerPageUniqueIds?.[globalFilters?.pageNumber]?.length === cardUniqueIDDetails?.length} style={{ marginRight: 10 , marginTop:2}} onChange={handleCheckAll}>Select all</Checkbox>

      </div>

      <div className='rawData-buttons'>
        {selectedCount > 0 && <p className='rawData-selected-count'>{selectedCount} records selected <CloseOutlined onClick={resetSelection} /></p>}
        <RangePicker format="YYYY-MM-DD" value={customDate} style={{ width: 250 }} onChange={dateChangeHandler} />
        <Button className='rawData-button' type="primary" style={{ backgroundColor: selectedCount > 0 ? '#678DFF' : '#C3C3C3', borderColor: selectedCount > 0 ? '#678DFF' : '#C3C3C3' }}
          disabled={selectedCount === 0} onClick={onAssignCasperID}>Assign Casper ID</Button>
        {selectedCasperIdInFilters && <>

          <Button
            onClick={() => simulateHandler()}
            style={{ display: 'flex', alignItems: 'center', color: "#005BF3", background: "#005BF326", borderRadius: '25px' }}
            icon={<img src={biCalculator} alt="biCalculator" style={{ width: '16px', height: '16px' }} />}
          >
            Results
          </Button>
          {/* <Button onClick={() => simulateHandler()} style={{ display: 'flex', alignItems: 'center' }} icon={<PlayOutlineIcon style={{ width: '16px', height: '16px' }} />}>Run Calculation</Button> */}
          <Button disabled={rawData?.[firstKey]?.[0]?.is_locked_in} icon={<LockOutlined />}  onClick={showLockModal}>Lock</Button>
        </>
        }
      </div>

      <Results visible={resultsVisible} onClose={closeModal} />

      {/* Lock Confirmation Modal */}
      <Modal
        title="Lock Casper ID"
        visible={lockModalVisible}
        onOk={handleLock}
        onCancel={closeLockModal}
        okText="Yes"
        cancelText="Cancel"
      >
        <p>Are you sure you want to lock this Casper ID? If you proceed further, you won't be able to make more edits.<br/>
        Click Yes to continue.</p>
      </Modal>
    </div>
  );
};

export default DataRecordsHeader;
