import React from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { ConfigProvider } from "antd";
import { useState } from "react";
import { loginUser, profile } from "../../features/Login/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
const LoginContainer = ({ setLogin }) => {

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch()

  const { loading, error } = useSelector((state) => state.user)

  const onFinish = (values) => {
    const userCredentials = values
    dispatch(loginUser(userCredentials)).then((result) => {
      if (result.payload) {
        dispatch(profile())
      }
      else {
        console.error("Login Failed")
      }
    })
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#000",
            defaultBg: "#000",
            colorPrimaryHover: "#000"
          },
        },
      }}
    >
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        size="large"
        layout="vertical"
        style={{
          maxWidth: 400,
        }}
      >
        <Form.Item
          name="username"
          label="Username / e-mail"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            value={email}
            onChange={(e) => { setEmail(e.target.value) }}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Password"
            value={password}
            onChange={(e) => { setPassword(e.target.value) }}
            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
          >
            {loading ? "Loading..." : "Log in"}
          </Button>
          {error != null ? error : ""}
          Don’t have an account? <a href="/signup">Sign up </a>
        </Form.Item>
      </Form>
    </ConfigProvider>
  );
};

export default LoginContainer;
