import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VesselPage from './vesselPage';  // Ensure this file exists
import RawDataFilters from '../../components/RawDataFilters';

const VesselAnalysis = () => {
  
  // const {  globalFilters } = useSelector(state => state.rawData);
  const { vesselAnalysisFilters } = useSelector((state) => state.vesselsAnalysis);
  const [iMONumber, setIMONumber] = useState(vesselAnalysisFilters?.imoNumber || "");
  const [voyageYear, setVoyageYear] = useState("2024"); // Default year
  const [selectedCasperIdInFilters, setSelectedCasperIdInFilters] = useState('')
  const [selectedCardsWithEssentialData, setSelectedCardsWithEssentialData] = useState('')

  return (
    <div className='raw-data-page'>
      <div className='vessel-analysis-page'>
        <RawDataFilters type={"Vessal Analysis"} setSelectedCasperIdInFilters={setSelectedCasperIdInFilters} iMONumber={iMONumber} setIMONumber={setIMONumber} voyageYear={voyageYear} setVoyageYear={setVoyageYear} setSelectedCardsWithEssentialData={setSelectedCardsWithEssentialData} />
      </div>
      <div className='vessel-analysis-page2'>
        <VesselPage />
      </div>
    </div>
  );
}

export default VesselAnalysis;
