import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { GoogleMap, Marker, LoadScript, Polyline, Circle } from '@react-google-maps/api';
import { Skeleton } from 'antd';
import FuelTable from './FuelConsuption';
import { fetchGoogleMapDetails } from '../../features/Vessels/VesselSlice';
import { useDispatch } from 'react-redux';

export const dashSpace = (item, containerWidth) => {
  const labelWidth = item.length * 12;
  // const containerWidth = 400;

  const remainingSpace = containerWidth - labelWidth;
  const dashWidth = 18;

  let dashedString = "";
  let totalDashesWidth = 0;

  while (totalDashesWidth + dashWidth <= remainingSpace) {
    dashedString += " - ";
    totalDashesWidth += dashWidth;
  }

  return item + dashedString;
}
const VesselOverview = () => {
  const google = window.google;
  const dispatch = useDispatch()

  const selectedVessel = useSelector((state) => state.vessel.selectedVessel)
  const allVessels = useSelector((state) => state.vessel.vessels)
  const [vesselData, setVesselData] = useState([])
  const [fuelData, setFuelData] = useState([])
  const [mapDetails, setMapDetails] = useState([])
  const [path, setPath] = useState([]);

  const [mapCenter, setMapCenter] = useState({ lat: 27.964157, lng: -82.452606 });

  const [zoomLevel, setZoomLevel] = useState(3);
  const mapRef = useRef(null);

  const handleZoomChanged = () => {
    // Get the current zoom level from the map
    if (mapRef.current) {
      const newZoomLevel = mapRef.current.getZoom();

      // Update the state or a variable with the new zoom level
      setZoomLevel(newZoomLevel);
    }
  };
  useEffect(() => {

    if (selectedVessel === null) {
      return
    }
    const items = [
      {
        key: '1',
        label: 'Name',
        children: selectedVessel.vessel_name,
      },
      {
        key: '2',
        label: 'Registered owner',
        children: selectedVessel.active_owner,
      },
      {
        key: '3',
        label: 'Fo Consumption Load:',
        children: selectedVessel.consumption
      },
      {
        key: '4',
        label: 'Type*',
        children: selectedVessel.vessel_type,
      },
      {
        key: '5',
        label: 'Flag',
        children: selectedVessel.registration_flag,
      },
      {
        key: '6',
        label: 'Deadweight',
        children: selectedVessel.deadweight + " mt",
      },
      {
        key: "7",
        label: "Last Sync Date",
        children: selectedVessel?.max_as_of_date_voyage_data
      }
    ];

    const items2 = [
      {
        key: '1',
        label: 'LSMGO',
        children: selectedVessel.lsmgo,
      },
      {
        key: '2',
        label: 'HSFO',
        children: selectedVessel.hsfo,
      },
      {
        key: '3',
        label: 'MGO',
        children: selectedVessel.mdo,
      },
    ]

    setZoomLevel(3);

    const params = {
      voyage_number: getVoyageNumber(selectedVessel.imo_number),
      imo_number: selectedVessel.imo_number
    }
    dispatch(fetchGoogleMapDetails(params)).then((result) => {

      if (result.payload) {
        setMapDetails(result.payload.coordinates)
        if (result.payload.coordinates && result.payload.coordinates.length > 0) {
          const coordinates = result.payload.coordinates.map(coord => ({
            lat: parseFloat(coord.lat),
            lng: parseFloat(coord.lng)
          }));
          setPath(coordinates);

          const midPoint = coordinates.reduce(
            (acc, cur) => {
              acc.lat += cur.lat;
              acc.lng += cur.lng;
              return acc;
            },
            { lat: 0, lng: 0 }
          );

          const numPoints = coordinates.length;
          setMapCenter({
            lat: midPoint.lat / numPoints,
            lng: midPoint.lng / numPoints
          });
        }
        // console.log(result.payload.coordinates)
      }
    })

    // console.log(path)
    setVesselData(items)
    setFuelData(items2)
  }, [selectedVessel])

  function getVoyageNumber(imoNumber) {
    const vessel = allVessels.find(vessel => vessel.imo_number === imoNumber);

    if (vessel) {
      return vessel.voyage_number;
    } else {
      return null;
    }
  }

  if (selectedVessel === null) {
    return (
      <div className="card vesselOverview">
        <div style={{ height: "50vh" }}>
          <Skeleton active />
        </div>
        <div className="card" style={{ height: "100%" }}>
          <Skeleton active />
        </div>
        <div className="card" style={{ height: "100%" }}>
          <Skeleton active />
        </div>
      </div>
    )
  }
  else
    return (
      <div className="card vesselOverview">
        <div className="card" style={{ height: "40vh", padding: '2%', boxShadow: 'none' }}>

          <LoadScript googleMapsApiKey="AIzaSyDTg790iZlfKpDScxDQ9FMxSG0oPH8Oe8M">
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '10px' }}
              zoom={zoomLevel}
              center={mapCenter}
              onZoomChanged={handleZoomChanged}
              options={{
                restriction:{
                  strictBounds:true
                },
                minZoom:3
              }}
              onLoad={(map) => {
                // Store the map instance in the ref
                mapRef.current = map;
              }}
            >


              {path.length > 0 && (
                <>
                  <Circle
                    center={path[0]}
                    radius={100000 / Math.pow(2, zoomLevel - 3)}
                    options={{
                      fillColor: 'black',
                      fillOpacity: 1,
                      strokeColor: 'black',
                      strokeOpacity: 1,
                      strokeWeight: 1,
                    }}
                  />

                  <Circle
                    center={path[path.length - 1]}
                    radius={100000 / Math.pow(2, zoomLevel - 3)}
                    options={{
                      fillColor: 'blue',
                      fillOpacity: 1,
                      strokeColor: 'blue',
                      strokeOpacity: 1,
                      strokeWeight: 1,
                    }}
                  />
                  <Polyline
                    path={path}
                    options={{
                      strokeColor: 'black',
                      strokeOpacity: 1.0,
                      strokeWeight: 3
                    }}
                  />
                </>
              )}
            </GoogleMap>
          </LoadScript>
        </div>

        <div style={{ fontSize: '20px', fontWeight: '500', paddingLeft: '16px' }}>
          <span>Vessel Overview</span>
        </div>
        <div className="cardSubHeading" >
          <span>Vessel Information</span>
        </div>
        <div className="card" style={{ padding: "16px" }}>

          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", gap: '5px' }}>
            {vesselData.map((item, index) => {
              return (
                <div style={{ display: "flex" }}>
                  <span style={{ fontSize: "12px", opacity: 0.4, width: "200px", fontWeight: "500" }}>{dashSpace(item.label, 400)}</span>
                  <span style={{ fontSize: "14px", fontWeight: "500" }}>{item.children}</span>
                </div>
              )
            })}
          </div>
        </div>
        <div className="cardSubHeading" >
          <span>Fuel Consumption</span>
        </div>
        <div className="card" style={{ padding: "16px" }}>

          {fuelData.length === 0 ? "" :
            <FuelTable />
          }
        </div>
      </div>
    )
}

export default VesselOverview