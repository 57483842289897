import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import RawDataFilters from '../../components/RawDataFilters'
import DataRecords from '../../components/DataRecords'
import IceClass from '../../components/Modals/IceClass'
import "../../css/casperanalysis.css"
import { DataContainer } from '../../container/CasperAnalysis/DataContainer'
import PastRecords from '../../components/PastRecords/PastRecords'

const CasperAnalysisPage = () => {
  const { shipOwners, shipOwnersStatus, pastRecords, globalFilters } = useSelector(state => state.rawData);
  const [modal, setModal] = useState(false)
  const [iMONumber, setIMONumber] = useState(globalFilters?.imoNumber || "");
  const [voyageYear, setVoyageYear] = useState("2024"); // Default year
  const [selectedCasperIdInFilters, setSelectedCasperIdInFilters] = useState('')

  const [selectedUniqueIdAuditTrace, setSelectedUniqueIdAuditTrace] = useState('');
  const [selectedCasperIdAuditTrace, setSelectedCasperIdAuditTrace] = useState('');
  return (
    <div className='casper-analysis-page'>
      {!pastRecords ? <> <div className='casper-analysis-header'>
        <div className='casper-analysis-filter'>
          <RawDataFilters
            type={"Casper Analysis"}
            setSelectedCasperIdInFilters={setSelectedCasperIdInFilters}
            iMONumber={iMONumber}
            setIMONumber={setIMONumber}
            voyageYear={voyageYear}
            setVoyageYear={setVoyageYear}
          />

        </div>
        <div className="casper-analysis-data">
          <DataContainer
            iMONumber={iMONumber}
            voyageYear={voyageYear}
          />
          {/* header card component on header */}

        </div>
      </div>
        <div className='casper-analysis-body'>
          <DataRecords setModal={setModal} setSelectedCasperIdAuditTrace={setSelectedCasperIdAuditTrace} />
        </div>
      </>
        :
        <PastRecords selectedUniqueIdAuditTrace={selectedUniqueIdAuditTrace} setSelectedUniqueIdAuditTrace={setSelectedUniqueIdAuditTrace} selectedCasperIdAuditTrace={selectedCasperIdAuditTrace} />
      }


      <IceClass modal={modal} setModal={setModal} />

    </div >
  )
}

export default CasperAnalysisPage

