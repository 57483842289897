import React from "react";
import TabComponent from "../../components/Tab";
const Notification = () => {
  return (
    <div className="notificationCard">
      <TabComponent />
    </div>
  );
};

export default Notification;
