import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { makeRequest } from "../../api/api";
import { hostname } from "../../data";


export const loginUser = createAsyncThunk(
    'user/loginUser',
    async (userCredentials) => {
        try {
            const response = await axios.post(`${hostname}login/`, userCredentials);
            const token = response.data.access;
            return { user: "Azolla", token };
        } catch (error) {
            throw error;
        }
    }
);

export const profile = createAsyncThunk(
    'user/profile',
    async () => {
        try {
            const token = localStorage.getItem("Authorization");
            if (!token) {
                throw new Error('Token not found');
            }
            const response = await makeRequest("get", `${hostname}api/user_profile/`);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);
export const signupUser = createAsyncThunk(
    'signup',
    async (userCredentials) => {
        try {
            const response = await makeRequest("post", `${hostname}auth/create_user/`, userCredentials);
            return response.data;
        } catch (error) {
            throw error;
        }
    }
);



const userSlice = createSlice({
    name: "user",
    initialState: {
        loading: false,
        user: null,
        error: null,
        login: false,
    },
    reducers: {
        restoreLogin: (state) => {
            const storedToken = localStorage.getItem("Authorization");
            const user = localStorage.getItem("user")
            if (storedToken && user) {
                state.login = true;
                state.user = JSON.parse(user)
            }
            else {
                state.login = false
                state.user = null
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.pending, (state) => {
            state.loading = true;
            state.user = null;
            state.error = null;
        })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = null
                state.error = null;
                state.login = true;
                localStorage.setItem("Authorization", `Bearer ${action.payload.token}`);
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = false;
                state.user = null;
                state.login = false;
                if (action.error.message === "Request failed with status code 401") {
                    state.error = "Access Denied! Invalid Credentials";
                } else {
                    state.error = action.error.message;
                }
            })
            .addCase(profile.fulfilled, (state,action) => {
                state.user=action.payload.data
                console.log(action.payload.data)
                localStorage.setItem("user", JSON.stringify(action.payload.data));
            })
            .addCase(profile.rejected, (state,action) => {
                state.user="User not found";
                localStorage.removeItem("user");
            });
    }
});

export const { restoreLogin } = userSlice.actions;

export default userSlice.reducer;


// export const loginUser= createAsyncThunk(
//     'user/loginUser',
//     async(userCredentials)=>{
//         const request = await axios({
//             url: `${hostname}api/login/`,
//             method:'POST',
//             data: userCredentials
//         })
//         const response=await request.data.data
//         localStorage.setItem("Authorization",`Bearer ${JSON.stringify(response.token)}`)
//         return response
//     }
// )

// const userSlice = createSlice({
//     name:"user",
//     initialState:{
//         loading:false,
//         user: null,
//         error:null,
//         login:false,
//     },
//     extraReducers: (builder)=>{
//         builder.addCase(loginUser.pending,(state)=>{
//             state.loading=true;
//             state.user=null;
//             state.error=null;
//         })
//         .addCase(loginUser.fulfilled,(state,action)=>{
//             state.loading=false;
//             state.user=action.payload;
//             state.error=null;
//         })
//         .addCase(loginUser.rejected,(state,action)=>{
//             state.loading=false;
//             state.user=null;
//             if(action.error.message==="Request failed with status code 401")
//             {
//                 state.error="Access Denied! Invalid Credentials"
//             }
//             else{
//             state.error=action.error.message;
//             }
//         })
//     }
// })

// export default userSlice.reducer;