import React, { useState } from 'react'
import { Button, Form, Select, Input, message } from 'antd'
import { useDispatch } from 'react-redux'
import { submitDues, submitTransactions } from '../features/Transaction/TransactionSlice'
import { hostname } from '../data'
import axios from 'axios'
import qs from "qs"

let timeout;
let currentValue;
const fetch = (value, callback, query) => {
    if (timeout) {
        clearTimeout(timeout);
        timeout = null;
    }
    currentValue = value;
    const fake = () => {
        const str = qs.stringify({
            code: 'utf-8',
            q: value,
        });
        const url = hostname + query
        axios({
            url: url,
            method: "get",
            headers: {
                "Authorization": localStorage.getItem("Authorization")
            }
        })
            .then((response) => {
                if (currentValue === value) {
                    const result = response.data.data;
                    if (result === null) {
                        callback(null)
                    }
                    else {
                        const data = result.map((item) => ({
                            value: item,
                            text: item,
                        }));
                        callback(data);
                    }
                }
            });
    };
    if (value) {
        timeout = setTimeout(fake, 300);
    } else {
        callback([]);
    }
};



const AddTransaction = ({ fields, type, closeModal }) => {
    const [messageApi, contextHolder] = message.useMessage();
    const [imoData, setImoData] = useState([]);
    const [voyageData, setVoyageData] = useState([]);
    const [imo_number, setImoNumber] = useState();
    const [voyage_number, setVoyage] = useState();
    const searchImoNumber = (newValue) => {
        const query = `api/get_suggestions/?registered_owner=CLDN Cobelfret Pte. Ltd.&keyword=${newValue}`
        fetch(newValue, setImoData, query);
    };

    const imoChangeHandler = (newValue) => {
        setImoNumber(newValue);
        const query = `api/get_suggested_voyages/?imo_number=${newValue}`
        fetch(newValue, setVoyageData, query);
    };


    const voyageChangeHandler = (newValue) => {
        setVoyage(newValue);
    };
    const key = 'updatable';
    const dispatch = useDispatch()
    const submitHandler = (values) => {
        console.log(values)
        const payload = {
            ...values,
            txn_amt: parseFloat(values.txn_amt),
            eua_volume: parseInt(values.eua_volume)
        }
        messageApi.open({
            key,
            type: 'loading',
            content: 'Loading...',
            duration: 0,
            onClick: () => {
                messageApi.destroy()
            }
        });
        switch (type) {
            case "transactions":
                dispatch(submitTransactions(payload)).then((result) => {
                    console.log(result)
                    if (result.payload) {
                        messageApi.open({
                            key,
                            type: 'success',
                            content: "Data Added Successfully",
                            duration: 5,
                            onClick: () => {
                                messageApi.destroy()
                            }
                        });
                        closeModal()
                    }
                    else {
                        console.log(result.error.message);
                        messageApi.open({
                            key,
                            type: 'error',
                            content: result.error.message,
                            duration: 5,
                            onClick: () => {
                                messageApi.destroy()
                            }
                        });
                    }
                })
                return
            case "dues":
                dispatch(submitDues(values)).then(() => {
                    closeModal()
                })
                return
            default:
                return;
        }
    }
    return (
        <div>
            {contextHolder}
            <Form
                name="normal_login"
                className="freemium-form"
                initialValues={{ remember: true }}
                size="large"
                layout="vertical"
                onFinish={submitHandler}
                style={{
                    width: "100%",
                }}
            >
                <div className="formContainerElements">
                    {fields.map((value, index) => {
                        if (value.name === "imo_number") {
                            return (
                                <Form.Item
                                    name={value.name}
                                    label={value.label}
                                    rules={value.message}
                                >
                                    <Select
                                        showSearch
                                        value={imo_number}
                                        name={value.name}
                                        placeholder={value.placeholder}
                                        style={{
                                            width: 200,
                                        }}
                                        defaultActiveFirstOption={false}
                                        suffixIcon={null}
                                        filterOption={false}
                                        onSearch={searchImoNumber}
                                        onChange={imoChangeHandler}
                                        onClear={() => { setImoNumber(null) }}
                                        notFoundContent={null}
                                        options={(imoData || []).map((d) => ({
                                            value: d.value.imo_number,
                                            label: d.value.imo_number,
                                        }))}
                                        allowClear
                                    />
                                </Form.Item>
                            )
                        }
                        else if (value.name === "voyage_number") {
                            return (
                                <Form.Item
                                    name={value.name}
                                    label={value.label}
                                    rules={value.message}
                                >
                                    <Select
                                        // showSearch
                                        value={voyage_number}
                                        name={value.name}
                                        placeholder={value.placeholder}
                                        style={{
                                            width: 200,
                                        }}
                                        defaultActiveFirstOption={false}
                                        suffixIcon={null}
                                        filterOption={false}
                                        // onSearch={searchVoyageNumber}
                                        onChange={voyageChangeHandler}
                                        onClear={() => { setVoyage(null) }}
                                        notFoundContent={null}
                                        options={(voyageData || []).map((d) => ({
                                            value: d.value,
                                            label: d.value,
                                        }))}
                                        allowClear
                                    />
                                </Form.Item>
                            )
                        }
                        return (
                            <Form.Item
                                name={value.name}
                                label={value.label}
                                rules={value.message}
                            >
                                <Input
                                    type={value.name.includes('date') ? "Date" : value.name.includes('txn_amt') || value.name.includes('eua_volume') ? "number" : ""}
                                    placeholder={value.placeholder}
                                />
                            </Form.Item>
                        )
                    })}
                </div>
                <div style={{ display: "flex", marginTop: "10px", justifyContent: "flex-end" }}>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

export default AddTransaction