import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeRequest } from "../../api/api";
// Thunk for fetching vessel data
export const fetchVesselData = createAsyncThunk(
  'vesselsAnalysis/fetchVesselData',
  async (params) => {
    const {
      imo_number = '',
      requested_page = 1,
      shipowner_name = '',
      vessel_name = ''
    } = params;

      const request = await makeRequest("get", `api/ems/fetch_vessel_data/?imo_number=${imo_number}&requested_page=${requested_page}&ship_owner=${shipowner_name}&vessel_name=${vessel_name}`);
      const response = await request.data;
    return response;
    } 
);


// Thunk for fetching document suggestions
export const fetchDocSuggestions = createAsyncThunk(
  'vesselsAnalysis/fetchDocSuggestions',
  async () => {
    const request = await makeRequest("get", `api/ems/get_doc_suggestion/`);
    return request.data.data;
  }
);

// Thunk for updating p-wind and p-prop
export const updatePwindPprop = createAsyncThunk(
  'vesselsAnalysis/updatePwindPprop',
  async (payload) => {
   const request = await makeRequest("patch", `api/ems/update_pwind_ppop/`, payload);
      return request.data.data;
  }
);

// Thunk for fetching document suggestions
export const deleteCasperID = createAsyncThunk(
  'vesselsAnalysis/deleteCasperID',
  async (params) => {
    const request = await makeRequest("PATCH", `api/ems/delete_casperid_rec/`, params);
    return request.data.data;
  }
);


const VesselsAnalysisSlice = createSlice({
  name: 'vesselsAnalysis',
  initialState: {
    status: 'idle',
    vessels: [],
    page: 1,
    total_pages: 0,
    vesselAnalysisFilters: {},
    fetchVesselDataStatus: 'idle',
    fetchVesselDataError: null,
    docSuggestions: [],
    docSuggestionsStatus: 'idle',
    updatePwindPpropStatus: 'idle',
    updatePwindPpropError: null,
  },
  reducers: {
    clearVessels: (state) => {
      state.page = 1;
      state.vessels = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetch vessel data
      .addCase(fetchVesselData.pending, (state) => {
        state.fetchVesselDataStatus = 'loading';
      })
      .addCase(fetchVesselData.fulfilled, (state, action) => {
        console.log(action.meta.arg)
        state.vesselAnalysisFilters = action.meta.arg || {};
        state.fetchVesselDataStatus = 'succeeded';
        state.vessels = action.payload.data || [];
        state.page = action.payload.page || 1;
        state.total_pages = action.payload.total_pages || 0;
      })
      .addCase(fetchVesselData.rejected, (state, action) => {
        state.fetchVesselDataStatus = 'failed';
        state.fetchVesselDataError = action.payload;
      })
      // Handle update p-wind and p-prop
      .addCase(updatePwindPprop.pending, (state) => {
        state.updatePwindPpropStatus = 'loading';
      })
      .addCase(updatePwindPprop.fulfilled, (state, action) => {
        state.updatePwindPpropStatus = 'succeeded';
        console.log(action)
      })
      .addCase(updatePwindPprop.rejected, (state, action) => {
        state.updatePwindPpropStatus = 'failed';
        state.updatePwindPpropError = action.payload;
        console.log(action)
      })
      .addCase(fetchDocSuggestions.pending, (state) => {
        state.docSuggestionsStatus = 'loading';
      })
      .addCase(fetchDocSuggestions.fulfilled, (state, action) => {
        state.docSuggestionsStatus = 'succeeded';
        state.docSuggestions = action.payload;
      })
      .addCase(fetchDocSuggestions.rejected, (state) => {
        state.docSuggestionsStatus = 'failed';
      });
  },
});

export const { clearVessels } = VesselsAnalysisSlice.actions;
export default VesselsAnalysisSlice.reducer;
