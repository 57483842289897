import React, { useEffect, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import * as d3 from "d3";

function ChartsComponent({ chartData, voyageGradient, chartId }) {
    // console.log(chartData)
    const { voyageCiiData } = useSelector(state => state.voyageOptimization)
    const chartContainerRef = useRef(null);
    // console.log(chartData)

    useEffect(() => {

        // console.log(voyageGradient)

        const containerId = `chart-container-${chartId}`;

        const yScaleMax = Math.max(...chartData.cii_y_axis) + 3 >= (voyageGradient[3] + 5) ? Math.max(...chartData.cii_y_axis) + 3 : (voyageGradient[3] + 5);
        // console.log(yScaleMax)
        const xScaleMax = Math.max(...chartData.distance_x_axis);
        // console.log(xScaleMax)


        // Polyline coordinates
        const polyLineCoord = [[0, chartData.cii_y_axis[0]], [chartData.distance_x_axis[0], chartData.cii_y_axis[0]], [chartData.distance_x_axis[1], chartData.cii_y_axis[1]], [chartData.distance_x_axis[2], chartData.cii_y_axis[2]], [chartData.distance_x_axis[3], chartData.cii_y_axis[3]]];

        // Overall Voyage CII coordinates
        const overallVoyageCIICoord = [0, chartData.cii_y_axis[3]];

        // Divide line coordinates
        const divideLineCoord = [[chartData.distance_x_axis[1], 0], [chartData.distance_x_axis[1], chartData.cii_y_axis[1]]];
        // Dimensions
        const parentWidth = chartContainerRef.current.clientWidth - chartContainerRef.current.clientWidth / 10;
        const margin = { top: 20, right: 20, bottom: 50, left: 50 };
        const width = parentWidth - margin.left - margin.right;
        const height = 250;
        // Scales
        const xScale = d3
            .scaleLinear()
            .domain([0, xScaleMax]) // Adjust as needed based on the data and polyline coordinates
            .range([0, width]);

        const yScale = d3
            .scaleLinear()
            .domain([0, yScaleMax]) // Adjust as needed
            .range([height, 0]);

        // Strip lines data
        const stripLines = [
            { start: voyageGradient[3], end: yScaleMax, text: '', color: 'rgb(255, 133, 133)', visible: true },
            { start: voyageGradient[2], end: voyageGradient[3], text: '', color: 'rgb(251, 212, 193)', visible: true },
            { start: voyageGradient[1], end: voyageGradient[2], text: '', color: 'rgb(249, 226, 143)', visible: true },
            { start: voyageGradient[0], end: voyageGradient[1], text: '', color: 'rgb(214, 233, 201)', visible: true },
            { start: 0, end: voyageGradient[0], text: '', color: 'rgb(113, 189, 126)', visible: true }
        ];


        // Select the existing chart container
        const chartContainer = d3.select(`#${containerId}`);
        // Remove existing SVG content
        chartContainer.selectAll("*").remove();

        // Create SVG container
        const svg = d3
            .select(`#${containerId}`) // Select by ID or any other selector
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);



        // X-axis
        svg.append("g")
            .attr("transform", `translate(0, ${height})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .tickValues(d3.range(0, xScaleMax, xScaleMax / 10)) // Specify tick values here
            )
            .append("text")
            .attr("x", width / 2)
            .attr("y", margin.bottom - 10)
            .attr("fill", "black")
            .style("text-anchor", "middle")
            .text("Voyage Days");

        // Y-axis
        svg.append("g")
            .call(d3.axisLeft(yScale).tickValues(d3.range(0, yScaleMax, yScaleMax / 10)))
            .append("text")
            .attr("transform", "rotate(-90)")
            .attr("x", -height / 2)
            .attr("y", -margin.left + 15)
            .attr("fill", "black")
            .style("text-anchor", "middle")
            .text("CII");

        // Line chart
        const line = d3
            .line()
            .x((d) => xScale(d.voyageDays))
            .y((d) => yScale(d.cii));



        // Add shaded rectangles based on stripLines data
        stripLines.forEach(stripLine => {
            if (stripLine.visible) {
                svg.append("rect")
                    .attr("x", 0)
                    .attr("y", yScale(stripLine.end))  // Use yScale for the end value
                    .attr("width", width)
                    .attr("height", yScale(stripLine.start) - yScale(stripLine.end))  // Adjust height based on yScale
                    .attr("fill", stripLine.color);
            }
        });


        // Polyline
        svg.append("polyline")
            .attr("points", polyLineCoord.map(point => `${xScale(point[0])},${yScale(point[1])}`).join(" "))
            .attr("fill", "none")
            .attr("stroke", "rgb(68, 84, 108)")
            .attr("stroke-width", 2);
        // Dashed red line
        svg.append("line")
            .attr("x1", xScale(overallVoyageCIICoord[0]))
            .attr("y1", yScale(overallVoyageCIICoord[1]))
            .attr("x2", width)
            .attr("y2", yScale(overallVoyageCIICoord[1]))
            .attr("stroke", "rgb(103 1 1)")
            .attr("stroke-dasharray", "5,5");

        // Text above dashed line
        svg.append("text")
            .attr("x", xScale(overallVoyageCIICoord[0]) + 5)
            .attr("y", yScale(overallVoyageCIICoord[1]) - 6)
            .text("Overall Voyage CII")
            .style("font-size", "10px")
            .attr("fill", "#ae0000");

        // Text for EUA to be surrended
        // svg.append("text")
        //     .attr("x", 100)
        //     .attr("y", -voyageGradient[3] + 2)
        //     .text("EUA to be surrendered: " + chartData.total_eua.toFixed(2))
        //     .style("font-size", "13px")
        //     .attr("fill", "black");

        // Dashed black line
        svg.append("line")
            .attr("x1", xScale(divideLineCoord[0][0]))
            .attr("y1", yScale(divideLineCoord[0][1]))
            .attr("x2", xScale(divideLineCoord[1][0]))
            .attr("y2", yScale(divideLineCoord[1][1]))
            .attr("stroke", "black")
            .attr("stroke-width", 1);

        // Text labels
        svg.append("text")
            .attr("x", xScale(divideLineCoord[0][0]) - 5)
            .attr("y", yScale(divideLineCoord[0][1]) - 10)
            .attr("fill", "black")
            .style("font-size", "10px")
            .style("text-anchor", "end")
            .html(function () {
                return `
            <tspan x="${xScale(divideLineCoord[0][0]) - 5}" dy="-1em">Ballast leg</tspan>
            <tspan x="${xScale(divideLineCoord[0][0]) - 5}" dy="1em">& Load port</tspan>
        `;
            });

        svg.append("text")
            .attr("x", xScale(divideLineCoord[1][0]) + 5)
            .attr("y", yScale(divideLineCoord[0][1]) - 10)
            .attr("fill", "black")
            .style("font-size", "10px")
            .style("text-anchor", "start")
            .html(function () {
                return `
                    <tspan x="${xScale(divideLineCoord[1][0]) + 5}" dy="-1em">Laden leg</tspan>
                    <tspan x="${xScale(divideLineCoord[1][0]) + 5}" dy="1em">& Discharge port</tspan>
                `;
            });

        // Cleanup
        return () => {
            svg.selectAll("*").remove();
        };
    }, [chartData]);

    return <>
        <p style={{ textAlign: "center", position: 'relative', top: "10px" }}>EUA to be surrendered: {chartData.total_eua.toFixed(2)}</p>
        <div ref={chartContainerRef} id={`chart-container-${chartId}`}></div>
    </>;
}

export default ChartsComponent;
