import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Table } from 'antd';

const TableDataStaticInfo = () => {
    const { voyageCiiData, ciiStatus } = useSelector(state => state.voyageOptimization)


    // console.log(voyageCiiData)

    return (
        <div>
            <table>
                <tr>
                    <th colSpan={2}>Key:</th>
                    {[...Array(5)].map((_, index) => (
                        <td style={{ width: "25px" }} key={index}
                            className={String.fromCharCode(65 + index) === 'A' ? 'classgreen' : String.fromCharCode(65 + index) === 'B' ? 'classSkyBlue' : String.fromCharCode(65 + index) === 'C' ? 'classOrange' : String.fromCharCode(65 + index) === 'D' ? 'classPink' : "classRed"}
                        >{String.fromCharCode(65 + index)}</td>
                    ))}
                    {/* <td colSpan={3}>Values in parenthesis indicate Laycan can be met. Vessel speed in ‘knots’.</td> */}

                </tr>

            </table>
            <table>

                {/* Last row */}
                <tr>
                    <th colSpan={2}>Note:</th>
                    <td colSpan={10}><span style={{ width: 'fit-content' }}>Values in parenthesis indicate Laycan can be met. Vessel speed in ‘knots’.</span></td>
                </tr>
            </table>
        </div>
    );
};

export default TableDataStaticInfo;