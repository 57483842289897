import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { makeRequest } from '../../api/api';

export const fetchVesselsList = createAsyncThunk('voyageOptimization/vessels', async () => {
    try {
        const response = await makeRequest("get", `voyage_optimization/vessel_list/`)
        return response.data;
    } catch (error) {
        throw error;
    }
});

export const getCIIInfo = createAsyncThunk(
    'voyageOptimization/CiiInfo',
    async (payload) => {
        // console.log(payload)
        const request = await makeRequest("post", `/voyage_optimization/get_cii/`, payload);
        // console.log(request.data.data)
        return request.data.data; // Return the entire response data
    }
);
export const postFuelTables = createAsyncThunk(
    'voyageOptimization/fuelTableInfo',
    async (payload) => {
        console.log(payload)
        const request = await makeRequest("post", `/voyage_optimization/save_fuel_info/`, payload);
        // console.log(request.data)
        return request.data; // Return the entire response data
    }
);

export const deleteFuelInfo = createAsyncThunk(
    'voyageOptimization/deleteFuelInfo',
    async(imo_number)=>{
        const request = await makeRequest("delete", `/voyage_optimization/delete_fuel_info/?imo_number=${imo_number}`);
        return request.data;
    }
)


const VoyageOptimizationSlice = createSlice({
    name: 'voyageOptimization',
    initialState: {
        vesselsJSON: {},
        status: 'idle',
        ciiStatus: 'idle',
        voyageCiiData: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchVesselsList.pending, (state) => {
                state.status = 'loading';
                state.vesselsJSON = {};
            })
            .addCase(fetchVesselsList.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.vesselsJSON = action.payload
            })
            .addCase(fetchVesselsList.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(getCIIInfo.pending, (state) => {
                state.ciiStatus = 'loading';
            })
            .addCase(getCIIInfo.fulfilled, (state, action) => {
                state.ciiStatus = 'succeeded';
                state.voyageCiiData = action.payload
            })
            .addCase(getCIIInfo.rejected, (state, action) => {
                state.ciiStatus = 'failed';
                state.error = action.error.message;
            })

    },
});

export default VoyageOptimizationSlice.reducer;
