import React from 'react';
import { Modal, Button } from 'antd';

const DeletingFuel = ({ isVisible, handleCancel, handleRemove, title, message }) => {
  return (
    <Modal
      title={title}
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button
          key="cancel"
          onClick={handleCancel}
          className='cancel-button-stepper'
        >
          Cancel
        </Button>,
        <Button
          key="remove"
          type="primary"
          onClick={handleRemove}
          style={{ backgroundColor: '#FB2F2F', color: 'white', border: 'none' }}
        >
          Remove
        </Button>,
      ]}
    >
      <p style={{ fontFamily: 'Aeonik TRIAL', fontWeight: 400, fontStyle: 'italic', fontSize: '12px', lineHeight: '13.68px', color: '#737373' }}>
        {message}
      </p>
    </Modal>
  );
};

export default DeletingFuel;
